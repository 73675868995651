import { get, del, post, put } from '@/services/http';
import { EmailReceiverList } from "@/collections/email_receivers";
import { EmailTemplateModel } from "@/models/email_template";
import { EmailReceiverModel } from '@/models/email_receiver';

export class ScheduleProfileModel {
  public id: number;
  public name: string;
  public outputType: string = 'email'; //email, slack, google_sheets
  public destination: string; //emails (email), slack_channel_id,  (email,google_drive_folder_id) (google_sheets), google_sheets_link (exist_google_sheets),
  public fileFormat: string = 'csv'; //csv, pdf, google_sheets, existed_google_sheets, existed_google_new_sheets
  public interval: string = 'daily';
  public deliveryDate: any;
  public deliveryWeekday: any;
  public deliveryHour: any;
  public timezone: string = '';
  public emails!: string[];
  public receivers: EmailReceiverList = new EmailReceiverList();
  public emailTemplate: EmailTemplateModel = new EmailTemplateModel();

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.outputType = data.outputType;
    this.destination = data.destination;
    this.fileFormat = data.fileFormat;
    this.interval = data.interval;
    this.timezone = data.timezone;
    this.deliveryDate = [];
    this.deliveryWeekday = [];
    this.deliveryHour = [];
    this.emails = data.emails || [];
    this.receivers = new EmailReceiverList();
    this.emailTemplate = new EmailTemplateModel();
  }

  public async fetch() {
    const res: any = await get(`/schedule/profile/${this.id}`);
    this.mapData(res.data);
  }

  public async save() {
    let destination: string = this.destination;
    if (this.outputType === 'email' && this.receivers && this.receivers.items.length > 0) {
      destination = this.receivers.items.map((receiver: any) => receiver.email).join(',');
    }
    if (this.outputType === 'email' && this.emails && this.emails.length > 0) {
      destination = this.emails.join(',');
    }
    const data = {
      name: this.name,
      outputType: this.outputType,
      destination: destination,
      fileFormat: this.fileFormat,
      interval: this.interval,
      timezone: this.timezone,
      deliveryDate: this.deliveryDate,
      deliveryWeekday: this.deliveryWeekday,
      deliveryHour: this.deliveryHour,
      receivers: (this.receivers && this.receivers.items.length > 0)? this.receivers.items.map((receiver: EmailReceiverModel) => receiver.id): null,
      emailTemplate: (this.emailTemplate? this.emailTemplate.id: null),
    }
    await post('/schedule/profile', data);
  }

  public async update() {
    let destination: string = this.destination;
    if (this.outputType === 'email' && this.receivers && this.receivers.items.length > 0) {
      destination = this.receivers.items.map((receiver: any) => receiver.email).join(',');
    }
    if (this.outputType === 'email' && this.emails && this.emails.length > 0) {
      destination = this.emails.join(',');
    }
    const data = {
      id: this.id,
      name: this.name,
      outputType: this.outputType,
      destination:  destination,
      fileFormat: this.fileFormat,
      interval: this.interval,
      timezone: this.timezone,
      deliveryDate: this.deliveryDate,
      deliveryWeekday: this.deliveryWeekday,
      deliveryHour: this.deliveryHour,
      receivers: (this.receivers && this.receivers.items.length > 0)? this.receivers.items.map((receiver: EmailReceiverModel) => receiver.id): null,
      emailTemplate: (this.emailTemplate? this.emailTemplate.id: null),
    }
    await put(`/schedule/profile/${this.id}`, data);
  }

  public async remove() {
    await del(`/schedule/profile/${this.id}`);
  }

  public mapData(data: any = {}) {
    const sortFunc = (a:number, b:number) => a - b;
    this.id = data.id;
    this.name = data.name;
    this.outputType = data.outputType;
    this.fileFormat = data.fileFormat;
    this.interval = data.interval;
    this.timezone = data.timezone;
    this.deliveryDate = this.refineDate(data.deliveryDate.sort(sortFunc));
    this.deliveryWeekday = data.deliveryWeekday.sort(sortFunc);
    this.deliveryHour = this.refineHour(data.deliveryHour.sort(sortFunc));
    this.destination = data.destination;
    if (this.outputType === 'email') {
      let emails: string = data.destination + '';
      this.emails = emails.split(',');
      this.receivers = new EmailReceiverList();
      this.receivers.mapData(data.receivers);
      if (data.emailTemplate) {
        this.emailTemplate = new EmailTemplateModel();
        this.emailTemplate.mapData(data.emailTemplate);
      }
    }
  }

  public getScheduleDate(day: string, dayOfMonth: string) {
    if (dayOfMonth && dayOfMonth !== '0') {
      return this.convertDayOfMonthToLabel(dayOfMonth);
    }
    return day;
  }

  public convertDayOfMonthToLabel(dayOfMonth: string) {
    switch (dayOfMonth) {
      case '-1':
        return 'Last day of Month';
      case '-2':
        return 'Day before end of Month';
      case '-3':
        return 'Two days before end of Month';
      default:
        return dayOfMonth;
    }
  }

  public refineHour(hours: any) {
    let strHours: string[] = [];
    for (const hour of hours) {
      if (Number(hour) < 10) {
        strHours.push('0' + Number(hour));
      } else {
        strHours.push('' + Number(hour));
      }
    }
    return strHours;
  }

  public refineDate(dates: any) {
    let strDates: string[] = [];
    for (const date of dates) {
      if (Number(date) < 10 && Number(date) > 0) {
        strDates.push('0' + Number(date));
      } else {
        strDates.push('' + Number(date));
      }
    }
    return strDates;
  }

}
