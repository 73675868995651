
import { Component, Vue, Prop } from 'vue-property-decorator';
import { CustomReportList } from '@/collections/custom_reports';
import { CustomReportModel } from '@/models/custom_report';
import { MeasureList } from '@/collections/measures';
import { MeasureModel } from '@/models/measure';
import HeaderTitle from '@/components/HeaderTitle.vue';
import ContentLoadResultMessage from '@/components/ContentLoadResultMessage.vue';
import { StoreModel } from '@/models/store';
import { RuleModel } from '@/models/rule';
import FeatureLockedNotify from '@/components/FeatureLockedNotify.vue';
import { TemplateReportList } from '@/collections/template_reports';
import LoadingSlider from '@/components/LoadingSlider.vue';
import { EventHandler } from '@/modules/events';
import EmptyStage from '@/components/EmptyStage.vue';
import ActionButton from '@/components/ActionButton.vue';
import DialogCreateReport from '../components/DialogCreateReport.vue';

interface GroupReport {
  measure: MeasureModel;
  reports: CustomReportList;
}

@Component({
  components: {
    HeaderTitle,
    ContentLoadResultMessage,
    FeatureLockedNotify,
    LoadingSlider,
    EmptyStage,
    ActionButton,
    DialogCreateReport,
  },
})
export default class CustomReports extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;
  @Prop({ default: () => new TemplateReportList() })
  public templateReports!: TemplateReportList;
  public customReports: CustomReportList = new CustomReportList();
  public groupReports: GroupReport[] = [];
  public groupReports1: GroupReport[] = [];
  public groupReports2: GroupReport[] = [];
  public loading: boolean = false;
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: false })
  public hasSchemas!: boolean;
  public rule: RuleModel = new RuleModel({ code: 'favorite_reports_count' });
  public addDefaultReportLoading: boolean = false;
  public loadingSlides: string[] = [
    'checking your profile',
    'fetching reports',
    'adding reports',
  ];
  public currentSelectingMeasure: string = '';
  public dialogDelete: boolean = false;
  public loadingDelete: boolean = false;
  public deletingReport: CustomReportModel = new CustomReportModel();
  public async created() {
    this.loading = true;
    for (const rule of this.store.currentPackage.rules.items) {
      if (rule.code === this.rule.code) {
        this.rule = rule;
        break;
      }
    }
    if (this.measures.items.length === 0) {
      await this.measures.fetch({ isOlap: true });
    }
    await this.customReports.fetch();
    if (this.customReports.size() === 0) {
      await this.addDefaultFavorites();
    }
    for (const measure of this.measures.items) {
      const reports = new CustomReportList();
      for (const report of this.customReports.items) {
        if (report.measure && report.measure.code === measure.code) {
          reports.add(report);
        }
      }
      if (reports.size() > 0) {
        this.groupReports.push({
          measure,
          reports,
        });
      }
    }

    this.loading = false;
  }

  public canAddCustomReport() {
    const validateValues: any = [
      {
        code: 'favorite_reports_count',
        value: this.customReports.size() + '',
      },

    ];
    const validateResult = this.store.validateRule(validateValues);
    return validateResult.isValidate;
  }

  public async fullLoadData() {
    this.loading = true;
    this.customReports = new CustomReportList();
    this.groupReports = [];
    await this.customReports.fetch();
    for (const measure of this.measures.items) {
      const reports = new CustomReportList();
      for (const report of this.customReports.items) {
        if (report.measure && report.measure.code === measure.code) {
          reports.add(report);
        }
      }
      if (reports.size() > 0) {
        this.groupReports.push({
          measure,
          reports,
        });
      }
    }
    this.loading = false;
  }

  public async deleteCustomReport() {
    this.loadingDelete = true;
    await this.deletingReport.del();
    this.deletingReport = new CustomReportModel();
    this.loadingDelete = false;
    this.dialogDelete = false;
    this.fullLoadData();
  }

  public getCustomReportLink(report: CustomReportModel) {
    return '/my-reports/' + report.id;
  }

  public async addDefaultFavorites() {
    const firstTimeAdd = localStorage.getItem('addFavorites') || 'false';
    if (firstTimeAdd !== 'false') {
      return;
    } else {
      localStorage.setItem('addFavorites', 'true');
    }
    this.addDefaultReportLoading = true;
    const purposes: any = [
      {
        purpose: 'Track sales reports',
        reports: [
          { id: 13 },
          { id: 10 },
          { id: 278 },
          { id: 283 },
          { id: 221 },
        ],
      },
      {
        purpose: 'Control Inventory',
        reports: [
          { id: 489 },
          { id: 313 },
          { id: 513 },
          { id: 293 },
          { id: 557 },
        ],
      },
      {
        purpose: 'Calculate cost & profits',
        reports: [
          { id: 473 },
          { id: 149 },
          { id: 180 },
          { id: 306 },
          { id: 324 },
        ],
      },
      {
        purpose: 'Track orders data',
        reports: [
          { id: 465 },
          { id: 517 },
          { id: 471 },
          { id: 395 },
          { id: 454 },
          { id: 545 },
          { id: 501 },
        ],
      },
      {
        purpose: 'Get customer insights',
        reports: [
          { id: 462 },
          { id: 454 },
          { id: 284 },
          { id: 38 },
          { id: 503 },
          { id: 224 },
        ],
      },
      {
        purpose: 'Analyze product performance',
        reports: [
          { id: 523 },
          { id: 436 },
          { id: 10 },
          { id: 25 },
          { id: 16 },
          { id: 456 },
          { id: 466 },
        ],
      },
      {
        purpose: 'Export data for 3rd system',
        reports: [
          { id: 517 },
          { id: 347 },
          { id: 220 },
          { id: 425 },
          { id: 473 },
        ],
      },
    ];
    const purpose = this.store.config.purpose;
    let defaultReports: any = [];
    for (const item of purposes) {
      if (item.purpose === purpose) {
        defaultReports = item.reports;
        break;
      }
    }
    if (defaultReports.length === 0) {
      defaultReports = purposes[0].reports;
    }
    for (const report of defaultReports) {
      for (const templateReport of this.templateReports.items) {
        if (templateReport.id === report.id) {
          const newCustomReport: CustomReportModel = await templateReport.duplicate();
          this.customReports.items.push(newCustomReport);
        }
      }
    }
    this.addDefaultReportLoading = false;
  }
}
