import { get } from '@/services/http';
import { EmailTemplateModel } from '@/models/email_template';
import List from './list';

export class EmailTemplateList extends List<EmailTemplateModel> {

  public async fetch() {
    const res: any = await get(`/email_templates`, {});
    const data = res.data || [];
    this.mapData(data.rows);
  }

  private mapData(data: any = []) {
    for (const s of data) {
      const template: EmailTemplateModel = new EmailTemplateModel();
      template.mapData(s);
      this.add(template);
    }
  }
}
