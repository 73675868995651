export class RelatedLinkModel {
  public name: string;
  public url: string;

  constructor(data: any = {}) {
    this.name = data.name;
    this.url = data.url;
  }

  public mapData(data: any = {}) {
    this.name = data.name;
    this.url = data.url;
  }
}
