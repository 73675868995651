import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VCalendar from 'v-calendar';
import CrispChat from '@dansmaculotte/vue-crisp-chat';
import VueI18n from 'vue-i18n';
import vGoogleTranslate from 'v-google-translate';

Vue.use(VCalendar, {
    componentPrefix: 'vc',
});
Vue.use(Vuetify);
Vue.use(CrispChat, {
    websiteId: 'ca19064a-172b-458f-a074-2eb3e445d473',
});
Vue.use(VueI18n);
Vue.use(vGoogleTranslate);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#00BCD4',
                secondary: '#ff9800',
                accent: '#EEEEEE',
                error: '#ff5722',
                warning: '#ffc107',
                info: '#795548',
                success: '#8bc34a',
                background: '#FAFAFA',
                dark_primary: '#007888',
                semi_dark_primary: '#00a0be',
                light_primary: '#99e4ed',
                gray: '#f5f5f5',
                deep_sku_blue: '#59c8e4',
                orange: '#faaf3b',
                dark_blue: '#12222D',
                charcoal_blue: '#2c4051',
                dark_gray: '#484d4f',
                black: '#000000',
                white: '#fff',
                lime_green: '#99be50',
                yellow: '#f9d085',
                pink: '#f6687b',
                green: '#33b528',
                purple: '#7657e4',
                turquoise_blue: '#16b8a9',
                light1: '#f4f4f4',
                light2: '#fefaf3',
                light3: '#e7f8f6',
                light4: '#f6f6f7',
                light5: '#dddddd',
                light6: '#f4f8ed',
            },
        },
        dark: false,
        options: {
            customProperties: true,
        },
    },
});
