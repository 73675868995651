
import { Component, Vue, Prop } from "vue-property-decorator";
import dateFormat from "dateformat";
import { StoreModel } from "@/models/store";
import moment from "moment";
import ActionButton from "@/components/ActionButton.vue";
import { EventBus } from "@/main";
import { save } from "@shopify/app-bridge/actions/ContextualSaveBar";
import { loadMore } from "@shopify/app-bridge/actions/Picker";

@Component({
  components: {
    ActionButton,
  },
})
export default class AddChatChannel extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public selectedChannel: string = "";
  public accountID: string = "";
  public accountName: string = "";
  public saveLoading: boolean = false;
  public channels = [
    { name: "WhatsApp", value: "whatsapp" },
    { name: "Telegram", value: "telegram" },
    { name: "Skype", value: "skype" },
    { name: "Microsoft Teams", value: "teams" },
  ];

  public created() {
    if (!this.store.config.chatChannels) {
      this.store.config.chatChannels = {
        channel: "",
        accountID: "",
        accountName: "",
      };
    }
    this.selectedChannel = this.store.config.chatChannels.channel;
    this.accountID = this.store.config.chatChannels.accountID;
    this.accountName = this.store.config.chatChannels.accountName;
  }
  public async saveChannel() {
    if (
      this.selectedChannel === "" ||
      this.accountID === "" ||
      this.accountName === ""
    ) {
      EventBus.$emit("show-snackbar", {
        message: "Please fill all the fields",
        color: "error",
      });
      return;
    }
    const chatChannel = {
      channel: this.selectedChannel,
      accountID: this.accountID,
      accountName: this.accountName,
    };
    this.store.config.chatChannels = chatChannel;
    try {
      this.saveLoading = true;
      await this.store.saveSettings();
      EventBus.$emit("show-snackbar", {
        message: "Add Chat Channel Successfully!",
        color: "success",
      });
    } catch (error: any) {
      EventBus.$emit("show-snackbar", {
        message: "Error while saving chat channel!",
        color: "error",
      });
    }
    this.saveLoading = false;
  }
}
