
import { Component, Prop, Vue } from 'vue-property-decorator';
import OnboardPage from '../components/Onboard.vue';
import { StoreModel } from '@/models/store';

@Component({
  components: {
    OnboardPage,
  },
})
export default class OnboardPurpose extends Vue {
  public purposes: any = [
    {value: 'Sales Tracking', selected: false},
    {value: 'Control Inventory', selected: false},
    {value: 'Inventory Replenishment', selected: false},
    {value: 'Inventory Planning', selected: false},
    {value: 'Sales Forecasting', selected: false},
    {value: 'Analyze product performance', selected: false},
  ];
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public loading: boolean = false;
  public error: boolean = false;
  public purposeRules: any = [
    this.isPurposeExist,
  ];
  public created() {
    localStorage.setItem('onboard_page', '/onboard-purpose');
    const selectedPurposes = this.store.config.purpose;
    let index: number = 0;
    if (!selectedPurposes  || selectedPurposes.length === 0) {
      return;
    }
    for (const purpose of this.purposes) {
      for (const selectedPurpose of selectedPurposes) {
        if (purpose.value === selectedPurpose) {
          this.purposes[index].selected = true;
        }
      }
      index = index + 1;
    }
  }

  public isPurposeExist(v: any) {
    return !!v || 'Purpose is required';
  }

  public async nextStep() {
    const selectedPurposes: any = [];
    for (const purpose of this.purposes) {
      if (purpose.selected) {
        selectedPurposes.push(purpose.value);
      }
    }

    if (selectedPurposes.length > 0) {
      this.store.config.purpose = selectedPurposes;
      this.error = false;
      this.loading = true;
      await this.store.saveSettings();
      this.loading = false;
      this.$router.push('/onboard-pricing');
    } else {
      this.error = true;
    }
  }
}
