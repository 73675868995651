import { get, post } from '@/services/http';
import { UserRoleModel } from '@/models/user_role';
import List from './list';

export class UserRolesList extends List<UserRoleModel> {

  public async fetch() {
    const res: any = await get(`/userroles`, {});
    const userRoles = res.data || [];
    for (const data of userRoles) {
      const userRole = new UserRoleModel();
      userRole.mapData(data);
      this.add(userRole);
    }
  }

  public getById(id: number) {
    for (const item of this.items) {
      if (item.id === id) {
        return item;
      }
    }
    return new UserRoleModel();
  }

}
