
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class SchedulerHourSelector extends Vue {
  @Prop({ default: [] })
  public initData!: string[];
  @Prop({ default: [] })
  public value!: string[];
  public selectedItems: string[] = [];
  public selectingItems: any[] = [];

  public created() {
    for (const item of this.initData) {
      this.selectingItems.push({
        key: item,
        value: false,
      });
    }
  }

  public selectMetricItem(item: any) {
    item.value = !item.value;
  }
}
