export default class List<T> {
  public items: T[];

  constructor() {
    this.items = [];
  }

  public size(): number {
    return this.items.length;
  }

  public add(value: T): void {
    this.items.push(value);
  }

  public get(index: number): T {
    return this.items[index];
  }

  public toArray(): T[] {
    return this.items;
  }
}
