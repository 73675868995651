
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import Selector from '../components/OptionSelector.vue';


@Component({
  components: {
    Selector,
  },
})
export default class SchedulerOutputDrive extends Vue {
  public connected: boolean = true;
  public fileFormats: any = [
    'CSV',
    'Excel',
    'PDF',
  ];
}
