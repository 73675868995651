
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import dateFormat from 'dateformat';
import { StoreModel } from '@/models/store';
import moment from 'moment';
import { firstContentfulPaint } from '@shopify/app-bridge/actions/WebVitals';

interface Range {
  start?: any;
  end?: any;
}

@Component({
  components: {},
})
export default class DateFilter extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: '' })
  public initRange!: string;
  @Prop({ default: false })
  public disabled!: boolean;
  public defaultRange: string = '';
  public dates: string[] = [];
  public range: Range = {};
  public dateRangeMenu: boolean = false;
  @Prop({default: false})
  public showDateRangeMenu!: boolean;
  @Prop({ default: [] })
  public value!: string[];
  @Prop({ default: () => [] })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  public customDateRange: string = '';
  public defaultRanges: any = [
    {
      text: 'Today',
      value: 'today',
    },
    
    {
      text: 'Week to date',
      value: 'week-to-date',
    },
    {
      text: 'Month to date',
      value: 'month-to-date',
    },
    {
      text: 'This year',
      value: 'year-to-date',
    },
    {
      text: 'Yesterday',
      value: 'yesterday',
    },
    {
      text: 'Last week',
      value: 'last-week',
    },
    {
      text: 'Last month',
      value: 'last-month',
    },
    {
      text: 'Last 3 months',
      value: '3m',
    },
    {
      text: 'Last 6 months',
      value: '6m',
    },
    {
      text: 'Last 12 months',
      value: '12m',
    },
    {
      text: 'Last 7 days',
      value: '7d',
    },
    {
      text: 'Last 14 days',
      value: '14d',
    },
    {
      text: 'Last 30 days',
      value: '30d',
    },
    {
      text: 'Last 90 days',
      value: '90d',
    },
    {
      text: 'Last 180 days',
      value: '180d',
    },
    {
      text: 'Last year',
      value: 'last-year',
    },
    {
      text: 'Last 2 years',
      value: '2y',
    },
    {
      text: 'Last 3 years',
      value: '3y',
    },
    {
      text: 'This fiscal year',
      value: 'this-fiscal-year',
    },
    {
      text: 'Last 7 days last year',
      value: '7d-last-year',
    },
    {
      text: 'Last 14 days last year',
      value: '14d-last-year',
    },
    {
      text: 'Last 30 days last year',
      value: '30d-last-year',
    },
    {
      text: 'Last 90 days last year',
      value: '90d-last-year',
    },
    {
      text: 'Last 180 days last year',
      value: '180d-last-year',
    },
    
  ];
  public created() {
    for (let i = 0; i < this.defaultRanges.length; i++) {
      const item = {
        text: this.defaultRanges[i].text,
        value: this.defaultRanges[i].value,
        disabled: this.defaultRanges[i].disabled,
      };
      this.defaultRanges[i] = item;
    }
  }

  public get minStartDate() {
    if (this.timeRangeLimit.length > 0) {
      if (
          this.timeRangeLimit.length > 1 &&
          this.timeRangeLimit[0] === this.timeRangeLimit[1]
      ) {
        return new Date();
      }
      if (!this.isAthenaFinished) {
        return new Date(this.timeRangeLimit[0]);
      }
    }
  }

  public isDisableRange(days: number) {
    return false;
    if (
      this.timeRangeLimit.length === 0 ||
      this.timeRangeLimit[0] === this.timeRangeLimit[1]
    ) {
      return true;
    }
    if (!this.isAthenaFinished) {
      const startRangeStr = this.getPastDate(days);
      if (this.isGreaterDate(this.timeRangeLimit[0], startRangeStr)) {
        return true;
      }
    }
    return false;
  }

  public isDisabledDateRange(timerange: string) {
    const range = this.convertTimeRange(timerange);
    const startDate = range.start;
    const endDate = range.end;
    if (startDate && endDate) {
      const days = endDate.diff(startDate, 'days');
      return this.isDisableRange(days);
    }
    return false;
  }

  public getPastDate(days: number) {
    const installedDate = new Date();
    const year = installedDate.getFullYear();
    const month = installedDate.getMonth();
    const day = installedDate.getDate() - days;
    return new Date(year, month, day).toDateString();
  }

  public toTimestamp(strDate: string = '') {
    let datum: number = 0;
    if (!strDate) {
      datum = Date.now();
    } else {
      datum = Date.parse(strDate);
    }
    return datum / 1000;
  }

  public isGreaterDate(firstDateStr: string, secondDateStr: string) {
    if (this.toTimestamp(firstDateStr) - this.toTimestamp(secondDateStr) > 0) {
      return true;
    } else {
      return false;
    }
  }

  public get customRangeText() {
    if (this.disabled) {
      return 'No Date Filter Available';
    }
    if (this.dates.length >= 2) {
      const startDateStr = moment(this.dates[0]).format('MMM D, YYYY');
      const endDateStr = moment(this.dates[1]).format('MMM D, YYYY');
      return `${startDateStr} - ${endDateStr}`;
    }
    if (this.value && this.value.length >= 2) {
      const startDateStr = moment(this.value[0]).format('MMM D, YYYY');
      const endDateStr = moment(this.value[1]).format('MMM D,  YYYY');
      return `${startDateStr} - ${endDateStr}`;
    }

    return 'custom';
  }

  private convertTimeRange(timerange: string) {
    const now = moment();
    let startDate;
    let endDate;
    switch (timerange) {
      case 'today':
        startDate = now;
        endDate = now;
        break;
      case 'yesterday':
        const yesterday = now.clone().subtract(1, 'days');
        startDate = yesterday;
        endDate = yesterday;
        break;
      case '7d':
        startDate = now.clone().subtract(7, 'days');
        endDate = now.clone().subtract(1, 'days');
        break;
      case '14d':
      startDate = now.clone().subtract(14, 'days');
      endDate = now.clone().subtract(1, 'days');
      break;
      case '3m':
        startDate = now.clone().subtract(3, 'months');
        endDate = now.clone().subtract(1, 'days');
        break;
      case '6m':
        startDate = now.clone().subtract(6, 'months');
        endDate = now.clone().subtract(1, 'days');
        break;
      case '12m':
        startDate = now.clone().subtract(1, 'years');
        endDate = now.clone().subtract(1, 'days');
        break;
        case '2y':
        startDate = now.clone().subtract(2, 'years');
        endDate = now.clone().subtract(1, 'days');
        break;
      case '3y':
        startDate = now.clone().subtract(3, 'years');
        endDate = now.clone().subtract(1, 'days');
        break;
      case 'week-to-date':
        startDate = now.clone().startOf('week');
        endDate = now.clone().endOf('day');
        break;
      case 'month-to-date':
        startDate = now.clone().startOf('month');
        endDate = now.clone().endOf('day');
        break;
      case 'year-to-date':
        startDate = now.clone().startOf('year');
        endDate = now.clone().endOf('day');
        break;
      case 'last-week':
        startDate = now.clone().subtract(1, 'week').startOf('week');
        endDate = now.clone().subtract(1, 'week').endOf('week');
        break;
      case 'last-month':
        startDate = now.clone().subtract(1, 'month').startOf('month');
        endDate = now.clone().subtract(1, 'month').endOf('month');
        break;
      case 'last-year':
        startDate = now.clone().subtract(1, 'year').startOf('year');
        endDate = now.clone().subtract(1, 'year').endOf('year');
        break;
      case 'this-fiscal-year':
        const fiscalYear = this.getFiscalYearTimestamps();
        startDate = fiscalYear.start;
        endDate = fiscalYear.end;
        break;
      case '7d-last-year':
        startDate = now.clone().subtract(1, 'year').subtract(7, 'days');
        endDate = now.clone().subtract(1, 'year').subtract(1, 'days');
        break;
      case '14d-last-year':
        startDate = now.clone().subtract(1, 'year').subtract(14, 'days');
        endDate = now.clone().subtract(1, 'year').subtract(1, 'days');
        break;
      case '30d-last-year':
        startDate = now.clone().subtract(1, 'year').subtract(30, 'days');
        endDate = now.clone().subtract(1, 'year').subtract(1, 'days');
        break;
      case '90d-last-year':
        startDate = now.clone().subtract(1, 'year').subtract(90, 'days');
        endDate = now.clone().subtract(1, 'year').subtract(1, 'days');
        break;
      case '180d-last-year':
        startDate = now.clone().subtract(1, 'year').subtract(180, 'days');
        endDate = now.clone().subtract(1, 'year').subtract(1, 'days');
        break;
      case '30d':
        startDate = now.clone().subtract(30, 'days');
        endDate = now.clone().subtract(1, 'days');
        break;
      case '90d':
        startDate = now.clone().subtract(90, 'days');
        endDate = now.clone().subtract(1, 'days');
        break;
      case '180d':
        startDate = now.clone().subtract(180, 'days');
        endDate = now.clone().subtract(1, 'days');
        break;
      case 'custom':
        break;
    }
    const range = {
      start: startDate,
      end: endDate,
    };
    return range;
  }

  public getFiscalYearTimestamps() {
    const fiscalYears: any = [
      {
        year: 2024,
        start: '2024-02-04',
        end: '2025-02-01',
      },
      {
        year: 2025,
        start: '2025-02-02',
        end: '2026-01-31',
      },
      {
        year: 2026,
        start: '2026-02-01',
        end: '2027-01-30',
      }
    ];
    const thisYear = moment().year();
    const fiscalYear = fiscalYears.find((fiscalYear: any) => {
      return thisYear === fiscalYear.year;
    });

    // Convert the start and end dates to moment date objects
    const start = moment(fiscalYear.start, 'YYYY-MM-DD');
    const end = moment(fiscalYear.end, 'YYYY-MM-DD');
    return { start, end };
  }

  @Watch('defaultRange', { immediate: true, deep: true })
  private async onDefaultRangeChanged(newVal: any, oldVal: any) {
    if (this.defaultRange) {
      this.range = this.convertTimeRange(this.defaultRange);
      this.customDateRange = this.defaultRange;
    }
  }

  @Watch('customDateRange', { immediate: true, deep: true })
  private async onCustomDateRangeChanged(newVal: any, oldVal: any) {
    if (!this.customDateRange || this.customDateRange === 'custom') {
      return;
    }
    this.defaultRange = this.customDateRange;
  }

  @Watch('dates', { immediate: true, deep: true })
  private async onDatesChanged(newVal: any) {
    if (this.dates.length < 2) {
      return;
    }
    this.$emit('input', this.dates);
  }

  @Watch('initRange', { immediate: true, deep: true })
  private async onInitRangeChanged(newVal: any) {
    this.defaultRange = this.initRange;
  }

  @Watch('range', { immediate: true, deep: true })
  private async onRangeChanged(newVal: any) {
    if (this.range.start && this.range.end) {
      if (this.customDateRange === 'custom') {
        this.dates = [
        dateFormat(this.range.start, 'yyyy-mm-dd'),
        dateFormat(this.range.end, 'yyyy-mm-dd'),
        this.customDateRange,
      ];
      } else {
        this.dates = [
        dateFormat(this.range.start, 'yyyy-mm-dd'),
        dateFormat(this.range.end, 'yyyy-mm-dd'),
        this.defaultRange,
      ];
      }
    } else {
      this.dates = [];
    }
    this.dateRangeMenu = false;
  }

  @Watch('showDateRangeMenu', { immediate: true, deep: true })
  private async onShowDateRangeMenuChange(newVal: any) {
    if (newVal && newVal === true) {
      this.dateRangeMenu = true;
    }
  }
}
