import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './sass/main.scss';
import VueI18n from 'vue-i18n';
import { messages, defaultLocale } from '@/i18n';

Vue.config.productionTip = false;
const i18n = new VueI18n({
  messages,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
});

export const EventBus = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
  data: {
    isLogined: false,
  },
  watch: {
    $route: () => {
      // @ts-ignore: Unreachable code error
      window.Appcues.page();
    },
  },
}).$mount('#app');
