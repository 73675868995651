import { get, post } from '@/services/http';
import { PluginPackageModel } from '@/models/plugin_package';
import List from './list';

export class PluginPackageList extends List<PluginPackageModel> {
  public mapData(data: any = []) {
    for (const item of data) {
      const pluginPackage = new PluginPackageModel();
      pluginPackage.mapData(item);
      this.add(pluginPackage);
    }
  }

  public async fetch(params: any = {}) {
    const res: any = await get(`/plugin_packages`, params);
    const pluginPackages = res.data || [];
    for (const data of pluginPackages) {
      const pluginPackage: PluginPackageModel = new PluginPackageModel();
      pluginPackage.mapData(data);
      this.add(pluginPackage);
    }
  }

  public async fetchByPackage(packageId: number) {
    const res: any = await get(`/plugin_packages/package/${packageId}`, {});
    const pluginPackages = res.data || [];
    for (const data of pluginPackages) {
      const pluginPackage: PluginPackageModel = new PluginPackageModel();
      pluginPackage.mapData(data);
      this.add(pluginPackage);
    }
  }

  public async fetchByPlugin(pluginId: number) {
    const res: any = await get(`/plugin_packages/${pluginId}`, {});
    const pluginPackages = res.data || [];
    for (const data of pluginPackages) {
      const pluginPackage: PluginPackageModel = new PluginPackageModel();
      pluginPackage.mapData(data);
      this.add(pluginPackage);
    }
  }
}
