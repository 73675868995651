
import { Component, Prop, Vue } from 'vue-property-decorator';
import dateFormat from 'dateformat';
import DataSyncWarning from '../components/DataSyncWarning.vue';
import { StoreModel } from '@/models/store';
import { EtlProcessModel } from '@/models/etl_process';
import { EventHandler } from '@/modules/events';
import { EventBus } from '@/main';
import FeatureLockedNotify from '@/components/FeatureLockedNotify.vue';
import ActionButton from '@/components/ActionButton.vue';

@Component({
  components: {
    DataSyncWarning,
    FeatureLockedNotify,
    ActionButton,
  },
})
export default class HeaderTitle extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: false })
  public hiddenEtlStatus!: boolean;
  public triggerDataDialog: boolean = false;
  public isLoggedInFromHades: boolean = false;

  public async created() {
    this.isLoggedInFromHades = localStorage.getItem('loggedInFromHades') === 'true';
    if (this.needToSyncData) {
      await this.triggerData();
    }
  }

  public get needToSyncData() {
    const now = Date.now();
    let lastTimeEtl = Date.parse(this.timeRangeLimit[1])
    let interval = this.timeRangeLimit[2];
    if (!this.timeRangeLimit[1]) {
      for (const etlStatus of this.store.etlStatuses.items) {
        if (etlStatus.resource === 'order_daily_sales_olap') {
          lastTimeEtl = Date.parse(etlStatus.lastUpdatedAt);
          interval = 'hourly'
        }
      }
    }
    if (interval === 'daily') {
      return now > lastTimeEtl + 24 * 60 * 60 * 1000 + 30 * 60 * 1000;
    } else {
      return now > lastTimeEtl + 90 * 60 * 1000;
    }
  }

  public async forceSync() {
    await this.triggerData();
    EventBus.$emit(
      'show-snackbar',
      'Data is syncing. This process can take some time!',
    );
  }

  public get dataSyncMessage() {
    const startUpdatedTimeStr: string = dateFormat(
      this.timeRangeLimit[1],
      'mmm d, yyyy HH:MM:ss',
      false
    );
    const lastUpdatedTimeStr: string = dateFormat(
      this.timeRangeLimit[1],
      'mmm d, yyyy HH:MM:ss',
      false
    );
    const interval = this.timeRangeLimit[2] === 'hourly' ? 'Hourly' : 'Daily';

    if (this.isAthenaFinished) {
      let preMsg = '';
      if (this.canForceTrigger) {
        preMsg += `${interval} synced report. `;
      }
      return `${preMsg}Last updated time: ${lastUpdatedTimeStr}`;
    }
    if (this.timeRangeLimit.length === 0 || this.timeRangeLimit[0] === this.timeRangeLimit[1]) {
      return 'Importing Data';
    }
    return 'The data importing is inprogress, completed from ' +
      startUpdatedTimeStr +
      ', to ' +
      lastUpdatedTimeStr;
  }

  public get isReportDataPage() {
    return [
      'customReport',
      'templateReportByType',
      'templateReport',
      'detailReportPage',
      'productExplore',
      'viewCardReport',
    ].includes(this.$route.name || '');
  }

  public get showStatus() {
    if (this.hiddenEtlStatus) {
      return false;
    }
    return !this.isAthenaFinished || this.isReportDataPage;
  }

  public formatDatetime(datetime: string, format: string = 'mmm d, yy') {
    return dateFormat(datetime, format, false);
  }

  public async triggerData() {
    const now = Date.now();
    let lastSyncDataTime;
    // @ts-ignore: Unreachable code error
    if (window.LAST_SYNC_DATA_TIME) {
      // @ts-ignore: Unreachable code error
      lastSyncDataTime = window.LAST_SYNC_DATA_TIME;
      if (now < lastSyncDataTime + 30 * 60 * 1000) {
        return false;
      }
    }

    const eventHandler = new EventHandler({
      store: this.store,
    });
    try {
      eventHandler.track('AUTO_TRIGGER_DATA');
    } catch (error: any) {
      // skipped
    }
    try {
      const etlProcess: EtlProcessModel = new EtlProcessModel();
      await etlProcess.trigger();
      // @ts-ignore: Unreachable code error
      window.LAST_SYNC_DATA_TIME = Date.now()
    } catch (error: any) {
      try {
        eventHandler.track('AUTO_TRIGGER_DATA_FAILED');
      } catch (error: any) {
        // skipped
      }
    }
    //this.triggerDataDialog = true;
  }

  public get canForceTrigger() {
    if (!this.isAthenaFinished) {
      return false;
    }
    if (!this.store.currentPackage.id) {
      return true;
    }
    for (const rule of this.store.currentPackage.rules.items) {
      if (rule.code === 'data_sync_schedule') {
        if (rule.value === 'hourly') {
          return true;
        }
      }
    }
    return false;
  }

  private async copyCurrentLink() {
    const shopify_store = localStorage.getItem('shopify_store') || '';
    const storeName = shopify_store.replace('.myshopify.com', '');
    let link = `https://admin.shopify.com/store/${storeName}/apps/assisty`;
    link += this.$route.path;
    navigator.clipboard.writeText(encodeURI(link));
    EventBus.$emit(
      'show-snackbar',
      'Link is copied',
    );
  }
}
