import { get } from '@/services/http';
import axios from 'axios';

export class ParamModel {
  public name: string;
  public code: string;
  public value: any[];
  public valueType: string;
  public dataType: string;
  public listValues!: any[];
  public sortOrder: number = 0;
  public NeedApiRequest: boolean = false;
  public source?: any;
  public loading: boolean = false;
  public default: any = [];
  constructor(data: any = {}) {
    this.name = data.name;
    this.code = data.code;
    this.value = data.value;
    this.valueType = data.valueType;
    this.dataType = data.dataType;
    this.listValues = data.listValues;
    this.sortOrder = data.sortOrder;
    this.NeedApiRequest = data.NeedApiRequest;
    this.default = data.default;
  }

  public mapData(data: any = {}) {
    this.name = data.name;
    this.code = data.code;
    if (data.value) {
      this.value = data.value;
    } else if (data.default_value) {
      if (!this.value) {
        this.value = [];
      }
      this.value.push(data.default_value);
    }

    if (data.sort_order) {
      this.sortOrder = data.sort_order;
    }
    this.valueType = data.value_type || data.valueType;
    this.dataType = data.data_type || data.dataType;
    if (data.dropdown_values && data.dropdown_values.length > 0) {
      this.listValues = data.dropdown_values;
    }
    this.NeedApiRequest = data.need_api_request || data.NeedApiRequest;
    this.default = data.default;
  }

  public async fetch(params = {}) {

    if (true) {
      if (!this.source) {
        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
      }
      if (this.loading) {
        this.source.cancel();
        this.loading = false;
        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
      }
      this.loading = true;
      const res: any = await get(`/params/${this.code}`, params, this.source.token);
      this.loading = false;
      // check if the res.data contain a value with name = Size or not, if not, add and object {id: 'SIZE', name: 'Size'}
      let values = res.data;
      if (this.code === 'variant_option_title') {
        let defaultValue = {id: 'SIZE', name: 'Size'};
        let isExist = false;
        for (let i = 0; i < values.length; i++) {
          if (values[i].id === 'SIZE') {
            isExist = true;
            break;
          }
        }
        if (!isExist) {
          values.push(defaultValue);
        }
      }
      return values;
    }
    return [];
  }
}
