import dateFormat from 'dateformat';
import { get, post, put, del } from '@/services/http';

export class EmailTemplateModel {
  public id: number;
  public merchantId: number;
  public title: string
  public content: string
  public status: string // DRAFT, ACTIVE, INACTIVE
  public createdAt?: string;
  public updatedAt?: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.merchantId = data.merchantId;
    this.title = data.title;
    this.content = data.content;
    this.status = data.status;
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.merchantId = data.merchant_id;
    this.title = data.title;
    this.content = data.content;
    this.status = data.status;
    this.createdAt = dateFormat(data.createdAt, 'mmm d, yyyy HH:MM:ss', false);
    this.updatedAt = dateFormat(data.updatedAt, 'mmm d, yyyy HH:MM:ss', false);
  }

  public async fetch() {
    const res: any = await get(`/email_templates/${this.id}`);
    this.mapData(res.data);
  }

  public async create() {
    const res: any = await post('/email_templates', {
      merchantId: this.merchantId,
      title: this.title,
      content: this.content,
      status: this.status || 'INACTIVE',
    });
    this.mapData(res.data);
  }

  public async test(receiverId: number) {
    await post(`/email_templates/${this.id}/test`, {
      receiver_id: receiverId,
      merchantId: this.merchantId,
      title: this.title,
      content: this.content,
      status: this.status
    });
  }

  public async update() {
    const res: any = await put(`/email_templates/${this.id}`, {
      id: this.id,
      merchantId: this.merchantId,
      title: this.title,
      content: this.content,
      status: this.status
    });
    this.mapData(res.data);
  }

  public async active() {
    const res: any = await put(`/email_templates/${this.id}/active`, {});
    this.mapData(res.data);
  }

  public async deactive() {
    const res: any = await put(`/email_templates/${this.id}/inactive`, {});
    this.mapData(res.data);
  }

  public async remove() {
    await del(`/email_templates/${this.id}`);
  }
}
