import { get, post, put } from '@/services/http';
import { PluginRuleList } from '@/collections/plugin_rules';
import { PluginRuleModel } from './plugin_rule';

export class UserPluginModel {
  public id: number;
  public merchantId: number;
  public pluginId: number;
  public price: number;
  public discountId?: number;
  public discountPercentage?: number;
  public discountAmount?: number;
  public installedAt: string;
  public activedAt?: string;
  public uninstalledAt?: string;
  public rules?: PluginRuleList;


  constructor(data: any = {}) {
    this.id = data.id;
    this.merchantId = data.merchantId;
    this.pluginId = data.pluginId;
    this.price = data.price || 0;
    this.discountId = data.discountId || 0;
    this.discountPercentage = data.discountPercentage || 0;
    this.discountAmount = data.discountAmount;
    this.installedAt = data.installedAt;
    this.activedAt = data.activatedAt;
    this.uninstalledAt = data.uninstalledAt;
    this.rules = new PluginRuleList();
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.merchantId = data.merchantId;
    this.pluginId = data.pluginId;
    this.price = data.price || 0;
    this.discountId = data.discountId || 0;
    this.discountPercentage = data.discountPercentage || 0;
    this.discountAmount = data.discountAmount;
    this.installedAt = data.installedAt;
    this.activedAt = data.activatedAt;
    this.uninstalledAt = data.uninstalledAt;
    if (data.rules && data.rules.items) {
      this.rules = this.mapRuleData(data.rules.items);
    }
  }

  public mapRuleData(ruleData: any = []) {
    const pluginRules: PluginRuleList = new PluginRuleList();
    for (const data of ruleData) {
      const pluginRule: PluginRuleModel = new PluginRuleModel();
      pluginRule.mapData(data);
      pluginRules.add(pluginRule);
    }
    return pluginRules;
  }

  public async create() {
    const res: any = await post(`/user_plugin/create`, {
      pluginId: this.pluginId,
      merchantId: this.merchantId,
      price: this.price,
      discountId: this.discountId,
      discountPercentage: this.discountPercentage,
      discountAmount: this.discountAmount,
      installedAt: this.installedAt,
      activedAt: this.activedAt,
      uninstalledAt: this.uninstalledAt,
      rules: this.rules,
      status: 'ACTIVE',
    });
    this.mapData(res.data.userPlugin);
  }

  public async uninstall(id: number) {
    const res: any = await put(`/user_plugin/${id}/inactive`);
  }
}
