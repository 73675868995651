import { get } from '@/services/http';
import { StoreConnectionModel } from '@/models/store_connection';
import List from './list';

export class StoreConnectionRequestList extends List<StoreConnectionModel> {
  public async fetch() {
    const res: any = await get(`/multistores/list_request`);
    this.items = [];
    this.mapData(res.data);
  }

  public mapData(data: any = []) {
    for (const s of data) {
      const storeConnection = new StoreConnectionModel();
      storeConnection.mapData(s);
      this.add(storeConnection);
    }
  }
}
