
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { get, put } from '@/services/http';
import ActionButton from '@/components/ActionButton.vue';

@Component({
  components: {
    ActionButton,
  },
})
export default class WelcomeContact extends Vue {
  public success: boolean = false;
  public error: boolean = false;
  public loading: boolean = false;
  public installing: boolean = false;
  public contactEmail: string = '';
  public contactPhone: string = '';
  public contactName: string = '';
  public productCategory: string = '';
  public topReports: string = '';
  @Prop({ default: '/' })
  public nextPage!: string;
  @Prop({default: true})
  public redirect!: boolean;
  @Prop({default: true})
  public editMode!: boolean;
  public contactData: any = {contactEmail: '', phone: ''};
  public emailRules: any = [
    this.isEmailExist,
    this.isEmailValidate,
  ];
  public nameRules: any = [
    this.isNameExist,
  ];
  public phoneRules: any = [
    this.isPhoneExist,
  ];
  public productCategories: any = [
    'Style and Fashion',
    'Apparel and Accessories',
    'Sporting goods',
    'Consumer electronic and Appliance',
    'Sporting goods',
    'Health and Fitness',
    'Home and Kitchen',
    'Food & Beverage',
    'Jewelry and Luxury goods',
    'Entertainment and recreation',
    'Chiren & Infants',
    'Business & Industrial',
    'Other',
  ];
  public isNameExist(v: any) {
    return !!v || 'Name is required';
  }

  public isEmailExist(v: any) {
    return !!v || 'E-mail is required';
  }

  public isPhoneExist(v: any) {
    return !!v || 'Phone is required';
  }

  public isEmailValidate(v: any) {
    return /.+@.+/.test(v) || 'E-mail must be valid';
  }

  public async created() {
    this.loading = false;
    this.success = true;
    await this.getContact();
  }

  public async saveContact() {
    this.editMode = false;
    const storeSettingURL = `/settings`;
    await put(storeSettingURL, {
      contactName: this.contactName,
      contactEmail: this.contactEmail,
      phone: this.contactPhone,
      productCategory: this.productCategory,
      topReports: this.topReports,
    });
    await this.updateInstallationStatus();
    window.location.href = '/';
    return;
  }

  public async getContact() {
    const res: any = await get('/settings');
    this.contactName = res.data.contactName;
    this.contactEmail = res.data.contactEmail;
    this.contactPhone = res.data.phone;
    this.productCategory = res.data.productCategory;
    this.topReports = res.data.topReports;
  }

  public changeEditMode() {
    this.editMode = !this.editMode;
  }

  private async updateInstallationStatus() {
    let storeID: number = 0;
    const res: any = await get('/stores/detail');
    storeID = res.data.id;
    await put(`/stores/${storeID}/installing_status`);
  }
}
