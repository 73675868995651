
import { Component, Prop, Vue } from 'vue-property-decorator';
import ChatWithUs from '../components/ChatWithUs.vue';
import { StoreModel } from '@/models/store';
import StatusPageComponent from '@/components/StatusPage.vue';

@Component({
  components: {
    ChatWithUs,
    StatusPageComponent,
  },
})
export default class StatusPage extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];

  public statusCode: string|number = 'network_error';

  public async created() {
    this.statusCode = this.$route.params.code;
  }
}
