
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {get, post, put} from '@/services/http';
import { StoreModel } from '@/models/store';
import ActionButton from '@/components/ActionButton.vue';
import Alert from '@/components/Alert.vue';
import {UserPluginList} from '@/collections/user_plugins';
import BaseComponent from "@/components/BaseComponent.vue";

@Component({
  components: {
    BaseComponent,
    ActionButton,
    Alert,
  },
})
export default class PluginConfiguration extends BaseComponent {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({default: ''})
  public pluginCode!: string;

  public success: boolean = false;
  public error: boolean = false;
  public loading: boolean = false;

  public loadingPlugin: boolean = false;
  public loadingTriggerEtlPlugin: boolean = false;
  public pluginApiKey: string = '';

  public snackbar: boolean = false;
  public snackbarTimeout: number = 3000;
  public snackbarText: string = '';

  public apiKeyRules: any = [
    this.isApiKeyExist,
  ];

  public async created() {
    this.loading = false;
    this.success = true;
    await this.getConfig(this.pluginCode);
  }

  public isApiKeyExist(v: any) {
    return !!v || 'API key is required';
  }

  public async getConfig(plugin: string) {
    let pluginId = null;
    let configName = null;
    if (plugin === 'stocky') {
      pluginId = '24';
      configName = 'stocky_api_key';
    } else if (plugin === 'mimoran') {
      pluginId = '25';
      configName = 'mimoran_api_key';
    }
    const apiUrl = `/plugin/${pluginId}/config`;
    const response: any = await get(apiUrl, {
      name: configName,
    });
    if (response.status === true && response.data.length > 0) {
      this.pluginApiKey = response.data[0].value;
    }
  }

  public async saveConfig(plugin: string) {
    let pluginId = null;
    let configs = null;
    this.loadingPlugin = true;
    this.pluginApiKey = this.pluginApiKey.trim();
    if (!this.pluginApiKey || this.loading) {
      return;
    }
    if (plugin === 'stocky') {
      pluginId = '24';
      configs = [{
        name: 'stocky_api_key',
        value: this.pluginApiKey,
      }];
    } else if (plugin === 'mimoran') {
      pluginId = '25';
      configs = [{
        name: 'mimoran_api_key',
        value: this.pluginApiKey,
      }];
    }
    const apiUrl = `/plugin/${pluginId}/config`;
    const response: any = await put(apiUrl, {configs});
    if (response.status === true) {
      this.snackbarText = 'We are updating the data, please wait until it is ready';
    } else {
      this.snackbarText = 'Something went wrong, please try again later';
    }
    this.snackbar = true;
    this.loading = false;

    this.loadingPlugin = false;
  }

  public async triggerUpdateData(plugin: string) {
    let pluginId = null;
    this.loadingTriggerEtlPlugin = true;
    this.pluginApiKey = this.pluginApiKey.trim();
    if (!this.pluginApiKey || this.loading) {
      return;
    }
    if (plugin === 'stocky') {
      pluginId = '24';
    } else if (plugin === 'mimoran') {
      pluginId = '25';
    }
    const apiUrl = `/plugin/${pluginId}/trigger_etl`;
    const response: any = await get(apiUrl, {});
    if (response.status === true) {
      this.snackbarText = 'We are updating the data, please wait until it is ready';
    } else {
      this.snackbarText = 'Something went wrong, please try again later';
    }
    this.snackbar = true;
    this.loading = false;
    this.loadingTriggerEtlPlugin = false;
  }

}
