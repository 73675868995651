
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { MeasureModel } from '@/models/measure';
import { MeasureList } from '@/collections/measures';

@Component({
  components: {
  },
})
export default class MeasureSelector extends Vue {
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;
  @Prop({ default: null })
  public initMeasure!: MeasureModel;
  @Prop({ default: null })
  public value!: MeasureModel|null;
  public measure: MeasureModel = new MeasureModel();
  public measureName: string = '';
  public selectingMeasures: MeasureList = new MeasureList();
  public measureMenu: boolean = false;
  @Prop({default: false})
  public disabled!: boolean;
  public keyword: string = '';

  public selectMeasure() {
    this.$emit('measure-changed', this.measure);
    this.measureName = this.measure.name;
    this.$emit('input', this.measure);
  }

  @Watch('keyword', { immediate: true, deep: true  })
  private async onKeywordChanged(newVal: any) {
    if (this.keyword === '') {
      this.selectingMeasures.items = this.measures.items;
      return;
    } else {
      const search = new RegExp(this.keyword , 'i');
      this.selectingMeasures.items = this.measures.items.filter(
        (item) => search.test(item.name),
      );
    }
  }

  @Watch('initMeasure', { immediate: true, deep: true  })
  private async onInitMeasureChanged(newVal: any) {
    this.measure = this.initMeasure;
    this.measureName = this.initMeasure.name;
    this.selectingMeasures.items = this.measures.items;
  }
}
