
import { Component, Vue, Prop } from 'vue-property-decorator';
import { StoreModel } from '@/models/store';
import { EventHandler } from "@/modules/events";


@Component({
  components: {
  },
})
export default class AppcueTrigger extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: 'link' })
  public type!: string; //link, button or icon
  @Prop({ default: '' })
  public flowName!: string;
  @Prop({ default: 'Show Hint' })
  public displayText!: string;
  @Prop({ default: false })
  public trialOnly!: boolean;
  @Prop({ default: 'primary' })
  public color!: string;
  @Prop({ default: 'white' })
  public textColor!: string;
  public flows: any = [
    {
      name: 'replenishment-setting-overview',
      id: 'b8c68bf6-7dcf-40d3-a6f4-a59f42a023a5',
    },
    {
      name: 'report_detail_filter',
      id: '187a0562-2088-48c4-8f0e-892e8aa5ad72',
    },
    {
      name: 'report_detail_layout',
      id: 'a06ae154-30b7-4d0d-a174-8c648394dff6',
    }
   
  ];

  public get flowId() {
    const flow = this.flows.find((f: any) => f.name === this.flowName);
    return flow ? flow.id : '';
  }

  public triggerAppcue() {
    const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track("Trigger Appcue Flow", {
        name: this.flowName,
        id: this.flowId,
      });

    // @ts-ignore: Unreachable code error
    window.Appcues.show(this.flowId);
  }

  public get canShowFlow() {
    if (!this.trialOnly) {
      return true;
    }
    if (this.store.isTrial()) {
      return true;
    }
    return false;
  }
}
