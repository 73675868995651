
import { Component, Prop, Vue } from 'vue-property-decorator';
import HeaderTitle from '../components/HeaderTitle.vue';
import ChatWithUs from '../components/ChatWithUs.vue';
import { UserModel } from '@/models/user';
import { UsersList } from '@/collections/users';
import { StoreModel } from '@/models/store';
import { RuleModel } from '@/models/rule';
import { UserRoleModel } from '@/models/user_role';
import { UserRolesList } from '@/collections/user_roles';
import FeatureLockedNotify from '@/components/FeatureLockedNotify.vue';
import StatusPageComponent from '@/components/StatusPage.vue';
import { DimensionModel } from '@/models/dimension';
import { FilterDimensionList } from '@/collections/filter_dimensions';
import SidebarMenu from '../components/SidebarMenu.vue';
import {displayAlert} from "@/services/alert";
import ActionButton from '../components/ActionButton.vue';

@Component({
  components: {
    HeaderTitle,
    ChatWithUs,
    FeatureLockedNotify,
    StatusPageComponent,
    SidebarMenu,
    ActionButton,
  },
})
export default class Users extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];

  public success: boolean = false;
  public error: boolean = false;
  public isLoading: boolean = false;
  public userHeaders: any = [];
  public users: UsersList = new UsersList();
  public dialogAddUser: boolean = false;
  public dialogEditUser: boolean = false;
  public dialogDeleteUser: boolean = false;
  public dialogChangeStatus: boolean = false;
  public dialogResetPassword: boolean = false;
  public resetPasswordSuccess: boolean = false;
  public resetPasswordSuccessMessage: string = '';
  public createUserErrorMessage: string =
    'Please input information for the new user.';
  public email: string = '';
  public password: string = '';
  public loading: boolean = false;
  public hasPermission: boolean = true;
  public newUserAccount: UserModel = new UserModel();
  public currentSelectedUser: UserModel = new UserModel();
  public errorMessage: string = '';
  public rule: RuleModel = new RuleModel({ code: 'multi_user_login' });
  public emailRules: any = [this.isEmailExist, this.isEmailValidate];
  public nameRules: any = [this.isNameExist];
  public passwordRules: any = [this.isPasswordMin8, this.isPasswordExist];
  public userRoles: UserRolesList = new UserRolesList();
  public storeLocationSelect: any = [];
  public storeLocation: string = '';
  public async created() {
    this.isLoading = true;
    this.userHeaders = [
      {
        text: this.$t('user_management.Name') + '',
        value: 'name',
        width: '25%',
      },
      {
        text: this.$t('user_management.Email') + '',
        value: 'email',
        align: 'center',
      },
      {
        text: this.$t('user_management.Role') + '',
        value: 'userroleName',
        align: 'center',
      },
      {
        text: this.$t('user_management.Status') + '',
        value: 'status',
        align: 'center',
      },
      {
        text: this.$t('user_management.Action') + '',
        value: 'action',
        width: '25%',
        align: 'center',
      },
    ];
    for (const rule of this.store.currentPackage.rules.items) {
      if (rule.code === this.rule.code) {
        this.rule = rule;
        break;
      }
    }
    try {
      await this.users.fetch();
      await this.userRoles.fetch();
      await this.loadFilterDimensionListValue();
    } catch (e: any) {
      if (e.response.status === 403) {
        this.hasPermission = false;
      }
    }
    this.isLoading = false;
  }
  public isNameExist(v: any) {
    return !!v || this.$t('user_management.Name is required');
  }

  public isEmailExist(v: any) {
    return !!v || this.$t('user_management.E-mail is required');
  }

  public isPasswordExist(v: any) {
    return !!v || this.$t('user_management.Password is required');
  }

  public isPasswordMin8(v: any) {
    if (v.length < 8) {
      return this.$t('user_management.Password must be at least 8 characters');
    }
    return true;
  }

  public isEmailValidate(v: any) {
    return /.+@.+/.test(v) || this.$t('user_management.E-mail must be valid');
  }

  public checkEmailValidate(v: any) {
    return /.+@.+/.test(v);
  }

  public validateNewUserData() {
    if (
      !this.newUserAccount.name ||
      !this.newUserAccount.email ||
      !this.newUserAccount.password ||
      !this.isEmailValidate(this.newUserAccount.email) ||
      !this.isPasswordMin8(this.newUserAccount.password)
    ) {
      return false;
    }
    return true;
  }

  public showAddNewUserDialog() {
    this.newUserAccount = new UserModel();
    this.dialogAddUser = true;
  }

  public async createUser() {
    // add new user
    this.error = false;
    this.loading = true;
    if (this.validateNewUserData()) {
      try {
        if (this.storeLocation) {
          const userRole = new UserRoleModel();
          await userRole.save([this.storeLocation]);
          this.newUserAccount.userroleId = userRole.id;
          this.userRoles.add(userRole);
        }
        await this.newUserAccount.save();
        this.users.add(this.newUserAccount);
        this.dialogAddUser = false;
        displayAlert(`Created user ${this.newUserAccount.name} successfully`);
      } catch (e: any) {
        this.error = true;
        if (e.response.status === 400) {
          this.createUserErrorMessage = 'It exists';
        } else {
          this.createUserErrorMessage = 'Can not create this user';
        }
      }
    } else {
      this.error = true;
    }
    this.loading = false;
  }

  public async saveUser() {
    const userRole = this.userRoles.getById(
      this.currentSelectedUser.userroleId,
    );
    const stores = userRole.getAccessableStoreLocations();
    if (
      this.storeLocation &&
      (!stores.length || (stores.length && this.storeLocation !== stores[0]))
    ) {
      await userRole.saveNew([this.storeLocation]);
      this.currentSelectedUser.userroleId = userRole.id;
    }
    this.currentSelectedUser.update();
    this.dialogEditUser = false;
    displayAlert('Updated user successfully');
  }

  public async ShowChangeUserStatusDialog(user: any) {
    // change status user
    this.dialogChangeStatus = true;
    this.currentSelectedUser = user;
  }

  public async changeUserStatus() {
    // change status user
    this.loading = true;
    if (this.currentSelectedUser.isActive) {
      await this.currentSelectedUser.deactive();
    } else {
      await this.currentSelectedUser.active();
    }
    this.loading = false;
    this.dialogChangeStatus = false;
  }

  public showEditUserDialog(user: UserModel) {
    // edit user
    this.currentSelectedUser = user;
    if (this.currentSelectedUser.userroleId) {
      const userRole = this.userRoles.getById(
        this.currentSelectedUser.userroleId,
      );
      const stores = userRole.getAccessableStoreLocations();
      if (stores.length) {
        this.storeLocation = stores[0];
      }
    }
    this.dialogEditUser = true;
  }

  public editUser() {
    //
  }

  public showResetPasswordDialog(user: UserModel) {
    // reset password
    this.dialogResetPassword = true;
    this.currentSelectedUser = user;
  }

  public async resetPassword() {
    // reset password
    this.loading = true;
    await this.currentSelectedUser.resetPassword();
    this.resetPasswordSuccess = true;
    this.resetPasswordSuccessMessage = 'Reset Password Successfully';
    displayAlert(this.resetPasswordSuccessMessage);
    this.loading = false;
    await new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
    this.dialogResetPassword = false;
  }

  public showDeleteUserDialog(user: UserModel) {
    this.currentSelectedUser = user;
    this.dialogDeleteUser = true;
  }

  public async deleteUser() {
    // delete user
    this.loading = true;
    await this.currentSelectedUser.remove();
    this.isLoading = true;
    this.users = new UsersList();
    await this.users.fetch();
    this.loading = false;
    this.isLoading = false;
    this.dialogDeleteUser = false;
    displayAlert(`Deleted user successfully`);
  }

  public getListUsers() {
    const userList: any[] = [];
    for (const user of this.users.items) {
      user.userroleName = this.userRoles.getById(user.userroleId).name
        ? this.userRoles.getById(user.userroleId).name
        : '-';
    }
    return this.users.items;
  }

  public async loadFilterDimensionListValue() {
    try {
      const dimensionValues = new FilterDimensionList();
      dimensionValues.dimension = this.getDimensionByCode();
      await dimensionValues.fetch();
      this.storeLocationSelect.push({ name: 'ALL', value: 'ALL' });
      for (const item of dimensionValues.toArray()) {
        this.storeLocationSelect.push({
          name: item.name,
          value: item.name,
        });
      }
    } catch {
      this.storeLocationSelect = [];
    }
  }

  public getDimensionByCode() {
    const dimension = new DimensionModel();
    dimension.code = 'by_location';
    dimension.fieldCode = 'Location[Location]';
    return dimension;
  }

  public canAddMultipleUsers() {
    const validateValues: any = [
      {
        code: 'multi_user_login',
        value: this.users.size() + '',
      },
    ];
    const validateResult = this.store.validateRule(validateValues);
    return validateResult.isValidate;
  }
}
