
import { Component, Prop, Vue } from 'vue-property-decorator';
import { get, post } from '@/services/http';
import ActionButton from '@/components/ActionButton.vue';
import Alert from '@/components/Alert.vue';

@Component({
  components: {
    ActionButton,
    Alert,
  },
})
export default class GoogleDriveSettings extends Vue {
  public success: boolean = false;
  public error: boolean = false;
  public loading: boolean = false;
  public snackbar: boolean = false;
  public snackbarTimeout: number = 3000;
  public snackbarText: string = '';

  public accounts: any[] = [];

  public async created() {
    await this.getAccounts();

    this.loading = false;
    this.success = true;
  }

  public async getAccounts() {
    const apiUrl = `/token/google_oauth`;
    const response: any = await get(apiUrl, {});
    if (response.hasOwnProperty('data')) {
      Vue.set(this, 'accounts', response.data);
    }
  }

  public async revokeAccount(email: string) {
    const apiUrl = `/token/google_oauth/revoke`;
    const response: any = await post(apiUrl, {email});

    await this.getAccounts();

    this.snackbarText = response.message;
    this.snackbar = true;
    this.loading = false;
  }

}
