
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import { get, post } from '@/services/http';
import { StoreModel } from '@/models/store';
import ActionButton from '@/components/ActionButton.vue';
import Alert from '@/components/Alert.vue';
import {EventBus} from '@/main';
import { isShopifyEmbedded } from '@shopify/app-bridge/utilities';

// Declare global objects
declare const gapi: any;
declare const google: any;

@Component({
  components: {
    ActionButton,
    Alert,
  },
})
export default class GoogleDriveSelector extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public success: boolean = false;
  public error: boolean = false;
  public loading: boolean = false;
  public snackbar: boolean = false;
  public snackbarTimeout: number = 3000;
  public snackbarText: string = '';

  @Prop({ default: '' })
  public initSelectedAccount!: string;
  @Prop({ default: '' })
  public initSelectedDriveFolder!: string;

  public accounts: any[] = [];
  public selectedAccount: string = '';
  public selectedDriveFolder: string = '';
  public selectedDriveFolderName: string = '';
  private pickerApiLoaded: boolean = false;


  public async created() {
    this.loading = true;
    if (this.initSelectedAccount) {
      this.selectedAccount = this.initSelectedAccount;
    }
    if (this.initSelectedDriveFolder) {
      this.selectedDriveFolder = this.initSelectedDriveFolder;
    }
    await this.getAccounts();
    await this.handleClientLoad();

    this.loading = false;
    this.success = true;
  }

  public async getAccounts() {
    const apiUrl = `/token/google_oauth`;
    const response: any = await get(apiUrl, {});
    if (response.hasOwnProperty('data')) {
      const options_ = [];
      for (const item of response.data) {
        options_.push({
          text: item.email,
          value: item.email,
        });
      }
      this.accounts = options_;
      if (!this.selectedAccount && options_.length > 0) {
        this.selectedAccount = options_[0]['value'];
      }
    }
  }

  public async remove(item: any) {
    this.selectedAccount = ''; // You can adjust this logic based on your use case
    this.accounts = this.accounts.filter(i => i.value !== item.value);
    const emailToRemove = item.value;
    const apiUrl = `/token/google_oauth/revoke`;
    try {
      const response: any = await post(apiUrl, {email: emailToRemove});
    } catch (e) {
      console.log(e);
    }
  };

  public async selectFolderDrive() {
    if (!this.selectedAccount) {
      return;
    }
    const response: any = await get('/token/google_oauth/access_token', {email: this.selectedAccount});
    const oauthToken = response.data.access_token;

    if (this.pickerApiLoaded && oauthToken) {
      let origin = null;
      if (isShopifyEmbedded()) {
        origin = 'https://admin.shopify.com';
      } else {
        origin = `${process.env.VUE_APP_HOST}`;
      }

      const picker = new google.picker.PickerBuilder()
          .addView(new google.picker.DocsView(google.picker.ViewId.FOLDERS).setParent('root').setSelectFolderEnabled(true))
          .setSelectableMimeTypes('application/vnd.google-apps.folder')
          .setOAuthToken(oauthToken)
          .setCallback(this.pickerDriveCallback)
          .setOrigin(origin)
          .build();
      picker.setVisible(true);
    }
  }

  public async connectGoogleAccount() {
    sessionStorage.setItem('current_full_path', this.$route.fullPath);
    EventBus.$emit('redirect-to-google-auth');
  }

  private handleClientLoad() {
    gapi.load('picker', { callback: () => {
        this.pickerApiLoaded = true;
      }});
  }

  private pickerDriveCallback(data: any) {
    if (data.action === google.picker.Action.PICKED) {
      const selectedFile = data.docs[0];
      const folderId = selectedFile.id;
      const folderName = selectedFile.name;
      this.selectedDriveFolder = folderId;
      this.selectedDriveFolderName = folderName;
    }
  }

  @Watch('selectedAccount', { immediate: true, deep: true  })
  private async onSelectedAccountChanged(newVal: any, oldVal: any) {
    // if (!this.selectedAccount) { return; }
    this.$emit('updateSelectedAccount', this.selectedAccount);
  }

  @Watch('selectedDriveFolder', { immediate: true, deep: true  })
  private async onSelectedDriveFolderChanged(newVal: any, oldVal: any) {
    if (!this.selectedDriveFolder) { return; }
    this.$emit('updateSelectedDriveFolder', this.selectedDriveFolder);
  }

}
