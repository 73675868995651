
import { Component, Vue, Prop } from 'vue-property-decorator';
import { MeasureList } from '@/collections/measures';
import { StoreModel } from '@/models/store';
import { MeasureModel } from '@/models/measure';

@Component({
  components: {
  },
})
export default class EtlStatusNote extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({default: () => new MeasureList()})
  public measures!: MeasureList;
  @Prop({ default: () => new MeasureModel() })
  public measure!: MeasureModel;
  @Prop({})
  public measureCode!: string;
  public resource: string = '';
  public startTime: string = '';
  public endTime: string = '';
  public limitStartTime: string = '';
  public apiCount: number = 0;
  public modelCount: number = 0;

  public created() {
    if (!this.measure || !this.measure.code || this.measure.code === undefined) {
      for (const measure of this.measures.items) {
        if (measure.code === this.measureCode) {
          this.measure = measure;
          break;
        }
      }
    }
    for (const dataResource of this.measure.dataResources) {
      if (dataResource.name.includes('inventory') || dataResource.name.includes('product')) {
        this.resource = 'products';
        if (this.store.productCount) {
          this.modelCount = this.store.productCount.modelCount;
          this.apiCount = this.store.productCount.apiCount;
        }
        break;
      }
      this.resource = 'orders';
      if (this.store.orderCount) {
        this.modelCount = this.store.orderCount.modelCount;
        this.apiCount = this.store.orderCount.apiCount;
      }
      break;
    }
  }
}
