
import { Component, Prop, Vue } from 'vue-property-decorator';
import HeaderTitle from '@/components/HeaderTitle.vue';
import ActionButton from '@/components/ActionButton.vue';
import { SpreadsheetTemplateModel } from '@/models/spreadsheet_template';
import { StoreModel } from '@/models/store';
import { SpreadsheetTemplateList } from "@/collections/spreadsheet_templates";
import GoogleDriveSelector from '@/components/GoogleDriveSelector.vue';
import { SpreadsheetConnectedModel } from '@/models/spreadsheet_connected';
import { EventBus } from "@/main";
@Component({
  components: {
    HeaderTitle,
    ActionButton,
    GoogleDriveSelector,
  },
})
export default class SpreadsheetTemplates extends Vue {
  @Prop({ default: null })
  public app!: any;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public isLoading = false;
  public spreadsheetTemplates = new SpreadsheetTemplateList();
  public templateHeaders: any = [
    { text: 'Name', value: 'name' },
    { text: 'Description', value: 'description' },
    { text: 'Actions', value: 'actions', sortable: false },
  ];
  public editingTemplate: SpreadsheetTemplateModel = new SpreadsheetTemplateModel();
  public connectedSheet: SpreadsheetConnectedModel = new SpreadsheetConnectedModel();
  public dialogCloneTemplate: boolean = false;
  public selectedGoogleAccount: string = '';
  public selectedDriveFolder: string = '';
  public cloneTemplateLoading: boolean = false;
  public connectSuccess: boolean = false;
  public userEmail: string = '';
  public async created() {
    this.isLoading = true;
    await this.spreadsheetTemplates.fetch();
    this.isLoading = false;
  }
  public async cloneTemplate() {
    this.cloneTemplateLoading = true;
    this.connectSuccess = false;
    try {
      const newSpreadsheet: any = await this.editingTemplate.clone(this.userEmail, this.selectedDriveFolder);
      if (newSpreadsheet) {
        const res: any = await this.editingTemplate.createSchedule(newSpreadsheet, this.store.config.timezone);
        if (res.data) {
          const connectedSheet = new SpreadsheetConnectedModel(res.data);
          connectedSheet.refreshData();
        }
        EventBus.$emit("show-snackbar", 
        {
          message: "The "+ this.editingTemplate.name + "spreadsheet data has been refreshed successfully.",
          color: "success",
        });
        this.connectSuccess = true;
      }
    } catch (e) {
      this.cloneTemplateLoading = false;
      this.connectSuccess = false;
      EventBus.$emit("show-snackbar", 
      {
        message: "Failed to clone the spreadsheet template.",
        color: "error",
      });
    }
    this.cloneTemplateLoading = false;
  }
  public showCloneDialog(item: SpreadsheetTemplateModel) {
    this.editingTemplate = item;
    this.dialogCloneTemplate = true;
  }

  public viewConnectedSpreadsheet() {
    this.$router.push('/spreadsheet-connected');
  }

  public get isPaidUser() {
    const isPaid: boolean = this.store.currentPackage.name !== 'Free';
    return isPaid;
  }
}
