import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { get, post, put, del } from '@/services/http';
import { ReportCardList } from '@/collections/report_cards';
import { ReportCardModel } from '@/models/report_card';
import { MeasureList } from '@/collections/measures';
import { FunnelEventModel } from '@/models/funnelEvent';


export class DashboardModel {
  public id: number;
  public name: string;
  public type: string;
  public merchantId?: number;
  public reportCardList: ReportCardList = new ReportCardList();
  public lockBoards: any = [
    {
      id: 117,
      name: 'Business Overview',
      isLock: false,
      description: 'Snapshot of Key Business Metrics and Performance Indicators',
      notice: '',
      formula: '',
    },
    {
      id: 1606,
      name: 'Replenishment Metrics',
      isLock: true,
      description: 'Insights into Inventory Replenishment and Stock Management',
      notice: '',
      formula: '',
    },
    {
      id: 336,
      name: 'Sales Metrics',
      isLock: false,
      description: 'Comprehensive Overview of Sales Data and Performance Trends',
      notice: '',
      formula: '',
    },
    {
      id: 335,
      name: 'Finance Metrics',
      isLock: false,
      description: 'Detailed Analysis of Financial Health and Key Financial Indicators',
      notice: '',
      formula: '',
    },
    {
      id: 337,
      name: 'Inventory Metrics',
      isLock: true,
      description: 'Current Status and Analysis of Inventory Levels and Turnover',
      notice: '',
      formula: '',
    },
    {
      id: 64,
      name: 'Sales Overview',
      isLock: false,
      description: 'Summary of Sales Activity, Trends, and Revenue Performance',
      notice: '',
      formula: '',
    },
    {
      id: 87,
      name: 'Daily Sales',
      isLock: false,
      description: 'Daily Sales Figures and Trends Analysis',
      notice: '',
      formula: '',
    },
    {
      id: 339,
      name: 'Sales by Geography',
      isLock: false,
      description: 'Regional Sales Breakdown and Geographic Performance Insights',
      notice: '',
      formula: '',
    },
    {
      id: 342,
      name: 'Sales by Location',
      isLock: false,
      description: 'Detailed Sales Data and Trends by Individual Locations',
      notice: '',
      formula: '',
    },
    {
      id: 343,
      name: 'POS Sales',
      isLock: false,
      description: 'Point of Sale Transactions and Performance Analysis',
      notice: '',
      formula: '',
    },
    {
      id: 346,
      name: 'Product Sales',
      isLock: false,
      description: 'Product-wise Sales Analysis and Performance Metrics',
      notice: '',
      formula: '',
    },
    {
      id: 348,
      name: 'Inventory Sales Matching',
      isLock: false,
      description: 'Alignment and Comparison of Inventory Levels with Sales Data',
      notice: '',
      formula: '',
    },
    {
      id: 349,
      name: 'Product Profitability',
      isLock: false,
      description: 'Analysis of Profit Margins and Profitability by Product',
      notice: '',
      formula: '',
    },
    {
      id: 347,
      name: 'Product Return',
      isLock: false,
      description: 'Overview and Analysis of Product Return Rates and Patterns',
      notice: '',
      formula: '',
    },
    {
      id: 350,
      name: 'Inventory On-hand',
      isLock: false,
      description: 'Current Status and Evaluation of On-hand Inventory Quantities',
    },
    {
      id: 355,
      name: 'Low Stock',
      isLock: false,
      description: 'Alerts and Analysis of Products Approaching Low Stock Levels',
      notice: '',
      formula: [
        'This dashboard is designed to alert you about products and variants that are at risk of running out of stock. Here is how we determine which items make it to this list:',
        '1. <b>Days of Inventory Remaining</b>: We calculate the number of days each product or variant is expected to last, based on current stock levels. Items with less than 7 days of inventory remaining are flagged.',
        '2. <b>Sales History Analysis</b>: We analyze sales data for each product or variant over the last 90 days. Only those items that have recorded sales during this period are considered, as they are actively moving and at risk of stockout.',
        '3. <b>Customizable Historical Period for Sales</b>: You have the flexibility to adjust the historical period for sales analysis. By default, it is set to the last 90 days, but you can configure this setting to suit your specific inventory monitoring needs.',
      ],
    },
    {
      id: 356,
      name: 'Overstock',
      isLock: false,
      description: 'The "Overstock" dashboard identifies items with over 30 days of inventory, signaling potential excess based on recent sales.',
      notice: '',
      formula: [
        'This is tailored to identify products and variants in your inventory that may be excessively stocked. Here is our approach to determining which items are considered overstocked:',
        '1. <b>Days of Inventory Remaining</b>: We focus on the projected duration that each product or variant will last, given the current stock levels. Items are flagged as overstocked if they have more than 30 days of inventory remaining. This threshold indicates a potentially excessive stock level that might lead to increased carrying costs or stock obsolescence.',
        '2. <b>Recent Sales Activity</b>: We review the sales data for each product or variant over a user-configurable historical period (defaulted to the last 90 days). This step is crucial to ensure that we are considering items that have had some sales activity, but not enough to justify their current stock levels.',
        '3. <b>User-Defined Historical Sales Period</b>: You have the ability to adjust the historical sales analysis period. While the default setting is the past 90 days, this can be customized to better align with your unique inventory management strategies and sales cycles.',
      ],
    },
    {
      id: 357,
      name: 'Out-of-Stock',
      isLock: false,
      description: 'Identification and Analysis of Currently Out-of-Stock Items',
      notice: '',
      formula: [
        'Out of Stock by Product or Variant: This report lists items whose current on-hand inventory is less than or equal to 0 and are marked as "active" in the system. It identifies products and variants that are completely out of stock.',
        'Sold Out Product or Variant: For this report, we identify items with an inventory level of 0 or less, which have also recorded sales within the selected date range (default is the last 30 days). This is determined using the "last sales date" field, focusing on items that have recently sold out.',
      ],
    },
    {
      id: 358,
      name: 'Inventory Remaning Days',
      isLock: true,
      description: 'Estimation of Days Remaining Before Inventory Depletion',
      notice: '',
      formula: '',
    },
    {
      id: 351,
      name: 'Inventory Movement',
      isLock: true,
      description: 'Tracking and Analysis of Inventory Movement',
      notice: 'Dashboard data is available from the date of app installation onwards. Data prior to this date cannot be retrieved from Shopify.',
      formula: '',
    },
    {
      id: 352,
      name: 'Month End Inventory',
      isLock: true,
      description: 'This is the description for month end inventory',
      notice: 'Dashboard data is available from the date of app installation onwards. Data prior to this date cannot be retrieved from Shopify.',
      formula: [],
    },
    {
      id: 359,
      name: 'Slow Moving Product',
      isLock: false,
      description: 'Identification and Analysis of Products with Low Sales Velocity',
      notice: 'Dashboard data is available from the date of app installation onwards. Data prior to this date cannot be retrieved from Shopify.',
      formula: '',
    },
    {
      id: 360,
      name: 'Dead Stock',
      isLock: true,
      description: 'Overview of Non-Moving Inventory Classified as Dead Stock',
      notice: 'Dashboard data is available from the date of app installation onwards. Data prior to this date cannot be retrieved from Shopify.',
      formula: '',
    },
    {
      id: 364,
      name: 'Aging Stock',
      isLock: true,
      description: 'Analysis of Inventory Aging and Long-Term Stock Accumulation',
      notice: 'Dashboard data is available from the date of app installation onwards. Data prior to this date cannot be retrieved from Shopify.',
      formula: [
        'The report calculates the age of inventory items based on the number of days that have elapsed since the last inventory was received.',
        '1. <b>dentify the Last Received Date</b>: For each inventory item, determine the most recent date when it was received into stock.',
        '2. <b>Calculate the Current Date</b>: Establish the current date on which the report is being run.',
        '3. <b>Determine the Age of Each Inventory Item:</b> For each item, subtract the last received date from the current date. This difference gives the number of days the item has been in stock since it was last received.',
      ],
    },
    {
      id: 365,
      name: 'Inventory Value',
      isLock: false,
      description: 'Current Monetary Value of Total Inventory Holdings',
      notice: 'Dashboard data is available from the date of app installation onwards. Data prior to this date cannot be retrieved from Shopify.',
      formula: '',
    },
    {
      id: 366,
      name: 'Sell-through Rate',
      isLock: true,
      description: 'This dashboard displays sell-through rates, key for assessing sales performance and inventory.',
      notice: 'Dashboard data is available from the date of app installation onwards. Data prior to this date cannot be retrieved from Shopify.',
      formula: [
        '<b>Sell-through Rate Formula</b>: Sell-Through Rate=(Total Units Sold x 100)/(Beginning Quantity + Units Received)',
        '1. <b>Total Units Sold</b>: Sum the units sold during the specified period.',
        '2. <b>Beginning Quantity</b>: Determine the quantity of inventory at the start of the period.',
        '3. <b>Units Received</b>: Count the total units of inventory received during the same period.',
        '4. <b>Calculate the Rate</b>: Multiply the total units sold by 100, then divide by the sum of the beginning quantity and units received to get the percentage.',
      ],
    },
    {
      id: 367,
      name: 'Inventory Turnover',
      isLock: true,
      description: 'The Inventory Turnover Rate dashboard simplifies tracking how often inventory is sold and replenished.',
      notice: 'Dashboard data is available from the date of app installation onwards. Data prior to this date cannot be retrieved from Shopify.',
      formula: [
        '<b>Inventory Turnover Rate Formula</b>: Inventory Turnover Rate= (Cost of Goods Sold (COGS)) / (Average Inventory)',
        '1. <b>Cost of Goods Sold (COGS)</b>: Calculate the total cost of goods sold during the period. This includes the direct costs attributable to the production of the goods sold by a company.',
        '2. <b>Average Inventory</b>: Determine the average inventory for the period. This is typically calculated by adding the beginning inventory to the ending inventory for the time frame, then dividing by two.',
        '3. <b>Calculate the Rate</b>: Divide the COGS by the average inventory. This results in the inventory turnover rate, which indicates how many times inventory is sold and replaced over the period.',
      ],
    },
    {
      id: 368,
      name: 'Inventory To Sales',
      isLock: true,
      description: 'The Inventory Sales Ratio dashboard offers a clear perspective on sales compared to average inventory value.',
      notice: 'Dashboard data is available from the date of app installation onwards. Data prior to this date cannot be retrieved from Shopify.',
      formula: [
        '<b>Inventory to Sales Ratio Formula</b>',
        'Inventory to Sales Ratio = (Average Inventory Value)/(Net Sales)',
        '1. <b>Average Inventory Value</b>: Calculate the average value of inventory over a specific period. This is typically done by adding the inventory value at the beginning and at the end of the period, then dividing by two.',
        '2. <b>Net Sales</b>: Determine the total net sales value during the same period. Net sales are calculated by subtracting any returns or allowances from total gross sales.',
        '3. <b>Calculate the Ratio</b>: Divide the average inventory value by the net sales. This ratio illustrates the relationship between the inventory held and the actual sales made, shedding light on inventory efficiency.',
      ],
    },
    {
      id: 371,
      name: 'Stock Availability',
      isLock: true,
      description: 'This dashboard displays the percentage of days items are in stock, offering a quick snapshot of inventory consistency and availability.',
      notice: 'Dashboard data is available from the date of app installation onwards. Data prior to this date cannot be retrieved from Shopify.',
      formula: [
        '<b>Stock Availability Formula</b>',
        '<b>Stock Avaibility</b> = (Number of In-stock Days) * 100/( Total Observed Days)',
        '1. <b>Number of In-Stock Days</b>: Count the total number of days an item has been available in stock during the observed period.',
        '2. <b>Total Observed Days</b>: Determine the total number of days in the same period for which the stock status is being analyzed.',
        '3. <b>Calculate the Percentage</b>: Divide the number of in-stock days by the total observed days, and multiply by 100 to convert it into a percentage.',
      ],
    },
    {
      id: 372,
      name: 'Stockout Revenue Analysis',
      isLock: true,
      description: 'The "Stockout Revenue Analysis" dashboard quantifies potential revenue loss from stockouts, highlighting their impact on sales and guiding inventory optimization.',
      notice: 'Dashboard data is available from the date of app installation onwards. Data prior to this date cannot be retrieved from Shopify.',
      formula: [
        '<b>Stockout Revenue Loss Calculation with Out of Stock Rate Threshold</b>',
        '1. <b>Define Out of Stock Rate Threshold</b>: Set a threshold rate. Products with an out of stock rate higher than this threshold are considered unrecoverable and excluded from further revenue loss analysis.',
        '2. <b>Out of Stock Rate Calculation</b>: For each product, calculate its out of stock rate as the ratio of stockout days to total days in the observed period.',
        '3. <b>Estimate Recoverable Lost Sales</b>: For products below the threshold, estimate potential sales based on historical sales data or average sales, assuming these products are recoverable and can be restocked.',
        '4. <b>Calculate Recoverable Revenue Loss</b>: Multiply the estimated recoverable sales by the product\'s retail price to determine the recoverable revenue loss.',
      ],
    },
    {
      id: 361,
      name: 'Just-in-Time Replenishment Model',
      isLock: true,
      description: 'Analysis and Insights on Implementing a Just-in-Time Inventory Replenishment Strategy',
      notice: '',
      formula: '',
    },
    {
      id: 363,
      name: 'Historical Sales-Based Forecasting',
      isLock: true,
      description: 'Analyzes past sales data to estimate future inventory needs by calculating product sale speeds from historical sales trends.',
      notice: '',
      formula: '',
    },
    {
      id: 373,
      name: 'Inventory Movement-Based Forecasting',
      isLock: true,
      description: 'Estimates sale speed and future stock requirements by tracking inventory changes, useful when complete sales data is unavailable.',
      notice: 'Dashboard data is available from the date of app installation onwards. Data prior to this date cannot be retrieved from Shopify.',
      formula: [
        '<b>Inventory Movement-Based Forecasting Calculation</b>',
        '1. <b>Track Inventory Movements</b>: Monitor and record changes in inventory levels over a specific period, noting reductions due to sales and increases from restocking.',
        '2. <b>Calculate Average Daily Inventory Movement</b>: Determine the average rate at which inventory decreases daily, indicating the average daily sale speed. This is done by dividing the total inventory reduction by the number of days in the observed period.',
        '3. <b>Forecast Future Inventory Needs</b>: Project future stock requirements based on the calculated average daily inventory movement. Extend this rate into the future (for a chosen forecast period) to estimate the quantity of inventory needed to meet expected sales demand without full sales data.',
      ],
    },
    {
      id: 819,
      name: 'Replenishment Master Board',
      isLock: true,
      description: 'Navigate your inventory replenishment journey with ease, using this dashboard to monitor, forecast, and manage your stock levels all in one place.',
      notice: '',
      formula: '',
    },
  ];
  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.type = data.type;
  }

  public async create() {
    const res: any = await post('/dashboards', {
      name: this.name,
    });
    this.mapData(res.data);
    try {
      const funnelEvent = new FunnelEventModel({
        name: 'CREATED_DASHBOARD',
      });
      funnelEvent.create();
    } catch (error) {
      // skipped
    }
  }

  public async publishDashboard() {
    if (this.type !== 'PUBLIC') {
      const res: any = await put('dashboards/' + this.id + '/public');
      this.type = 'PUBLIC';
    } else {
      const res: any = await put('dashboards/' + this.id + '/private');
      this.type = 'PRIVATE';
    }
  }

  public async publishSystem() {
    if (this.type !== 'SYSTEM') {
      const res: any = await put('dashboards/' + this.id + '/system');
      this.type = 'SYSTEM';
    } else {
      const res: any = await put('dashboards/' + this.id + '/private');
      this.type = 'PRIVATE';
    }
  }

  public async copy() {
    const res: any = await post('/published_dashboards/' + this.id + '/copy');
    this.mapData(res.data.dashboard);
    try {
      const funnelEvent = new FunnelEventModel({
        name: 'CREATED_DASHBOARD',
      });
      funnelEvent.create();
    } catch (error) {
      // skipped
    }
  }

  public async update() {
    const res: any = await put('/dashboards/' + this.id, {
      name: this.name,
    });
  }

  public async delete() {
    const res: any = await del(`/dashboards/${this.id}`);
  }

  public async fetch() {
    if (!this.id) {
      return;
    }
    
    this.reportCardList = new ReportCardList();
    let res: any = await get(`/dashboards/${this.id}`);
    this.mapData(res.data);
    res = await get(`/dashboards/${this.id}/cards`);
    for (const cardData of res.data) {
      const reportCard = new ReportCardModel();
      reportCard.mapData(cardData);
      this.reportCardList.add(reportCard);
    }
    this.sort();
  
    // await this.updateSort();
  }

  public async fetchPublic() {
    if (!this.id) {
      return;
    }
    
      this.reportCardList = new ReportCardList();
      let res: any = await get(`/published_dashboards/${this.id}`);
      this.mapData(res.data);
      res = await get(`/published_dashboards/${this.id}/cards`);
      for (const cardData of res.data) {
        const reportCard = new ReportCardModel();
        reportCard.mapData(cardData);
        this.reportCardList.add(reportCard);
      }
      this.sort();
  }

  public async fetchSystem() {
    if (!this.id) {
      return;
    }
    
    this.reportCardList = new ReportCardList();
    let res: any = await get(`/system_dashboards/${this.id}`);
    this.mapData(res.data);
    res = await get(`/system_dashboards/${this.id}/cards`);
    for (const cardData of res.data) {
      const reportCard = new ReportCardModel();
      reportCard.mapData(cardData);
      this.reportCardList.add(reportCard);
    }
    this.sort();
    
  }

  public sort() {
    const compare = (a: ReportCardModel, b: ReportCardModel) => {
      if (!a.sort || !a.sort.dashboard) {
        if (!b.sort || !b.sort.dashboard) {
          if (a.id < b.id) {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 1;
        }
      } else {
        if (!b.sort || !b.sort.dashboard) {
          return -1;
        } else {
          if (Number(a.sort.dashboard[this.id]) < Number(b.sort.dashboard[this.id])) {
            return -1;
          } else {
            return 1;
          }
        }
      }
      return 0;
    };
    this.reportCardList.items = this.reportCardList.items.sort(compare);
  }

  public async updateSort() {
    const data: any = [];
    for (const card of this.reportCardList.items) {
      data.push({ id: card.id });
    }
    const params: any = { cards: data };
    try {
      const res = await put(`/dashboards/${this.id}/options`, params);
    } catch (e: any) {
      // show the error
      // console.log(e.message);
    }
  }

  public async updateCardOption() {
    const data: any = [];
    for (const card of this.reportCardList.items) {
      data.push({
        id: card.id,
        size: {
          height: card.cardHeightSize,
          width: card.cardWidthSize,
        },
      });
    }
    const params: any = { cards: data };
    const res = await put(`/dashboards/${this.id}/options`, params);
  }

  public async moveCardPosition(cardId: number, isMoveLeft: boolean = true) {
    let position: number = 0;
    let index: number = 0;
    const newCardList: ReportCardList = new ReportCardList();
    for (const card of this.reportCardList.items) {
      newCardList.add(card);
    }

    for (const card of this.reportCardList.items) {
      if (card.id === cardId) {
        position = index;
        break;
      }
      index++;
    }
    let firstCard: ReportCardModel;
    let secondCard: ReportCardModel;
    if (isMoveLeft) {
      if (position === 0) {
        return;
      } else {
        firstCard = this.reportCardList.items[position - 1];
        secondCard = this.reportCardList.items[position];
        newCardList.items[position - 1] = secondCard;
        newCardList.items[position] = firstCard;
      }
    } else {
      if (position === this.reportCardList.size()) {
        return;
      } else {
        firstCard = this.reportCardList.items[position];
        secondCard = this.reportCardList.items[position + 1];
        newCardList.items[position] = secondCard;
        newCardList.items[position + 1] = firstCard;
      }
    }
    this.reportCardList = newCardList;
    this.updateSort();
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.type = data.type;
    this.merchantId = data.merchantId;
  }

  public getLockboard(id: number) {
    let board: any = {
      id: 0,
      name: 'Dashboard Unavailable',
      isLock: true,
      description: 'Dashboard currently unavailable; please check back later or contact support for assistance',
      notice: '',
    };
    for (const item of this.lockBoards) {
      if (item.id === id) {
        board = item;
      }
    }
    return board;
  }
}
