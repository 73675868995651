
import { Component, Watch, Vue, Prop } from 'vue-property-decorator';
import { ReportModel } from '@/models/report';
import { ComparisonDateModel } from '@/models/comparison_date';
import { OpenaiClient } from '@/models/openai_client';

@Component({})
export default class ReportCardSummary extends Vue {
  public chart?: any;
  @Prop({default: false})
  public fullWidth!: boolean;
  @Prop({default: false})
  public needReload!: boolean;
  @Prop({default: []})
  public selectedReportColumns!: any;
  public activeColumns: any = [];
  @Prop({default: new ReportModel()})
  public report!: ReportModel;
  @Prop({default: 0})
  public id!: number;
  @Prop({default: () => new ComparisonDateModel()})
  public comparisonDate!: ComparisonDateModel;
  public titleFontSize: string = '14px';
  public valueFontSize: string = 'h3';
  public cardWidth: string = '250px';
  public cardPaddingTop: string = '50px';
  public summaryText: string[] = [];

  public async reload() {
    let maxColumns: number = this.report.columns.length;
    if (maxColumns >= 2) {
      maxColumns = 2;
    }
    let i: number = 0;
    const newColumns: any = [];
    this.activeColumns = this.selectedReportColumns;
    if (!this.selectedReportColumns || this.selectedReportColumns.length === 0) {
      this.activeColumns = this.report.columns;
    } else {
      maxColumns = this.selectedReportColumns.length;
    }
    for (const column of this.activeColumns) {
      
      if (i <= maxColumns) {
        const newColumn = this.getReportColumnData(column);
        if (newColumn) {
          i++;
          newColumns.push(newColumn);
        }
      } else {
        break;
      }
    }
    this.activeColumns = newColumns;
    this.report.columns = newColumns;
    const metricCount = newColumns.length;
    this.cardWidth = 100 * (1 / metricCount) + '%';
    switch (metricCount) {
      case 1: {
        this.valueFontSize = 'h3';
        break;
      }
      case 2: {
        this.valueFontSize = 'h4';
        break;
      }
      default: {
        this.valueFontSize = 'h5';
      }
    }
    this.$emit('reload-completed', 'BIG_NUMBER');
  }

  public goToCard() {
    // Replace 'id' with the actual ID of the card
    this.$router.push(`/view/card/${this.id}`);
  }

  public getReportColumnData(activeColumn: any) {
    for (const item of this.report.columns) {
      if (item.code === activeColumn.code) {
        item.name = activeColumn.name;
        return item;
      }
    }
  }

  public getSelectedColumnName(column: any) {
    for (const item of this.selectedReportColumns) {
      if (item.code === column.code) {
        return item.name;
      }
    }
    return column.name;
  }
  public async summaryMetric() {
    const openaiClient: OpenaiClient = new OpenaiClient();
    let textToSummary: string = '';
    for (const column of this.report.columns) {
      textToSummary = textToSummary + ',' + column.name + ': value=' + column.values[0];
      if (column.comparison) {
        textToSummary = textToSummary + ', last period value:'  + column.comparison.last_period.values[0];
      }
    }
    const res = await openaiClient.summary(textToSummary);
    this.summaryText = res.choices[0].text.split('\n\n');
  }

  public getComparisonValue(column: any) {
    if (column.comparison) {
      return column.comparison.period_over_period_percent.values[0];
    }
  }

  public showComparePercentage(column: any) {
    if (column.comparison) {
      return Number(column.comparison.period_over_period.originalValues[0]) !== 0 &&
      column.comparison.period_over_period.originalValues[0] !== 'null';
    }
  }

  public getLastPeriodValue(column: any) {
    if (column.comparison) {
      return column.comparison.last_period.values[0];
    }
  }

  public isIncrease(column: any) {
    const periodOverPeriod = Number(column.comparison.period_over_period.originalValues[0]);
    const lastPeriod = Number(column.comparison.last_period.originalValues[0]);
    const isPositiveTrend = column.comparison.is_positive_trend;
    if (isPositiveTrend) {
      return periodOverPeriod >= 0;
    } else {
      return periodOverPeriod < 0;
    }
  }

  public getColumnSize(screen: string) {
    let columnCount = this.report.columns.length;
    if (columnCount === 0) {
      columnCount = 1;
    }
    if (columnCount <= 2) {
      return 12 / columnCount;
    }
    switch (screen) {
      case 'xs': {
        return 12;
        break;
      }
      case 'sm': {
        return 12;
        break;
      }
      case 'md': {
        if (this.fullWidth) {
          return 12 / columnCount;
        } else {
          return 6 / columnCount;
        }
        break;
      }
      case 'lg': {
        if (this.fullWidth) {
          return 12 / columnCount;
        } else {
          return 6 / columnCount;
        }
        break;
      }
    }
    return 12;
  }
  public isSelectedColumn(code: string) {
    if (!this.selectedReportColumns || this.selectedReportColumns.length === 0) {
      return true;
    }
    for (const c of this.selectedReportColumns) {
      if (c.code === code) {
        return true;
      }
    }
    return false;
  }

  public formatBigNumberValue(column: any) {
    if (column.values.length === 0) {
      return 0;
    }
    return column.values[0];
  }

  @Watch('needReload', { immediate: true, deep: true  })
  private async onNeedReloadTableChanged(newVal: any, oldVal: any) {
    if (newVal === true) {
      await this.reload();
    }
  }
}
