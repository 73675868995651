
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import Selector from './OptionSelector.vue';

@Component({
  components: {
    Selector,
  },
})
export default class SchedulerOutputFileFormat extends Vue {
  @Prop({ default: 'csv' })
  public value!: any;
  @Prop({ default: () => ['csv', 'excel', 'pdf'] })
  public fileFormats!: string[];
  public selectedFileFormats: string[] = [];
  public convertedFileFormats: string[] = [];
  @Prop({default: ''})
  public initFileFormats!: string;
  public currentFileFormat: string = '';
  public formarts: any = {
    csv: 'CSV',
    excel: 'Excel',
    pdf: 'PDF',
    google_sheets: 'New Google Spreadsheet',
    existed_google_sheets: 'Create a single sheet',
    existed_google_new_sheets: 'Add a new sheet on every run',
  };
  public outputFormarts: any = {
    'CSV': 'csv',
    'Excel': 'excel',
    'PDF': 'pdf',
    'New Google Spreadsheet': 'google_sheets',
    'Create a single sheet': 'existed_google_sheets',
    'Add a new sheet on every run': 'existed_google_new_sheets',
  };

  public created() {
    if (!this.fileFormats.includes(this.initFileFormats)) {
      this.currentFileFormat = this.fileFormats[0];
    } else {
      this.currentFileFormat = this.initFileFormats;
    }
    this.convertFileFormatInit();
  }

  public get getConvertedFileFormat() {
    if (this.initFileFormats) {
      return [this.convertFileFormat(this.currentFileFormat)];
    } else {
      return [];
    }
  }

  public convertFileFormatInit() {
    this.convertedFileFormats = [];
    for (const format of this.fileFormats) {
      this.convertedFileFormats.push(this.convertFileFormat(format));
    }
    if (!this.currentFileFormat) {
      this.$emit('input', this.fileFormats[0]);
    }
  }

  public convertFileFormat(fileFormat: string) {
    if (this.formarts[fileFormat]) {
      return this.formarts[fileFormat];
    } else {
      return this.formarts[this.formarts[this.fileFormats[0]]];
    }
  }

  public convertFileFormatOutput(fileFormat: string) {
    if (this.outputFormarts[fileFormat]) {
      return this.outputFormarts[fileFormat];
    } else {
      return this.fileFormats[0];
    }
  }

  @Watch('selectedFileFormats', { immediate: true, deep: true })
  private onSelectedFileFormatChanged(newVal: any) {
    if (this.outputFormarts[this.selectedFileFormats[0]] !== undefined) {
      this.$emit('input', this.outputFormarts[this.selectedFileFormats[0]]);
    } else {
      this.$emit('input', this.fileFormats[0]);
    }
  }
}
