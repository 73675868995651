import { get, post, put } from '@/services/http';
import { RuleList } from '@/collections/rules';

export class UserPackageModel {
  public id: number;
  public name?: string;
  public price: number;
  public discount: number;
  public durationLimitInIntervals?: number;
  public activatedAt?: string;
  public rules: RuleList;
  public status?: string;
  public chargeId?: number;
  public billingPackageId: number;
  public discountCode?: string;
  public billingPackageVersion?: number;
  public cancelledAt?: string;
  public trialEndAt?: string;
  public createdAt?: string;
  public updatedAt?: string;
  public interval: string = 'EVERY_30_DAYS';
  public predictEtlTime?: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.billingPackageId = data.billingPackageId;
    this.rules = new RuleList();
    this.discount = data.discount || 0;
    this.price = data.price || 0;
  }

  public get passedTrial() {
    return (this.toTimestamp(undefined) - this.toTimestamp(this.trialEndAt)) > 0;
  }

  public toTimestamp(strDate: string|undefined) {
    let datum: number = 0;
    if (!strDate) {
      datum = Date.now();
    } else {
      datum = Date.parse(strDate);
    }
    return datum / 1000;
  }

  public async fetch() {
    const res: any = await get(`/current_package`);
    this.mapData(res.data);
  }

  public async create(trialDays: number = 7, discount: number = 0,
                      returnUrl: string, needInstallPlugins: number[] = [], isYearly: boolean = false) {

    let interval: string = 'EVERY_30_DAYS';
    if (isYearly) {
      interval = 'ANNUAL';
    }
    const res: any = await post(`/user_packages`, {
      billingPackageId: this.billingPackageId,
      trialDays,
      discountPercentage: discount,
      returnUrl,
      discountCode: this.discountCode,
      plugins: needInstallPlugins,
      interval: interval,
    });
    this.mapData(res.data.userPackage);
    return res.data.confirmationUrl;
  }

  public async confirmed() {
    const res: any = await put(`/user_packages/active`, {
      chargeId: this.chargeId,
    });
    this.mapData(res.data.userPackage);
  }

  public async getCurrent() {
    try {
      const res: any = await get(`/user_packages/current`);
      this.mapData(res.data.userPackage);
      // if (this.name === 'Free') {
      //   this.activatedAt = this.createdAt;
      // }
      if (this.activatedAt) {
        let activatedAt = new Date(this.activatedAt);
        const predictTime = new Date(
          activatedAt.setMinutes(
            activatedAt.getMinutes() + res.data.predictETLTime
          )
        );
        const current = new Date();
        const diff = predictTime.getTime() - current.getTime();
        //const diffMinutes = Math.floor(diff / 60000);
        const diffMinutes = -10;
        if (diffMinutes > 0) {
          this.predictEtlTime = `The data importing process can take more than ${diffMinutes} minutes. `;
        }
        
        if (diffMinutes > 60 * 5) {
          this.predictEtlTime = 'The data importing process can take more than 5 hours to finish.';
        }

        if (diffMinutes < 5) {
          this.predictEtlTime = `The data importing process can be finished in a few minutes.`;
        }
      }
    } catch (e){
      console.log(e);
      // skipped
    }
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.price = data.price;
    this.discount = data.discount;
    this.durationLimitInIntervals = data.durationLimitInIntervals;
    this.activatedAt = data.activatedAt;
    this.rules = new RuleList();
    this.rules.mapData(data.rules);
    this.status = data.status;
    this.chargeId = data.chargeId;
    this.billingPackageId = data.billingPackageId;
    this.billingPackageVersion = data.billingPackageVersion;
    this.cancelledAt = data.cancelledAt;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.interval = data.interval;
    this.trialEndAt = data.trialEndAt;
  }
}
