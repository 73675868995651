
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import HeaderTitle from '../components/HeaderTitle.vue';
import { StoreModel } from '@/models/store';
import { MeasureList } from '@/collections/measures';
import { CustomReportModel } from '@/models/custom_report';
import { MeasureModel } from '@/models/measure';
import { DimensionModel } from '@/models/dimension';
import { AnalyticsTypeModel } from '@/models/analytics_type';
import { FilterColumnList } from '@/collections/filter_columns';
import { TemplateReportModel } from '@/models/template_report';
import { ReportCardModel } from '@/models/report_card';
import { TemplateReportList } from '@/collections/template_reports';
import { ReportMetadataList } from '@/collections/report_metadatas';
import { ReportMetadataModel } from '@/models/report_metadata';
import { CustomReportList } from '@/collections/custom_reports';
import { DashboardList } from '@/collections/dashboards';
import { DashboardModel } from '@/models/dashboard';
import { EventHandler } from '@/modules/events';
import { OpenaiModel } from '@/models/openai';
import { SearchModel } from '@/models/search';
import { FilterDimensionList } from '@/collections/filter_dimensions';
import ActionButton from '@/components/ActionButton.vue';

@Component({
  components: {
    HeaderTitle,
    ActionButton,
  },
})
export default class ReportSelect extends Vue {
  @Prop({ default: () => new DashboardList() })
  public dashboards!: DashboardList;
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  public dates: string[] = [];
  public loading: boolean = false;
  public error: boolean = false;
  public errorMessage: string = '';
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;
  public timeranges: string[] = [];
  public newCustomReport: CustomReportModel = new CustomReportModel();
  public customReports: CustomReportList = new CustomReportList();
  public measure: MeasureModel = new MeasureModel();
  public dimension: DimensionModel = new DimensionModel();
  public analyticsType: AnalyticsTypeModel = new AnalyticsTypeModel();
  public filterColumns: FilterColumnList = new FilterColumnList();
  public initFilterColumns: FilterColumnList = new FilterColumnList();
  public reportColumns: any = [];
  public initReportColumns: any = [];
  public pivotOption: string = '';
  public defaultRange: string = '';
  public reportSearchResults: ReportMetadataModel[] = [];
  public customReportSearchResults: CustomReportModel[] = [];
  @Prop({ default: () => new ReportMetadataList() })
  public templateReports!: ReportMetadataList;
  public search: string = '';
  public searchDialog: boolean = false;
  public maxSearchResult: number = 10;
  public searchLoading: boolean = false;
  public loadingSave: boolean = false;
  public loadingCustomReport: boolean = false;
  public dashboardModel: DashboardModel = new DashboardModel();
  public openaiModel: OpenaiModel = new OpenaiModel();
  public searchModel: SearchModel = new SearchModel();
  public dialogConfirmCreate: boolean = false;
  public createLoading: boolean = false;
  public selectingReport: ReportMetadataModel = new ReportMetadataModel();
  public selectingCustomReport: CustomReportModel = new CustomReportModel();
  public async created() {
    this.searchLoading = true;
    await this.searchReport();
    this.loadingCustomReport = true;
    await this.customReports.fetch();
    this.loadingCustomReport = false;
    this.loadCurrentDashboard();
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track('View Report Select Page', {
        dashboardName: this.dashboardModel.name,
        dashboardId: this.dashboardModel.id,
      });
    } catch (e) {
      // ignore this
    }
    this.searchLoading = false;
  }

  public loadCurrentDashboard() {
    if (this.$route.query.dashboard_id !== undefined) {
      const dashboardId = Number(this.$route.query.dashboard_id);
      if (dashboardId) {
        this.dashboardModel = new DashboardModel({id: dashboardId});
        this.dashboardModel.fetch();
      }
    }
  }

  public getReportLink(report: TemplateReportModel) {
    return (`/reports/${report.id}`);
  }

  public async searchReport() {
    this.searchLoading = true;
    if (this.search !== '') {
      this.reportSearchResults = await this.openaiModel.searchByOpenAI(this.search);
      this.customReportSearchResults = this.customReports.items.filter((item: CustomReportModel) => {
          const searchKeyword = this.search.toLocaleLowerCase().replace(/\s/gi, '(.*)');
          const itemName = item.name.toLowerCase();
          if (itemName.match(searchKeyword)) {
            return item;
          }
        });
    } else {
      this.reportSearchResults = this.searchModel.getRecentViewReports(this.templateReports);
      const popularReports = this.searchModel.getPopularReports(this.templateReports);
      for (const item of popularReports) {
        this.reportSearchResults.push(item);
      }
      this.customReportSearchResults = this.customReports.items;
    }
    this.searchLoading = false;
  }

  public isLastItem(index: number) {
    if (this.reportSearchResults.length > this.maxSearchResult) {
      if (index >= this.maxSearchResult - 1) {
        return true;
      }
    } else {
      if (index === this.reportSearchResults.length - 1) {
        return true;
      }
    }
    return false;
  }

  public viewLink(link: any) {
    if (link) {
      this.$router.push(link);
    } else {
      this.$router.push('/reports');
    }
  }

  public getTranslatedReportName(reportName: string) {
    if (this.$te(`report_name.${reportName}`)) {
      return this.$t(`report_name.${reportName}`);
    } else {
      return reportName;
    }
  }

  public viewReport(report: TemplateReportModel) {

    this.$router.push(this.getReportLink(report));
  }

  public async getReportTemplate(id: number) {
    let templateReport: TemplateReportModel = new TemplateReportModel();
    try {
      templateReport.id = id;
      await templateReport.fetch();
    } catch (e: any) {
      templateReport = new TemplateReportModel();
    }

    return templateReport;
  }

  public async chooseTemplateReport(report: ReportMetadataModel) {
    this.selectingReport = report;
    this.dialogConfirmCreate = true;
  }

  public async createCustomReportFromTemplate(id: number) {
    try {
      const newCustomReport: CustomReportModel = new CustomReportModel();
      const report = await this.getReportTemplate(id);
      newCustomReport.name = 'Copy of ' + report.name;
      newCustomReport.dimension = report.dimension;
      newCustomReport.measure = report.measure;
      newCustomReport.analyticsType = report.analyticsType;
      newCustomReport.filterDimensions = new FilterDimensionList();
      newCustomReport.filterColumns = report.filterColumns;
      newCustomReport.reportParams = report.reportParams;
      newCustomReport.defaultRange = report.predefinedTimeRange;
      newCustomReport.pivotOption = report.pivotOption;
      newCustomReport.reportColumns = report.reportColumns;
      newCustomReport.filterTimerange = [];
      await newCustomReport.save();
      this.$router.push(`/my-reports/${newCustomReport.id}`);
    } catch (e: any) {
      // do nothing
    }

  }
  public async createReportCard(id: number) {
    const report = await this.getReportTemplate(id);
    if (!report || !report.id) {
      return;
    }
    this.loadingSave = true;
    const reportCard = new ReportCardModel();
    reportCard.name = report.name;
    reportCard.typeReport = 'TABLE';
    reportCard.dimension = report.dimension;
    reportCard.measure = report.measure;
    reportCard.analyticsType = report.analyticsType;
    reportCard.filterColumns = report.filterColumns;
    reportCard.filterTimerange = report.filterTimerange;
    reportCard.reportColumns = report.reportColumns;
    reportCard.reportParams = report.reportParams;
    reportCard.dashboardIds = this.dashboardModel.id;
    if (report.predefinedTimeRange && report.predefinedTimeRange !== 'custom') {
      reportCard.defaultRange = report.predefinedTimeRange;
    } else {
      reportCard.defaultRange = '30d';
    }
    reportCard.pivotOption = report.pivotOption;
    try {
      await reportCard.create();
      this.dashboardModel.reportCardList.add(reportCard);
      await this.dashboardModel.updateCardOption();
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track('Create Report Card', {
        name: reportCard.name,
        id: reportCard.id,
        measure: reportCard.measure.code,
        dimension: reportCard.dimension ? reportCard.dimension.code : '',
      });
    } catch (e) {
      // ignore this
    }
    this.loadingSave = false;
    this.$router.push(`/myboard/${this.dashboardModel.id}`);
  }

  public async confirmCreate() {
    this.createLoading = true;
    if (this.selectingReport.id) {
      await this.confirmCreateFromTemplate();
    } else {
      await this.confirmCreateFromCustomReport();
    }
    this.createLoading = false;
    this.dialogConfirmCreate = false;
  }

  public async confirmCreateFromTemplate() {
    const id = this.selectingReport.id;
    if (this.dashboardModel.id) {
      await this.createReportCard(id);
    } else {
      await this.createCustomReportFromTemplate(id);
    }
  }

  public async confirmCreateFromCustomReport() {
    const report = this.selectingCustomReport;
    if (this.dashboardModel.id) {
      await this.createReportCardFromCustom(report);
    } else {
      await this.cloneCustomReport(report);
    }
  }

  public async chooseCustomReport(report: CustomReportModel) {
    this.selectingCustomReport = report;
    this.dialogConfirmCreate = true;
  }

  public async cloneCustomReport(report: CustomReportModel) {
    try {
      const newCustomReport: CustomReportModel = new CustomReportModel();
      newCustomReport.name = 'Copy of ' + report.name;
      newCustomReport.dimension = report.dimension;
      newCustomReport.measure = report.measure;
      newCustomReport.analyticsType = report.analyticsType;
      newCustomReport.filterDimensions = report.filterDimensions;
      newCustomReport.filterColumns = report.filterColumns;
      newCustomReport.reportParams = report.reportParams;
      newCustomReport.defaultRange = report.defaultRange;
      newCustomReport.pivotOption = report.pivotOption;
      newCustomReport.reportColumns = report.reportColumns;
      newCustomReport.filterTimerange = report.filterTimerange;
      newCustomReport.productSegmentId = report.productSegmentId;
      newCustomReport.calculatedMetrics = report.calculatedMetrics;
      await newCustomReport.save();
      this.$router.push(`/my-reports/${newCustomReport.id}`);
    } catch (e: any) {
      // do nothing
    }
  }

  public async createReportCardFromCustom(report: CustomReportModel) {
    this.loadingSave = true;
    const reportCard = new ReportCardModel();
    reportCard.name = report.name;
    reportCard.typeReport = 'TABLE';
    reportCard.dimension = report.dimension;
    reportCard.measure = report.measure;
    reportCard.analyticsType = report.analyticsType;
    reportCard.filterColumns = report.filterColumns;
    reportCard.reportColumns = report.reportColumns;
    reportCard.reportParams = report.reportParams;
    reportCard.filterTimerange = report.filterTimerange;
    reportCard.dashboardIds = this.dashboardModel.id;
    reportCard.productSegmentId = report.productSegmentId;
    if (report.defaultRange && report.defaultRange !== 'custom') {
      reportCard.defaultRange = report.defaultRange;
    } else {
      reportCard.defaultRange = '30d';
    }
    reportCard.pivotOption = report.pivotOption;
    try {
      await reportCard.create();
      this.dashboardModel.reportCardList.add(reportCard);
      await this.dashboardModel.updateCardOption();
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track('Create Report Card from Favorite report', {
        name: reportCard.name,
        id: reportCard.id,
        measure: reportCard.measure.code,
        dimension: reportCard.dimension ? reportCard.dimension.code : '',
      });
    } catch (e) {
      // ignore this
    }
    this.loadingSave = false;
    this.$router.push(`/myboard/${this.dashboardModel.id}`);
  }


  public get searchResultNumber() {
    if (!this.search) {
      return 'Most Popular Reports';
    } else {
      return 'Search Results';
    }
  }

  public get searchCustomReportResultNumber() {
    if (this.customReportSearchResults.length === 1) {
      return this.$t('menu.Show') + ' ' +
      this.customReportSearchResults.length + ' / ' +
      this.customReportSearchResults.length +
      this.$t('menu.Of your favorite reports');
    }
    if ((this.customReportSearchResults.length > 0) &&
    (this.customReportSearchResults.length <= this.maxSearchResult)) {
      return this.$t('menu.Show') + ' ' +
      this.customReportSearchResults.length + ' / ' +
      this.customReportSearchResults.length + ' ' +
      this.$t('menu.Of your favorite reports');
    }
    if ((this.customReportSearchResults.length > 0) &&
    (this.customReportSearchResults.length > this.maxSearchResult)) {
      return this.$t('menu.Show') + ' ' +
      this.maxSearchResult + ' / ' +
      this.customReportSearchResults.length + ' ' +
      this.$t('menu.Of your favorite reports');
    }
    return this.$t('menu.Search Results');
  }

  @Watch('templateReports', { immediate: true, deep: true })
  private onTemplateReportsChanged(newVal: any) {
    if (this.templateReports.items.length > 0) {
      this.reportSearchResults = this.templateReports.items;
    }
  }
}
