
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { StoreModel } from '@/models/store';
import { ChannelList } from '@/collections/channels';


@Component
export default class SlackChannelSelector extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;

  public selectedChannelId: string = '';
  public channels: ChannelList = new ChannelList();

  public async created() {
    await this.channels.fetch();
  }

  @Watch('selectedChannelId', { immediate: true, deep: true })
  private async onChannelChanged(newVal: any) {
    this.$emit('input', this.selectedChannelId);
  }
}
