import { get } from '@/services/http';
import { SupplierModel } from '@/models/supplier';
import List from './list';

export class SupplierList extends List<SupplierModel> {

  public async fetch() {
    const res: any = await get(`/suppliers`, {});
    const data = res.data || [];
    this.mapData(data);
  }

  private mapData(data: any = []) {
    for (const s of data) {
      const supplier: SupplierModel = new SupplierModel();
      supplier.mapData(s);
      this.add(supplier);
    }
  }
}
