import { get, post } from '@/services/http';
import { ScheduleModel } from '@/models/schedule';
import List from './list';

export class ScheduleList extends List<ScheduleModel> {
  public countAll: number = 0;

  public async fetch() {
    const res: any = await get(`/schedule`, {});
    const data = res.data || [];
    this.mapData(data);
  }

  private mapData(data: any = []) {
    this.items = [];
    for (const s of data) {
      const profile = new ScheduleModel();
      profile.mapData(s);
      this.add(profile);
    }
  }
}
