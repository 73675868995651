
import { Component, Prop, Vue } from "vue-property-decorator";
import { StoreModel } from "@/models/store";
import HeaderTitle from "@/components/HeaderTitle.vue";

@Component({
  components: {
    HeaderTitle,
  },
})
export default class DashboardLibrary extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: "available" })
  public isLockedReports!: string;
  @Prop({ default: false })
  public hasSchemas!: boolean;

  public async created() {}
}
