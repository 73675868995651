import { get, post } from '@/services/http';
import { ComparisonDateModel } from '@/models/comparison_date';
import List from './list';

export class ComparisonDateList extends List<ComparisonDateModel> {
  public mapData(data: any = []) {
    for (const s of data) {
      const comparisonDate = new ComparisonDateModel();
      comparisonDate.mapData(s);
      this.add(comparisonDate);
    }
  }
}
