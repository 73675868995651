
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { FilterColumnList } from '@/collections/filter_columns';
import { MeasureModel } from '@/models/measure';
import { DimensionModel } from '@/models/dimension';
import { AnalyticsTypeModel } from '@/models/analytics_type';
import { ReportModel } from '@/models/report';
import { StoreModel } from '@/models/store';
import { MetricModel } from '@/models/metric';
import { MetricList } from '@/collections/metrics';
import { Container, Draggable } from 'vue-smooth-dnd';
import { DimensionColumnList } from '@/collections/dimension_columns';

import { EventBus } from '@/main';
import ActionButton from '@/components/ActionButton.vue';

interface ColumnView {
  name?: string;
  reportColumns: [];
  isDefault?: boolean;
}

@Component({
  components: {
    Container,
    Draggable,
    ActionButton,
  },
})
export default class ColumnSelector extends Vue {
  @Prop({ default: null })
  public value!: any[];
  public columnSelector: boolean = false;
  @Prop({ default: false })
  public columnSelectorMenu!: boolean;
  public isLoadingColumn: boolean = false;
  public report: ReportModel = new ReportModel();
  public filterColumns: FilterColumnList = new FilterColumnList();
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: () => new MeasureModel() })
  public measure!: MeasureModel;
  @Prop({ default: () => new DimensionModel() })
  public dimension!: DimensionModel;
  public selectedDimension: DimensionModel = new DimensionModel();
  @Prop({ default: () => new AnalyticsTypeModel() })
  public analyticsType!: AnalyticsTypeModel;
  @Prop({ default: '' })
  public pivotOption!: string;
  @Prop({ default: [] })
  public filterTimerange!: string[];
  @Prop({ default: [] })
  public initReportColumns!: any;
  public reportColumns: any = [];
  @Prop({ default: [] })
  public initSelectedReportColumns!: any;
  public selectedReportColumns: any = [];
  public selectingItems: any = [];
  @Prop({ default: () => new DimensionColumnList() })
  public dimensionColumns!: DimensionColumnList;
  public columnGroups: any = [];
  public defaultColumns: any = [];
  public dialogAddDimensionColumn: boolean = false;
  public pivotColumns: any = [];
  public pivotDimensions: any = [];
  public pivotMeasures: any = [];
  public metricSources: any = [];
  public dialogPivotOption: boolean = false;
  public addedPivotMeasure: any = null;
  public addedPivotColumn: any = null;
  public addedPivotAutoSelect: boolean = false;
  public selectedPivotColumns: any = [];
  @Prop({ default: 'column' })
  public currentTab!: string;
  public isCreatingColumnView: boolean = false;
  public newColumnViewName: string = '';
  public dialogEditColumnConfig: boolean = false;
  public editingColumnIndex: number = -1;
  public newColumnName: string = '';
  public newColumnView: any = { name: '', isDefault: false };
  @Prop({ default: null })
  public activeColumnView!: ColumnView;
  @Prop({ default: -1 })
  public activeColumnViewIndex!: number;
  @Prop({ default: 0 })
  public customReportId!: number;
  @Prop({ default: 0 })
  public templateReportId!: number;
  public newCalculatedMetric: MetricModel = new MetricModel();
  @Prop({ default: () => new MetricList() })
  public initCalculatedMetrics!: MetricList;
  public calculatedMetrics: MetricList = new MetricList();
  public metricSource: any = [];
  public dialogDelete: boolean = false;
  public deletingMetric: MetricModel = new MetricModel();
  public activeMetricIndex: number = -1;
  public nameRules: any = [this.isNameExist];
  public formulaRules: any = [this.isNameExist, this.formulaValidRule];
  public metricDataTypes: any = [
    { label: 'Number', value: 'number' },
    { label: 'Percentage', value: 'percentage' },
    { label: 'Text', value: 'text' },
  ];
  public alias: any = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'K',
    'M',
    'N',
    'P',
    'Q',
  ];
  public selectAll: boolean = false;
  public created() {
    this.initSelectingItems();
    this.initDimensionColumn();
    this.initPivotColumns();
    EventBus.$on('open-create-column-view-form', async () => {
      this.isCreatingColumnView = true;
    });
    EventBus.$on('close-column-view-form', async () => {
      this.isCreatingColumnView = false;
    });
    EventBus.$on('open-add-more-column-dialog-event', async () => {
      this.dialogAddDimensionColumn = true;
    });
  }
  public isNameExist(v: any) {
    return !(!v && this.newCalculatedMetric.name) || 'Must not be empty';
  }

  public confirmDeleteMetric(metric: MetricModel, index: number) {
    this.deletingMetric = metric;
    this.activeMetricIndex = index;
    this.dialogDelete = true;
  }

  public editMetric(metric: MetricModel, index: number) {
    this.newCalculatedMetric = metric;
    this.activeMetricIndex = index;
  }

  public cancelEditMetric() {
    this.activeMetricIndex = -1;
    this.newCalculatedMetric = new MetricModel();
  }

  public deleteMetric() {
    if (
      this.activeMetricIndex > -1 &&
      this.activeMetricIndex < this.calculatedMetrics.items.length
    ) {
      this.calculatedMetrics.items.splice(this.activeMetricIndex, 1);
      this.activeMetricIndex = -1;
      this.deletingMetric = new MetricModel();
      EventBus.$emit('update-calculated-metric', {
        metrics: this.calculatedMetrics,
        customReportId: this.customReportId,
        templateReportId: this.templateReportId,
      });
      this.dialogDelete = false;
    }
  }

  public getMetricAlias(index: number) {
    let count: number = 0;
    for (const item of this.alias) {
      if (count === index) {
        return item;
      }
      count++;
    }
  }

  public selectMetric() {
    if (this.newCalculatedMetric.columns.length > 10) {
      return;
    }
    if (this.metricSource && this.metricSource.code) {
      this.newCalculatedMetric.columns.push({
        name: this.metricSource.name,
        code: this.metricSource.code,
      });
    }
    this.metricSource = null;
  }

  public createCalculatedMetric() {
    if (!this.isCalculcatedMetricFormulaValid()) {
      return;
    }
    let code = this.newCalculatedMetric.name;
    code = code.replace(/[&\/\\#,+()$~%.'" \[\]@:*?<>{}\(\)!]/g, '');
    this.newCalculatedMetric.code = code;
    if (
      this.activeMetricIndex !== -1 &&
      this.activeMetricIndex < this.calculatedMetrics.items.length
    ) {
      this.calculatedMetrics.items[this.activeMetricIndex] =
        this.newCalculatedMetric;
      this.activeMetricIndex = -1;
    } else {
      this.calculatedMetrics.add(this.newCalculatedMetric);
    }

    this.$emit('update-calculated-metric', {
      metrics: this.calculatedMetrics,
      customReportId: this.customReportId,
      templateReportId: this.templateReportId,
    });
    this.autoAddCalculatedMetricToReport(this.newCalculatedMetric);
    this.newCalculatedMetric = new MetricModel();
  }

  public autoAddCalculatedMetricToReport(metric: MetricModel) {

    let isExist: boolean = false;
    for (const item of this.initSelectedReportColumns) {
      if (item.code === metric.code) {
        isExist = true;
        break;
      }
    }
    if (!isExist) {
      const column = {
        name: metric.name,
        code: metric.code,
        dataType: metric.dataType,
        luisMapping: metric.code,
      };
      this.initSelectedReportColumns.push(column);
      this.initSelectingItems();
    }
    this.saveColumnAndClose();
  }
  public formulaValidRule() {
    return this.isCalculcatedMetricFormulaValid() || 'Formula is invalid!';
  }

  public isNewCalcualtedMetricComplete() {
    if (!this.newCalculatedMetric.name) {
      return false;
    }
    if (!this.newCalculatedMetric.formula) {
      return false;
    }
    if (
      !this.newCalculatedMetric.columns ||
      this.newCalculatedMetric.columns.length === 0
    ) {
      return false;
    }
    return true;
  }
  public isCalculcatedMetricFormulaValid() {
    if (!this.newCalculatedMetric.formula) {
      return true;
    }
    const formula = this.newCalculatedMetric.formula;
    try {
      let testFormula = formula;
      for (let i = 0; i < this.newCalculatedMetric.columns.length; i++) {
        testFormula = testFormula.replaceAll(
          this.getMetricAlias(i),
          (i + 1).toString(),
        );
      }
      const result = eval(testFormula);
      // Check if the result is a finite number
      return isFinite(result);
    } catch (error) {
      // An error occurred during evaluation (e.g., syntax error)
      return false;
    }
  }

  public get actionTitle() {
    switch (this.currentTab) {
      case 'pivot': {
        return 'Column Pivot Table';
      }

      case 'view': {
        return 'Create Column Views';
      }

      case 'metric': {
        return 'Create Calculated Metrics';
      }

      default: {
        return 'Manage Columns for Report';
      }
    }
  }

  public getShortColumnName(columnName: string) {
    const max: number = 70;
    const len = columnName.length;
    let substr = columnName.substring(0, max);
    if (len >= max) {
      substr = substr + ' ...';
    }
    return substr;
  }

  public async initDimensionColumn() {
    this.columnGroups = [
      { text: 'Order Details', value: 'order_detail', items: [] },
      { text: 'Refunds', value: 'refunds', items: [] },
      { text: 'Product', value: 'product', items: [] },
      { text: 'Fulfillment', value: 'fulfillment', items: [] },
      { text: 'Tax', value: 'tax', items: [] },
      { text: 'Discounts', value: 'discounts', items: [] },
      { text: 'Shipping', value: 'shipping', items: [] },
      { text: 'Transaction', value: 'transaction', items: [] },
      { text: 'Customer Joureny', value: 'customer_journey', items: [] },
      { text: 'Billing / Payment', value: 'billing_payment', items: [] },
      { text: 'Customer', value: 'customer', items: [] },
      { text: 'Client Details', value: 'client_details', items: [] },
      { text: 'Presentments', value: 'presentments', items: [] },
      { text: 'Staffs', value: 'staff', items: [] },
      { text: 'Store Location', value: 'store_location', items: [] },
      { text: 'Sales Channel', value: 'sales_channel', items: [] },
      { text: 'Vendor', value: 'vendor', items: [] },
      { text: 'datetime', value: 'datetime', items: [] },
    ];
    await this.refreshColumnGroup();
  }

  public enableRename(index: number) {
    this.editingColumnIndex = index;
    this.dialogEditColumnConfig = true;
    this.newColumnName = this.selectingItems[index].newName;
  }

  public updateColumnConfig() {
    this.dialogEditColumnConfig = true;
    if (this.editingColumnIndex === -1) {
      return;
    }
    const index = this.editingColumnIndex;
    this.selectingItems[index].newName = this.newColumnName;
    this.newColumnName = '';
    this.editingColumnIndex = -1;
    this.dialogEditColumnConfig = false;
  }

  public massSelectItem() {
    for (const item of this.selectingItems) {
      item.value = this.selectAll;
    }
  }

  public initSelectingItems() {
    this.selectedReportColumns = [];
    for (const item of this.initSelectedReportColumns) {
      this.selectedReportColumns.push(item);
    }

    this.selectingItems = [];
    for (const item of this.selectedReportColumns) {
      this.selectingItems.push({
        column: item,
        value: true,
        newName: item.name,
        sort: '',
      });
    }
    for (const item of this.reportColumns) {
      if (!this.isSelectedColumn(item.code)) {
        this.selectingItems.push({
          column: item,
          value: false,
          newName: item.name,
          sort: '',
        });
      }
    }
  }

  public get canAddPivotOption() {
    if (!this.addedPivotColumn || !this.addedPivotMeasure) {
      return false;
    }
    return this.addedPivotColumn.code && this.addedPivotMeasure.code;
  }

  public initMetricSources() {
    this.metricSources = [];
    const count = 0;
    for (const item of this.reportColumns) {
      if (item.code.indexOf('_pivot_') === -1) {
        if (['image'].includes(item.dataType)) {
          continue;
        }
        if (!['text', 'date', 'datetime'].includes(item.dataType)) {
          item.alias = this.getMetricAlias(count);
          this.metricSources.push(item);
        }
      }
    }
  }

  public initPivotData() {
    this.pivotDimensions = [];
    this.pivotMeasures = [];
    this.metricSources = [];
    for (const item of this.reportColumns) {
      if (item.code.indexOf('_pivot_') === -1) {
        if (['image'].includes(item.dataType)) {
          continue;
        }
        if (['text', 'date', 'datetime'].includes(item.dataType)) {
          this.pivotDimensions.push(item);
        } else {
          this.pivotMeasures.push(item);
          this.metricSources.push(item);
        }
      }
    }

    for (const group of this.columnGroups) {
      for (const item of group.items) {
        if (['image'].includes(item.data_type)) {
          continue;
        }
        if (['text', 'date', 'datetime'].includes(item.data_type)) {
          this.pivotDimensions.push(item);
        } else {
          this.pivotMeasures.push(item);
        }
      }
    }
  }

  public initPivotColumns() {
    this.pivotColumns = [];
    if (this.pivotOption){
      try {
        this.initPivotColumnFromJson();
      } catch (error) {
        this.initPivotColumnFromString();
      }
    }
  }
  public initPivotColumnFromString() {
    if (this.pivotOption) {
      const pivotItems = this.pivotOption.split('_pivot_');
      for (const item of pivotItems) {
        const options = item.split('_cols_');
        if (options.length >= 2) {
          const columnCode: string = options[0]
            .replace('_rows_', '')
            .replace('+', ' ');
          const measureCode = options[1].replace('+', ' ');
          const columnValue = this.getReportColumnByCode(columnCode);
          const measureValue = this.getReportColumnByCode(measureCode);
          if (columnValue && measureValue) {
            this.pivotColumns.push({
              column: columnValue,
              measure: measureValue,
              autoSelect: false,
            });
          }
        }
      }
    }
  }

  public initPivotColumnFromJson() {
    if (this.pivotOption) {
      const pivotItems = JSON.parse(this.pivotOption);
      for (const item of pivotItems) {
        const columnValue = this.getReportColumnByCode(item.column.code);
        const measureValue = this.getReportColumnByCode(item.measure.code);
        const autoSelect = item.autoSelect;
        if (columnValue && measureValue) {
          this.pivotColumns.push({
            column: columnValue,
            measure: measureValue,
            autoSelect: autoSelect,
          });
        }
      }
    }
  }

  public addPivotOption() {
    this.pivotColumns.push({
      column: this.addedPivotColumn,
      measure: this.addedPivotMeasure,
      autoSelect: this.addedPivotAutoSelect,
    });
    this.addedPivotColumn = [];
    this.addedPivotMeasure = [];
    this.updatePivotOption();
  }

  public deletePivotOption(option: any) {
    const newOptions: any = [];
    for (const item of this.pivotColumns) {
      if (
        item.column.code !== option.column.code ||
        item.measure.code !== option.measure.code
      ) {
        newOptions.push(item);
      }
    }
    this.pivotColumns = newOptions;
    this.addedPivotColumn = null;
    this.addedPivotMeasure = null;
    this.removePivotColumns();
    this.updatePivotOption();
    this.initSelectingItems();
    this.saveColumn();
    this.$emit('need-refresh-table-data');
  }

  public removePivotColumns() {
    this.selectedPivotColumns = [];
    this.reportColumns = [];
    for (const item of this.initReportColumns) {
      if (item.code.indexOf('_pivot_') === -1) {
        this.reportColumns.push(item);
      }
    }
    const newSelectedReportColumns: any = [];
    for (const item of this.selectedReportColumns) {
      if (item.code.indexOf('_pivot_') === -1) {
        newSelectedReportColumns.push(item);
      }
    }
    this.selectedReportColumns = newSelectedReportColumns;
  }

  public updatePivotOption() {
    //convert pivotColumns from JSON to string using stringify()
    const pivotOption = JSON.stringify(this.pivotColumns);
    EventBus.$emit('update-pivot-option', pivotOption);
  }

  public getReportColumnByCode(code: string) {
    for (const column of this.reportColumns) {
      if (column.code === code) {
        return column;
      }
    }
    for (const dimension of this.dimensionColumns.items) {
      if (dimension.code === code) {
        return dimension;
      }
    }
    return null;
  }

  public async refreshColumnGroup() {
    if (!this.dimensionColumns || this.dimensionColumns.size() === 0) {
      return;
    }
    const newColumnGroups: any = [];
    for (const group of this.columnGroups) {
      const items = await this.getDimensionColumns(group.value);
      if (items && items.length > 0) {
        group.items = items;
        newColumnGroups.push(group);
      }
    }
    this.columnGroups = newColumnGroups;
    for (const index in this.columnGroups) {
      const group = this.columnGroups[index];
      this.defaultColumns.push({ header: group.text });
      for (const column of group.items) {
        this.defaultColumns.push(column);
      }
      if (parseInt(index) === this.columnGroups.length - 1) {
        this.defaultColumns.push({ divider: true });
      }
    }
  }

  public async getDimensionColumns(group: string) {
    const columns: any = [];
    for (const item of this.dimensionColumns.items) {
      if (
        item.group_name === group &&
        (await this.measure.isSupportedColumn(item.code))
      ) {
        if (!this.isAddedColumn(item.code)) {
          columns.push(item);
        }
      }
    }
    return columns;
  }

  public addColumn(dimensionColumn: any) {
    this.selectingItems.push({
      column: dimensionColumn,
      value: true,
      newName: dimensionColumn.name,
      sort: '',
    });
    this.dialogAddDimensionColumn = false;
    this.saveColumn();
    this.refreshColumnGroup();
    this.columnSelector = true;
    this.$emit('need-refresh-table-data');
  }
  public onDrop(result: any) {
    this.selectingItems = this.applyDrag(this.selectingItems, result);
  }

  public applyDrag(result: any, dragResult: any) {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) {
      return result;
    }
    let itemToAdd = payload;
    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }
    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }
    return result;
  }

  public isSelectedColumn(code: string) {
    if (this.selectingItems && this.selectingItems.length > 0) {
      for (const item of this.selectingItems) {
        if (item.column.code === code && item.value) {
          return true;
        }
      }
    }
    return false;
  }

  public isAddedColumn(code: string) {
    if (!this.selectingItems || this.selectingItems.length === 0) {
      return false;
    }
    if (this.selectingItems && this.selectingItems.length > 0) {
      for (const item of this.selectingItems) {
        if (item.column.code === code) {
          return true;
        }
      }
    }
    return false;
  }

  public async showColumnSelector() {
    this.columnSelector = true;
    this.initSelectingItems();
    this.initPivotData();
    this.initMetricSources();
  }

  public getSelectedPivotColumns() {
    this.selectedPivotColumns = [];
    const selectedPivotColumns: any = [];
    if (!this.pivotOption || this.pivotOption === 'undefined') {
      return [];
    } else {
      let hasSelected: boolean = false;
      for (const item of this.reportColumns) {
        if (
          item.code.indexOf('_pivot_') !== -1 &&
          this.isSelectedColumn(item.code)
        ) {
          hasSelected = true;
          break;
        }
      }
      if (!hasSelected) {
        for (const item of this.reportColumns) {
          if (item.code.indexOf('_pivot_') !== -1) {
            selectedPivotColumns.push(item);
          }
        }
      }

      return selectedPivotColumns;
    }
  }

  public saveColumn() {
    const selectedItems: any = [];
    for (const item of this.selectingItems) {
      if (item.value) {
        const column = {
          name: item.newName,
          code: item.column.code,
          dataType: item.column.dataType,
          luisMapping: item.column.luisMapping,
        };
        selectedItems.push(column);
      }
    }

    this.$emit('input', selectedItems);
    this.$emit('need-refresh-table-data');
  }

  public saveColumnAndClose() {
    const selectedItems: any = [];
    for (const item of this.selectingItems) {
      if (item.value) {
        const column = {
          name: item.newName,
          code: item.column.code,
          dataType: item.column.dataType,
          luisMapping: item.column.luisMapping,
        };
        selectedItems.push(column);
      }
    }
    this.$emit('input', selectedItems);
    this.$emit('need-refresh-table-data');
    EventBus.$emit('reset-active-column-view');
    EventBus.$emit('close-column-selector-form');
  }

  public createColumnView() {
    if (!this.newColumnView.name) {
      return;
    }
    const selectedItems: any = [];
    for (const item of this.selectingItems) {
      if (item.value) {
        const column = {
          name: item.newName,
          code: item.column.code,
          dataType: item.column.dataType,
          luisMapping: item.column.luisMapping,
        };
        selectedItems.push(column);
      }
    }
    EventBus.$emit('create-column-view', {
      name: this.newColumnView.name,
      isDefault: this.newColumnView.isDefault,
      index: -1,
      reportColumns: selectedItems,
      customReportId: this.customReportId,
      templateReportId: this.templateReportId,
    });
    this.isCreatingColumnView = false;
  }

  public updateColumnView() {
    if (!this.newColumnView.name) {
      return;
    }
    const selectedItems: any = [];
    for (const item of this.selectingItems) {
      if (item.value) {
        const column = {
          name: item.newName,
          code: item.column.code,
          dataType: item.column.dataType,
          luisMapping: item.column.luisMapping,
        };
        selectedItems.push(column);
      }
    }
    EventBus.$emit('create-column-view', {
      name: this.newColumnView.name,
      isDefault: this.newColumnView.isDefault,
      index: this.activeColumnViewIndex,
      reportColumns: selectedItems,
      customReportId: this.customReportId,
      templateReportId: this.templateReportId,
    });
    this.isCreatingColumnView = false;
  }

  @Watch('columnSelectorMenu', { immediate: true, deep: true })
  private async onColumnSelectorMenuChange(newVal: any) {
    if (newVal && newVal === true) {
      this.columnSelector = this.columnSelectorMenu;
    }
  }

  @Watch('initReportColumns', { immediate: true, deep: true })
  private async onReportColumnsChange(newVal: any) {
    if (newVal) {
      if (this.initReportColumns && this.initReportColumns.length > 0) {
        this.reportColumns = [];
        for (const item of this.initReportColumns) {
          this.reportColumns.push(item);
        }
        this.initSelectingItems();
        this.initPivotColumns();
        this.initPivotData();
        this.initMetricSources();
      }
    }
  }

  @Watch('dimensionColumns', { immediate: true, deep: true })
  private async onDimensionColumnsChange(newVal: any) {
    if (this.dimensionColumns && this.dimensionColumns.size() > 0) {
      this.refreshColumnGroup();
    }
  }

  @Watch('activeColumnView', { immediate: true, deep: true })
  private async onactiveColumnViewChanged(newVal: any) {
    if (this.activeColumnView && this.activeColumnView.name) {
      this.newColumnView.name = this.activeColumnView.name;
      this.newColumnView.isDefault = this.activeColumnView.isDefault;
      this.isCreatingColumnView = true;
    }
  }

  @Watch('initCalculatedMetrics', { immediate: true, deep: true })
  private async initCalculatedMetricsChanged(newVal: any) {
    if (
      this.initCalculatedMetrics &&
      this.initCalculatedMetrics.items.length > 0
    ) {
      this.calculatedMetrics = this.initCalculatedMetrics;
    }
  }

  @Watch('dialogDelete', { immediate: true, deep: true })
  private async dialogDeleteChanged(newVal: any) {
    if (!this.dialogDelete) {
      this.activeMetricIndex = -1;
      this.deletingMetric = new MetricModel();
    }
  }
}
