
import { Component, Prop, Vue } from 'vue-property-decorator';
import HeaderTitle from '@/components/HeaderTitle.vue';
import ActionButton from '@/components/ActionButton.vue';
import { StoreModel } from '@/models/store';
import { EventBus } from "@/main";
import { EmailTemplateModel } from "@/models/email_template";
import { EmailTemplateList } from "@/collections/email_templates";
import { EventHandler } from "@/modules/events";
import { VueEditor } from "vue2-editor";
import StatusPageComponent from '@/components/StatusPage.vue';
import { EmailReceiverList } from "@/collections/email_receivers";
import { EmailReceiverModel } from '@/models/email_receiver';

@Component({
  components: {
    HeaderTitle,
    ActionButton,
    VueEditor,
    StatusPageComponent,
  },
})
export default class EmailTemplate extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public success: boolean = false;
  public error: boolean = false;
  public isLoading: boolean = false;
  public dialogDelete: boolean = false;
  public headers: any = [];
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  public deleteLoading: boolean = false;
  public saveLoading: boolean = false;
  public emailTemplate: EmailTemplateModel = new EmailTemplateModel();
  public notFoundError: boolean = false;
  public testEmailTemplateDialog: boolean = false;
  public testingReceiver: EmailReceiverModel = new EmailReceiverModel();
  public emailReceivers: EmailReceiverList = new EmailReceiverList();
  public sendingTestingEmail: boolean = false;

  public async created() {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`view email template detail page`);
    } catch (e) {
      // ignore this
    }
    this.isLoading = true;
    this.emailReceivers = new EmailReceiverList();
    await this.emailReceivers.fetch();
    await this.fullLoadData();
    this.isLoading = false;
  }

  public get variables() {
    return {
      items: [
        {
          name: 'Report content',
          description: 'The content of report such as the summary, the table detail, etc. Report content is a required param in the content of a template with the code {{report_content}}',
          code: '{{report_content}}'
        },
        {
          name: 'Report title',
          description: '',
          code: '{{report_title}}'
        },
        {
          name: 'Report download links',
          description: '',
          code: '{{report_download_url}}'
        },
        {
          name: 'Receiver name',
          description: '',
          code: '{{receiver_name}}'
        },
        {
          name: 'Receiver email',
          description: '',
          code: '{{receiver_email}}'
        },
      ]
    };
  }

  public async copyVariable(code: string) {
    await navigator.clipboard.writeText(code);
    EventBus.$emit(
      'show-snackbar',
      `Copied ${code} successfully!`
    );
  }

  public setupTestingEmailTemplate() {
    this.testingReceiver = new EmailReceiverModel();
    this.sendingTestingEmail = false;
    this.testEmailTemplateDialog = true;
  }

  public async sendTestingEmail() {
    this.sendingTestingEmail = true;
    try {
      await this.emailTemplate.test(this.testingReceiver.id);
      EventBus.$emit(
        'show-snackbar',
        `Send test email to ${this.testingReceiver.email} successfully!`
      );
      this.testEmailTemplateDialog = false;
    } catch (e: any) {
      if (e.name === 'AxiosError') {
        if (e.response.status === 400) {
          EventBus.$emit(
            'show-snackbar',
            {
              message: 'Receiver is required to send test email.',
              color: 'red',
            }
          );
        }
      }
    }
    this.sendingTestingEmail = false;
  }

  public get customToolbar() {
    return [
      ['bold', 'italic', 'underline', { list: 'bullet' }, 'link'],
    ];
  }

  public async saveTemplate() {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`save email template`, {});
    } catch (e) {
      // ignore this
    }
    this.saveLoading = true;
    try {
      await this.emailTemplate.update();
      EventBus.$emit(
        'show-snackbar',
        'Saved template successfully!'
      );
    } catch (e: any) {
      if (e.name === 'AxiosError') {
        if (e.response.status === 400) {
          const error: any = JSON.parse(e.response.data);
          EventBus.$emit(
            'show-snackbar',
            {
              message: error.message,
              color: 'red',
            }
          );
        }
      }
    }
    this.saveLoading = false;
  }

  public async fullLoadData() {
    this.isLoading = true;
    this.emailTemplate = new EmailTemplateModel();
    this.emailTemplate.id = Number(this.$route.params.template_id);
    this.notFoundError = !this.emailTemplate.id;
    if (!this.notFoundError) {
      try {
        await this.emailTemplate.fetch();
      } catch (e: any) {
        if (e.name === 'AxiosError') {
          if (e.response.status === 404) {
            this.notFoundError = true;
          }
        }
      }
    }
    this.isLoading = false;
  }

  public async confirmDelete(template: EmailTemplateModel) {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`confirm delete email template`);
    } catch (e) {
      // ignore this
    }
    await this.deleteItem();
  }

  public async deleteItem() {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`delete email template`);
    } catch (e) {
      // ignore this
    }
    this.deleteLoading = true;
    let index: number = -1;
    await this.emailTemplate.remove();
    EventBus.$emit('show-snackbar', 'Email template deleted successfully!');
    this.deleteLoading = false;
    this.dialogDelete = false;
  }

  public async activeTemplate() {
    this.saveLoading = true;
    this.emailTemplate.status = 'ACTIVE';
    await this.emailTemplate.active();
    this.saveLoading = false;
  }

  public async deactiveTemplate() {
    this.saveLoading = true;
    this.emailTemplate.status = 'INACTIVE';
    await this.emailTemplate.deactive();
    this.saveLoading = false;
  }
}
