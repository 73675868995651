
import { Component, Watch, Vue, Prop } from 'vue-property-decorator';
import { ReportMetadataList } from '@/collections/report_metadatas';
import { ReportMetadataModel } from '@/models/report_metadata';
import { TagList } from '@/collections/tags';
import { TagModel } from '@/models/tag';
import { TagTypeModel } from '@/models/tag_type';
import { TagTypeList } from '@/collections/tag_types';
import HeaderTitle from '@/components/HeaderTitle.vue';
import ChatWithUs from '@/components/ChatWithUs.vue';
import { EventHandler } from '@/modules/events';
import { MeasureList } from '@/collections/measures';
import { StoreModel } from '@/models/store';
import { OpenaiModel } from '@/models/openai';
import { FunnelEventModel } from '@/models/funnelEvent';

interface ReportTerm {
  term: string;
  count: number;
  selected: boolean;
}

@Component({
  components: {
    HeaderTitle,
    ChatWithUs,
  },
})
export default class TemplateReports extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: () => new ReportMetadataList() })
  public templateReports!: ReportMetadataList;
  public reports: ReportMetadataList = new ReportMetadataList();
  @Prop({ default: () => new TagList() })
  public tags!: TagList;
  public selectedTag: TagModel = new TagModel();
  public tagType: TagTypeModel = new TagTypeModel();
  @Prop({ default: () => new TagTypeList() })
  public tagTypes!: TagTypeList;

  @Prop({ default: false })
  public loading!: boolean;
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: false })
  public hasSchemas!: boolean;
  @Prop({ default: 'available' })
  public isLockedReports!: string;
  public grouping: boolean = false;
  public index: number = 0;
  public search: string = '';
  public reportSearchResults: ReportMetadataList = new ReportMetadataList();
  public reportTerms: ReportTerm[] = [];
  public maxTermShow: number = 15;
  public openaiModel: OpenaiModel = new OpenaiModel();
  public thinking: boolean = false;
  public icon: string = 'mdi-message-text';
  public color: string = 'grey';
  public intervalId: any = null;
  public count: number = 0;
  public maxCount: number = 3;
  public intervalTime: number = 500;
  public iconText: string = '';
  public isSearch: boolean = false;
  public aiSearch: boolean = true;
  public countedReport: boolean = false;
  public isLoadingDashboardList: boolean = false;
  public hasPremiunReport: boolean = false;
  public firstTimeInit: boolean = true;
  public reportsByTag: any = [];

  public async created() {
    this.initTagType();
    try {
      const funnelEvent = new FunnelEventModel({
        name: 'VIEWED_REPORT_LIBRARY',
      });
      funnelEvent.create();
    } catch (error) {
      // skipped
    }
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`view reports page`);
    } catch (e) {
      // ignore this
    }
  }

  public formatText(input: string) {
    // Keep the first character of the string in uppercase and the rest in lowercase
    return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
  }

  public initTagType() {
    for (const tagType of this.tagTypes.items) {
      if (!tagType.tags || tagType.tags.size() === 0) {
          for (const tag of this.tags.items) {
            if (tag.tagType.id === tagType.id) {
              tagType.tags.add(tag);
            }
        }
      }
    }
    this.selectedTag = new TagModel();
    const tagTypeId = Number(this.$route.params.type_id);
    if (tagTypeId === 0) {
      this.tagType = new TagTypeModel();
      this.tagType.id = 0;
      this.tagType.name = 'All Categories';
      this.tagType.tags = this.tags;
      return;
    }
    for (const item of this.tagTypes.items) {
      if (tagTypeId === item.id) {
        const tagType = new TagTypeModel();
        tagType.id = item.id;
        tagType.name = item.name;
        tagType.tags = item.tags;
        this.tagType = tagType;
      }
    }
  }

  public generateReportByTag(orginalTag: TagModel) {
    for (const report of this.reportSearchResults.items) {
      for (const tag of report.tags.items) {
        if (tag.id === orginalTag.id) {
          //check if the array reportsByTag has the an item with index value of tagId
          //then push the report to the array
          //if not, add an item { tag: tag, reports: []};
          let exist = false;
          for (const item of this.reportsByTag) {
            if (item.tag.id === orginalTag.id) {
              exist = true;
              item.reports.push(report);
            }
          }
          if (!exist) {
            this.reportsByTag.push({ tag: orginalTag, reports: [report] });
          }
          break;
        }
      }
    }
  }

  public generateReportByCurrentTagType() {
    this.reportsByTag = [];
    for (const tag of this.tagType.tags.items) {
      this.generateReportByTag(tag);
    }
  }

  public selectTag(tag: TagModel) {
    this.search = '';
    if (tag.id === this.selectedTag.id) {
      this.selectedTag = new TagModel();
      this.init();
    } else {
      this.selectedTag = tag;
      this.startThinking();
      this.generateReportForSelectedTag();
      this.stopThinking();
    }
  }

  public generateReportForSelectedTag() {
    if (!this.selectedTag || !this.selectedTag.id) {
      return;
    }
    const newReports: ReportMetadataList = new ReportMetadataList();
    for (const report of this.templateReports.items) {
        for (const tag of report.tags.items) {
          if (tag.id === this.selectedTag.id) {
            if (this.search) {
              if (report.name.toLowerCase().includes(this.search.toLowerCase())) {
                newReports.add(report);
              }
            } else {
              newReports.add(report);
            }
            break;
          }
        }
      }
    this.reportSearchResults = newReports;
  }

  public startThinking() {
    this.thinking = true;
    this.intervalId = setInterval(() => {

      if (this.count >= this.maxCount) {
        this.iconText = '';
        this.count = 0;
      } else {
        this.iconText = this.iconText + '.';
        this.count = this.count + 1;
      }
    }, this.intervalTime);
    this.color = 'blue';

  }

  public stopThinking() {
    clearInterval(this.intervalId);
    this.iconText = '.';
    this.color = 'grey';
    this.count = 1;
    this.thinking = false;
  }

  public async searchReport(replaceUrl: boolean = true) {
    const currentKeyword = this.$route.query.keyword;
      if (replaceUrl && this.search !== currentKeyword && this.search !== undefined  && this.search !== null ) {
        this.$router.replace({ query: { keyword: this.search } });
      } else if (currentKeyword && currentKeyword !== undefined && currentKeyword !== null){
        this.search = currentKeyword + '';
      }
      this.isSearch = true;
      this.reportSearchResults = new ReportMetadataList();
      this.startThinking();

      this.reportSearchResults.items = this.templateReports.items.filter((item) => {
        if (item.name.toLowerCase().includes(this.search.toLowerCase())) {
          return item;
        }
      });

      this.generateReportByCurrentTagType();
      this.stopThinking();

  }

  public getTranslatedText(text: string, module: string) {
    if (this.$te(`${module}.${text}`)) {
      return this.$t(`${module}.${text}`);
    } else {
      return text;
    }
  }

  public getTranslatedReportName(reportName: string) {
    let name: string = reportName;
    if (this.$te(`report_name.${reportName}`)) {
      name = this.$t(`report_name.${reportName}`) + '';
    } else {
      name = reportName;
    }
    //low case name
    name = name.toLowerCase();
    //make first letter upper case
    name = name.charAt(0).toUpperCase() + name.slice(1);
    return name;
  }

  public getTranslatedTagName(tagName: string) {
    if (this.$te(`report_tags.${tagName}`)) {
      return this.$t(`report_tags.${tagName}`);
    } else {
      return tagName;
    }
  }

  public get pageTitle() {
    if (this.tagType.name && this.tagType.name !== undefined) {
      return this.formatText(this.tagType.name);
    } else {
      return '';
    }
  }

  public init() {
    this.startThinking();
    this.isSearch = false;
    this.reportSearchResults = this.templateReports

    if (this.selectedTag && this.selectedTag.id) {
      this.generateReportForSelectedTag();
    } else {
      this.generateReportByCurrentTagType();
    }
    this.stopThinking();
  }

  public viewReport(item: ReportMetadataModel, tag: TagModel) {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track('click on report', {
        tagId: tag.id,
        templateReportId: item.id,
        tagName: tag.name,
        templateReportName: item.name,
      });
    } catch (e) {
      // ignore this
    }
    this.$router.push(`/reports/${this.tagType.id}/${this.tagType.name.replace(/\s+/g, '-').toLowerCase()}/${item.id}`);
  }

  public getReportLink(item: ReportMetadataModel) {
    return (`/reports/${this.tagType.id}/${this.tagType.name.replace(/\s+/g, '-').toLowerCase()}/${item.id}`);
  }

  public goToRoadmap() {
    window.open('https://roadmap.assisty.ai', '_blank');
  }

  private sort(reports: ReportMetadataList, tag: TagModel) {
    const compare = (a: ReportMetadataModel, b: ReportMetadataModel) => {
      if (!a.sortOrder || !a.sortOrder.tag) {
        return 1;
      }
      if (!b.sortOrder || !b.sortOrder.tag) {
        return -1;
      }
      if (a.sortOrder.tag[tag.id] < b.sortOrder.tag[tag.id]) {
        return -1;
      }
      if (a.sortOrder.tag[tag.id] > b.sortOrder.tag[tag.id]) {
        return 1;
      }
      return 0;
    };
    reports.items = reports.items.sort(compare);
    return reports;
  }

  private sortReportTerm(reportTerms: ReportTerm[]) {
    const compare = (a: ReportTerm, b: ReportTerm) => {
      return b.count - a.count;
    };
    reportTerms = reportTerms.sort(compare);
    return reportTerms;
  }

  @Watch('$route.params.type_id', { immediate: true, deep: true })
  private async tagTypeChanged(newVal: any) {
    if (this.firstTimeInit) {
      return;
    }
    if (this.templateReports.size() === 0) {
      return;
    }
    this.initTagType();
    await this.init();
    this.searchReport(false);
  }

  @Watch('$route.path', { immediate: true, deep: true  })
  private async pathChanged(newVal: any) {
    if (['/reports', '/'].includes(newVal) && this.tagTypes.size() > 0) {
      this.$router.push(`/reports/0}/all-categories`);
    }
  }

  @Watch('templateReports', { immediate: true, deep: true  })
  private async templateReportsChanged(newVal: any) {
    if (!this.templateReports || this.templateReports.items.length === 0) {
      return;
    }
    if (this.firstTimeInit) {
      this.firstTimeInit = false;
    }
    this.initTagType();
    await this.init();
    const search = this.$route.query.keyword;
    if (search && search !== undefined) {
      this.search = search + '';
      this.searchReport(false);
    }
  }
}
