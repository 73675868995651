
import { Component, Prop, Vue } from 'vue-property-decorator';
import HeaderTitle from '@/components/HeaderTitle.vue';
import ActionButton from '@/components/ActionButton.vue';
import { StoreModel } from '@/models/store';
import { EventBus } from "@/main";
import { EmailTemplateModel } from "@/models/email_template";
import { EmailTemplateList } from "@/collections/email_templates";
import { EventHandler } from "@/modules/events";
import { VueEditor } from "vue2-editor";
import dateFormat from 'dateformat';
import EmptyStage from '@/components/EmptyStage.vue';

@Component({
  components: {
    HeaderTitle,
    ActionButton,
    EmptyStage,
    VueEditor,
  },
})
export default class EmailTemplates extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public success: boolean = false;
  public error: boolean = false;
  public isLoading: boolean = false;
  public emailTemplates: EmailTemplateList = new EmailTemplateList();
  public deletingTemplate: EmailTemplateModel = new EmailTemplateModel();
  public dialogDelete: boolean = false;
  public headers: any = [];
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  public deleteLoading: boolean = false;
  public saveLoading: boolean = false;
  public newTemplateDialog: boolean = false;
  public newTemplate: EmailTemplateModel = new EmailTemplateModel();

  public async created() {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`view email template listing page`);
    } catch (e) {
      // ignore this
    }
    this.isLoading = true;

    this.headers = [
      {
        text: 'ID',
        value: 'id',
      },
      {
        text: 'Title',
        value: 'title',
      },
      {
        text: 'Created at',
        value: 'createdAt',
      },
      {
        text: 'Last updated at',
        value: 'updatedAt',
      },
    ];
    await this.fullLoadData();
    this.isLoading = false;
  }

  public get customToolbar() {
    return [
      ['bold', 'italic', 'underline', { list: 'bullet' }, 'link'],
    ];
  }

  public async saveTemplate() {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`save email template`, {});
    } catch (e) {
      // ignore this
    }
    this.saveLoading = true;
    if (this.newTemplate.id) {
      await this.newTemplate.update();
      EventBus.$emit('show-snackbar', 'Supplier updated successfully!');
    } else {
      await this.newTemplate.create();
      EventBus.$emit('show-snackbar', 'Supplier added successfully!');
    }
    this.saveLoading = false;
    this.newTemplateDialog = false;
    await this.fullLoadData();
  }

  public canManageSupplier() {
    return true;
  }

  public async fullLoadData() {
    this.isLoading = true;
    this.emailTemplates = new EmailTemplateList();
    await this.emailTemplates.fetch();
    this.isLoading = false;
  }

  public editTemplate(template: EmailTemplateModel) {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`click edit email template`);
    } catch (e) {
      // ignore this
    }
    this.newTemplate = template;
    this.newTemplateDialog = true;
  }

  public confirmDelete(template: EmailTemplateModel) {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`confirm delete email template`);
    } catch (e) {
      // ignore this
    }
    this.deletingTemplate = template;
    this.dialogDelete = true;
  }

  public async deleteItem() {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`delete email template`);
    } catch (e) {
      // ignore this
    }
    this.deleteLoading = true;
    let index: number = -1;
    for (const item of this.emailTemplates.items) {
      index = index + 1;
      if (item.id === this.deletingTemplate.id) {
        this.emailTemplates.items.splice(index, 1);
        break;
      }
    }
    await this.deletingTemplate.remove();
    EventBus.$emit('show-snackbar', 'Email template deleted successfully!');
    this.deleteLoading = false;
    this.dialogDelete = false;
  }
}
