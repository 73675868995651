
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import Selector from '../components/OptionSelector.vue';

@Component({
  components: {
    Selector,
  },
})
export default class SchedulerOutputEmail extends Vue {
  @Prop({ default: () => {} })
  public value!: any;
  @Prop({ default: [] })
  public defaultEmails!: string[];
  @Prop({ default: [] })
  public selectedEmails!: string[];
  public emailError: boolean = false;
  public emailErrorMessage: string = '';
  public emailRules: any = [
    this.isEmailExist,
    this.isEmailValidate,
  ];
  public currentValueEmail: string|null = null;
  public emails: string[] = [];
  public created() {
   
  }
  public isEmailExist(v: any) {
    return !!v || 'E-mail is required';
  }

  public isEmailValidate(v: any) {
    let isValid: boolean = true;
    for (const email of this.emails) {
      isValid = isValid && /.+@.+/.test(email);
    }
    return isValid || 'E-mail must be valid';
  }

  public assignSelectedData() {
    this.$emit('input', this.emails);
  }

  public updateEmails(currentValue: string) {
    this.currentValueEmail = currentValue;
  }

  @Watch('emails', { immediate: true, deep: true })
  private onEmailsChanged(newVal: any) {
    this.assignSelectedData();
  }

  @Watch('selectedEmails', { immediate: true, deep: true })
  private onSelectedEmailsChanged(newVal: any) {
    if (this.selectedEmails && this.selectedEmails.length > 0) {
      this.emails = this.selectedEmails;
    }
  }
}
