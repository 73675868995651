
import { Component, Watch, Vue, Prop } from 'vue-property-decorator';
import { ReportMetadataList } from '@/collections/report_metadatas';
import { ReportMetadataModel } from '@/models/report_metadata';
import { TagTypeModel } from '@/models/tag_type';
import { TagTypeList } from '@/collections/tag_types';
import { EventHandler } from '@/modules/events';
import { StoreModel } from '@/models/store';
import { DashboardList } from '@/collections/dashboards';


interface ReportTerm {
  term: string;
  count: number;
  selected: boolean;
}

@Component({
  components: {},
})
export default class SidebarMenu extends Vue {
  public tagTypes: TagTypeList = new TagTypeList();
  public tagType: TagTypeModel = new TagTypeModel();
  public publicDashboards: DashboardList = new DashboardList();
  public isLoadingDashboardList: boolean = false;
  public async created() {
    this.tagTypes.fetch();
    this.initTagType();
    this.loadDashboardList();
  }

  public async loadDashboardList() {
    try {
      if (this.publicDashboards.size() === 0) {
        this.isLoadingDashboardList = true;
        await this.publicDashboards.fetchPublished();
        this.isLoadingDashboardList = false;
      }
    } catch (e) {
      this.isLoadingDashboardList = false;
    }
  }

  public initTagType() {
    const tagTypeId = Number(this.$route.params.type_id);
    for (const item of this.tagTypes.items) {
      if (tagTypeId === item.id) {
        const tagType = new TagTypeModel();
        tagType.id = item.id;
        tagType.name = item.name;
        tagType.tags = item.tags;
        this.tagType = tagType;
        break;
      }
    }
  }

  @Watch('$route.params.type_id', { immediate: true, deep: true })
  private async tagTypeChanged(newVal: any) {
    this.initTagType();
  }

  @Watch('$route.path', { immediate: true, deep: true })
  private async pathChanged(newVal: any) {
    if (['/reports', '/'].includes(newVal) && this.tagTypes.size() > 0) {
      this.$router.push(
        `/reports/${this.tagTypes.items[0].id}/${this.tagTypes.items[0].name
          .replace(/\s+/g, '-')
          .toLowerCase()}`,
      );
    }
  }
}
