
import { Component, Prop, Vue } from 'vue-property-decorator';
import HeaderTitle from '../components/HeaderTitle.vue';
import Steps from '../components/Steps.vue';

@Component({
  components: {
    HeaderTitle,
    Steps,
  },
})
export default class OnboardPage extends Vue {
  @Prop({ default: '' })
  public nextPage!: string;
  @Prop({ default: 5 })
  public steps!: number;
  @Prop({ default: 1 })
  public active!: number;
  @Prop({ default: 'small' })
  public size!: string;
  @Prop({ default: 'Next' })
  public action!: string;
  @Prop({ default: false })
  public loading!: boolean;

  public async click() {
    this.$emit('submitted');
  }
}
