import { render, staticRenderFns } from "./ProductSegmentBuilder.vue?vue&type=template&id=61c7b94d&"
import script from "./ProductSegmentBuilder.vue?vue&type=script&lang=ts&"
export * from "./ProductSegmentBuilder.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports