
import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import HeaderTitle from "@/components/HeaderTitle.vue";
import { get } from "@/services/http";
import HourSelector from "@/components/SchedulerHourSelector.vue";
import DaySelector from "@/components/SchedulerDaySelector.vue";
import OutputEmail from "@/components/SchedulerOutputEmail.vue";
import OutputFileFormat from "@/components/SchedulerOutputFileFormat.vue";
import OutputSlack from "@/components/SchedulerOutputSlack.vue";
import OutputDrive from "@/components/SchedulerOutputDrive.vue";
import ComingSoon from "@/components/SchedulerComingSoon.vue";
import { StoreModel } from "@/models/store";
import { RuleModel } from "@/models/rule";
import FeatureLockedNotify from "@/components/FeatureLockedNotify.vue";
import { ScheduleProfileModel } from "@/models/schedule_profile";
import { ScheduleModel } from "@/models/schedule";
import { MeasureList } from "@/collections/measures";
import { EventHandler } from "@/modules/events";
import { ReportModel } from "@/models/report";
import dateFormat from "dateformat";
import FilterColumn from "@/components/FilterColumn.vue";
import { FilterColumnList } from "@/collections/filter_columns";
import { CustomReportList } from "@/collections/custom_reports";
import { CustomReportModel } from "@/models/custom_report";
import Alert from "@/components/Alert.vue";
import ActionButton from "@/components/ActionButton.vue";
import GoogleDriveSelector from "@/components/GoogleDriveSelector.vue";
import BaseComponent from "@/components/BaseComponent.vue";
import { EventBus } from "@/main";
import { EmailReceiverModel } from "@/models/email_receiver";
import { EmailReceiverList } from "@/collections/email_receivers";
import { EmailTemplateList } from "@/collections/email_templates";
import { EmailTemplateModel } from "@/models/email_template";

@Component({
  components: {
    HeaderTitle,
    HourSelector,
    DaySelector,
    OutputSlack,
    OutputDrive,
    OutputEmail,
    FeatureLockedNotify,
    ComingSoon,
    FilterColumn,
    OutputFileFormat,
    Alert,
    ActionButton,
    GoogleDriveSelector,
    BaseComponent
  },
})
export default class ScheduleCreate extends BaseComponent {
  public module: string = 'schedule_reports_count';
  public scheduleCount: string = '';

  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: null })
  public app!: any;

  @Prop({ default: () => new ScheduleModel() })
  public initSchedule!: ScheduleModel;
  @Prop({ default: () => 1 })
  public createScheduleStep!: number;
  public dates: string[] = [];
  public loading: boolean = false;
  public error: boolean = false;
  public errorMessage: string = "";
  public measureError: boolean = false;
  public measureErrorMessage: string = "";
  public timeRangeError: boolean = false;
  public timeRangeErrorMessage: string = "";
  public installing: boolean = false;
  public selectedCustomReports: CustomReportList = new CustomReportList();
  public measure: string = "";
  public dimension: string = "";
  public analyticType: string = "";
  public merchantRole: string = "";
  public step: number = 1;
  public steps: number = 4;
  public cancelTitle: string = "Cancel";
  public nextTitle: string = "Next";
  public deliveryTitle: string = "When does the schedule run?";
  public deliverySubtitle: string = "Time from 0:00 to 23:00";
  public filter: string = "";
  public newProfile: ScheduleProfileModel = new ScheduleProfileModel();
  public newSchedule: ScheduleModel = new ScheduleModel();
  public googleEmails: string[] = [];
  public defaultEmails: string[] = [];
  public interval: any = { text: "Daily", value: "daily" };
  public intervals: any = [
    { text: "Daily", value: "daily" },
    { text: "Weekly", value: "weekly" },
    { text: "Monthly", value: "monthly" },
  ];
  public reportColumns: any = [];
  public report: ReportModel = new ReportModel();
  public filterColumns: FilterColumnList = new FilterColumnList();
  public initFilterColumns: FilterColumnList = new FilterColumnList();
  public turnonFilter: boolean = false;
  public isLoadingColumn: boolean = false;
  public dimensions: any = [
    "Day",
    "Week",
    "Month",
    "Product",
    "Collection",
    "Product Variant",
    "Location",
  ];
  public timeranges: any = [];
  public weekDays: any = [];
  public times: any = [];
  public days: any = [
    { text: "01", value: "01" },
    { text: "02", value: "02" },
    { text: "03", value: "03" },
    { text: "04", value: "04" },
    { text: "05", value: "05" },
    { text: "06", value: "06" },
    { text: "07", value: "07" },
    { text: "08", value: "08" },
    { text: "09", value: "09" },
    { text: "10", value: "10" },
    { text: "11", value: "11" },
    { text: "12", value: "12" },
    { text: "13", value: "13" },
    { text: "14", value: "14" },
    { text: "15", value: "15" },
    { text: "16", value: "16" },
    { text: "17", value: "17" },
    { text: "18", value: "18" },
    { text: "19", value: "19" },
    { text: "20", value: "20" },
    { text: "21", value: "21" },
    { text: "22", value: "22" },
    { text: "23", value: "23" },
    { text: "24", value: "24" },
    { text: "25", value: "25" },
    { text: "26", value: "26" },
    { text: "27", value: "27" },
    { text: "28", value: "28" },
    { text: "29", value: "29" },
    { text: "30", value: "30" },
    { text: "31", value: "31" },
    { text: "Two days before end of Month", value: "-3" },
    { text: "One day before end of Month", value: "-2" },
    { text: "Last day of Month", value: "-1" },
  ];
  public hours: any = [
    { text: "01 AM", value: "01" },
    { text: "02 AM ", value: "02" },
    { text: "03 AM", value: "03" },
    { text: "04 AM", value: "04" },
    { text: "05 AM", value: "05" },
    { text: "06 AM", value: "06" },
    { text: "07 AM", value: "07" },
    { text: "08 AM", value: "08" },
    { text: "09 AM", value: "09" },
    { text: "10 AM", value: "10" },
    { text: "11 AM", value: "11" },
    { text: "12 PM", value: "12" },
    { text: "13 PM", value: "13" },
    { text: "14 PM", value: "14" },
    { text: "15 PM", value: "15" },
    { text: "16 PM", value: "16" },
    { text: "17 PM", value: "17" },
    { text: "18 PM", value: "18" },
    { text: "19 PM", value: "19" },
    { text: "20 PM", value: "20" },
    { text: "21 PM", value: "21" },
    { text: "22 PM", value: "22" },
    { text: "23 PM", value: "23" },
    { text: "0 AM", value: "00" },
  ];
  public selectedHours: string[] = [];
  public selectedDays: string[] = [];
  public emailOutput: any = {};
  public slackOutput: any = {};
  public driveOutput: any = {};
  public spreadsheetOutput: any = {};
  public measureRules: any = [this.isMeasureExit];
  public customReports: CustomReportList = new CustomReportList();
  public scheduleSuccess: boolean = false;

  public selectedGoogleAccount: string = "";
  public selectedDriveFolder: string = "";
  public googleSheetsDestination: string = "";
  public saveAsNewScheduleProfile: boolean = false;
  public receivers: EmailReceiverList = new EmailReceiverList();
  public selectedReceivers: EmailReceiverModel[] = [];
  public emailTemplates: EmailTemplateList = new EmailTemplateList();
  public selectedEmailTemplate: EmailTemplateModel = new EmailTemplateModel();

  // variables of custom email template
  public useCustomEmailTemplate: boolean = false;
  public emailNoData: boolean = false;

  public async created() {
    this.loading = true;
    await this.receivers.fetch();
    await this.emailTemplates.fetch();
    try {
      const res: any = await get('/schedule/count');
      if (res && res.data) {
        this.scheduleCount = res.data['count'].toString();
      }
    } catch (e: any) {
      //show error
      EventBus.$emit('show-snackbar', {
        message: 'Failed to get schedule count',
        color: 'error',
      });
    }

    this.step = this.createScheduleStep;

    this.translateDateLabel();

    for (let i = 0; i < 24; i++) {
      if (i < 10) {
        this.times.push({
          label: `0${i}:00`,
          value: `0${i}`,
        });
      } else {
        this.times.push({
          label: `${i}:00`,
          value: `${i}`,
        });
      }
    }
    try {
      await this.getContact();
    } catch (e) {
      //show error
      EventBus.$emit('show-snackbar', {
        message: 'Failed to get contact email',
        color: 'error',
      });
    }
    this.loading = false;
  }

  public operatorName(value: string) {
    const operators: any = [
      { name: "equal", value: "eq" },
      { name: "equal (case-insensitive)", value: "eqi" },
      { name: "not equal", value: "ne" },
      { name: "is lower than", value: "lt" },
      { name: "is lower than or equal to", value: "lte" },
      { name: "is greater than", value: "gt" },
      { name: "is greater than or equal to", value: "gte" },
      { name: "contains", value: "contains" },
      { name: "not contains", value: "notContains" },
      { name: "is blank", value: "null" },
      { name: "is not blank", value: "notNull" },
    ];
    for (const item of operators) {
      if (item.value === value) {
        return item.name;
      }
    }
    return value;
  }

  public filterValuesToString(values: any) {
    if (!values) {
      return "";
    }
    return values.join(" or ");
  }

  public removeReport(index: number) {
    if (this.selectedCustomReports.items.length === 1) {
      return;
    }
    this.selectedCustomReports.items.splice(index, 1);
  }
  public isMeasureExit(v: any) {
    return !!v || this.$t("schedule.Please select a metric");
  }

  public isEmailExist(v: any) {
    return !!v || this.$t("onboard.E-mail is required");
  }

  public isEmailValidate(v: any) {
    return /.+@.+/.test(v) || this.$t("onboard.E-mail must be valid");
  }

  public generateSortByParam(sortBy: any = [], sortDesc: any = []) {
    if (!sortBy || sortBy.length === 0) {
      return [];
    }
    let index: number = 0;
    const sortByResult: any = [];
    for (let index = 0; index < sortBy.length; index++) {
      sortByResult.push({
        key: sortBy[index],
        order: sortDesc[index],
      });
    }

    return sortByResult;
  }

  public async saveSchedule() {
    this.sendMixpanelEvent("Save Schedule");
    this.loading = true;
    if (this.selectedReceivers.length > 0) {
      this.newSchedule.emails = this.selectedReceivers.map((receiver) => receiver.email);
      this.newSchedule.receivers = new EmailReceiverList();
      for (const receiver of this.selectedReceivers) {
        this.newSchedule.receivers.add(receiver);
      }
    }
    if (this.selectedEmailTemplate.id) {
      this.newSchedule.emailTemplate = this.selectedEmailTemplate;
    }

    this.newSchedule.interval = this.interval.value.toLowerCase();
    switch (this.interval.value) {
      case "daily": {
        this.newSchedule.deliveryWeekday = [];
        this.newSchedule.deliveryDate = [];
        break;
      }
      case "weekly": {
        this.newSchedule.deliveryDate = [];
        break;
      }
      case "monthly": {
        this.newSchedule.deliveryWeekday = [];
        break;
      }
    }
    //check if save to a new profile or not
    if (this.saveAsNewScheduleProfile) {
      this.saveScheduleProfile(this.newSchedule);
    }
    //create new schedule
    try {
      let nextRoute = '/schedules';
      if (this.newSchedule.id) {
        await this.newSchedule.update();
        nextRoute = `/schedule/${this.newSchedule.id}`;
      } else {
        await this.newSchedule.save();
      }
      localStorage.removeItem('schedule_to_create');
      sessionStorage.removeItem('create_schedule_step');

      console.log(nextRoute);

      this.$router.push(nextRoute);
      this.loading = false;
      EventBus.$emit('show-snackbar', {
        message: 'Schedule saved successfully',
        color: 'success',
      });
    } catch (e: any) {
      //show error
      EventBus.$emit('show-snackbar', {
        message: 'Failed to save schedule',
        color: 'error',
      });
    }


  }

  public saveScheduleProfile(schedule: ScheduleModel) {
    const newProfile: ScheduleProfileModel = new ScheduleProfileModel();
    newProfile.name = schedule.interval + ' - ' + schedule.outputType + ' - ' +  schedule.fileFormat;
    newProfile.outputType = schedule.outputType;
    newProfile.destination = schedule.destination;
    newProfile.fileFormat = schedule.fileFormat;
    newProfile.interval = schedule.interval;
    newProfile.timezone = schedule.timezone;
    newProfile.deliveryDate = schedule.deliveryDate;
    newProfile.deliveryWeekday = schedule.deliveryWeekday;
    newProfile.deliveryHour = schedule.deliveryHour;
    newProfile.emails = schedule.emails;
    newProfile.receivers = schedule.receivers;
    newProfile.emailTemplate = schedule.emailTemplate;
    newProfile.save();
  }

  public viewAllScheduledReport() {
    this.$router.push("/schedules");
  }

  public sendMixpanelEvent(eventName: string = "") {}

  public translateDateLabel() {
    this.weekDays = [
      {
        text: this.$t("schedule.Monday") + "",
        value: "1",
      },
      {
        text: this.$t("schedule.Tuesday") + "",
        value: "2",
      },
      {
        text: this.$t("schedule.Wednesday") + "",
        value: "3",
      },
      {
        text: this.$t("schedule.Thursday") + "",
        value: "4",
      },
      {
        text: this.$t("schedule.Friday") + "",
        value: "5",
      },
      {
        text: this.$t("schedule.Saturday") + "",
        value: "6",
      },
      {
        text: this.$t("schedule.Sunday") + "",
        value: "0",
      },
    ];
    this.timeranges = [
      { text: this.$t("schedule.Today") + "", value: "TODAY" },
      { text: this.$t("schedule.Yesterday") + "", value: "YESTERDAY" },
      { text: this.$t("schedule.7D") + "", value: "7D" },
      { text: this.$t("schedule.30D") + "", value: "30D" },
      { text: this.$t("schedule.3M") + "", value: "3M" },
      { text: this.$t("schedule.6M") + "", value: "6M" },
      { text: this.$t("schedule.1Y") + "", value: "1Y" },
      { text: this.$t("schedule.3Y") + "", value: "3Y" },
    ];
  }

  public async nextStep() {
    if (!this.validateInputData()) {
      return;
    }
    if (this.step < 4) {
      this.step = this.step + 1;
      sessionStorage.setItem('create_schedule_step', this.step.toString());
    } else {
      if (this.newSchedule.outputType === "drive" && this.newSchedule.fileFormat === "google_sheets") {
        this.newSchedule.destination = `${this.selectedGoogleAccount},${this.selectedDriveFolder}`;
      }
      if (this.newSchedule.outputType === 'google_sheets') {
        this.newSchedule.destination = this.googleSheetsDestination;
      }
      if (this.emailNoData) {
        this.newSchedule.options[0]['emailNoData'] = this.emailNoData;
      } else {
        this.newSchedule.options[0]['emailNoData'] = false;
      }
      await this.saveSchedule();
    }
  }

  public validateInputData() {
    this.error = false;
    this.errorMessage = "";
    switch (this.step) {
      case 1: {
        if (!this.newSchedule.title) {
          return false;
        }
        break;
      }
      case 3: {
        if (
          !this.newSchedule.deliveryHour ||
            this.newSchedule.deliveryHour.length === 0
        ) {
          this.error = true;
          this.errorMessage = "Please select a time slot";
          return false;
        }
        switch (this.interval.value) {
          case "weekly": {
            if (
              !this.newSchedule.deliveryWeekday ||
                this.newSchedule.deliveryWeekday.length === 0
            ) {
              this.error = true;
              this.errorMessage = "Please select at least one day";
              return false;
            }

            break;
          }
          case "monthly": {
            if (
              !this.newSchedule.deliveryDate ||
                this.newSchedule.deliveryDate.length === 0
            ) {
              this.error = true;
              this.errorMessage = "Please select a date";
              return false;
            }
            break;
          }
        }
        break;
      }
      case 4: {
        switch (this.newSchedule.outputType) {
          case "google_sheets":
            if (!this.googleSheetsDestination || !this.googleSheetsDestination.trim()) {
            this.error = true;
            this.errorMessage = "Please enter the url of google sheets file";
            return false;
          }
          break;
          case "email":
            if (
              !this.newSchedule.emails ||
                this.newSchedule.emails.length === 0
          ) {
            this.error = true;
            this.errorMessage = "Please enter an email";
            return false;
          }
          break;
          case "drive":
            if (!this.selectedGoogleAccount) {
            this.error = true;
            this.errorMessage = "Please select a Google Drive account";
            return false;
          }
          break;
          default:
            return false;
        }
        break;
      }
    }
    return true;
  }

  public getNotificationType(type: string) {
    switch (type) {
      case "email": {
        return "Email";
      }
      case "google_sheets": {
        return "Google Drive";
      }
    }
  }

  public async back() {
    if (this.step > 1) {
      this.step = this.step - 1;
      sessionStorage.setItem('create_schedule_step', this.step.toString());
    } else {
      localStorage.removeItem('schedule_to_create');
      sessionStorage.removeItem('create_schedule_step');
      this.$router.push("/schedules");
    }
  }

  public getCancelTitle() {
    if (this.step === 1) {
      return this.$t("schedule.Cancel");
    }
    return this.$t("schedule.Back");
  }

  public getNextTitle() {
    if (this.step === 4) {
      return "Save";
    }
    return "Next";
  }

  public async getContact() {
    if (this.newSchedule.emails && this.newSchedule.emails.length > 0) {
      localStorage.setItem("defaultEmail", this.newSchedule.emails[0]);
      this.defaultEmails = this.newSchedule.emails;
      return;
    }
    let defaultEmail: string = localStorage.getItem("defaultEmail") || "";
    if (!defaultEmail) {
      const res: any = await get("/settings");
      this.defaultEmails = [res.data.contactEmail];
      localStorage.setItem("defaultEmail", res.data.contactEmail);
    }
  }

  public canScheduleToExistingGoogleSpreadsheet() {
    const validateValues: any = [
      {
        code: "export_google_spreadsheet",
      },
    ];
    const validateResult = this.store.validateRule(validateValues);
    return validateResult.isValidate;
  }

  public updateSelectedGoogleAccount(account: string) {
    this.selectedGoogleAccount = account;
  }

  public updateSelectedDriveFolder(folder: string) {
    this.selectedDriveFolder = folder;
  }

  public initScheduleData() {
    this.newSchedule.id = this.initSchedule.id;
    this.newSchedule.title = this.initSchedule.title;
    this.newSchedule.outputType = this.initSchedule.outputType;
    this.newSchedule.destination = this.initSchedule.destination;
    this.newSchedule.fileFormat = this.initSchedule.fileFormat;
    this.newSchedule.interval = this.initSchedule.interval;
    this.newSchedule.timezone = this.initSchedule.timezone;
    this.newSchedule.deliveryDate = this.initSchedule.deliveryDate;
    this.newSchedule.deliveryWeekday = this.initSchedule.deliveryWeekday;
    this.newSchedule.deliveryHour = this.initSchedule.deliveryHour;
    this.newSchedule.emails = this.initSchedule.emails;
    this.newSchedule.options = this.initSchedule.options;
    this.newSchedule.receivers = this.initSchedule.receivers;
    if (this.newSchedule.options[0] && this.newSchedule.options[0]['emailNoData']) {
      this.emailNoData = this.newSchedule.options[0]['emailNoData'];
    }
    console.log(this.newSchedule.options);

    if (this.initSchedule.emailTemplate && this.initSchedule.emailTemplate.id) {
      this.newSchedule.emailTemplate = this.initSchedule.emailTemplate;
    }

    for (const item of this.intervals) {
      if (item.value === this.initSchedule.interval) {
        this.interval= item;
      }
    }
    // for outputType 'drive'
    if (this.newSchedule.outputType === 'drive' && this.newSchedule.fileFormat === 'google_sheets') {
      const tmp = this.newSchedule.destination.split(',');
      this.selectedGoogleAccount = tmp[0] || '';
      this.selectedDriveFolder = tmp[1] || '';
      this.newSchedule.destination = '';
    }
    if (this.newSchedule.outputType === 'google_sheets') {
      this.googleSheetsDestination = this.newSchedule.destination;
    }
  }

  @Watch("initSchedule", { immediate: true, deep: true })
  private async onInitScheduleChanged(newVal: any) {
    if (this.initSchedule) {
      this.useCustomEmailTemplate = this.initSchedule.emailTemplate.id ? true : false;
      this.initScheduleData();
    }
  }
}
