import { get, post, put, del } from '@/services/http';
import { FilterColumnList } from '@/collections/filter_columns';
import { FilterColumnModel } from '@/models/filter_column';
import { ParamModel } from '@/models/param';
import { ParamList } from '@/collections/params';
import { MeasureModel } from '@/models/measure';
import { DimensionModel } from '@/models/dimension';
import { AnalyticsTypeModel } from '@/models/analytics_type';

interface Option {
  sortBy?: any;
  sortDesc?: any;
  page?: string;
  itemsPerPage?: string;
  multiSort?: boolean;
}

export class ProductSegmentModel {
  public id!: number;
  public name: string = '';
  public type!: string;
  public description!: string;
  public status: string = 'active';
  public count: number = 0;
  public options: any;
  public created!: string;
  public updated!: string;
  public filters: FilterColumnList;
  public measure: MeasureModel;
  public dimension: DimensionModel;
  public analyticsType: AnalyticsTypeModel;
  public params: ParamList;
  public reportColumns: any = [];
  public timerange!: string[];
  public pageOptions!: Option;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.type = data.type;
    this.description = data.description;
    this.status = data.status;
    this.count = data.count;
    this.options = data.options;
    this.filters = new FilterColumnList();
    this.measure = new MeasureModel();
    this.dimension = new DimensionModel();
    this.analyticsType = new AnalyticsTypeModel({code: 'top_high'});
    this.params = new ParamList();
    this.timerange = [];
  }

  public async fetch() {
    const res: any = await get(`/segment/${this.id}`);
    this.mapData(res.data);
  }

  public async remove() {
    const res: any = await del(`/segment/${this.id}`);
  }

  public async save() {
    const data: any = {
      name: this.name,
      type: this.type,
      description: this.description,
      options: {
        filters: this.filters.items,
        params: this.params.items,
        measure: this.measure.code,
        dimension: this.dimension.code,
        analyticsType: this.analyticsType.code,
        reportColumns: this.reportColumns,
        timerange: this.timerange,
        pageOptions: this.pageOptions,
      },
    };

    const res: any = await post('/segment', data);
    this.mapData(res.data);
  }

  public async update() {
    const data: any = {
      name: this.name,
      type: this.type,
      description: this.description,
      options: {
        filters: this.filters.items,
        params: this.params.items,
        measure: this.measure.code,
        dimension: this.dimension.code,
        analyticsType: this.analyticsType.code,
        reportColumns: this.reportColumns,
        timerange: this.timerange,
        pageOptions: this.pageOptions,
      },
    };
    const res: any = await put(`/segment/${this.id}`, data);
  }

  public async generateProducts() {
    const data: any = {
      name: this.name,
      type: this.type,
      description: this.description,
      options: {
        filters: this.filters.items,
        params: this.params.items,
        measure: this.measure.code,
        dimension: this.dimension.code,
        analyticsType: this.analyticsType.code,
        reportColumns: this.reportColumns,
        timerange: this.timerange,
        pageOptions: this.pageOptions,
      },
    };
    const res: any = await post(`/segment/${this.id}/generate`, data);
  }
  public async addProducts(addProductIds: any) {
    const res: any = await post(`/segment/${this.id}/products`, {productIds: addProductIds});
  }

  public async removeProducts(removeProductIds: any) {
    const res: any = await del(`/segment/${this.id}/products`, {productIds: removeProductIds});
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.type = data.type;
    this.status = data.status;
    this.description = data.description;
    this.count = data.count;
    this.reportColumns = data.options.reportColumns;
    if (data.options.pageOptions) {
      this.pageOptions = data.options.pageOptions;
    }
    if (data.type === 'data_driven') {
      this.measure = new MeasureModel({ code: data.options.measure });
      this.dimension = new DimensionModel({ code: data.options.dimension });
      this.analyticsType = new AnalyticsTypeModel({ code: data.options.analyticsType });
      if (data.options.filters) {
        this.importFilters(data.options.filters);
      }
      if (data.options.params) {
        this.importParams(data.options.params);
      }
      if (data.options.timerange && data.options.timerange.length > 0) {
        this.timerange = data.options.timerange;
      }
    } else {
      this.measure = new MeasureModel({ code: 'product_info' });
      this.dimension = new DimensionModel({ code: 'by_product' });
      this.analyticsType = new AnalyticsTypeModel({ code: 'top_high' });
    }
  }

  private importParams(data: any = []) {
    if (!data) {
      return;
    }
    if (!Array.isArray(data)) {
      return;
    }
    this.params = new ParamList();
    for (const values of data) {
      const param: ParamModel = new ParamModel();
      param.mapData(values);
      this.params.add(param);
    }
  }

  private importFilters(data: any = []) {
    if (!data) {
      return;
    }
    if (!Array.isArray(data)) {
      return;
    }

    this.filters = new FilterColumnList();
    for (const values of data) {
      const filterColumn: FilterColumnModel = new FilterColumnModel();
      filterColumn.mapData(values);
      this.filters.add(filterColumn);
    }
  }


}
