
import { Component, Prop, Vue } from 'vue-property-decorator';
import { get } from '@/services/http';
import HeaderTitle from '../components/HeaderTitle.vue';

@Component({
  components: {
    HeaderTitle,
  },
})
export default class DataFileView extends Vue {
  public success: boolean = false;
  public error: boolean = false;
  public loading: boolean = false;
  public installing: boolean = false;
  public question: string = 'Best Selling Product';
  public fileName: string = '';
  public downloadURL: string = 'https://files.assisty.ai/download/';
  public reportHeaders: any = [];
  public reportItems: any = [];
  public tableTitle: any = [];

  public async created() {
    this.fileName = this.$route.params.file_name;
    this.downloadURL = this.downloadURL + this.fileName;
    await this.getFileData();
  }

  // get content of the file
  public async getFileData() {
    const res: any = await get('/files/' + this.fileName);
    let firstRow: boolean = true;
    for (const row of res.rows) {
      const item: Record<string, any> = {};
      // create the table header for the first row
      for (const header in row) {
        if (row[header]) {
          if (firstRow) {
            if (header !== 'undefined') {
              const reportHeader: any = {
                text: header,
                value: this.snakeCase(header),
                align: 'left',
              };
              this.reportHeaders.push(reportHeader);
            }
          }
          item[this.snakeCase(header)] = row[header];
        }
      }
      firstRow = false;
      // insert a new row into the table items
      this.reportItems.push(item);
    }
    // get tableTitle
    this.tableTitle = res.headers;
  }

  private snakeCase(str: string) {
    return str.replace(/\W+/g, ' ')
      .split(/ |\B(?=[A-Z])/)
      .map((word) => word.toLowerCase())
      .join('_');
  }
}
