
import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import HeaderTitle from "@/components/HeaderTitle.vue";
import { get } from "@/services/http";
import HourSelector from "@/components/SchedulerHourSelector.vue";
import DaySelector from "@/components/SchedulerDaySelector.vue";
import OutputEmail from "@/components/SchedulerOutputEmail.vue";
import OutputFileFormat from "@/components/SchedulerOutputFileFormat.vue";
import OutputSlack from "@/components/SchedulerOutputSlack.vue";
import OutputDrive from "@/components/SchedulerOutputDrive.vue";
import ComingSoon from "@/components/SchedulerComingSoon.vue";
import { StoreModel } from "@/models/store";
import { RuleModel } from "@/models/rule";
import FeatureLockedNotify from "@/components/FeatureLockedNotify.vue";
import { ScheduleProfileModel } from "@/models/schedule_profile";
import { MeasureList } from "@/collections/measures";
import { EventHandler } from "@/modules/events";
import { ReportModel } from "@/models/report";
import dateFormat from "dateformat";
import FilterColumn from "@/components/FilterColumn.vue";
import { FilterColumnList } from "@/collections/filter_columns";
import { CustomReportList } from "@/collections/custom_reports";
import { CustomReportModel } from "@/models/custom_report";
import Alert from "@/components/Alert.vue";
import ActionButton from "@/components/ActionButton.vue";
import GoogleDriveSelector from "@/components/GoogleDriveSelector.vue";
import { EventBus } from "@/main";
import { EmailReceiverModel } from "@/models/email_receiver";
import { EmailReceiverList } from "@/collections/email_receivers";
import { EmailTemplateList } from "@/collections/email_templates";
import { EmailTemplateModel } from "@/models/email_template";

@Component({
  components: {
    HeaderTitle,
    HourSelector,
    DaySelector,
    OutputSlack,
    OutputDrive,
    OutputEmail,
    FeatureLockedNotify,
    ComingSoon,
    FilterColumn,
    OutputFileFormat,
    Alert,
    ActionButton,
    GoogleDriveSelector,
  },
})
export default class ScheduleProfile extends Vue {
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: null })
  public app!: any;
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;

  public dates: string[] = [];
  public loading: boolean = false;
  public error: boolean = false;
  public errorMessage: string = "";
  public measureError: boolean = false;
  public measureErrorMessage: string = "";
  public timeRangeError: boolean = false;
  public timeRangeErrorMessage: string = "";
  public installing: boolean = false;
  public selectedCustomReports: CustomReportList = new CustomReportList();
  public measure: string = "";
  public dimension: string = "";
  public analyticType: string = "";
  public merchantRole: string = "";
  public step: number = 1;
  public steps: number = 4;
  public cancelTitle: string = "Cancel";
  public nextTitle: string = "Next";
  public deliveryTitle: string = "When does the schedule run?";
  public deliverySubtitle: string = "Time from 0:00 to 23:00";
  public filter: string = "";
  public newProfile: ScheduleProfileModel = new ScheduleProfileModel({
    time: "09",
    day: "weekdays",
  });
  public currentProfile: ScheduleProfileModel = new ScheduleProfileModel();
  public googleEmails: string[] = [];
  public defaultEmails: string[] = [];
  public interval: any = { text: "Daily", value: "daily" };
  public intervals: any = [];
  public reportColumns: any = [];
  public report: ReportModel = new ReportModel();
  public filterColumns: FilterColumnList = new FilterColumnList();
  public initFilterColumns: FilterColumnList = new FilterColumnList();
  public turnonFilter: boolean = false;
  public isLoadingColumn: boolean = false;
  public dimensions: any = [
    "Day",
    "Week",
    "Month",
    "Product",
    "Collection",
    "Product Variant",
    "Location",
  ];
  public timeranges: any = [];
  public weekDays: any = [];
  public times: any = [];
  public days: any = [
    { text: "01", value: "01" },
    { text: "02", value: "02" },
    { text: "03", value: "03" },
    { text: "04", value: "04" },
    { text: "05", value: "05" },
    { text: "06", value: "06" },
    { text: "07", value: "07" },
    { text: "08", value: "08" },
    { text: "09", value: "09" },
    { text: "10", value: "10" },
    { text: "11", value: "11" },
    { text: "12", value: "12" },
    { text: "13", value: "13" },
    { text: "14", value: "14" },
    { text: "15", value: "15" },
    { text: "16", value: "16" },
    { text: "17", value: "17" },
    { text: "18", value: "18" },
    { text: "19", value: "19" },
    { text: "20", value: "20" },
    { text: "21", value: "21" },
    { text: "22", value: "22" },
    { text: "23", value: "23" },
    { text: "24", value: "24" },
    { text: "25", value: "25" },
    { text: "26", value: "26" },
    { text: "27", value: "27" },
    { text: "28", value: "28" },
    { text: "29", value: "29" },
    { text: "30", value: "30" },
    { text: "31", value: "31" },
    { text: "Two days before end of Month", value: "-3" },
    { text: "One day before end of Month", value: "-2" },
    { text: "Last day of Month", value: "-1" },
  ];
  public hours: any = [
    { text: "01 AM", value: "01" },
    { text: "02 AM ", value: "02" },
    { text: "03 AM", value: "03" },
    { text: "04 AM", value: "04" },
    { text: "05 AM", value: "05" },
    { text: "06 AM", value: "06" },
    { text: "07 AM", value: "07" },
    { text: "08 AM", value: "08" },
    { text: "09 AM", value: "09" },
    { text: "10 AM", value: "10" },
    { text: "11 AM", value: "11" },
    { text: "12 PM", value: "12" },
    { text: "13 PM", value: "13" },
    { text: "14 PM", value: "14" },
    { text: "15 PM", value: "15" },
    { text: "16 PM", value: "16" },
    { text: "17 PM", value: "17" },
    { text: "18 PM", value: "18" },
    { text: "19 PM", value: "19" },
    { text: "20 PM", value: "20" },
    { text: "21 PM", value: "21" },
    { text: "22 PM", value: "22" },
    { text: "23 PM", value: "23" },
    { text: "0 AM", value: "00" },
  ];
  public selectedHours: string[] = [];
  public selectedDays: string[] = [];
  public emailOutput: any = {};
  public slackOutput: any = {};
  public driveOutput: any = {};
  public spreadsheetOutput: any = {};
  public measureRules: any = [this.isMeasureExit];
  public customReports: CustomReportList = new CustomReportList();
  public scheduleSuccess: boolean = false;

  public selectedGoogleAccount: string = "";
  public selectedDriveFolder: string = "";
  public googleSheetsDestination: string = "";
  public receivers: EmailReceiverList = new EmailReceiverList();
  public selectedReceivers: EmailReceiverModel[] = [];
  public emailTemplates: EmailTemplateList = new EmailTemplateList();
  public selectedEmailTemplate: EmailTemplateModel = new EmailTemplateModel();
  public async created() {
    this.loading = true;
    await this.receivers.fetch();
    await this.emailTemplates.fetch();
    this.translateDateLabel();

    for (let i = 0; i < 24; i++) {
      if (i < 10) {
        this.times.push({
          label: `0${i}:00`,
          value: `0${i}`,
        });
      } else {
        this.times.push({
          label: `${i}:00`,
          value: `${i}`,
        });
      }
    }
    try {
      await this.assignProfileData();
      await this.getContact();
    } catch (e) {
      //
      EventBus.$emit("show-snackbar", {
        message: "Error loading data",
        color: "error",
      });
    }
    this.addEmptyReport();
    this.loading = false;
  }

  public async assignProfileData() {
    let scheduleProfileId: number = Number(this.$route.params.profile_id);
    if (scheduleProfileId) {
      this.currentProfile = new ScheduleProfileModel();
      this.currentProfile.id = scheduleProfileId;
      try {
        await this.currentProfile.fetch();
      } catch (e: any) {
        //
        EventBus.$emit("show-snackbar", {
          message: "Error loading profile data",
          color: "error",
        });
      }
      this.newProfile.id = this.currentProfile.id;
      this.newProfile.name = this.currentProfile.name;
      this.newProfile.outputType = this.currentProfile.outputType;
      this.newProfile.destination = this.currentProfile.destination;
      this.newProfile.fileFormat = this.currentProfile.fileFormat;
      this.newProfile.interval = this.currentProfile.interval;
      this.newProfile.timezone = this.currentProfile.timezone;
      this.newProfile.deliveryDate = this.currentProfile.deliveryDate;
      this.newProfile.deliveryWeekday = this.currentProfile.deliveryWeekday;
      this.newProfile.deliveryHour = this.currentProfile.deliveryHour;
      this.newProfile.emails = this.currentProfile.emails;
      if (this.currentProfile.receivers) {
        this.selectedReceivers = [];
        for (const receiver of this.currentProfile.receivers.items) {
          this.selectedReceivers.push(receiver);
        }
      }
      if (this.currentProfile.emailTemplate && this.currentProfile.emailTemplate.id) {
        this.selectedEmailTemplate = this.currentProfile.emailTemplate;
      } else {
        this.selectedEmailTemplate = new EmailTemplateModel();
        this.selectedEmailTemplate.id = 0;
        this.selectedEmailTemplate.title = "None";
      }

      for (const interval of this.intervals) {
        if (interval.value === this.currentProfile.interval) {
          this.interval = interval;
        }
      }
      // for outputType 'drive'
      if (this.newProfile.outputType === 'drive' && this.newProfile.fileFormat === 'google_sheets') {
        const tmp = this.newProfile.destination.split(',');
        this.selectedGoogleAccount = tmp[0] || '';
        this.selectedDriveFolder = tmp[1] || '';
        this.newProfile.destination = '';
      }
      if (this.newProfile.outputType === 'google_sheets') {
        this.googleSheetsDestination = this.newProfile.destination;
      }
      
    }
  }
  public addEmptyReport() {
    const customReport = new CustomReportModel();
    this.selectedCustomReports.add(customReport);
  }

  public removeReport(index: number) {
    if (this.selectedCustomReports.items.length === 1) {
      return;
    }
    this.selectedCustomReports.items.splice(index, 1);
  }
  public isMeasureExit(v: any) {
    return !!v || this.$t("schedule.Please select a metric");
  }

  public isEmailExist(v: any) {
    return !!v || this.$t("onboard.E-mail is required");
  }

  public isEmailValidate(v: any) {
    return /.+@.+/.test(v) || this.$t("onboard.E-mail must be valid");
  }

  public generateSortByParam(sortBy: any = [], sortDesc: any = []) {
    if (!sortBy || sortBy.length === 0) {
      return [];
    }
    let index: number = 0;
    const sortByResult: any = [];
    for (let index = 0; index < sortBy.length; index++) {
      sortByResult.push({
        key: sortBy[index],
        order: sortDesc[index],
      });
    }

    return sortByResult;
  }

  public async saveScheduleProfile() {
    this.sendMixpanelEvent("Save Schedule Profile");
    this.loading = true;
    if (this.selectedReceivers.length > 0) {
      this.newProfile.emails = this.selectedReceivers.map((receiver) => receiver.email);
      this.newProfile.receivers = new EmailReceiverList();
      for (const receiver of this.selectedReceivers) {
        this.newProfile.receivers.add(receiver);
      }
    }
    if (this.selectedEmailTemplate.id) {
      this.newProfile.emailTemplate = this.selectedEmailTemplate;
    }
    this.newProfile.interval = this.interval.value.toLowerCase();
    switch (this.interval.value) {
      case "daily": {
        this.newProfile.deliveryWeekday = [];
        this.newProfile.deliveryDate = [];
        break;
      }
      case "weekly": {
        this.newProfile.deliveryDate = [];
        break;
      }
      case "monthly": {
        this.newProfile.deliveryWeekday = [];
        break;
      }
    }
    try {
      if (this.newProfile.id) {
        await this.newProfile.update();
        EventBus.$emit("show-snackbar", {
          message: "Profile updated successfully",
          color: "success",
        });
      } else {
        await this.newProfile.save();
        EventBus.$emit("show-snackbar", {
          message: "Profile saved successfully",
          color: "success",
        });
      }
    } catch (e: any) {
      EventBus.$emit("show-snackbar", {
        message: "Error saving profile",
        color: "error",
      });
    }

    
    this.$router.push("/schedule/profiles");
    this.loading = false;
  }

  public viewAllScheduledReport() {
    this.$router.push("/schedule");
  }

  public sendMixpanelEvent(eventName: string = "") {}

  public translateDateLabel() {
    this.weekDays = [
      {
        text: this.$t("schedule.Monday") + "",
        value: "1",
      },
      {
        text: this.$t("schedule.Tuesday") + "",
        value: "2",
      },
      {
        text: this.$t("schedule.Wednesday") + "",
        value: "3",
      },
      {
        text: this.$t("schedule.Thursday") + "",
        value: "4",
      },
      {
        text: this.$t("schedule.Friday") + "",
        value: "5",
      },
      {
        text: this.$t("schedule.Saturday") + "",
        value: "6",
      },
      {
        text: this.$t("schedule.Sunday") + "",
        value: "0",
      },
    ];
    this.timeranges = [
      { text: this.$t("schedule.Today") + "", value: "TODAY" },
      { text: this.$t("schedule.Yesterday") + "", value: "YESTERDAY" },
      { text: this.$t("schedule.7D") + "", value: "7D" },
      { text: this.$t("schedule.30D") + "", value: "30D" },
      { text: this.$t("schedule.3M") + "", value: "3M" },
      { text: this.$t("schedule.6M") + "", value: "6M" },
      { text: this.$t("schedule.1Y") + "", value: "1Y" },
      { text: this.$t("schedule.3Y") + "", value: "3Y" },
    ];

    this.intervals = [
      { text: "Daily", value: "daily" },
      { text: "Weekly", value: "weekly" },
      { text: "Monthly", value: "monthly" },
    ];
  }

  public async nextStep() {
    if (!this.validateInputData()) {
      return;
    }
    if (this.step < 4) {
      this.step = this.step + 1;
    } else {
      if (
        this.newProfile.outputType === 'drive' &&
        this.newProfile.fileFormat === 'google_sheets'
      ) {
        this.newProfile.destination = `${this.selectedGoogleAccount},${this.selectedDriveFolder}`;
      }
      if (this.newProfile.outputType === 'google_sheets') {
        this.newProfile.destination = this.googleSheetsDestination;
      }
      this.saveScheduleProfile();
    }
  }

  public validateInputData() {
    this.error = false;
    this.errorMessage = "";
    switch (this.step) {
      case 1: {
        if (!this.newProfile.name) {
          return false;
        }
        break;
      }
      case 3: {
        if (
          !this.newProfile.deliveryHour ||
          this.newProfile.deliveryHour.length === 0
        ) {
          this.error = true;
          this.errorMessage = "Please select a time slot";
          return false;
        }
        switch (this.interval.value) {
          case "weekly": {
            if (
              !this.newProfile.deliveryWeekday ||
              this.newProfile.deliveryWeekday.length === 0
            ) {
              this.error = true;
              this.errorMessage = "Please select at least one day";
              return false;
            }

            break;
          }
          case "Monthly": {
            if (
              !this.newProfile.deliveryDate ||
              this.newProfile.deliveryDate.length === 0
            ) {
              this.error = true;
              this.errorMessage = "Please select a date";
              return false;
            }
            break;
          }
        }
        break;
      }
      case 4: {
        switch (this.newProfile.outputType) {
          case "google_sheets":
            if (!this.googleSheetsDestination || !this.googleSheetsDestination.trim()) {
              this.error = true;
              this.errorMessage = "Please enter the url of google sheets file";
              return false;
            }
            break;
          case "email":
            if (
              !this.newProfile.emails ||
              (this.newProfile.emails.length === 0 && this.selectedReceivers.length === 0)
            ) {
              this.error = true;
              this.errorMessage = "Please enter an email";
              return false;
            }
            break;
          case "drive":
            if (!this.selectedGoogleAccount) {
              this.error = true;
              this.errorMessage = "Please select a Google Drive account";
              return false;
            }
            break;
          default:
            return false;
        }
        break;
      }
    }
    return true;
  }

  public getNotificationType(type: string) {
    switch (type) {
      case "email": {
        return "Email";
      }
      case "google_sheets": {
        return "Google Drive";
      }
    }
  }

  public async back() {
    if (this.step > 1) {
      this.step = this.step - 1;
    } else {
      this.$router.push("/schedule/profiles");
    }
  }

  public getCancelTitle() {
    if (this.step === 1) {
      return this.$t("schedule.Cancel");
    }
    return this.$t("schedule.Back");
  }

  public getNextTitle() {
    if (this.step === 4) {
      return "Save";
    }
    return "Next";
  }

  public async getContact() {
    if (this.newProfile.emails && this.newProfile.emails.length > 0) {
      localStorage.setItem("defaultEmail", this.newProfile.emails[0]);
      this.defaultEmails = this.newProfile.emails;
      return;
    }
    let defaultEmail: string = localStorage.getItem("defaultEmail") || "";
    if (!defaultEmail) {
      const res: any = await get("/settings");
      this.defaultEmails = [res.data.contactEmail];
      localStorage.setItem("defaultEmail", res.data.contactEmail);
    }
  }

  public canScheduleToExistingGoogleSpreadsheet() {
    const validateValues: any = [
      {
        code: "export_google_spreadsheet",
      },
    ];
    const validateResult = this.store.validateRule(validateValues);
    return validateResult.isValidate;
  }

  public updateSelectedGoogleAccount(account: string) {
    this.selectedGoogleAccount = account;
  }

  public updateSelectedDriveFolder(folder: string) {
    this.selectedDriveFolder = folder;
  }
}
