
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import SlackConnection from '@/components/SlackConnection.vue';
import SlackChannelSelector from '@/components/SlackChannelSelector.vue';
import { StoreModel } from '@/models/store';


@Component({
  components: {
    SlackConnection,
    SlackChannelSelector,
  },
})
export default class SchedulerOutputDrive extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({default: null})
  public app!: any;

  public slackChannel: string = '';
  public newestStoreInfo: StoreModel = new StoreModel();

  public async created() {
    this.newestStoreInfo = this.store;
    await this.newestStoreInfo.fetch();
  }

  @Watch('slackChannel', { immediate: true, deep: true })
  private async onChannelChanged(newVal: any) {
    this.$emit('input', this.slackChannel);
  }
}
