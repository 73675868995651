import { get, post, put } from '@/services/http';

export class InventoryParam {
  public replenishmentExclusion: boolean = false;
  public leadtime: number = 0;
  public supplierId: number = 0;
  public orderCycle: number = 0;
  public fixOrderQuantity: number = 0;
  public minOrderQuantity: number = 0;
  public maxOrderQuantity: number = 0;
  public minInventoryQuantity: number = 0;
  public maxInventoryQuantity: number = 0;
  public minStockCoverDays: number = 0;
  public maxStockCoverDays: number = 0;

  constructor(data: any = {}) {
    
      this.replenishmentExclusion = data.replenishmentExclusion;
      this.leadtime = data.leadtime;
      this.supplierId = data.supplierId;
      this.orderCycle = data.orderCycle;
      this.fixOrderQuantity = data.fixOrderQuantity;
      this.minOrderQuantity = data.minOrderQuantity;
      this.maxOrderQuantity = data.maxOrderQuantity;
      this.minInventoryQuantity = data.minInventoryQuantity;
      this.maxInventoryQuantity = data.maxInventoryQuantity;
      this.minStockCoverDays = data.minStockCoverDays;
      this.maxStockCoverDays = data.maxStockCoverDays;
  }

  public async save(reportRequestParams: any) {
    const data = {
      options: reportRequestParams,
      replenishment_exclusion: this.replenishmentExclusion? 1 : 0,
      leadtime: this.leadtime,
      supplier_id: this.supplierId,
      order_cycle: this.orderCycle,
      fix_order_quantity: this.fixOrderQuantity,
      min_order_quantity: this.minOrderQuantity,
      max_order_quantity: this.maxOrderQuantity,
      min_inventory_quantity: this.minInventoryQuantity,
      max_inventory_quantity: this.maxInventoryQuantity,
      min_stock_cover_days: this.minStockCoverDays,
      max_stock_cover_days: this.maxStockCoverDays,
    };
    const res: any = await put('/replenishment_settings', data);
  }

  public mappData(data: any = {}) {
    this.replenishmentExclusion = data.replenishmentExclusion;
    this.leadtime = data.leadtime;
    this.supplierId = data.supplierId;
    this.orderCycle = data.orderCycle;
    this.fixOrderQuantity = data.fixOrderQuantity;
    this.minOrderQuantity = data.minOrderQuantity;
    this.maxOrderQuantity = data.maxOrderQuantity;
    this.minInventoryQuantity = data.minInventoryQuantity;
    this.maxInventoryQuantity = data.maxInventoryQuantity;
    this.minStockCoverDays = data.minStockCoverDays;
    this.maxStockCoverDays = data.maxStockCoverDays;
  }

  public canSave() {
    if (!this.supplierId) { 
      return false;
    }
    return true;
  }
}