import { get, post } from '@/services/http';
import { TemplateReportModel } from '@/models/template_report';
import List from './list';
import { captureException } from 'logrocket';

export class TemplateReportList extends List<TemplateReportModel> {
  public async fetch() {
    const res: any = await get(`/template_reports`);
    const templateReports = res.data || [];
    const items = [];
    for (const s of templateReports) {
      const templateReport = new TemplateReportModel();
      templateReport.mapData(s);
      items.push(templateReport);
    }
    this.items = items;
  }

  public async searchByOpenAI(search: string) {
    const res: any = await get(`/template_reports/search?keyword=${search}`);
    const templateReports = res.data || [];
    const items = [];
    for (const s of templateReports) {
      const templateReport = new TemplateReportModel();
      templateReport.mapData(s);
      items.push(templateReport);
    }
    this.items = items;
  }
}
