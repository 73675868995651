import { get, post } from '@/services/http';
import { ResourceModel } from '@/models/resource';
import List from './list';

export class ResourceList extends List<ResourceModel> {
  public async fetch() {
    let resources = [
      {
        name: 'Breakdown by Product Collection',
        code: 'by_category',
        type: 'dimension',
        validData: 'unlocked',
        ruleCode: 'sales_by_collection',
      },
      {
        name: 'Breakdown by Product Metafield',
        code: 'by_product_meta_field',
        type: 'dimension',
        validData: 'unlocked',
        ruleCode: 'product_metafield',
      },
      {
        name: 'Analyze Sales by Hour',
        code: 'by_hour',
        type: 'dimension',
        validData: 'unlocked',
        ruleCode: 'hourly_sales_analytics',
      },
      {
        name: 'Analyze Sales by the Option Value',
        code: 'by_variant_option_value',
        type: 'dimension',
        validData: 'unlocked',
        ruleCode: 'variant_option_analytics',
      },
      {
        name: 'Replenishment for Bundle Product',
        code: 'inventory_planning_bundle',
        type: 'measure',
        validData: 'unlocked',
        ruleCode: 'bundle_product_sales_analytics',
      },
      {
        name: 'Inventory Planning by variant',
        code: 'inventory_planning',
        type: 'Inventory Replenishment',
        validData: 'unlocked',
        ruleCode: 'inventory_replenishment',
      },
      {
        name: 'Fixed Period Inventory Replenishment',
        code: 'fixed_period_inventory_replenishment',
        type: 'Inventory Replenishment',
        validData: 'unlocked',
        ruleCode: 'inventory_replenishment',
      },
      {
        name: 'Inventory Replenishment by Min/Max stock cover days',
        code: 'inventory_replenishment_min_max_stock_cover_days',
        type: 'Inventory Replenishment',
        validData: 'unlocked',
        ruleCode: 'inventory_replenishment',
      },
      {
        name: 'Daily Inventory Replenishment',
        code: 'inventory_planning_date',
        type: 'Inventory Replenishment',
        validData: 'unlocked',
        ruleCode: 'inventory_replenishment',
      },
      {
        name: 'Fixed Order Quantity Inventory Replenishment',
        code: 'fixed_order_quantity_inventory_replenishment',
        type: 'Inventory Replenishment',
        validData: 'unlocked',
        ruleCode: 'inventory_replenishment',
      },
      {
        name: 'Stock Availability Reports',
        code: 'stock_availability',
        type: 'Inventory Replenishment',
        validData: 'unlocked',
        ruleCode: 'stock_availability',
      },
      {
        name: 'Out of stock days Reports',
        code: 'out_of_stock_days',
        type: 'Inventory Replenishment',
        validData: 'unlocked',
        ruleCode: 'stock_availability',
      },
      {
        name: 'Sales & Stockout Reports',
        code: 'sales_overview_stockouts',
        type: 'Inventory Replenishment',
        validData: 'unlocked',
        ruleCode: 'stock_availability',
      },
      {
        name: 'Inventory Replenishment by Min/Max stock cover days',
        code: 'inventory_replenishment_min_max',
        type: 'Inventory Replenishment',
        validData: 'unlocked',
        ruleCode: 'inventory_replenishment',
      },
      {
        name: 'Essential Sales Analytics',
        code: 'measure_essential_sales_analytics',
        type: 'Essential Sales Analytics',
        validData: 'unlocked',
        ruleCode: 'essential_sales_analytics',
      },
      {
        name: 'Inventory Forecasting',
        code: 'inventory_forecasting',
        type: 'Inventory Forecasting',
        validData: 'unlocked',
        ruleCode: 'sales_inventory_forecasting',
      },
      // {
      //   name: 'Combine Data of multipe Shopify Stores',
      //   code: 'olap_inventory_remaining_days_multiple_store',
      //   type: 'Mutile Shopify Store',
      //   validData: 'unlocked',
      //   ruleCode: 'multiple_shopify_store_analytics',
      // },
      // {
      //   name: 'Setting page for Multiple store analytics',
      //   code: 'setting_multiple_shopify_store_analytics',
      //   type: 'Mutile Shopify Store',
      //   validData: 'unlocked',
      //   ruleCode: 'multiple_shopify_store_analytics',
      // },
      {
        name: 'Sync Data from Shopify hourly',
        code: 'hourly_sync_data',
        type: 'Data Sync',
        validData: 'hourly',
        ruleCode: 'data_sync_schedule',
      },
      {
        name: 'Push Notification to Slack Channel',
        code: 'slack_push_notification',
        type: 'Slack',
        validData: 'unlocked',
        ruleCode: 'slack_push_notification',
      },
      {
        name: 'Export Data to Google Spreadsheet',
        code: 'export_google_spreadsheet',
        type: 'Export Data',
        validData: 'unlocked',
        ruleCode: 'export_google_spreadsheet',
      },
      {
        name: 'Custom Dashboard',
        code: 'add_report_dashboard',
        type: 'Custom Dashboard',
        validData: 'unlocked',
        ruleCode: 'add_report_dashboard',
      },
      {
        name: 'Mutile User Login',
        code: 'multi_user_login',
        type: 'User Management',
        validData: '3',
        ruleCode: 'multi_user_login',
      },
      {
        name: 'Number of Custom Reports',
        code: 'favorite_reports_count',
        type: 'Custom Report',
        ruleCode: 'favorite_reports_count',
      },
      {
        name: 'Download Full Data',
        code: 'download_full_data',
        type: 'Export Data',
        validData: 'all page',
        ruleCode: 'download_full_data',
      },
      {
        name: 'Number of Scheduled reports',
        code: 'schedule_reports_count',
        type: 'Schedule Report',
        validData: '3',
        ruleCode: 'schedule_reports_count',
      },
      {
        name: 'Month End Inventory & Sales',
        code: 'month_end_sales_inventory',
        type: 'Inventory Movement',
        validData: 'unlocked',
        ruleCode: 'inventory_movement',
      },
      {
        name: 'Inventory Movement by Product',
        code: 'inventory_movement',
        type: 'Inventory Movement',
        validData: 'unlocked',
        ruleCode: 'inventory_movement',
      },
      {
        name: 'Stock movement in a period',
        code: 'Inventory Transfer',
        type: 'Inventory Movement',
        validData: 'unlocked',
        ruleCode: 'inventory_movement',
      },
      {
        name: 'Month End Inventory Quantity & Value by Product',
        code: 'historical_inventory',
        type: 'Inventory Movement',
        validData: 'unlocked',
        ruleCode: 'inventory_movement',
      },
      {
        name: 'Sell Through Rate Report',
        code: 'sell_through_rate',
        type: 'measure',
        validData: 'unlocked',
        ruleCode: 'sell_through_rate',
      },
      {
        name: 'Inventory to Sales Report',
        code: 'inventory_to_sales_ratio',
        type: 'measure',
        validData: 'unlocked',
        ruleCode: 'inventory_to_sales_ratio',
      },
      {
        name: 'Inventory Turnover Rate',
        code: 'turrnover_rate',
        type: 'measure',
        validData: 'unlocked',
        ruleCode: 'turrnover_rate',
      },
      {
        name: 'Lost Revenue Report',
        code: 'lost_revenue',
        type: 'measure',
        validData: 'unlocked',
        ruleCode: 'lost_revenue',
      },
      {
        name: 'Inventory Deduction Forecast',
        code: 'inventory_forecasting_stock_movement',
        type: 'measure',
        validData: 'unlocked',
        ruleCode: 'inventory_forecasting_stock_movement',
      },
      {
        name: 'Stocky Integration',
        code: 'stocky',
        type: 'Inventory Replenishment',
        validData: 'unlocked',
        ruleCode: 'stocky',
      },
      {
        name: 'Mimoran Integration',
        code: 'mimoran',
        type: 'Inventory Replenishment',
        validData: 'unlocked',
        ruleCode: 'mimoran',
      },
    ];

    try {
      const res: any = await get(`/resource`, {});
      const data = res.data || [];
      if (data && data.length > 0) {
        resources = data;
      }
    } catch (e) {
      // console.log(e);
    }
    this.mapData(resources);
  }

  private mapData(data: any = []) {
    this.items = [];
    for (const r of data) {
      const resource = new ResourceModel();
      resource.mapData(r);
      this.add(resource);
    }
  }
}
