
import { Component, Vue } from 'vue-property-decorator';
import { UserModel } from '@/models/user';

@Component({
  components: {
  },
})
export default class UserChangePassword extends Vue {
  public user: UserModel = new UserModel();
  public password: string = '';
  public currentPassword: string = '';
  public loading: boolean = false;
  public error: boolean = false;
  public errorMessage: string = '';
  public currentPasswordRules: any = [
    this.isPasswordExist,
  ];
  public passwordRules: any = [
    this.isPasswordMin8,
    this.isPasswordExist,
  ];

  public isPasswordExist(v: any) {
    return !!v || this.$t('user_management.Password is required');
  }

  public isPasswordMin8(v: any) {
    if (v.length < 8) {
      return this.$t('user_management.Password must be at least 8 characters');
    }
    return true;
  }

  public async changePassword() {
    this.loading = true;
    if (!this.isPasswordExist(this.currentPassword) || !this.isPasswordMin8(this.password ||
      !this.isPasswordExist(this.password))) {
      this.loading = false;
      return;
    }
    try {
      await this.user.changePassword(this.currentPassword, this.password);
    } catch (e: any) {
      this.error = true;
      switch (e.response.status) {
        case 400:
          this.errorMessage = 'Password is invalid';
          break;
        case 403:
          this.errorMessage = 'Owner can not change password by yourself.';
          break;
        default:
          this.errorMessage = 'There is an error when changing your password';
          break;
      }
    }
    this.loading = false;
  }
}
