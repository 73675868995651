
import dateFormat from "dateformat";
import ReportCardBase from "@/components/ReportCardBase";
import ReportCardTable from "@/components/ReportCardTable.vue";
import ReportCardBarChart from "@/components/ReportCardBarChart.vue";
import ReportCardLineChart from "@/components/ReportCardLineChart.vue";
import ReportCardBigNumber from "@/components/ReportCardBigNumber.vue";
import ReportCardPieChart from "@/components/ReportCardPieChart.vue";
import ReportCardDoughnutChart from "@/components/ReportCardDoughnutChart.vue";
import ReportCardSummary from "@/components/ReportCardSummary.vue";
import { Component, Prop } from "vue-property-decorator";
import { ReportCardModel } from "@/models/report_card";
import { EventHandler } from "@/modules/events";
import { DashboardList } from "@/collections/dashboards";
import { DashboardModel } from "@/models/dashboard";
import ReportCardErrorMessage from "@/components/ReportCardErrorMessage.vue";
import LoadingSlider from "@/components/LoadingSlider.vue";
import FeatureLockedNotify from "@/components/FeatureLockedNotify.vue";
import { RuleModel } from "@/models/rule";
import { EventBus } from "@/main";
import Alert from "@/components/Alert.vue";
import EtlStatusNote from "@/components/EtlStatusNote.vue";
import ActionButton from "@/components/ActionButton.vue";

@Component({
  components: {
    ReportCardTable,
    ReportCardBarChart,
    ReportCardLineChart,
    ReportCardBigNumber,
    ReportCardPieChart,
    ReportCardDoughnutChart,
    ReportCardErrorMessage,
    LoadingSlider,
    FeatureLockedNotify,
    Alert,
    ActionButton,
    EtlStatusNote,
    ReportCardSummary,
  },
})
export default class ReportCard extends ReportCardBase {
  
  @Prop({ default: true })
  public actionMenu!: boolean;
  public dialogCloneCard: boolean = false;
  public dashboards: DashboardList = new DashboardList();
  public selectedDashboard: DashboardModel = new DashboardModel();
  public cardToClone = new ReportCardModel();
  public loadingClone: boolean = false;
  public messageColor: string = "";
  public message: string = "Please selelect a dashboard to save the card";
  
  public loadingSlides: string[] = [
    "Connecting to analytic services",
    "Fetching data",
    "Building report",
    "Downloading data",
    "Generating card data",
  ];
  public rulePaidMeasure: RuleModel = new RuleModel({ code: "paid_measures" });
  public showLockInventoryOptimization: boolean = false;
  public lockInventoryOptimizationDays: number = 1;

  public async created() {
    this.isCreated = true;
    EventBus.$on("report-card-change-chart-type", async (chartType: string) => {
      this.changeChartType(chartType);
    });
    this.messageColor = "";
    this.message = "Please selelect a dashboard to save the card";
    this.defaultRange = this.initDefaultRange;
    this.cardHeightSize = this.initCardHeightSize;
    this.cardWidthSize = this.initCardWidthSize;
    this.typeReport = this.initTypeReport;

    if (this.initReportCardModel && this.initReportCardModel.id) {
      this.id = this.initReportCardModel.id;
      this.reportCardModel = this.initReportCardModel;
    } else {
      this.id = Math.floor(Math.random() * 1000);
      this.reportCardModel = new ReportCardModel();
      const data: any = {
        id: this.id,
        sortOrder: 2,
        chartType: "",
        dimensionCode: this.dimension.code,
        measureCode: this.measure.code,
        analyticsTypeCode: this.analyticsType.code,
        name: this.reportName,
        defaultRange: this.defaultRange,
        isDetail: false,
        size: { height: this.cardHeightSize, width: this.cardWidthSize },
      };
      if (
        this.initSelectedReportColumns &&
        this.initSelectedReportColumns.length > 0
      ) {
        data.reportColumns = this.initSelectedReportColumns;
      }
      this.reportCardModel.mapData(data);
    }

    if (!this.canShowInventoryOptimization(this.measureCode)) {
      this.showLockInventoryOptimization = true;
      return;
    }
    this.getRules();
    this.defineMeasure();
    if (this.loadDataByDefault || !this.measure.hasTimerange) {
      await this.getData();
    }
    EventBus.$on("reload-report-data", async () => {
      await this.getData();
    });
    this.isCreated = true;
  }

  public updateCardTableOption(pageOptions: any) {
    this.pageOptions = pageOptions;
    this.sortBy = pageOptions.sortBy;
    this.sortDesc = pageOptions.sortDesc;
    this.perPage = pageOptions.perPage;
    this.page = pageOptions.page;
    this.getData();
  }

  public reloadData() {
    EventBus.$emit("reload-report-data");
  }

  public getRules() {
    for (const rule of this.store.currentPackage.rules.items) {
      if (rule.code === this.rulePaidMeasure.code) {
        this.rulePaidMeasure = rule;
      }
    }
  }

  public get daysToShowInventoryOptimization() {
    const now_timestamp: number = this.toTimestamp("");
    const installed_timestamp: number = this.toTimestamp(
      this.store.installedAt
    );
    const days = Math.round(
      (now_timestamp - installed_timestamp) / (24 * 60 * 60)
    );
    return this.lockInventoryOptimizationDays - days;
  }

  public canShowInventoryOptimization(measureCode: string) {
    const restrictMeasureList: any = [
      "sell_through_rate",
      "inventory_to_sales_ratio",
      "stock_availability",
      "inventory_turrnover",
      "aging_stock",
      "aging_stock_summary",
      "lost_revenue",
      "inventory_movement",
      "historical_inventory",
    ];
    if (!restrictMeasureList.includes(measureCode)) {
      return true;
    }
    const now_timestamp: number = this.toTimestamp("");
    const installed_timestamp: number = this.toTimestamp(
      this.store.installedAt
    );
    if (this.daysToShowInventoryOptimization <= 0) {
      return true;
    }
    return false;
  }

  public toTimestamp(strDate: string = "") {
    let datum: number = 0;
    if (!strDate) {
      datum = Date.now();
    } else {
      datum = Date.parse(strDate);
    }
    return datum / 1000;
  }

  

  public get getCardTitle() {
    let title: string = '';
    if (this.reportCardModel.name) {
      title = this.reportCardModel.name;
    } else if (this.reportName) {
      title = this.reportName;
    } else {
      title = this.measure.name;
    }
    //make report name to lowercase
    title = title.toLowerCase();
    //make the first character of the report name to uppercase
    title = title.charAt(0).toUpperCase() + title.slice(1);
    return title;
  }

  public moveCardLeft() {
    this.$emit("move-card-left", this.id);
  }

  public moveCardRight() {
    this.$emit("move-card-right", this.id);
  }

  public turnonEditingMode(status: boolean) {
    if (this.canChangeName) {
      this.isEditingName = status;
    }
  }
  public get chartTypeName() {
    switch (this.typeReport) {
      case "LINE_CHART": {
        return "Line Chart";
        break;
      }
      case "BAR_CHART": {
        return "Bar Chart";
        break;
      }
      case "TABLE": {
        return "Table";
        break;
      }
      case "BIG_NUMBER": {
        return "Metric";
        break;
      }
      case "PIE_CHART": {
        return "Pie Chart";
        break;
      }
      case "DOUGHNUT_CHART": {
        return "Doughnut Chart";
        break;
      }
    }
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`Change chart type`, {
        CardName: this.reportCardModel.name,
        cardId: this.reportCardModel.id,
        measure: this.reportCardModel.measure.code,
        dimension: this.reportCardModel.dimension
          ? this.reportCardModel.dimension.code
          : "",
        chartType: this.typeReport,
      });
    } catch (e) {
      // ignore this
    }
  }

  public async showCloneDialog() {
    this.loadingClone = true;
    this.dialogCloneCard = true;
    this.dashboards = new DashboardList();
    await this.dashboards.fetch();
    this.cardToClone = new ReportCardModel();
    this.cardToClone.name = this.reportCardModel.name;
    this.cardToClone.cardWidthSize = this.reportCardModel.cardWidthSize;
    this.cardToClone.cardHeightSize = this.reportCardModel.cardHeightSize;
    this.cardToClone.measure = this.reportCardModel.measure;
    this.cardToClone.dimension = this.reportCardModel.dimension;
    this.cardToClone.analyticsType = this.reportCardModel.analyticsType;
    this.cardToClone.filterTimerange = this.reportCardModel.filterTimerange;
    this.cardToClone.filterColumns = this.reportCardModel.filterColumns;
    this.cardToClone.typeReport = this.reportCardModel.typeReport;
    this.cardToClone.defaultRange = this.reportCardModel.defaultRange;
    this.cardToClone.reportColumns = this.reportCardModel.reportColumns;
    this.cardToClone.isDetail = this.reportCardModel.isDetail;
    this.cardToClone.dashboardIds = 0;
    this.cardToClone.pivotOption = this.reportCardModel.pivotOption;
    this.loadingClone = false;
  }

  public async cloneReportCard() {
    if (!this.selectedDashboard.id) {
      this.messageColor = "red--text";
      return;
    }
    this.loadingClone = true;
    this.cardToClone.dashboardIds = this.selectedDashboard.id;
    this.cardToClone.id = 0;

    try {
      await this.cardToClone.create();
      this.selectedDashboard.reportCardList.add(this.cardToClone);
      await this.selectedDashboard.updateCardOption();
      this.messageColor = "deep_sku_blue--text";
      this.message = "Card has been cloned!";
    } catch (e) {
      // ignore this
    }
    this.loadingClone = false;
    await this.sleep(1000);
    this.dialogCloneCard = false;
  }

  public designCard() {
    this.$router.push(`/design-card/${this.initReportCardModel.id}`);
  }
  public reloadCompleted(typeReport: string) {
    this.needReloadTable = false;
    this.needReloadBarChart = false;
    this.needReloadLineChart = false;
    this.needReloadBigNumber = false;
    this.needReloadPieChart = false;
    this.needReloadDoughnutChart = false;
    this.needReloadSummary = false;
    this.$emit("reload-completed", this.id);
  }

  public getReportLink() {
    if (this.initReportCardModel && this.initReportCardModel.id) {
      return "/card/view/" + this.initReportCardModel.id;
    }
    let link = "/reports/measures/" + this.measure.code;
    link = link + "?dimension=" + this.dimension.code;
    if (this.analyticsType.code && this.analyticsType.code !== undefined) {
      link = link + "&analytic=" + this.analyticsType.code;
    }
    if (this.filterTimerange && this.filterTimerange.length >= 2) {
      link = link + "&start_time=" + this.filterTimerange[0];
      link = link + "&end_time=" + this.filterTimerange[1];
    }
    if (this.defaultRange) {
      link = link + "&defaultRange=" + this.defaultRange;
    }

    if (
      this.filterColumns &&
      this.filterColumns.items &&
      this.filterColumns.items.length > 0
    ) {
      const filterParams = [];
      for (const item of this.filterColumns.items) {
        filterParams.push(
          `${item.name}__${item.code}__${item.operator}__${item.value.join(
            "_or_"
          )}__${item.dataType}`
        );
      }
      if (filterParams.length > 0) {
        link = link + "&filter=" + filterParams.join("_col_");
      }
    }

    if (this.pivotOption) {
      link = link + "&pivot_option=" + this.pivotOption;
    }
    if (
      this.reportParams &&
      this.reportParams.items &&
      this.reportParams.items.length > 0
    ) {
      const params = [];
      for (const item of this.reportParams.items) {
        params.push(
          `${item.name}__${item.code}__${item.dataType}__${
            item.valueType
          }__${item.value.join("_or_")}`
        );
      }
      if (params.length > 0) {
        link = link + "&params=" + params.join("_param_");
      }
    }

    link = link + "&title=" + this.getCardTitle;
    return link;
  }

  public get chartIcon() {
    switch (this.typeReport) {
      case "BAR_CHART": {
        return "mdi-chart-bar spin";
        break;
      }
      case "LINE_CHART": {
        return "mdi-chart-line spin";
        break;
      }

      case "TABLE": {
        return "mdi-table spin";
        break;
      }
      case "BIG_NUMBER": {
        return "mdi-numeric spin";
        break;
      }
      case "PIE_CHART": {
        return "mdi-chart-bar spin";
        break;
      }
      case "DOUGHNUT_CHART": {
        return "mdi-chart-donut spin";
        break;
      }
    }
  }

  public get dataSyncMessage() {
    let timeRangeLimit: string[] = [];
    if (
      !this.measure.dataResources ||
      this.measure.dataResources.length === 0
    ) {
      return "";
    }
    for (const dataResource of this.measure.dataResources) {
      if (
        [
          'olap_current_inventory_levels',
          'inventory_level_quantities'
        ].includes(dataResource.code)
        && this.store.currentPackage.price === 0
      ) {
        dataResource.code = 'inventory_levels';
      }
      for (const etlStatus of this.store.etlStatuses.items) {
        if (dataResource.code === etlStatus.resource) {
          let startTime = etlStatus.startTime;
          let lastUpdatedAt = etlStatus.lastUpdatedAt;
          if (new Date(lastUpdatedAt) < new Date(timeRangeLimit[1])) {
            lastUpdatedAt = timeRangeLimit[1];
          }
          if (new Date(startTime) > new Date(timeRangeLimit[0])) {
            startTime = timeRangeLimit[0];
          }
          timeRangeLimit = [startTime, lastUpdatedAt];
        }
      }
    }

    if (
      timeRangeLimit.length === 0 ||
      timeRangeLimit[0] === timeRangeLimit[1]
    ) {
      return "Data is being updated";
    }
    if (this.isAthenaFinished) {
      return `Last updated: ${dateFormat(
        timeRangeLimit[1],
        "mmm d, yyyy HH:MM:ss",
        false
      )}`;
    }
    return
      "Data is being updated";
  }

  private sleep(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
}
