import { get } from '@/services/http';
import { SubDimensionList } from '@/collections/sub_dimensions';
import { ParamList } from '@/collections/params';

export class DimensionModel {
  public id: number;
  public name: string;
  public code: string;
  public fieldCode: string;
  public label: string;
  public dataType: string;
  public sortOrder: number;
  public athenaDw?: boolean;
  public createdAt?: string;
  public updatedAt?: string;
  public subDimensions: SubDimensionList;
  public params: ParamList;


  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.code = data.code;
    this.fieldCode = data.fieldCode;
    this.label = data.label;
    this.dataType = data.dataType;
    this.sortOrder = data.sortOrder;
    this.subDimensions = new SubDimensionList();
    this.params = new ParamList();
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.code = data.luis_mapping;
    this.fieldCode = data.code;
    this.label = data.label;
    this.dataType = data.data_type;
    this.sortOrder = data.sort_order;
    this.athenaDw = data.athena_dw;
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
    this.subDimensions.mapData(data.sub_dimensions);
  }
}
