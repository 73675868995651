
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { DimensionModel } from '@/models/dimension';
import { DimensionList } from '@/collections/dimensions';
import dateFormat from 'dateformat';

@Component({
  components: {
  },
})
export default class GroupByDimension extends Vue {
  @Prop({ default: () => new DimensionList() })
  public dimensions!: DimensionList;
  @Prop({ default: null })
  public initDimension!: DimensionModel;
  @Prop({ default: null })
  public value!: DimensionModel|null;
  public dimension: DimensionModel = new DimensionModel();
  public dimensionName: string = '';
  public selectingDimensions: DimensionList = new DimensionList();
  public dimensionMenu: boolean = false;
  @Prop({default: false})
  public disabled!: boolean;
  public keyword: string = '';

  public selectDimension() {
    if (!this.dimension || !this.dimension.code) {
      return;
    }
    this.$emit('dimension-changed', this.dimension);
    this.$emit('input', this.dimension);
  }

  @Watch('initDimension', { immediate: true, deep: true  })
  private async onInitDimensionChanged(newVal: any) {
    this.dimension = this.initDimension;
    this.dimensionName = this.initDimension.name;
  }

}
