
import { Component, Prop, Vue } from 'vue-property-decorator';
import ReportPage from '@/components/ReportPage.vue';
import { TemplateReportList } from '@/collections/template_reports';
import { MeasureList } from '@/collections/measures';
import { StoreModel } from '@/models/store';
import { DimensionColumnList } from '@/collections/dimension_columns';


@Component({
  components: {
    ReportPage,
  },
})
export default class TemplateReportPage extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: 'available' })
  public isLockedReports!: string;
  @Prop({ default: false })
  public hasSchemas!: boolean;
  @Prop({ default: () => new TemplateReportList() })
  public templateReports!: TemplateReportList;
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;
  @Prop({ default: () => new DimensionColumnList() })
  public activeDimensions!: DimensionColumnList;
  @Prop({ default: null })
  public app!: any;
}
