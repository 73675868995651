
import { Component, Vue, Watch } from 'vue-property-decorator';
import mixpanel from 'mixpanel-browser';
import LogRocket from 'logrocket';
import createApp from '@shopify/app-bridge';
import { isShopifyEmbedded } from '@shopify/app-bridge/utilities';
import { Redirect, History, AppLink, NavigationMenu } from '@shopify/app-bridge/actions';

import {
  get, post, getIdentityToken, setIdentityToken, removeIdentityToken,
  verifyIdentityToken,
} from '@/services/http';
import { EventHandler } from '@/modules/events';
import { EventBus } from '@/main';

import { PermissionModel } from '@/models/permission';
import { StoreModel } from '@/models/store';
import {UserModel} from '@/models/user';
import { DimensionColumnList } from '@/collections/dimension_columns';
import { TagTypeList } from '@/collections/tag_types';
import { ReportMetadataList } from '@/collections/report_metadatas';
import { TagList } from '@/collections/tags';
import { MeasureList } from '@/collections/measures';
import Alert from '@/components/Alert.vue';
import VerticalMenuBar from '@/components/VerticalMenuBar.vue';
import AppBanner from '@/components/AppBanner.vue';

@Component({
  components: {
    SideBarHorizon: () => import('@/components/SideBarHorizon.vue'),
    Login: () => import('@/components/Login.vue'),
    UpgradeVersion: () => import('@/components/UpgradeVersion.vue'),
    Permission: () => import('@/components/Permission.vue'),
    DataPermissionNotice: () => import('@/components/DataPermissionNotice.vue'),
    DataSyncWarning: () => import('@/components/DataSyncWarning.vue'),
    Installing: () => import('@/components/Installing.vue'),
    HorizontalMenu: () => import('@/components/HorizontalMenu.vue'),
    LoadingSlider: () => import('@/components/LoadingSlider.vue'),
    Alert,
    VerticalMenuBar,
    AppBanner,
  },
})

export default class App extends Vue {
  public isLogined: boolean = false;
  public isRequiredLogin: boolean = false;
  public error: boolean = false;
  public loading: boolean = false;
  public installing: boolean = false;
  public checkInstalling: boolean = false;
  public isCallbackRequest: boolean = false;
  public creating: boolean = false;
  public errorMessage: string = '';
  public shopifyName: string = '';
  public isDataReady: boolean = true;
  public needUpgrade: boolean = false;
  public permission: PermissionModel = new PermissionModel();
  public isPermissionLoading: boolean = true;
  public currentFeature: string = '';
  public store: StoreModel = new StoreModel();
  public isLoggedInFromHades: boolean = false;
  public installationUrls: string[] = [
    'onboard',
    'onboardContact',
    'onboardMessaging',
    'onboardReport',
    'onboardPurpose',
    'onboardComplete',
    'onboardPricing',
    'slackCallback',
    'subscriptionConfirm',
  ];

  public app: any = null;
  public apiKey: string = String(process.env.VUE_APP_API_KEY);
  public host: string = '';
  public reportMetadatas: ReportMetadataList = new ReportMetadataList();
  public tagTypes: TagTypeList = new TagTypeList();
  public tags: TagList = new TagList();
  public measures: MeasureList = new MeasureList();
  public contentLoading: boolean = false;
  public showFullscreenPopup: boolean = true;
  public isFullLoadFinished: boolean = false;
  public mainRandomKey: number = Math.random();
  public storageSupported: boolean = true;
  public isExpandAppMenuBar: boolean = true;
  public isCallbackGoogle: boolean = false;
  public appSnackbar: boolean = false;
  public appSnackbarTimeout: number = 5000;
  public appSnackbarMessage: string = '';
  public appSnackbarColor: string = '';
  public referenceRoute: any = {};
  public activeDimensions: DimensionColumnList = new DimensionColumnList();
  public appContentMargin: string = '56px';

  public async created() {

    localStorage.setItem('isPinMenu', 'false');
    this.clearCache()
    try {
      if (!isShopifyEmbedded()) {
        if (!this.isLogined) {
          try {
            if (window.location.hostname === 'demo.assisty.ai') {
              const user: UserModel = new UserModel();
              await user.loginDemo();
              this.$emit('logged-in');
              localStorage.setItem('current_loggedin_email', user.email);
            }
          } catch (e: any) {
            console.log(e);
          }
        }

        const refererUrl = document.referrer;
        if (refererUrl) {
          const urlObj = new URL(refererUrl);
          const embeddedParam = urlObj.searchParams.get('embedded');

          if (embeddedParam === '1') {
            const idToken = urlObj.searchParams.get('id_token');
            if (idToken) {
              const responseToken: any = await post(
                  `/shopify/identify`, { id_token: idToken }
              );
              if (responseToken.token) {
                setIdentityToken(
                    responseToken.token,
                    responseToken.store.shop_name
                )
                this.isLogined = true;
                const query = Object.assign({}, this.$route.query);
                this.$router.replace({ query });
                await this.getStoreDetail();
                this.error = false;
              }
            }
          }
        }
      }
    } catch (e) {
      // skipped
    }

    if (this.$route.query.state && this.$route.query.code && this.$route.query.scope && this.$route.query.scope.includes('drive.file')) {
      this.isCallbackGoogle = true;
      let paramCode = `${this.$route.query.code}`;
      paramCode = paramCode.replaceAll('/', 'assisty_slash');
      const redirectUriEmbedded = `https://admin.shopify.com/store/${this.$route.query.state}/apps/assisty/oauth2callback?code=${paramCode}`;
      window.location.assign(redirectUriEmbedded);
      // @ts-ignore: Unreachable code error
      /* window.SHOP_NAME = `${this.$route.query.state}.myshopify.com`; */
    }
    try {
      this.getAppMenuBarStatus();
      localStorage.getItem('language');
    } catch (e) {
      this.storageSupported = false;
      return;
    }

    this.$i18n.locale = 'en';
    this.isLoggedInFromHades = localStorage.getItem('loggedInFromHades') === 'true';
    if (process.env.VUE_APP_ENVIRONMENT === 'production' && !this.isLoggedInFromHades) {
      try {
        LogRocket.init('igdczq/assisty');
      } catch {
        // skipped
      }
    }
    this.loading = true;
    this.checkIsRequiredLogin();
    if (isShopifyEmbedded()) {
      this.host = String(this.$route.query.host);
      this.app = createApp({
        apiKey: this.apiKey,
        host: this.host,
      });
    }
    if (this.isCallbackRequest) {
      await this.createStore();
    } else {
      if (this.isRequiredLogin) {
        await this.fullLoad();
        EventBus.$on('current-package-changed', async () => {
          const storeModel = new StoreModel();
          await storeModel.fetch();
          this.store = storeModel;
        });
      } else {
        this.isFullLoadFinished = true;
      }
      /* await this.permission.fetch(); */
    }
    this.setupCrisp(null);
    this.loading = false;
    EventBus.$on('update-new-store-data', async () => {
      const storeModel = new StoreModel();
      await storeModel.fetch();
      this.store = storeModel;
      EventBus.$emit('update-new-store-data-completed');
    });
    EventBus.$on('full-load-app', async () => {
      this.mainRandomKey = Math.random();
    });
    EventBus.$on('check-athena-finish-interval', async () => {
      this.checkAthenaFinishInterval();
    });
    EventBus.$on("pin-menu", async (isPin: any) => {
      this.pinMenu(isPin);
    });

  
    this.checkAthenaFinishInterval();

    EventBus.$on('redirect-to-google-auth', async () => {
      const scope = 'https://www.googleapis.com/auth/drive.file';
      const clientId = process.env.VUE_APP_GOOGLE_AUTH_CLIENT_ID;
      const redirectUrl = `${process.env.VUE_APP_HOST}/oauth2callback`;
      let url = '';
      if (isShopifyEmbedded()) {
        const state = this.store.shopName.split('.')[0];
        url = `https://accounts.google.com/o/oauth2/auth?state=${state}&scope=${scope}&response_type=code&access_type=offline&prompt=consent&redirect_uri=${redirectUrl}&client_id=${clientId}`;
      } else {
        url = `https://accounts.google.com/o/oauth2/auth?scope=${scope}&response_type=code&access_type=offline&prompt=consent&redirect_uri=${redirectUrl}&client_id=${clientId}`;
      }
      // If your app is embedded inside Shopify, use App Bridge to redirect
      if (isShopifyEmbedded()) {
        this.app = createApp({
          apiKey: this.apiKey,
          host: this.host,
        });

        Redirect.create(this.app).dispatch(Redirect.Action.REMOTE, url);
        // Otherwise, redirect using the `window` object
      } else {
        window.location.assign(url);
      }
    });

    EventBus.$on('show-snackbar', async (config: any) => {
      this.appSnackbar = true;
      if (config && config.message) {
        this.appSnackbarMessage = config.message;
      } else {
        this.appSnackbarMessage = config;
      }
      if (config && config.color) {
        this.appSnackbarColor = config.color;
      } else {
        this.appSnackbarColor = 'deep_sku_blue';
      }
    });

    EventBus.$on('clear-cache', async () => {
      this.clearCache()
    });
  }

  public pinMenu(isPin: boolean = false) {
    if (isPin) {
      this.appContentMargin = '300px';
      localStorage.setItem('isPinMenu', 'true');
    } else {
      this.appContentMargin = '56px';
      localStorage.setItem('isPinMenu', 'false');
    }
  }

  public getAppMenuBarStatus() {
    if (localStorage.getItem('isExpandAppMenuBar') === 'false') {
      this.isExpandAppMenuBar = false;
    } else {
      this.isExpandAppMenuBar = true;
    }
  }

  public get getAppMenuBarSize() {
    if (this.isExpandAppMenuBar) {
      return 'view-show-app-menu-bar';
    } else {
      return 'view-hide-app-menu-bar';
    }
  }

  public changeAppMenuBarStatus(isExpand: boolean) {
    this.isExpandAppMenuBar = isExpand;
  }
  public checkAthenaFinishInterval() {
    if (this.store.passedInstallingFlow === 'new') {
      return;
    }
    if (!this.store.isAthenaFinished) {
      localStorage.setItem('isAthenaFinished', 'true');
      const refreshId = setInterval(() => {
        if (this.store.isAthenaFinished) {
          clearInterval(refreshId);
          localStorage.setItem('isAthenaFinished', 'false');
        } else {
          this.store.fetch();
        }
      }, 60000);
    }
  }

  public async fullLoad() {
    this.isFullLoadFinished = false;
    await this.checkInstallation();
    this.isPermissionLoading = false;
    if (this.isLogined) {
      this.contentLoading = true;
      const preload = [];
      preload.push(this.tagTypes.fetch());
      if (this.activeDimensions.size() === 0) {
        preload.push(this.activeDimensions.fetch());
      }
      
      if (this.tags.size() === 0) {
        preload.push(this.tags.fetch());
      }
      if (this.reportMetadatas.size() === 0) {
        preload.push(this.reportMetadatas.fetch());
      }
      Promise.all(preload);
      if (this.measures.size() === 0) {
        await this.measures.fetch({ isOlap: true });
      }
      this.contentLoading = false;
    }
    this.isFullLoadFinished = true;
  }

  public doLogout() {
    removeIdentityToken();
    this.checkLogin();
  }

  public get isEmbeddedMode() {
    return isShopifyEmbedded();
  }

  public get isPassedInstallation() {
    return this.store.passedInstallingFlow !== 'new';
  }

  public get isConfirmingSubscription() {
    return [
      'subscriptionConfirm',
      'autoSubscription',
    ].includes(
      this.$route.name || ''
    );
  }

  public setupCrisp(e: any) {
    while (true) {
      try {
        // @ts-ignore: Unreachable code error
        this.$crisp.push(['on', 'session:loaded', this.setupCrisp]);
        // @ts-ignore: Unreachable code error
        this.$crisp.push(['on', 'chat:opened', this.setupCrisp]);
      } catch {
        // do nothing
      }
      break;
    }
  }

  public showReportSideBar() {
    return [
      'templateReports',
      'customReports',
      'customReport',
      'templateReport',
      'detailReportPage',
    ].includes(this.$route.name || '');
  }

  public checkPermission() {
    return true;
    const permissions: { [key: string]: string[] } = {
      notification: [
        'notifications',
        'notificationNewPage',
      ],
    };

    for (const permission of Object.keys(permissions)) {
      if (Object.keys(this.permission.features).includes(permission)) {
        if (permissions[permission].includes(this.$route.name || '')) {
          this.currentFeature = permission;
          return this.permission.features[permission];
        }
      }
    }

    return true;
  }

  public checkIsRequiredLogin() {
    const notRequiredLoginUrls = [
      'dataFileView',
      'slackSuccessPage',
      'statusPage',
    ];
    if (notRequiredLoginUrls.includes(this.$route.name || '')) {
      this.isRequiredLogin = false;
    } else {
      this.isRequiredLogin = true;
    }
  }

  public async getStoreDetail() {
    await this.store.fetch();
    localStorage.setItem('currency', this.store.information.currency);
    if (this.store.etlStatus !== 'ready') {
      this.isDataReady = false;
    }
    this.shopifyName = this.store.shopName;
    if (this.store.merchantAppVersion !== this.store.systemAppVersion) {
      this.needUpgrade = true;
    }

    if (this.store.passedInstallingFlow !== 'new') {
      const onboardUrls = [
        'onboard',
        'onboardContact',
        'onboardReport',
        'onboardPurpose',
        'onboardComplete',
        'onboardPricing',
      ];
      if (onboardUrls.includes(this.$route.name || '')) {
        if (isShopifyEmbedded()) {
          this.$router.push({ name: 'home' });
        } else {
          window.location.assign(`https://admin.shopify.com/store/${this.store.shopName.split('.')[0]}/apps/${process.env.VUE_APP_NAME}`);
        }
      }
    } else {
      if (this.$route.name === 'SetupGuide') {
        const storeModel = new StoreModel();
        await storeModel.fetch();
        this.store = storeModel;
      } else {
        if (!this.installationUrls.includes(this.$route.name || '')) {
          this.$router.push({ name: 'onboard' });
        }
      }
    }
    try {
      if (process.env.VUE_APP_ENVIRONMENT === 'production') {
        try {
          LogRocket.identify(`${this.store.id}`, {
            name: this.store.information.myshopify_domain,
            email: this.store.information.email,
          });
        } catch {
          // skipped
        }
      }
      if (!this.isLoggedInFromHades) {
        // @ts-ignore: Unreachable code error
        this.$crisp.push([
          'set', 'user:email', [this.store.information.email],
        ]);
        // @ts-ignore: Unreachable code error
        this.$crisp.push([
          'set', 'user:nickname', [this.store.information.name],
        ]);
        // @ts-ignore: Unreachable code error
        this.$crisp.push([
          'set',
          'session:data',
          [
            [
              ['assisty_plan', this.store.currentPackage.name],
              ['assisty_plan_actived_at', this.store.currentPackage.activatedAt || this.store.currentPackage.createdAt],
              ['shopify_plan', this.store.information.plan_display_name],
              ['assisty_installed_at', this.store.installedAt],
              ['id', this.store.id],
              ['url', this.store.information.domain],
              ['email', this.store.information.email],
              ['name', this.store.information.myshopify_domain],
              ['hades_url', `https://hades.assisty.ai/stores/${this.store.id}`],
              ['is_trial', this.store.isTrial()],
              ['current_step', this.store.currentStep],
              ['store_admin_url', this.store.information.myshopify_domain.replace('.myshopify.com', '')],
            ],
          ],
        ]);
        // @ts-ignore: Unreachable code error
        window.Appcues.identify(
          this.store.id, // unique, required
          {
            assisty_plan: this.store.currentPackage.name,
            assisty_plan_actived_at: this.store.currentPackage.activatedAt,
            shopify_plan: this.store.information.plan_display_name,
            assisty_installed_at: this.store.installedAt,
            id: this.store.id,
            url: this.store.information.domain,
            email: this.store.information.email,
            name: this.store.information.myshopify_domain,
            is_trial: this.store.isTrial(),
            current_step: this.store.currentStep,
          },
        );
      }
    } catch (err: any) {
      // do nothing
    }
  }

  private async checkLogin() {
    this.loading = true;
    if (Object.keys(this.$route.query).includes('hades')) {
      this.isLoggedInFromHades = true;
      localStorage.setItem('loggedInFromHades', 'true');
      let res: any;
      try {
        res = await get(`/hades/verify`, { token: this.$route.query.hades });
        const token = res.token;
        if (token) {
          setIdentityToken(token, res.store.shop_name);
          this.isLogined = true;
          const query = Object.assign({}, this.$route.query);
          delete query.hades;
          this.$router.replace({ query });
          await this.getStoreDetail();
          this.error = false;
        } else {
          this.isLogined = false;
        }
      } catch (err: any) {
        if (err.response.status === 401) {
          removeIdentityToken();
        }
        this.isLogined = false;
      }
    }

    if (verifyIdentityToken()) {
      if (this.$route.query.shop) {
        if (localStorage.getItem('shopify_store') !== this.$route.query.shop) {
          this.isLogined = false;
          removeIdentityToken();
          return;
        }
      }
      try {
        const verifyResult: any = await get('/token/verify');
        if (verifyResult.token) {
          setIdentityToken(verifyResult.token, verifyResult.store.shop_name);
          localStorage.setItem('current_loggedin_email', this.store.config.contactEmail);
        }
        this.isLogined = true;
        this.removeShopifyQueries();
        await this.getStoreDetail();
      } catch (err: any) {
        if (err.response.status === 401) {
          removeIdentityToken();
        }
        this.isLogined = false;
      }
    } else {
      this.isLogined = false;
    }
    if (this.isLogined) {
      mixpanel.init('c16cf54eeff128f8d922ed07c1b8844c', {
        debug: false,
      });

      if (!this.isLoggedInFromHades) {
        /* const distinctId = mixpanel.get_distinct_id(); */
        /* localStorage.setItem('mixpanel_distinct_id', distinctId); */
        const installedAt = Date.parse(this.store.installedAt);
        const now = Date.parse(Date().toString());
        const days = Math.floor((now - installedAt) / (1000 * 60 * 60 * 24));
        const circleCount = Math.ceil((days - 7) / 30);
        mixpanel.identify(`${this.store.id}`);
        mixpanel.people.set({
          store_id: this.store.id,
          shopipfy_store_name: this.store.shopName,
          country_name: this.store.information.country_name,
          created_at: this.store.information.created_at,
          currency: this.store.information.currency,
          domain: this.store.information.domain,
          email: this.store.information.email,
          myshopify_domain: this.store.information.myshopify_domain,
          name: this.store.information.name,
          phone: this.store.information.phone,
          plan_display_name: this.store.information.plan_display_name,
          shop_owner: this.store.information.shop_owner,
          timezone: this.store.information.timezone,
          current_package_name: this.store.currentPackage.name,
          current_package_id: this.store.currentPackage.billingPackageId,
          product_category: this.store.config.productCategory,
          role: this.store.config.role,
          purpose: this.store.config.purpose,
          is_discount: this.store.currentPackage.discount ? true : false,
          price: this.store.currentPackage.price,
          billing_circle_count: circleCount,
        });
      }
      return;
    } else {
      if (this.$route.path !== '/') {
        this.referenceRoute = this.$route;
        //this.$router.push({ name: 'home', query: this.$route.query });
      }
    }
    this.loading = false;
  }

  private removeShopifyQueries() {
    const query = Object.assign({}, this.$route.query);
    delete query.hmac;
    delete query.host;
    delete query.session;
    delete query.shop;
    delete query.timestamp;
    this.$router.replace({ query });
  }

  private async createStore() {
    this.creating = true;
    const query = this.$route.query;
    const data: { [key: string]: any } = {};
    for (const key of Object.keys(query)) {
      data[key] = query[key];
    }
    try {
      const res: any = await get(`/shopify/callback`, data);
      const token = res.token;
      if (token) {
        setIdentityToken(token, res.store.shop_name);
        window.location.assign(`https://admin.shopify.com/store/${data.shop.split('.')[0]}/apps/${process.env.VUE_APP_NAME}`);
        this.isLogined = true;
        this.error = false;
        this.installing = false;
        this.creating = false;
      }
    } catch (e: any) {
      this.error = true; this.errorMessage = e.response.data.message; this.creating = false;
      return;
    }
  }

  private async getClientIP() {
    try {
      const axios = require('axios').default;
      const options = {
        method: 'get',
        url: 'https://api.ipify.org?format=json',
      };
      const ip = await axios.request(options).then((response: any) => {
        return response.data.ip;
      });
      return ip;
    } catch (error: any) {
      return '';
    }
  }

  private async checkInstallation() {
    this.error = false;
    this.installing = false;
    this.checkInstalling = true;
    await this.checkLogin();
    if (this.isLogined) {
      this.error = false;
      this.loading = false;
      this.installing = false;
      this.checkInstalling = false;
      return;
    }
    const query = this.$route.query;
    const data: { [key: string]: any } = {};
    for (const key of Object.keys(query)) {
      data[key] = query[key];
    }
    try {
      const res: any = await get(`/shopify`, data);
      const installUrl = res.install_url;
      const token = res.token;
      if (installUrl) {
        // If your app is embedded inside Shopify, use App Bridge to redirect
        if (isShopifyEmbedded()) {
          this.app = createApp({
            apiKey: this.apiKey,
            host: this.host,
          });

          Redirect.create(this.app).dispatch(Redirect.Action.REMOTE, installUrl);
          // Otherwise, redirect using the `window` object
        } else {
          window.location.assign(installUrl);
        }
        this.error = false;
        this.installing = true;
      }
      if (token) {
        setIdentityToken(token, res.store.shop_name);
        this.isLogined = true;
        this.removeShopifyQueries();
        if (isShopifyEmbedded()) {
          this.app = createApp({
            apiKey: this.apiKey,
            host: this.host,
          });
        }
        await this.getStoreDetail();
        if (this.referenceRoute) {
          this.$router.push(this.referenceRoute)
          this.removeShopifyQueries()
        }
        this.error = false;
        this.installing = false;
      }
    } catch (e) {
      this.error = true;
      if (getIdentityToken()) {
        this.errorMessage = 'There are some error while installing. Please try again later';
      } else {
        this.errorMessage = 'You are not logged in yet. Please go to Shopify Store\'s Apps to access Assisty.';
      }
      this.installing = false;
    }
    this.checkInstalling = false;
  }

  @Watch('$route', { immediate: true, deep: true })
  private async onUrlChange(newVal: any) {
    this.isCallbackRequest = this.$route.name === 'callback';
    if (isShopifyEmbedded()) {
      History.create(this.app).dispatch(History.Action.REPLACE, this.$route.fullPath);
    }
    if (this.isLogined && this.isRequiredLogin) {
      try {
        if (!this.isLoggedInFromHades) {
          if (this.$route.name !== 'customReport'
            && this.$route.name !== 'templateReport'
          ) {
            const eventHandler = new EventHandler({
              store: this.store,
            });
            eventHandler.track(`view ${this.$route.name} page`);
            this.store.sendEvent('VIEW_PAGE', {
              name: this.$route.name,
              path: this.$route.path,
            });
          }
        }
      } catch (e) {
        // ignore this
      }
      this.getStoreDetail();
    }
  }

  private clearCache() {
    for (const key of Object.keys(localStorage)) {
      if (key.split('_')[0] === 'cache') {
        localStorage.removeItem(key);
      }
    }
  }
}
