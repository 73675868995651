import { get } from '@/services/http';
import { TagTypeModel } from '@/models/tag_type';

export class TagModel {
  public id: number;
  public name: string;
  public description: string;
  public tagType: TagTypeModel;
  public terms?: string[];
  public reportCount: number = 0;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.tagType = new TagTypeModel();
    this.terms = [];
    this.reportCount = 0;
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.tagType = new TagTypeModel({ id: data.tagTypeId });
    this.terms = data.terms ? data.terms.split(',') : null;
  }
}
