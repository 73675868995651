export class EtlStatusModel {
  public id: number;
  public resource: string;
  public status: string;
  public startTime: string;
  public endTime: string;
  public lastUpdatedAt: string;
  public nextTimeEtl: string;
  public interval: string;
  public etlType: string;
  public limitStartTime: string;
  public pendingEndTime: string;
  public pendingStartTime: string;
  public createdAt: string;
  public updatedAt: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.resource = data.resource;
    this.status = data.status;
    this.startTime = data.startTime;
    this.endTime = data.endTime;
    this.lastUpdatedAt = data.lastUpdatedAt;
    this.nextTimeEtl = data.nextTimeEtl;
    this.interval = data.interval;
    this.etlType = data.etlType;
    this.limitStartTime = data.limitStartTime;
    this.pendingEndTime = data.pendingEndTime;
    this.pendingStartTime = data.pendingStartTime;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.resource = data.resource;
    this.status = data.status;
    this.startTime = data.startTime;
    this.endTime = data.endTime;
    this.lastUpdatedAt = data.lastUpdatedAt;
    this.nextTimeEtl = data.nextTimeEtl;
    this.interval = data.interval;
    this.etlType = data.etlType;
    this.limitStartTime = data.limitStartTime;
    this.pendingEndTime = data.pendingEndTime;
    this.pendingStartTime = data.pendingStartTime;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}
