
import { Component, Prop, Vue } from 'vue-property-decorator';
import HeaderTitle from '../components/HeaderTitle.vue';
import ActionButton from '../components/ActionButton.vue';
import { StoreModel } from '@/models/store';
import { RouterLink } from 'vue-router';
import { PluginList } from '@/collections/plugins';
import { PluginModel } from '@/models/plugin';
import { UserPluginModel } from '@/models/user_plugin';
import { UserPluginList } from '@/collections/user_plugins';
import { PluginPackageList } from '@/collections/plugin_packages';
import { PluginPackageModel } from '@/models/plugin_package';
import { EventHandler } from '@/modules/events';

@Component({
  components: {
    HeaderTitle,
    ActionButton,
  },
})
export default class Plugins extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: false })
  public hasSchemas!: boolean;
  public plugins: PluginList = new PluginList();
  public userPlugins: UserPluginList = new UserPluginList();
  public pluginPackages: PluginPackageList = new PluginPackageList();
  public availablePlugins: any = [];
  public loading: boolean = false;

  public async created() {
    this.loading = true;
    await this.plugins.fetch();
    await this.userPlugins.fetch();
    await this.pluginPackages.fetchByPackage(this.store.currentPackage.billingPackageId);
    this.generateListAvailablePlugins();
    this.loading = false;
    try {
        const eventHandler = new EventHandler({
          store: this.store,
        });
        eventHandler.track('VIEW_PLUGIN_LISTING');
        this.store.sendEvent('VIEW_PLUGIN_LISTING');
      } catch (e) {
        // ignore this
      }
  }

  public viewLink(link: any) {
    if (link) {
      this.$router.push(link);
    } else {
      this.$router.push('/');
    }
  }

  public generateListAvailablePlugins() {
    this.availablePlugins = [];
    for (const plugin of this.plugins.items) {
      // check if the plugin is installed or not
      let isInstalled: boolean = false;
      let userPlugin: UserPluginModel = new UserPluginModel();
      for (const item of this.userPlugins.items) {
        if (item.pluginId === plugin.id) {
          userPlugin = item;
          isInstalled = true;
          break;
        }
      }
      // get the plugin package for this plugin
      let hasPackage: boolean = false;
      let pluginPackage: PluginPackageModel = new PluginPackageModel();
      for (const item of this.pluginPackages.items) {
        if (item.pluginId === plugin.id) {
          hasPackage = true;
          pluginPackage = item;
          break;
        }
      }
      this.availablePlugins.push(
        {
          isInstalled,
          hasPackage,
          plugin,
          userPlugin,
          pluginPackage,
        },
      );
    }
  }

  public viewPluginDetail(pluginId: number) {
    this.$router.push(`/plugin/${pluginId}`);
  }

  public getPluginPrice(availablePlugin: any) {
    if (availablePlugin.isInstalled) {
      return 'Installed';
    }
    if (availablePlugin.hasPackage) {
      if (availablePlugin.pluginPackage.price === 0) {
        return 'Free';
      }
      return '$' + availablePlugin.pluginPackage.price + '/Month';
    }
    return 'Not available for your package';
  }
}
