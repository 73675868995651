
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { get, put, post } from '@/services/http';
import ChatWithUs from '@/components/ChatWithUs.vue';
import { EventBus } from '@/main';

@Component({
  components: {
    ChatWithUs,
  },
})
export default class ReportCardErrorMessage extends Vue {
  public icon: string = 'mdi-alert-circle-outline';
  public title: string = 'Maybe there is something wrong';
  @Prop({default: 'big'})
  public size!: string;
  public iconColor: string = 'primary';
  public iconSize: number = 168;
  public width: number = 700;
  @Prop({default: 'no-data'})
  public type!: string;
  public message: string = '';
  public message2: string = '';
  public ctaMessage: string = 'Please click on the below button to start!';
  public btnChatWithUs: boolean = true;
  public btnCTA: boolean = true;
  public btnTitle: string = 'Start Now';
  public btnLink: string = '/';

  public created() {
    if (this.size === 'small') {
      this.width = 300;
      this.iconSize = 50;
    }
    switch (this.type) {
      case 'no-data': {
        this.title = 'No data available';
        this.iconColor = 'dark_gray';
        this.icon = 'mdi-numeric-0-circle-outline';
        this.message = 'There is no data for this report.';
        this.message2 = 'Please try to change the report options: date range, group by dimension, or filters.';
        this.ctaMessage = 'Or chat with us if you think there is something wrong!';
        this.btnChatWithUs = true;
        this.btnCTA = false;
        this.btnLink = '';
        this.btnTitle = '';
        break;
      }
      case 'no-chart': {
        this.title = 'No chart available';
        this.icon = 'mdi-numeric-0-circle-outline';
        this.message = 'There is no summary chart for this report.';
        this.message2 = 'Please try to change the report options: date range, group by dimension, or filters.';
        this.ctaMessage = 'Or chat with us if you think there is something wrong!';
        this.btnChatWithUs = true;
        this.btnCTA = false;
        this.btnLink = '';
        this.btnTitle = '';
        break;
      }
      case 'chart-data-error': {
        this.title = 'Maybe there is something wrong';
        this.iconColor = '#FF9E15';
        this.icon = 'mdi-alert-circle-outline';
        this.message = 'There is something wrong when loading this chart.';
        this.message2 = 'Please click on the button below to try again!';
        this.ctaMessage = 'Or chat with us for help!';
        this.btnChatWithUs = true;
        this.btnCTA = false;
        this.btnLink = '';
        this.btnTitle = '';
        break;
      }
      case 'table-data-error': {
        this.title = 'Maybe there is something wrong';
        this.iconColor = '#FF9E15';
        this.icon = 'mdi-alert-circle-outline';
        this.message = 'There is something wrong when loading this chart.';
        this.message2 = 'Please click on the button below to try again!';
        this.ctaMessage = 'Or chat with us for help!';
        this.btnChatWithUs = true;
        this.btnCTA = false;
        this.btnLink = '';
        this.btnTitle = '';
        break;
      }
      case 'no-favorite': {
        this.title = 'Add your favorite reports in two steps';
        this.icon = 'mdi-star-box-outline';
        this.message = 'View a report from the Report Library';
        this.message2 = 'Click on the Save As button';
        this.ctaMessage = 'you are done';
        this.btnChatWithUs = true;
        this.btnCTA = true;
        this.btnLink = '/';
        this.btnTitle = 'View All Reports';
        break;
      }
      case 'no-notification': {
        this.title = 'Schedule to get a report via your email';
        this.icon = 'mdi-star-box-outline';
        this.message = 'Assisty allows you to get a summary and detailed data file of a report via email';
        this.ctaMessage = 'Please click on the below button to start';
        this.btnChatWithUs = true;
        this.btnCTA = true;
        this.btnLink = '/schedule/new';
        this.btnTitle = 'Schedule New Report';
        break;
      }

      case 'data-error': {
        this.title = 'There is something wrong';
        this.iconColor = '#FF9E15';
        this.icon = 'mdi-alert-circle-outline';
        this.message = 'There is something wrong when loading data';
        this.message2 = 'Please click on the button below to try again';
        this.ctaMessage = 'Or chat with us for help';
        this.btnChatWithUs = true;
        this.btnCTA = false;
        this.btnLink = '';
        this.btnTitle = '';
        break;
      }

      default: {
          // statements;
          break;
      }
    }
  }

  public reloadData() {
    EventBus.$emit('reload-report-data');
  }
}
