
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { EventHandler } from '@/modules/events';
import { MeasureModel } from '@/models/measure';
import { DimensionModel } from '@/models/dimension';
import { AnalyticsTypeModel } from '@/models/analytics_type';
import { ProductSegmentModel } from '@/models/product_segment';
import { StoreModel } from '@/models/store';
import { FilterColumnList } from '@/collections/filter_columns';
import { ParamList } from '@/collections/params';
import { EventBus } from '@/main';
import ActionButton from '@/components/ActionButton.vue';

interface Option {
  sortBy?: any;
  sortDesc?: any;
  page?: string;
  itemsPerPage?: string;
  multiSort?: boolean;
}

@Component({
  components: {
    ActionButton,
  },
})
export default class ProductSegmentDialog extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: () => new MeasureModel() })
  public measure!: MeasureModel;
  @Prop({ default: () => new AnalyticsTypeModel() })
  public analyticsType!: AnalyticsTypeModel;
  public loading: boolean = false;
  @Prop({ default: () => new FilterColumnList() })
  public filterColumns!: FilterColumnList;
  @Prop({ default: () => new ParamList() })
  public reportParams!: ParamList;
  @Prop({ default: () => [] })
  public reportColumns!: any;
  @Prop({ default: () => {} })
  public pageOptions!: Option;
  @Prop({ default: () => [] })
  public dates!: string[];
  public name: string = '';
  public type: string = '';
  public description: string = '';
  public productSegmentDialog: boolean = false;
  public createSegmentLoading: boolean = false;
  public generateProductLoading: boolean = false;
  public productSegment: ProductSegmentModel = new ProductSegmentModel();
  public isCreatedProductSegment: boolean = false;
  public isGeneratedProductList: boolean = false;
  public isCreateSegmentError: boolean = false;
  public isGenerateProductError: boolean = false;
  @Prop({default: true})
  public needGenerateProduct!: boolean;
  @Prop({default: 0})
  public totalProduct!: number;
  public async created() {
    EventBus.$on('show-create-product-segment-dialog', async (data: any) => {
      this.productSegmentDialog = true;
      this.isCreateSegmentError = false;
      this.isGenerateProductError = false;
      this.isCreatedProductSegment = false;
      this.createSegmentLoading = false;
      this.generateProductLoading = false;
      this.isGeneratedProductList = false;
      this.productSegment = new ProductSegmentModel();
    });
  }

  public sendMixpanelEvent() {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });

      eventHandler.track('Create Segment', {
          segment_name: this.productSegment.name,
          segment_id: this.productSegment,
          dimension: 'Product',
          measure: this.measure.code,
        });

    } catch (e) {
      // ignore this catch
    }
  }

  public viewSegment() {
    this.productSegmentDialog = false;
    this.$router.push(`/product-segment/${this.productSegment.id}`);
  }

  public initDefaultData() {
    this.measure.code = 'product_info';
    this.analyticsType.code = 'top_high';
  }

  public async createProductSegment(type: string = 'data_driven') {
    if (!this.measure.code) {
      this.initDefaultData();
    }
    this.isCreateSegmentError = false;
    this.createSegmentLoading = true;
    this.productSegment.type = type;
    this.productSegment.measure = this.measure;
    this.productSegment.dimension = new DimensionModel({ code: 'by_product' });
    this.productSegment.analyticsType = this.analyticsType;
    this.productSegment.filters = this.filterColumns;
    this.productSegment.params = this.reportParams;
    this.productSegment.timerange = this.dates;
    this.productSegment.pageOptions = this.pageOptions;

    try {
      await this.productSegment.save();
      this.isCreatedProductSegment = true;
      this.isCreateSegmentError = false;
      this.sendMixpanelEvent();
      EventBus.$emit('show-snackbar', {
        message: 'Product segment created successfully',
        color: 'success',
      });
    } catch (e: any) {
      this.createSegmentLoading = false;
      this.isCreateSegmentError = true;
      this.isCreatedProductSegment = false;
      EventBus.$emit('show-snackbar', {
        message: 'Error creating product segment',
        color: 'error',
      });
    }
    this.createSegmentLoading = false;
    if (this.isCreatedProductSegment) {
      await this.generateSegmentProducts();
    }
  }

  public async generateSegmentProducts() {
    if (!this.needGenerateProduct) {
      return;
    }

    if (!this.productSegment.id) {
      return;
    }
    try {
      this.isGenerateProductError = false;
      this.isGeneratedProductList = false;
      this.generateProductLoading = true;
      await this.productSegment.generateProducts();
      this.isGeneratedProductList = true;
      this.generateProductLoading = false;
      EventBus.$emit('show-snackbar', {
        message: 'Products added to segment successfully',
        color: 'success',
      });
    } catch (e: any) {
      this.isGeneratedProductList = false;
      this.generateProductLoading = false;
      this.isGenerateProductError = true;
      EventBus.$emit('show-snackbar', {
        message: 'Error adding products to segment',
        color: 'error',
      });
    }
  }
}
