import { get, put, del, post } from '@/services/http';

export class UserModel {
  public id: number;
  public email: string;
  public name: string;
  public status: string;
  public storeName?: string;
  public password?: string;
  public userroleId: number;
  public userroleName?: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.email = data.email;
    this.name = data.name;
    this.status = 'ACTIVE';
    this.password = '';
    this.userroleId = 0;
  }

  public get isActive() {
    return this.status === 'ACTIVE';
  }

  public async fetch() {
    const res: any = await get(`/users/${this.id}`);
    this.mapData(res.data);
  }

  public async deactive() {
    const res: any = await put(`/users/${this.id}/deactive`);
    this.mapData(res.data);
  }

  public async active() {
    const res: any = await put(`/users/${this.id}/active`);
    this.mapData(res.data);
  }

  public async resetPassword() {
    const res: any = await put(`/users/${this.id}/reset_password`, {
      password: this.password,
    });
  }

  public async changePassword(currentPassword: string, password: string) {
    const res: any = await put(`/users/${this.id}/change_password`, {
      current_password: currentPassword,
      password,
    });
  }

  public async remove() {
    const res: any = await del(`/users/${this.id}`);
  }

  public async save() {
    const res: any = await post('/users', {
      name: this.name,
      email: this.email,
      password: this.password,
      userroleId: this.userroleId,
    });
    this.mapData(res.data);
  }

  public async login() {
    if (this.storeName && !this.storeName.includes('.myshopify.com')) {
      this.storeName = `${this.storeName}.myshopify.com`;
    }
    const res: any = await post('/token', {
      shop_name: this.storeName,
      email: this.email,
      password: this.password,
    });
    localStorage.setItem('token', res.data.token);
    localStorage.setItem('refresh_token', res.data.refresh_token);
  }

  public async loginDemo() {
    const res: any = await post('/token/demo', {});
    localStorage.setItem('token', res.data.token);
  }

  public async update() {
    const res: any = await put(`/users/${this.id}`, {
      name: this.name,
      userroleId: this.userroleId,
    });
    this.mapData(res.data);
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.email = data.email;
    this.name = data.name;
    this.status = data.status;
    this.userroleId = data.userroleId;
  }
}
