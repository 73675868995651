
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { get, getIdentityToken } from '@/services/http';

@Component({
  components: {},
})
export default class SlackSuccessPage extends Vue {
  public success: boolean = false;
  public error: boolean = false;
  public loading: boolean = false;
  public installing: boolean = false;
  public shopifyStore: string = '';
  public shopifyStoreRules: any = [
    this.isStoreNameExist,
  ];

  public isStoreNameExist(v: any) {
    return !!v || 'Shopify Store Name is required';
  }

  public created() {
    if (getIdentityToken()) {
      this.$router.push({ name: 'home' });
    }
  }

  public get shopName() {
    return `${this.shopifyStore}.myshopify.com`;
  }

  public async install() {
    const result: any = await get('/shopify/install', {
      shop: this.shopName,
      state: this.$route.query.state,
    });
    window.open(result.install_url, '_self');
  }
}
