import { get } from '@/services/http';

export class ResourceModel {
  public name: string;
  public code: string;
  public type: string;
  public validData: string;

  public ruleCode: string;

  constructor(data: any = {}) {
    this.name = data.name;
    this.code = data.code;
    this.type = data.type;
    this.validData = data.validData;
    this.ruleCode = data.ruleCode;
  }

  public mapData(data: any = {}) {
    this.name = data.name;
    this.code = data.code;
    this.type = data.type;
    this.validData = data.validData;
    this.ruleCode = data.ruleCode;

  }
}
