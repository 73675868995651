import { get, put, del, post } from '@/services/http';

export class UserRoleModel {
  public id: number;
  public name: string;
  public status: string;
  public rules: any[];
  public resources: any[];
  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.status = 'ACTIVE';
    this.rules = [];
    this.resources = [];
  }

  public get isActive() {
    return this.status === 'ACTIVE';
  }

  public async fetch() {
    const res: any = await get(`/userroles/${this.id}`);
    this.mapData(res.data);
  }

  public async deactive() {
    const res: any = await put(`/userroles/${this.id}/deactive`);
    this.mapData(res.data);
  }

  public async active() {
    const res: any = await put(`/userroles/${this.id}/active`);
    this.mapData(res.data);
  }

  public async remove() {
    const res: any = await del(`/userroles/${this.id}`);
  }

  public async save(storeLocations: string[] = []) {
    const options: any = {
        name: this.name,
        rules: this.rules,
        resources: this.resources,
    };
    if (storeLocations.length) {
      options['Location[Location]'] = storeLocations;
    }
    const res: any = await post('/userroles', options);
    this.mapData(res.data);
  }

  public async saveNew(storeLocations: string[] = []) {
    if (storeLocations.length) {
      const options = { 'Location[Location]': storeLocations };
      const res: any = await post('/userroles', options);
      this.mapData(res.data);
    }
    return this;
  }

  public async update() {
    const res: any = await put(`/userroles/${this.id}`, {
      name: this.name,
    });
    this.mapData(res.data);
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.status = data.status;
    this.rules = data.rules;
    this.resources = data.resources;
  }

  public getAccessableStoreLocations() {
    for (const rule of this.rules) {
      if (rule.code === 'store_location_access') {
        return rule.value['Location[Location]'];
      }
    }
    return [];
  }

}
