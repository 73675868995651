
import { Component, Prop, Vue } from 'vue-property-decorator';
import HeaderTitle from '@/components/HeaderTitle.vue';
import ActionButton from '@/components/ActionButton.vue';
import { SpreadsheetTemplateModel } from '@/models/spreadsheet_template';
import { SpreadsheetTemplateList } from "@/collections/spreadsheet_templates";
import { SpreadsheetConnectedModel } from '@/models/spreadsheet_connected';
import { StoreModel } from '@/models/store';
import { EventBus } from "@/main";

@Component({
  components: {
    HeaderTitle,
    ActionButton,
  },
})
export default class SpreadsheetIntegration extends Vue {
  @Prop({ default: null })
  public app!: any;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public isLoading = false;
 
  public async created() {
    
  }
  public mounted() {
      const calendly = document.createElement('script');
      calendly.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
      document.head.appendChild(calendly);
    }
}
