import { get, post } from '@/services/http';
import { DashboardModel } from '@/models/dashboard';
import List from './list';

export class DashboardList extends List<DashboardModel> {
  public mapData(data: any = []) {
    for (const s of data) {
      const dashboard = new DashboardModel();
      dashboard.mapData(s);
      this.add(dashboard);
    }
  }

  public async fetch() {
    const res: any = await get(`/dashboards`);
    this.mapData(res.data.rows);
  }

  public async fetchPublished() {
    const res: any = await get(`/published_dashboards`);
    this.mapData(res.data.rows);
  }
}
