
import { Component, Watch, Prop, Vue } from "vue-property-decorator";

import ActionButton from "@/components/ActionButton.vue";
import { EventBus } from "@/main";
import StoreLocationSettings from "@/components/StoreLocationSettings.vue";
import { InventoryTransferRuleList } from '@/collections/inventory_transfer_rules';
import { InventoryTransferRuleModel } from '@/models/inventory_transfer_rule';
import { Container, Draggable } from 'vue-smooth-dnd';
import { StoreModel } from '@/models/store';
import Alert from '@/components/Alert.vue';

@Component({
  components: {
    ActionButton,
    StoreLocationSettings,
    Container,
    Draggable,
    Alert,
  },
})
export default class InventoryTransferSettings extends Vue {
  public settingDialog: boolean = false;
  @Prop({ default: false })
  public isPrimaryButton!: boolean;
  @Prop({ default: "Inventory Parameters" })
  public buttonTitle!: string;
  @Prop({ default: "mdi-cog" })
  public buttonIcon!: string;
  @Prop({ default: "small" })
  public buttonSize!: string;
  @Prop({ default: false })
  public saveLoading!: boolean;
  public numberRule: any = [
    (v: string) => !v || !isNaN(Number(v)) || "Value must be a number",
  ];
  //salesTrend must be in the format: -30% or +20%
  public salesTrendRules: any = [
    (v: string) => /^[-+]?\d+%$/.test(v) || "Sales trend must be in the format: -30%, 20% or +15%",
  ];
  public isFormValid: boolean = false;
  public globalRule: InventoryTransferRuleModel = new InventoryTransferRuleModel();
  public storeLocationRules: InventoryTransferRuleList = new InventoryTransferRuleList();
  public allTransferRules: InventoryTransferRuleList = new InventoryTransferRuleList();
  public transferableQuantityCalculationList: any = [
    { value: "OVERSTOCKED", name: "Overstocked Quantity" },
    { value: "OVERSTOCKED_INCOMING", name: "Overstocked plus Incoming Quantity" },
    { value: "AVAILABLE", name: "All Available Quantity" },
    { value: "AVAILABLE_INCOMING", name: "All Available plus Incoming Quantity" },
  ];
  public transferSourceSelection: string = '';
  public transferSourceSelectionList: any = [
    { 
        value: "LOCATION_TYPE", 
        name: "Warehouse First, Then Store", 
        description: "Prioritize selecting warehouse locations first, followed by store locations." 
    },
    { 
      value: "DISTANCE", 
      name: "Same Region First, Then Outside",
      description: "Prioritize selecting locations in the same region first, followed by locations outside the region."
    },
    { 
      value: "QUANTITY", 
      name: "Most Overstocked First",
      description: "Prioritize selecting locations with the most overstocked quantity first."
    },
  ];
  public historicalPeriodList: any = [
    { value: "30d", name: "Last 30 Days" },
    { value: "60d", name: "Last 60 Days" },
    { value: "90d", name: "Last 90 Days" },
    { value: "180d", name: "Last 180 Days" },
    { value: 'same_month_last_year', name: 'Same Month Last Year'},
    { value: 'same_quarter_last_year', name: 'Same Quarter Last Year'},
  ];
  
  public tab: number = 0;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;  
  public isEnablePlugin: boolean = false;

  public async created() {
    if (this.store.validateRule([{
      code: 'inventory_transfer_suggestion',
    }]).isValidate) {
      this.isEnablePlugin = true;
    }    
    await this.loadRules();
    this.globalRule.scope = 'global';
  }

  public onDrop(result: any) {
    this.transferSourceSelectionList = this.applyDrag(this.transferSourceSelectionList, result);
  }

  public applyDrag(result: any, dragResult: any) {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) {
      return result;
    }
    let itemToAdd = payload;
    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }
    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }
    this.globalRule.transferSourceSelection = this.transferSourceSelectionList.map((item: any) => item.value).join(', ');
    return result;
  }

  public async loadRules() {
    await this.allTransferRules.fetch();
    this.storeLocationRules = new InventoryTransferRuleList();
    this.globalRule = new InventoryTransferRuleModel();
    for (const rule of this.allTransferRules.items) {
      if (rule.scope === 'location') {
        this.storeLocationRules.items.push(rule);
      }
    }
    for (const rule of this.allTransferRules.items) {
      if (rule.scope === 'global') {
        this.globalRule = rule;
        break;
      }
    }
    if (!this.globalRule.id) {
      this.globalRule.scope = 'global';
      this.globalRule.multipleLocationTransfer = false;
      this.globalRule.transferableQuantityCalculation = 'OVERSTOCKED';
      this.globalRule.transferSourceSelection = 'LOCATION_TYPE, DISTANCE, QUANTITY';
      
    }
    //sort the transferSourceSelectionList based on the value of transferSourceSelection in globalRule
    this.transferSourceSelectionList.sort((a: any, b: any) => {
        const aIndex = this.globalRule.transferSourceSelection.indexOf(a.value);
        const bIndex = this.globalRule.transferSourceSelection.indexOf(b.value);
        return aIndex - bIndex;
      });
  }

  public resetForm() {

  }

  public async applySetting() {
      try {
        const globalRule: InventoryTransferRuleList = new InventoryTransferRuleList();
        globalRule.items.push(this.globalRule);
        this.saveLoading = true;
        await globalRule.updateGlobalRule();
        this.saveLoading = false;
        EventBus.$emit("show-snackbar", "Settings saved successfully");
      } catch (error: any) {
        EventBus.$emit("show-snackbar", error.message);
        this.saveLoading = false;
      }
    }
}
