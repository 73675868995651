
import { Component, Prop, Vue } from 'vue-property-decorator';
import HeaderTitle from '@/components/HeaderTitle.vue';
import ActionButton from '@/components/ActionButton.vue';
import { StoreModel } from '@/models/store';
import { EventBus } from "@/main";
import { EmailReceiverModel } from "@/models/email_receiver";
import { EmailReceiverList } from "@/collections/email_receivers";
import { EventHandler } from "@/modules/events";
import EmptyStage from '@/components/EmptyStage.vue';

@Component({
  components: {
    HeaderTitle,
    ActionButton,
    EmptyStage,
  },
})
export default class EmailReceivers extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public success: boolean = false;
  public error: boolean = false;
  public isLoading: boolean = false;
  public emailReceivers: EmailReceiverList = new EmailReceiverList();
  public deletingReceiver: EmailReceiverModel = new EmailReceiverModel();
  public dialogDelete: boolean = false;
  public headers: any = [];
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  public deleteLoading: boolean = false;
  public saveLoading: boolean = false;
  public newReceiverDialog: boolean = false;
  public newReceiver: EmailReceiverModel = new EmailReceiverModel();

  public async created() {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`view email receivers listing page`);
    } catch (e) {
      // ignore this
    }
    this.isLoading = true;

    this.headers = [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        width: '150px',
      },
    ];
    await this.fullLoadData();
    this.isLoading = false;
  }

  public async saveReceiver() {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`save email receiver`, {});
    } catch (e) {
      // ignore this
    }
    this.saveLoading = true;
    if (this.newReceiver.id) {
      await this.newReceiver.update();
      EventBus.$emit('show-snackbar', 'Email Receiver updated successfully!');
    } else {
      await this.newReceiver.create();
      EventBus.$emit('show-snackbar', 'Email Receiver added successfully!');
    }
    this.saveLoading = false;
    this.newReceiverDialog = false;
    await this.fullLoadData();
  }

  public canManageSupplier() {
    return true;
  }

  public async fullLoadData() {
    this.isLoading = true;
    this.emailReceivers = new EmailReceiverList();
    await this.emailReceivers.fetch();
    this.isLoading = false;
  }

  public editTemplate(receiver: EmailReceiverModel) {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`click edit receiver`);
    } catch (e) {
      // ignore this
    }
    this.newReceiver = receiver;
    this.newReceiverDialog = true;
  }

  public confirmDelete(receiver: EmailReceiverModel) {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`confirm delete receiver`);
    } catch (e) {
      // ignore this
    }
    this.deletingReceiver = receiver;
    this.dialogDelete = true;
  }

  public async deleteItem() {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`delete receiver`);
    } catch (e) {
      // ignore this
    }
    this.deleteLoading = true;
    let index: number = -1;
    for (const item of this.emailReceivers.items) {
      index = index + 1;
      if (item.id === this.deletingReceiver.id) {
        this.emailReceivers.items.splice(index, 1);
        break;
      }
    }
    await this.deletingReceiver.remove();
    EventBus.$emit('show-snackbar', 'Email receiver deleted successfully!');
    this.deleteLoading = false;
    this.dialogDelete = false;
  }
}
