
import { Component, Vue, Prop } from 'vue-property-decorator';
import { EventBus } from '@/main';
import { FilterColumnList } from '@/collections/filter_columns';

interface FilterView {
  name?: string;
  filter: FilterColumnList;
  isDefault?: boolean;
}

@Component({
  components: {
  },
})
export default class FilterViewListing extends Vue {
  @Prop({default: false})
  public canCreateViewForTemplateReport!: boolean;
  @Prop({default: () => []})
  public filterViews!: FilterView[];
  @Prop({default: {name: '', isDefault: false, filter: new FilterColumnList()} })
  public activeFilterView!: FilterView;
  public maxItemShow: number = 0;
  public defaultFilterViev: FilterView = {name: 'All Data', isDefault: false, filter: new FilterColumnList()};
  public created() {

  }

  public get isShowingActive() {
    if (this.defaultFilterViev.name === this.activeFilterView.name) {
      return true;
    }
    return false;
  }
  public canItemShow(index: number) {
    if (!this.activeFilterView || !this.activeFilterView.name) {
      return index < this.maxItemShow;
    }
    if (this.isShowingActive) {
      return index < this.maxItemShow;
    } else {
      return index < this.maxItemShow - 1;
    }
  }

  public get canShowMore() {
    if (this.filterViews.length > this.maxItemShow) {
      return true;
    }
    return false;
  }

  public get moreItemCount() {
    if (this.filterViews.length > this.maxItemShow) {
      return this.filterViews.length - this.maxItemShow;
    }
    return 0;
  }

  public showDialogEditFilterView() {
    EventBus.$emit('show-dialog-edit-filter-fiew');
  }

  public loadFilterView(filterView: FilterView) {
    EventBus.$emit('load-filter-view', filterView);
  }

  public openCreateFilterViewForm() {
    EventBus.$emit('open-create-filter-view-form');
  }
}
