import { EtlStatusModel } from '@/models/etl_status';
import List from './list';

export class EtlStatusList extends List<EtlStatusModel> {
  public mapData(etlStatuses: []) {
    for (const item of etlStatuses) {
      const etlStatus = new EtlStatusModel();
      etlStatus.mapData(item);
      this.add(etlStatus);
    }
  }
}
