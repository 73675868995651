import { ReportMetadataList } from '@/collections/report_metadatas';
export class SearchModel {
  public maxRecent: number = 10;
  public addRecentViewReport(reportId: number) {
    let idString = localStorage.getItem('recentViewReport') || '';
    if (!idString) {
      localStorage.setItem('recentViewReport', '' + reportId);
      return;
    }
    const ids = idString.split(',');
    let count: number = 0;
    count = 0;
    idString = '' + reportId;
    for (const id of ids) {
      if (id !== reportId + '') {
        idString = idString + ',' + id;
        count = count + 1;
        if (count > this.maxRecent) {
          break;
        }
      }
    }
    localStorage.setItem('recentViewReport', idString);

  }

  public getRecentViewReports(fullReportList: ReportMetadataList) {
    const recentViewList: any = [];
    const idString = localStorage.getItem('recentViewReport') || '';
    const ids = idString.split(',');
    for (const id of ids) {
      for (const report of fullReportList.items) {
        if (id === report.id + '') {
          recentViewList.push(report);
          break;
        }
      }
    }
    return recentViewList;
  }

  public getPopularReports(fullReportList: ReportMetadataList) {
    const popularList: any = [];
    const ids: any = [13, 10, 278, 283, 221, 489, 313, 513, 293, 557];
    for (const id of ids) {
      for (const report of fullReportList.items) {
        if (id === report.id) {
          popularList.push(report);
          break;
        }
      }
    }
    return popularList;
  }

  public getPopularSearchTerms() {
    const popularSearchTerms: any = [
      'Track sales reports',
      'Control Inventory',
      'Calculate cost & profits',
      'Track orders data',
      'Analyze product performance',
      'Inventory Adjustment History',
      'Inventory Tracking',
      'Product Sales Report',
      'Sales by State',
      'Stock on hand report by location',
      'Refund Report',
      'Inventory Value',
      'Inventory Velocity',
      'Inventory Aging Report',
      'Weekly Sales Report',
      'Inventory Discrepancy',
      'Live Inventory Report',
      'Category Sales',
      'Finance Report',
      'Product Report',
      'Vendor Sales',
      'Sales Channel',
      'Store Location',
      'Collection Sales',
      'Taxable',
      'Customer Insight',
      'Stock On-hand',
    ];
    return popularSearchTerms;
  }
}
