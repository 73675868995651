
import { Component, Prop, Vue } from 'vue-property-decorator';
import ScheduleTemplate from '@/components/ScheduleTemplate.vue';
import { TemplateReportList } from '@/collections/template_reports';
import { MeasureList } from '@/collections/measures';
import { StoreModel } from '@/models/store';

@Component({
  components: {
    ScheduleTemplate,
  },
})
export default class ScheduleTemplateExplore extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: 'available' })
  public isLockedReports!: string;
  @Prop({ default: false })
  public hasSchemas!: boolean;
  @Prop({ default: () => new TemplateReportList() })
  public templateReports!: TemplateReportList;
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;
}
