
import { Component } from 'vue-property-decorator';
import Chart from 'chart.js/auto';
import Report from '@/components/Report';
import ChatWithUs from '@/components/ChatWithUs.vue';
import ContentLoadResultMessage from '@/components/ContentLoadResultMessage.vue';
import LoadingSlider from '@/components/LoadingSlider.vue';


@Component({
  components: {
    ChatWithUs,
    ContentLoadResultMessage,
    LoadingSlider,
  },
})
export default class BigNumberReport extends Report {
  public typeReport: string = 'BAR';
  public chart?: any;
  public loadingSlides: string[] = [
    'Connecting to chart services',
    'Summarising data',
    'Building chart',
    'Loading chart',
  ];
}
