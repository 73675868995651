
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class LoadingSlider extends Vue {
  public cycle: boolean = true;
  @Prop({default: 'Loading your data'})
  public loadingMessage!: string;
  @Prop({default: 'circular'})
  public type!: string;
  @Prop({default: true})
  public showSlide!: boolean;
  @Prop({default: true})
  public random!: boolean;
  public interval: number = 2000;
  @Prop({default: () => [
    'If You Can not Measure It, You Can not Improve It',
    'Data will talk to you if you are willing to listen',
    'Data is the language of the powerholders',
    'In God we trust; all others must bring data',
    'No data is clean but most is useful',
    'The goal is turn data to information, and information into insight',
    'Without data you are just another person with opinion',
    'Data is a for enhancing intuition',
  ]})
  public slidesLibrary!: string[];
  public slides: string[] = [];

  public created() {
    this.interval = this.randomIntFromInterval(1500, 2000);
    const len = this.slidesLibrary.length;
    for (let i = 0; i < len; i++) {
      let r: number;
      if (this.random) {
        r = this.randomIntFromInterval(1, len - 1);
      } else {
        r = i;
      }
      this.slides.push(this.slidesLibrary[r]);
    }
  }

  public randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
}
