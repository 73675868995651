
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { ProductSegmentModel } from '@/models/product_segment';
import { ProductSegmentList } from '@/collections/product_segments';

@Component({
  components: {
  },
})
export default class SegmentSelector extends Vue {
  public productSegments: ProductSegmentList = new ProductSegmentList();
  @Prop({ default: null })
  public initProductSegment!: ProductSegmentModel;
  @Prop({ default: null })
  public value!: ProductSegmentModel|null;
  public productSegment: ProductSegmentModel = new ProductSegmentModel();
  public segmentName: string = '';
  public selectingSegments: ProductSegmentList = new ProductSegmentList();
  public segmentMenu: boolean = false;
  @Prop({default: false})
  public disabled!: boolean;
  public keyword: string = '';
  public async created() {
   await this.loadProductSegments();
  }

  public async loadProductSegments() {
    await this.productSegments.fetch();
    this.selectingSegments.add(this.emptySegment);
    for (const segment of this.productSegments.items) {
      this.selectingSegments.add(segment);
      if (segment && segment.id && segment.id === this.initProductSegment.id) {
        this.productSegment = segment;
        this.segmentName = segment.name;
      }
    }
    if (!this.productSegment.id) {
      this.productSegment = this.emptySegment;
    }
  }

  public selectSegment() {
    this.segmentName = this.productSegment.name;
    this.segmentMenu = false;
    this.$emit('input', this.productSegment);
  }

  public get emptySegment() {
    const emptySegment = new ProductSegmentModel();
    emptySegment.name = 'All';
    return emptySegment;
  }
}
