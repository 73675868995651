
import { Component, Vue, Prop } from 'vue-property-decorator';
import Installing from '@/components/Installing.vue';
import { UserPackageModel } from '@/models/user_package';
import { BillingPackageList } from '@/collections/billing_packages';
import { StoreModel } from '@/models/store';
import { isShopifyEmbedded } from '@shopify/app-bridge/utilities';
import { EventHandler } from '@/modules/events';
import { EventBus } from '@/main';

@Component({
  components: {
    Installing,
  },
})
export default class SubscriptionConfirm extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public userPackage: UserPackageModel = new UserPackageModel();
  public currentPackage: UserPackageModel = new UserPackageModel();

  public async created() {
    this.userPackage.chargeId = Number(this.$route.query.charge_id);
    if (this.userPackage.chargeId) {
      while (true) {
        try {
          await this.userPackage.confirmed();
          break;
        } catch {
          try {
            await this.currentPackage.getCurrent();
          } catch {
            // skipped
          }
          if (this.currentPackage.id) {
            if (this.currentPackage.chargeId === this.userPackage.chargeId) {
              break;
            }
          }
        }
      }
      try {
        const eventHandler = new EventHandler({
          store: this.store,
        });
        eventHandler.track('START_PAID_PACKAGE', {
          billing_package_id: this.userPackage.billingPackageId,
        });
        this.store.sendEvent('START_PAID_PACKAGE', {
          billing_package_id: this.userPackage.billingPackageId,
        });
      } catch (e) {
        // ignore this
      }
      EventBus.$emit('current-package-changed');
      await this.store.updateInstallationStatus();
      this.$router.push('/getting-started');
    } else {
      const billingPackages: BillingPackageList = new BillingPackageList();
      await billingPackages.fetch();
      for (const item of billingPackages.items) {
        if (item.price === 0) {
          this.userPackage.billingPackageId = item.id;
          break;
        }
      }
      let returnUrl = `${process.env.VUE_APP_SUBSCRIPTION_RETURN_URL}`;
      if (isShopifyEmbedded()) {
        returnUrl = `https://admin.shopify.com/store/${
          this.store.shopName.split('.')[0]
        }/apps/${process.env.VUE_APP_NAME}/subscription/confirm`;
      }
      try {
        await this.currentPackage.getCurrent();
      } catch {
        // skipped
      }
      if (!this.currentPackage.id) {
        await this.userPackage.create(0, 0, returnUrl);
      }
      try {
        const eventHandler = new EventHandler({
          store: this.store,
        });
        await eventHandler.track('STOP_CONFIRMING_PAID_PACKAGE', {
          billing_package_id: this.userPackage.billingPackageId,
        });
        await this.store.sendEvent('STOP_CONFIRMING_PAID_PACKAGE', {
          billing_package_id: this.userPackage.billingPackageId,
        });
      } catch (e) {
        // ignore this
      }
      this.$router.push('/getting-started');
    }
  }
}
