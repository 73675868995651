import { get, post } from '@/services/http';
import { SubDimensionModel } from '@/models/sub_dimension';
import List from './list';

export class SubDimensionList extends List<SubDimensionModel> {
  public mapData(data: any = []) {
    for (const s of data) {
      const dimension = new SubDimensionModel();
      dimension.mapData(s);
      this.add(dimension);
    }
  }
}
