import { get, post } from '@/services/http';
import { AnalyticsTypeModel } from '@/models/analytics_type';
import List from './list';

export class AnalyticsTypeList extends List<AnalyticsTypeModel> {
  public mapData(data: any = []) {
    for (const s of data) {
      const analyticsType = new AnalyticsTypeModel();
      analyticsType.mapData(s);
      this.add(analyticsType);
    }
  }
}
