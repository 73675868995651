
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class VideoGuides extends Vue {
  public dialogVideoGuide: boolean = false;
  @Prop({default: []})
  public initVideoLinks!: any;
  public videoLinks!: any;
  public activeVideo: any = [
    {
      title: 'Key Features of Assisty',
      link: 'https://www.youtube.com/embed/EAhbEN4wTTY?si=x1l1F5HmjPxx7ry-',
    },
    {
      title: 'How to Create and Manage Product Segment',
      link: 'https://www.youtube.com/embed/nq53Fjp4kxM?si=BaLIy1izpiIKu_Xk',
    },
    {
      title: 'How to Schedule a Report',
      link: 'https://www.youtube.com/embed/Vm56P6FECUI?si=BHsn5Erd5BGvWGDP',
    },
    {
      title: 'How to Customize a Report',
      link: 'https://www.youtube.com/embed/gbETeFqU4pQ?si=ybMI-iG_xDG4Pt6l',
    },
    {
      title: 'Guide to Demand Forecasting',
      link: 'https://www.youtube.com/embed/eE0XH1_Q6jI?si=vKdB57K0ygHGCTUf',
    },
    {
      title: 'Overview of Inventory Replenishment Models',
      link: 'https://www.youtube.com/embed/rL7iRXNQAVg?si=ypQ-zQFiQgbKWQgm',
    },
    {
      title: 'Just-In-Time Inventory Replenishment Model',
      link: 'https://www.youtube.com/embed/545eQtVGYrQ?si=wqhtNdsR32Sj-cjA',
    },
  ];
  public created() {
    if (this.initVideoLinks && this.initVideoLinks.length > 0) {
      this.videoLinks = this.initVideoLinks;
    }
    if (this.videoLinks && this.videoLinks.length > 0) {
      this.activeVideo = this.videoLinks[0];
    }
  }

  public videoLinkClick(video: any) {
    this.activeVideo = video;
    this.dialogVideoGuide = true;
  }



}
