
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { MeasureList } from '@/collections/measures';
import { StoreModel } from '@/models/store';
import HeaderTitle from '../components/HeaderTitle.vue';
import { DashboardModel } from '@/models/dashboard';
import ReportCard from '../components/ReportCard.vue';
import DateFilter from '@/components/DateFilter.vue';
import { EventHandler } from '@/modules/events';
import { isShopifyEmbedded } from '@shopify/app-bridge/utilities';
import { Redirect } from '@shopify/app-bridge/actions';
import { FilterColumnModel } from '@/models/filter_column';
import { filter } from 'vue/types/umd';
import { ReportCardList } from '@/collections/report_cards';

@Component({
  components: {
    HeaderTitle,
    ReportCard,
    DateFilter,
  },
})
export default class DetailedDashboard extends Vue {
  @Prop({ default: null })
  public app!: any;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: 'available' })
  public isLockedReports!: string;
  @Prop({ default: false })
  public hasSchemas!: boolean;
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;
  public loading: boolean = false;
  public dashboardModel: DashboardModel = new DashboardModel();
  public dates: any = [];
  public defaultRange: string = '7d';
  public useDefaultConfig: boolean = false;
  public showFilter: boolean = false;
  public dashboardMenu: boolean = true;
  public isCreated: boolean = false;
  public dialogRenameDashboard: boolean = false;
  public dialogDeleteDashboard: boolean = false;
  public newDashboardName: string = '';
  public renameDashboardError: boolean = false;
  public needReload: any = [];
  public publishDashboardLoading: boolean = false;
  public isPublicDashboard: boolean = true;
  public filterColumn: FilterColumnModel = new FilterColumnModel();
  public dashboardId: number = 0;
  public pageTitle: string = '';
  public async created() {
    this.loadDateRangeFromParam();
    const path = this.$route.path;
    switch (path) {
      case '/dashboard/product': {

        const id = this.$route.query.id;
        const name = this.$route.query.name;
        this.pageTitle = 'Product - ' + name;
        this.dashboardId = 157;
        if (name && name !== undefined) {
          this.filterColumn.mapData(
            {
              name: 'Product Name',
              code: 'Item[Item Description]',
              value: [name],
              operator: 'eq',
            });
        }
        if (id && id !== undefined) {
          this.filterColumn.mapData(
            {
              name: 'Product',
              code: 'product_id',
              value: [id],
              operator: 'eq',
            });
        }
        break;
      }
      case '/dashboard/store': {
        const name = this.$route.query.name;
        this.pageTitle = 'Store Location - ' + name;
        this.dashboardId = 164;
        if (name && name !== undefined) {
          this.filterColumn.mapData(
            {
              name: 'store',
              code: 'Location[Location]',
              value: [name],
              operator: 'eq',
            });
        }
        break;
      }
      case '/dashboard/vendor': {
        const name = this.$route.query.name;
        this.pageTitle = 'Vendor Detailed Dashboard - ' + name;
        this.dashboardId = 165;
        if (name && name !== undefined) {
          this.filterColumn.mapData(
            {
              name: 'Vendor',
              code: 'Vendor Name',
              value: [name],
              operator: 'eq',
            });
        }
        break;
      }
      case '/dashboard/collection': {
        const name = this.$route.query.name;
        this.pageTitle = 'Collection Detailed Dashboard - ' + name;
        this.dashboardId = 167;
        if (name && name !== undefined) {
          this.filterColumn.mapData(
            {
              name: 'Collection',
              code: 'product_collections',
              value: [name],
              operator: 'eq',
            });
        }
        break;
      }
    }
    await this.fullLoad();
    this.applyFilter();
    this.isCreated = true;
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track('View Dashboard', {
        dashboardName: this.dashboardModel.name,
        dashboardId: this.dashboardModel.id,
      });
    } catch (e) {
      // ignore this
    }
  }

  public loadDateRangeFromParam() {
    // get timeRange Filter
    const start = this.$route.query.start_time;
    const end = this.$route.query.end_time;
    const period = this.$route.query.period;
    if (start && start !== undefined) {
      this.dates.push(start + '');
      if (end && end !== undefined) {
        this.dates.push(end + '');
      }
      this.defaultRange = 'custom';
      this.dates.push('custom');
    }
  }

  public goBack() {
    this.$router.go(-1);
  }

  public printDashboard() {
    window.print();
  }

  public async publishDashboard() {
    try {
      this.publishDashboardLoading = true;
      await this.dashboardModel.publishDashboard();
      this.publishDashboardLoading = false;
    } catch (e) {
      this.publishDashboardLoading = false;
    }
    this.isPublicDashboard = this.dashboardModel.type === 'PUBLIC' ? true : false;
  }
  public async fullLoad() {
    this.loading = true;
    this.dashboardModel = new DashboardModel({ id: this.dashboardId });
    await this.dashboardModel.fetchSystem();
    this.applyFilter();
    this.isPublicDashboard = this.dashboardModel.type === 'PUBLIC' ? true : false;
    this.newDashboardName = this.dashboardModel.name;
    this.loading = false;
    if (!this.dashboardModel.id || this.dashboardModel.id === undefined) {
      this.$router.push('/');
    }
    this.setReloadAllCard(false);
  }

  public applyFilter() {
    const newReportCardList = new ReportCardList();
    for (const reportCard of this.dashboardModel.reportCardList.items) {
      if (this.filterColumn && this.filterColumn.code) {
        if (!reportCard.filterColumns.items.includes(this.filterColumn)) {
          reportCard.filterColumns.add(this.filterColumn);
        }
      }
      newReportCardList.add(reportCard);
    }
    this.dashboardModel.reportCardList = newReportCardList;
  }

  public async duplicateDashboard() {
    await this.dashboardModel.copy();
    this.$router.push('/myboard/' + this.dashboardModel.id);

  }
  public setReloadAllCard(reload: boolean = true) {
    this.needReload = [];
    for (const card of this.dashboardModel.reportCardList.items) {
      this.needReload.push({ id: card.id, value: reload });
    }
  }

  public isNeedReloadCard(id: number) {
    for (const item of this.needReload) {
      if (item.id === id) {
        return item.value;
      }
    }
  }

  public reloadCompleted(cardId: number) {
    for (const item of this.needReload) {
      if (item.id === cardId) {
        item.value = false;
      }
    }
  }

  public async moveCardLeft(cardId: number) {
    await this.dashboardModel.moveCardPosition(cardId, true);
    this.setReloadAllCard(true);
  }

  public async moveCardRight(cardId: number) {
    await this.dashboardModel.moveCardPosition(cardId, false);
    this.setReloadAllCard(true);
  }

  public deleteCard(reportCardId: number) {
    let i: number = 0;
    for (const card of this.dashboardModel.reportCardList.items) {
      if (card.id === reportCardId) {
        this.dashboardModel.reportCardList.items.splice(i, 1);
        break;
      }
      i++;
    }
  }
  public addCard() {
    this.$router.push(`/report-select/${this.dashboardModel.id}`);
  }

  public getMeasure(code: string) {
    for (const measure of this.measures.items) {
      if (measure.code === code) {
        return measure;
      }
    }
  }

  public getDimension(measureCode: string, dimensionCode: string) {
    const measure = this.getMeasure(measureCode);
    if (measure && measure.dimensions) {
      for (const dimension of measure.dimensions.items) {
        if (dimension.code === dimensionCode) {
          return dimension;
        }
      }
    }
  }

  public showFilters() {
    if (!this.showFilter) {
      this.useDefaultConfig = false;
    } else {
      // this.useDefaultConfig = true;
    }
    this.showFilter = !this.showFilter;
  }

  public discardChanges() {
    this.useDefaultConfig = true;
    this.defaultRange = 'custom';
    this.dates = [];
  }

  public async deleteDashboard() {
    this.dialogDeleteDashboard = false;
    await this.dashboardModel.delete();
    this.$router.push('/');
  }

  public async renameDashboard() {
    this.dashboardModel.name = this.newDashboardName;
    await this.dashboardModel.update();
    this.dialogRenameDashboard = false;
  }

  @Watch('$route', { immediate: true, deep: true })
  private async onUrlChange(newVal: any) {
    if (!this.isCreated) {
      return;
    }
    await this.fullLoad();
  }
}
