
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import HeaderTitle from '../components/HeaderTitle.vue';
import ChatWithUs from '../components/ChatWithUs.vue';
import { FileDownloadList } from '@/collections/file_downloads';
import { FileDownloadModel } from '@/models/file_download';
import { StoreModel } from '@/models/store';
import { MeasureList } from '@/collections/measures';
import SidebarMenu from '../components/SidebarMenu.vue';
import {get} from "@/services/http";
import {displayAlert} from "@/services/alert";

@Component({
  components: {
    HeaderTitle,
    ChatWithUs,
    SidebarMenu,
  },
})
export default class DownloadManager extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;
  public success: boolean = false;
  public error: boolean = false;
  public isLoading: boolean = true;
  public files: FileDownloadList = new FileDownloadList();
  public fileHeaders: any = [];
  public async created() {

    this.fileHeaders = [
      {
        text: 'ID',
        value: 'id',
        width: '',
      },
      {
        text: 'Download Name',
        value: 'downloadName',
        width: '',
      },
      {
        text: 'Created',
        value: 'createdAt',
        width: '',
      },
      {
        text: 'Recipient Email',
        value: 'emails',
        width: '',
      },
      {
        text: 'Params',
        value: 'params',
        width: '',
      },
      {
        text: 'Status',
        value: 'status',
        align: '',
      },
      {
        text: 'Action',
        value: 'link',
        align: '',
      },
    ];
  }

  public async fullLoadData() {
    this.isLoading = true;
    this.files = new FileDownloadList();
    await this.files.fetch();
    for (const file of this.files.items) {
      for (const measure of this.measures.items) {
        if (file.measure.code === measure.code && measure.code) {
          file.measure = measure;
          break;
        }
      }

      for (const dimension of file.measure.dimensions.items) {
        if (file.dimension.code === dimension.code && dimension.code) {
          file.dimension = dimension;
          break;
        }
      }
    }
    this.isLoading = false;
  }

  public async regenerate(fileItem: FileDownloadModel) {
    try {
      await get('/regenerate', {fileId: fileItem.id});
      fileItem.status = 'EXPORTING';
      displayAlert('The exported file will be sent to emails above, please check your inbox');
    } catch (e) {
      displayAlert('Something went wrong, please try again later')
    }
  }

  public convertDateStr(dateStr: string) {
    if (!dateStr) {
      return '';
    }
    const date = new Date(dateStr);
    const timezoneOffset = 7 * 60;
    const adjustedDate = new Date(date.getTime() + timezoneOffset * 60 * 1000);
    const padZero = (num: number) => (num < 10 ? '0' : '') + num;
    return `${adjustedDate.getUTCFullYear()}-${padZero(adjustedDate.getUTCMonth() + 1)}-${padZero(adjustedDate.getUTCDate())} ${padZero(adjustedDate.getUTCHours())}:${padZero(adjustedDate.getUTCMinutes())}:${padZero(adjustedDate.getUTCSeconds())}`;
  }


  @Watch('measures', { immediate: true, deep: true })
  private async onMeasuresChanged(newVal: any) {
    if (this.measures.items.length > 0) {
      await this.fullLoadData();
    }
  }
}
