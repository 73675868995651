
import { Component, Prop, Vue } from 'vue-property-decorator';
import { StoreModel } from '@/models/store';
import { EventHandler } from '@/modules/events';
import { EventBus } from '@/main';
import ActionButton from "@/components/ActionButton.vue";


@Component({
  components: {
    ActionButton,
  },
})
export default class StartupOffer extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: null })
  public app!: any;
  public startupMeeting: string = 'https://calendly.com/assisty/startup-program?month=2024-11';
  public dialogOfferStartup: boolean = false;
  
  public async created() {
    await this.store.loadStoreType();
    this.showOfferStartup();
  }

  public showOfferStartup() {
    const isFree: boolean = this.store.currentPackage.name === 'Free';
    const isStartupOffer: string = localStorage.getItem('startup_offer') || 'false';
    const canShowOffer: boolean = isFree && isStartupOffer === 'false' && this.store.storeType !== 'BIG_STORE';
    //save the startup offer status to local storage
    localStorage.setItem('startup_offer', 'true');
    this.dialogOfferStartup = canShowOffer;
  }

  public openChatOfferStartup() {
    let openChatMessage: string = 'I am interested in the startup program. Can you please provide me more information?';
    // @ts-ignore: Unreachable code error
    this.$crisp.do('chat:open');
    // @ts-ignore: Unreachable code error
    // this.$crisp.do('message:send', ['text', this.$t(`chat_with_us.${this.defaultMessage}`)]);
    this.$crisp.do('message:send', ['text', openChatMessage]);
    this.dialogOfferStartup = false;
  }
}
