
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class Alert extends Vue {
  @Prop({default: 'info'})
  public type!: string;
  public icon: string = '';
  public iconColor: string = '';
  public borderColor: string = '';
  public backgroundColor: string = '';
  @Prop({default: ''})
  public title!: string;
  @Prop({default: ''})
  public content!: string;
  @Prop({default: true})
  public showAlert!: boolean;
  @Prop({default: ''})
  public actionTitle!: string;
  @Prop({default: ''})
  public secondaryActionTitle!: string;
  @Prop({default: ''})
  public actionLink!: string;
  @Prop({default: true})
  public canDimiss!: boolean;

  public created() {
    switch (this.type) {
      case 'info': {
        this.icon = 'mdi-information';
        break;
      }
      case 'update': {
        this.icon = 'mdi-information';
        break;
      }
      case 'success': {
        this.icon = 'mdi-check-circle';
        break;
      }
      case 'warning': {
        this.icon = 'mdi-information';
        break;
      }
      case 'error': {
        this.icon = 'mdi-alert-octagon';
        break;
      }
      default: {
        break;
      }
    }
  }

  public actionClick() {
    this.$emit('actionClick');
  }

  public secondaryActionClick() {
    this.$emit('secondaryActionClick');
  }
}
