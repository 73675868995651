
import { Component, Prop, Vue } from 'vue-property-decorator';
import OnboardPage from '../components/Onboard.vue';
import { StoreModel } from '@/models/store';

@Component({
  components: {
    OnboardPage,
  },
})
export default class OnboardContact extends Vue {
  public merchantName: string = '';
  public merchantEmail: string = '';
  public merchantPhone: string = '';
  public merchantRole: string = '';
  public roles: any = [
    'I am the store owner',
    'I am a marketer',
    'I am a salesman',
    'I am a purchaser',
    'Other',
  ];
  public productCategories: any = [
    'Style and Fashion',
    'Apparel and Accessories',
    'Sporting goods',
    'Consumer electronic and Appliance',
    'Sporting goods',
    'Health and Fitness',
    'Home and Kitchen',
    'Food & Beverage',
    'Jewelry and Luxury goods',
    'Entertainment and recreation',
    'Chiren & Infants',
    'Business & Industrial',
    'Other',
  ];
  public emailRules: any = [
    this.isEmailExist,
    this.isEmailValidate,
  ];
  public roleRules: any = [
    this.isRoleExist,
  ];
  public productCategoryRules: any = [
    this.isProductCategoryExist,
  ];
  public phoneRules: any = [
    this.isPhoneExist,
  ];
  public nameRules: any = [
    this.isNameExist,
  ];
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public loading: boolean = false;
  public error: boolean = false;
  public created() {
    localStorage.setItem('onboard_page', '/onboard-contact');
    const rolesCount = this.roles.length;
    for (let i = 0; i < rolesCount; i++) {
      this.roles[i] = this.$t(`onboard.${this.roles[i]}`);
    }
  }
  public isNameExist(v: any) {
    return !!v || this.$t('onboard.Name is required');
  }

  public isPhoneExist(v: any) {
    return !!v || this.$t('onboard.Phone Number is required');
  }

  public isRoleExist(v: any) {
    return !!v || this.$t('onboard.Role is required');
  }

  public isProductCategoryExist(v: any) {
    return !!v || this.$t('onboard.Product Category is required');
  }

  public isEmailExist(v: any) {
    return !!v || this.$t('onboard.E-mail is required');
  }

  public isEmailValidate(v: any) {
    return /.+@.+/.test(v) || this.$t('onboard.E-mail must be valid');
  }

  public checkEmailValidate(v: any) {
    return /.+@.+/.test(v);
  }

  public async nextStep() {
    
    if (this.store.config.contactEmail &&
        this.store.config.contactName &&
        this.checkEmailValidate(this.store.config.contactEmail)) {
        this.error = false;
        this.loading = true;
        await this.store.saveSettings();
        this.loading = false;
        this.$router.push('/onboard-purpose');
    } else {
      this.error = true;
    }
  }
}
