import { get, post, put } from '@/services/http';
import { TagModel } from '@/models/tag';
import { TagList } from '@/collections/tags';

interface SortOrder {
  tag: any;
}

export class ReportMetadataModel {
  public id: number;
  public name: string;
  public sortOrder: SortOrder;
  public instruction?: string;
  public tags: TagList;
  public terms: string[] = [];
  public industries: string[] = [];
  public isLocked?: boolean;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.tags = new TagList();
    this.sortOrder = data.sortOrder;
    this.terms = [];
    this.isLocked = false;
  }

  public async fetch() {
    const res: any = await get(`/template_reports/${this.id}`);
    this.mapData(res.data);
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.sortOrder = data.sortOrder;
    this.instruction = data.instruction;
    if (data.isLocked === 'ISLOCKED') {
      this.isLocked = true;
    } else {
      this.isLocked = false;
    }

    if (data.tagIds) {
      for (const tagId of data.tagIds.split(',')) {
        this.tags.add(new TagModel({ id: Number(tagId) }));
      }
    }
    if (data.terms) {
      this.terms = data.terms.split(',');
    } else {
      this.terms = [];
    }
    if (data.options && data.options.industries) {
      this.industries = data.options.industries;
    }
  }
}
