import Vue from 'vue';
import VueRouter from 'vue-router';
import CallbackPage from '../views/CallbackPage.vue';
import SlackCallbackPage from '../views/SlackCallbackPage.vue';
import Settings from '../views/Settings.vue';
import Onboard from '../views/Onboard.vue';
import OnboardContact from '../views/OnboardContact.vue';
import OnboardMessaging from '../views/OnboardMessaging.vue';
import OnboardReport from '../views/OnboardReport.vue';
import OnboardPurpose from '../views/OnboardPurpose.vue';
import OnboardPricing from '../views/OnboardPricing.vue';
import Notification from '../views/Notification.vue';
import DataFileView from '../views/DataFileView.vue';
import SlackSuccessPage from '../views/SlackSuccessPage.vue';
import CustomReports from '../views/CustomReports.vue';
import CustomDashboards from '../views/CustomDashboards.vue';
import TemplateReports from '../views/TemplateReports.vue';
import TemplateReportPage from '../views/TemplateReport.vue';
import Subscription from '../views/Subscription.vue';
import SubscriptionConfirm from '../views/SubscriptionConfirm.vue';
import Dashboard from '../views/SystemDashboard.vue';
import MyDashboard from '../views/MyDashboard.vue';
import Users from '../views/Users.vue';
import DownloadManager from '../views/DownloadManager.vue';
import CardDesign from '../views/CardDesign.vue';
import ReportSelect from '../views/ReportSelect.vue';
import StatusPage from '../views/StatusPage.vue';
import DetailedDashboard from '../views/DetailedDashboard.vue';
import Home from '../views/Home.vue';
import LiveDemo from '../views/LiveDemo.vue';
import AutoSubscription from '../views/AutoSubscription.vue';
import OrderConfirm from '../views/OrderConfirm.vue';
import Plugins from '../views/Plugins.vue';
import Plugin from '../views/Plugin.vue';
import Oauth2Callback from '../views/Oauth2Callback.vue';
import ProductSegmentDetail from '../views/ProductSegmentDetail.vue';
import ProductSegmentListing from '../views/ProductSegmentListing.vue';
import EtlStatusPage from '../views/EtlStatusPage.vue';
import ScheduleProfiles from '../views/ScheduleProfiles.vue';
import ScheduleProfile from '../views/ScheduleProfile.vue';
import Schedules from '../views/Schedules.vue';
import Schedule from '../views/Schedule.vue';
import ScheduleDetail from '../views/ScheduleDetail.vue';
import ProductSegmentExplore from '../views/ProductSegmentExplore.vue';
import ScheduleTemplateExplore from '../views/ScheduleTemplateExplore.vue';
import DashboardLibrary from '../views/DashboardLibrary.vue';
import PremiumOnboarding from '../views/PremiumOnboarding.vue';
import QuickbooksOauth2Callback from "@/views/QuickbooksOauth2Callback.vue";
import SetupGuide from '../views/SetupGuide.vue';
import EmailTemplates from '../views/EmailTemplates.vue';
import EmailTemplate from '../views/EmailTemplate.vue';
import EmailTemplateNew from '../views/EmailTemplateNew.vue';
import EmailReceivers from '../views/EmailReceivers.vue';
import SpreadsheetTemplates from '../views/SpreadsheetTemplates.vue';
import SpreadsheetConnected from '../views/SpreadsheetConnected.vue';
import SpreadsheetClone from '../views/SpreadsheetClone.vue';
import SpreadsheetIntegration from '../views/SpreadsheetIntegration.vue';
import QuickbooksIntegration from '../views/QuickbooksIntegration.vue';
import GettingStarted from '../views/GettingStarted.vue';

Vue.use(VueRouter);
const routes = [
  {
    path: '/slack/callback',
    name: 'slackCallback',
    component: SlackCallbackPage,
  },
  {
    path: '/shopify/callback',
    name: 'callback',
    component: CallbackPage,
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
  },
  {
    path: '/settings/:tab',
    name: 'settingTabs',
    component: Settings,
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    props: true,
  },
  {
    path: '/onboard',
    name: 'onboard',
    component: Onboard,
  },
  {
    path: '/onboard-contact',
    name: 'onboardContact',
    component: OnboardContact,
  },
  {
    path: '/onboard-messaging',
    name: 'onboardMessaging',
    component: OnboardMessaging,
  },
  {
    path: '/onboard-report',
    name: 'onboardReport',
    component: OnboardReport,
  },
  {
    path: '/onboard-purpose',
    name: 'onboardPurpose',
    component: OnboardPurpose,
  },
  {
    path: '/onboard-pricing',
    name: 'onboardPricing',
    component: OnboardPricing,
  },
  {
    path: '/subscription/confirm',
    name: 'subscriptionConfirm',
    component: SubscriptionConfirm,
  },
  {
    path: '/subscription',
    name: 'subscription',
    component: Subscription,
  },
  {
    path: '/files/:file_name',
    name: 'dataFileView',
    component: DataFileView,
  },
  {
    path: '/slack/success',
    name: 'slackSuccessPage',
    component: SlackSuccessPage,
  },
  {
    path: '/reports',
    name: 'templateReports',
    component: TemplateReports,
    props: true,
  },
  {
    path: '/reports/measures/:measure_code',
    name: 'detailReportPage',
    component: TemplateReportPage,
    props: true,
  },
  {
    path: '/reports/schedule/:preview',
    name: 'previewScheduledReport',
    component: TemplateReportPage,
    props: true,
  },
  {
    path: '/my-reports',
    name: 'customReports',
    component: CustomReports,
    props: true,
  },
  {
    path: '/my-dashboards',
    name: 'customDashboards',
    component: CustomDashboards,
    props: true,
  },
  {
    path: '/my-reports/:custom_report_id',
    name: 'customReport',
    component: TemplateReportPage,
    props: true,
  },
  {
    path: '/reports/:type_id/:type_name',
    name: 'templateReportsByTypes',
    component: TemplateReports,
    props: true,
  },
  {
    path: '/reports/:type_id/:type_name/:template_report_id',
    name: 'templateReportByType',
    component: TemplateReportPage,
    props: true,
  },
  {
    path: '/premium_reports/:premium/:type_id/:type_name',
    name: 'premiumTemplateReportsByTypes',
    component: TemplateReports,
    props: true,
  },
  {
    path: '/reports/:template_report_id',
    name: 'templateReport',
    component: TemplateReportPage,
    props: true,
  },
  {
    path: '/schedule',
    name: 'schedule',
    component: Notification,
    props: true,
  },
  {
    path: '/schedule/edit/:schedule_id/report/:report_index',
    name: 'scheduleEditReport',
    component: TemplateReportPage,
    props: true,
  },
  {
    path: '/card/view/:card_id',
    name: 'viewCardReport',
    component: TemplateReportPage,
    props: true,
  },
  {
    path: '*',
    redirect: '/',
  },
  {
    path: '/myboard/:dashboard_id',
    name: 'myDashboard',
    component: MyDashboard,
  },
  {
    path: '/my-board/:dashboard_id',
    name: 'myBoard',
    component: MyDashboard,
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
  },
  {
    path: '/download-manager',
    name: 'downloadManager',
    component: DownloadManager,
  },
  {
    path: '/design-card/:id',
    name: 'cardDesign',
    component: CardDesign,
  },
  {
    path: '/report-select',
    name: 'reportSelect',
    component: ReportSelect,
  },
  {
    path: '/status/:code',
    name: 'statusPage',
    component: StatusPage,
  },
  {
    path: '/public-board/:dashboard_id',
    name: 'publicBoard',
    component: Dashboard,
  },
  {
    path: '/board/:dashboard_id/:type',
    name: 'dashboardType',
    component: Dashboard,
  },
  {
    path: '/board/:dashboard_id',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/dashboard/product',
    name: 'productDashboard',
    component: DetailedDashboard,
  },
  {
    path: '/dashboard/store',
    name: 'storeDashboard',
    component: DetailedDashboard,
  },
  {
    path: '/dashboard/vendor',
    name: 'vendorDashboard',
    component: DetailedDashboard,
  },
  {
    path: '/dashboard/collection',
    name: 'collectionDashboard',
    component: DetailedDashboard,
  },
  {
    path: '/live-demo',
    name: 'liveDemo',
    component: LiveDemo,
  },
  {
    path: '/premium-onboarding',
    name: 'PremiumOnboarding',
    component: PremiumOnboarding,
  },
  {
    path: '/plugins',
    name: 'plugins',
    component: Plugins,
  },
  {
    path: '/plugin/:pluginId',
    name: 'plugin',
    component: Plugin,
  },
  {

    path: '/auto_subscription',
    name: 'autoSubscription',
    component: AutoSubscription,
  },
  {
    path: '/order_confirm',
    name: 'orderConfirm',
    component: OrderConfirm,
  },
  {
    path: '/oauth2callback',
    name: 'oauth2Callback',
    component: Oauth2Callback,
  },
  {
    path: '/quickbooks/oauth2callback',
    name: 'quickbooks/oauth2callback',
    component: QuickbooksOauth2Callback,
  },
  {
    path: '/product-segment/:segment_id',
    name: 'productSegmentDetail',
    component: ProductSegmentDetail,
    props: true,
  },
  {
    path: '/product/explore',
    name: 'productExplore',
    component: ProductSegmentExplore,
    props: true,
  },
  {
    path: '/product-segment',
    name: 'productSegmentListing',
    component: ProductSegmentListing,
    props: true,
  },
  {
    path: '/status-page',
    name: 'etlStatusPage',
    component: EtlStatusPage,
    props: true,
  },
  {
    path: '/importing-status',
    name: 'etlStatusPage',
    component: EtlStatusPage,
    props: true,
  },
  {
    path: '/schedule/profiles',
    name: 'scheduleProfiles',
    component: ScheduleProfiles,
    props: true,
  },
  {
    path: '/schedule/profile/:profile_id',
    name: 'scheduleProfileEdit',
    component: ScheduleProfile,
    props: true,
  },
  {
    path: '/schedule/profile',
    name: 'scheduleProfileCreate',
    component: ScheduleProfile,
    props: true,
  },
  {
    path: '/schedules',
    name: 'scheduleListing',
    component: Schedules,
    props: true,
  },
  {
    path: '/schedule/edit/:schedule_id',
    name: 'scheduleEdit',
    component: Schedule,
    props: true,
  },
  {
    path: '/schedule/create',
    name: 'scheduleCreate',
    component: Schedule,
    props: true,
  },
  {
    path: '/schedule/templates',
    name: 'scheduleTemplateExplore',
    component: ScheduleTemplateExplore,
    props: true,
  },
  {
    path: '/schedule/:schedule_id',
    name: 'scheduleDetail',
    component: ScheduleDetail,
    props: true,
  },
  {
    path: '/library/dashboard',
    name: 'DashboardLibrary',
    component: DashboardLibrary,
    props: true,
  },
  {
    path: '/setup-guide/:step',
    name: 'SetupGuide',
    component: SetupGuide,
    props: true,
  },
  {
    path: '/email-templates',
    name: 'EmailTemplates',
    component: EmailTemplates,
    props: true,
  },
  {
    path: '/email-templates/new',
    name: 'EmailTemplateNew',
    component: EmailTemplateNew,
    props: true,
  },
  {
    path: '/email-templates/:template_id',
    name: 'EmailTemplate',
    component: EmailTemplate,
    props: true,
  },
  {
    path: '/email-receivers',
    name: 'EmailReceivers',
    component: EmailReceivers,
    props: true,
  },
  {
    path: '/spreadsheet-templates',
    name: 'SpreadsheetTemplates',
    component: SpreadsheetTemplates,
    props: true,
  },
  {
    path: '/spreadsheet-connected',
    name: 'SpreadsheetConnected',
    component: SpreadsheetConnected,
    props: true,
  },
  {
    path: '/spreadsheet-clone/:id',
    name: 'SpreadsheetClone',
    component: SpreadsheetClone,
    props: true,
  },
  {
    path: '/spreadsheet-integration',
    name: 'SpreadsheetIntegration',
    component: SpreadsheetIntegration,
    props: true,
  },
  {
    path: '/quickbooks-integration',
    name: 'QuickbooksIntegration',
    component: QuickbooksIntegration,
    props: true,
  },
  {
    path: '/getting-started',
    name: 'GettingStarted',
    component: GettingStarted,
    props: true,
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;
