
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { FilterColumnList } from '@/collections/filter_columns';
import { FilterColumnModel } from "@/models/filter_column";
import { DimensionModel } from "@/models/dimension";

@Component({
  components: {
  },
})
export default class ReportSearch extends Vue {
  public searchValue: string = '';
  @Prop({ default: () => new FilterColumnList()})
  public filterColumns!: FilterColumnList;
  @Prop({ default: () => new DimensionModel()})
  public dimension!: DimensionModel;
  @Prop({ default: () => []})
  public reportColumns!: any;
  public avaiableColumns: any = [];
  public searchColumn: any = [];
  public searchLabel: string = 'Search Product';
  public searchMenu: boolean = false;
  @Prop({ default: 'ml-3 mt-3' })
  public menuClass!: string;

  public created() {
    this.setDefaultSearchColumn();
  }

  public setDefaultSearchColumn() {
    this.avaiableColumns = this.reportColumns.filter(
      (column: any) => column.dataType === 'text'
    );
    if (this.avaiableColumns &&  this.avaiableColumns.length > 0) {
      this.searchColumn = this.avaiableColumns[0];
      this.searchLabel = 'Search ' + this.getShortName(this.searchColumn.name);
    } else {
      this.searchColumn = [];
      this.searchLabel = 'No columns available for searching';
    }
    this.searchValue = '';
  }

  public clearSearchColumn() {
    this.searchColumn = [];
    this.setDefaultSearchColumn();
  }

  public selectSearchColumn(item: any) {
    if (this.searchColumn && this.searchColumn.name === item.name) {
      this.searchMenu = false;
      return;
    }
    this.searchColumn = item;
    this.searchLabel = 'Search ' + this.getShortName(item.name);
    this.searchValue = '';
  }

  public getShortName(name: string) {
    if (!name) {
      return '';
    }
    if (name.length > 20) {
      return name.substring(0, 20) + '...';
    }
    return name;
  }

  public searchProduct() {
    if (!this.searchValue) {
      return;
    }
    this.searchMenu = false;
    const productFilter = new FilterColumnModel();
    const productFilterData: any = {
      name: this.searchColumn.name,
      code: this.searchColumn.code,
      value: [this.searchValue],
      operator:'contains',
      dataType: 'text'
    };
    productFilter.mapData(productFilterData);
    this.$emit('input', productFilter);
  }

  @Watch('searchColumn', { deep: true })
  public onSearchColumnChange() {
    this.searchValue = '';
  }

  @Watch("reportColumns", { deep: true })
  public onReportColumnsChange() {
    if (this.reportColumns && this.reportColumns.length !== 0) {
      if (!this.searchColumn || !this.searchColumn.name)
      this.setDefaultSearchColumn();
    }
  }
}
