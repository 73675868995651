
import { Component, Vue, Prop } from 'vue-property-decorator';
import { CustomReportList } from '@/collections/custom_reports';
import { CustomReportModel } from '@/models/custom_report';
import { MeasureList } from '@/collections/measures';
import { MeasureModel } from '@/models/measure';
import HeaderTitle from '@/components/HeaderTitle.vue';
import ContentLoadResultMessage from '@/components/ContentLoadResultMessage.vue';
import { StoreModel } from '@/models/store';
import { RuleModel } from '@/models/rule';
import FeatureLockedNotify from '@/components/FeatureLockedNotify.vue';
import LoadingSlider from '@/components/LoadingSlider.vue';
import { DashboardList } from '@/collections/dashboards';
import { DashboardModel } from '@/models/dashboard';
import { EventHandler } from '@/modules/events';
import EmptyStage from '@/components/EmptyStage.vue';
import ActionButton from '@/components/ActionButton.vue';


interface GroupReport {
  measure: MeasureModel;
  reports: CustomReportList;
}

@Component({
  components: {
    HeaderTitle,
    ContentLoadResultMessage,
    FeatureLockedNotify,
    LoadingSlider,
    EmptyStage,
    ActionButton,
  },
})
export default class CustomDashboards extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public measures: MeasureList = new MeasureList();
  public loading: boolean = false;
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: false })
  public hasSchemas!: boolean;
  public rule: RuleModel = new RuleModel({ code: 'favorite_reports_count' });
  public addDefaultReportLoading: boolean = false;
  public loadingSlides: string[] = [
    'checking your profile',
    'fetching reports',
    'adding reports',
  ];
  public dashboards: DashboardList = new DashboardList();
  public dialogAddDashboard: boolean = false;
  public newDashboardName: string = '';
  public newDashboardError: boolean = false;
  public lockDashboardRule: RuleModel = new RuleModel({code: 'add_report_dashboard'});
  public async created() {
    this.loading = true;
    for (const rule of this.store.currentPackage.rules.items) {
      if (rule.code === this.rule.code) {
        this.rule = rule;
        break;
      }
    }
    // await this.measures.fetch({ isOlap: true });
    await this.loadDashboard();
    this.getLockDashboardRule();
    this.loading = false;
  }

  public canAddCustomDashboard() {
    const validateValues: any = [
      {
        code: 'add_report_dashboard',
      },
    ];
    const validateResult = this.store.validateRule(validateValues);
    return validateResult.isValidate;
  }

  public getLockDashboardRule() {
    for (const rule of this.store.currentPackage.rules.items) {
      if (rule.code === this.lockDashboardRule.code) {
        this.lockDashboardRule = rule;
        break;
      }
    }
  }

  public getDashboardLink(dashboard: DashboardModel) {
    return `/myboard/${dashboard.id}`;
  }

  public async loadDashboard() {
    try {
      this.dashboards = new DashboardList();
      await this.dashboards.fetch();
    } catch (e: any) {
      // do nothing
    }
  }

  public async createDashboard() {
    const newDashboard = new DashboardModel();
    if (this.newDashboardName) {
      newDashboard.name = this.newDashboardName;
      this.dialogAddDashboard = false;
      await newDashboard.create();
      localStorage.setItem('needReloadDashboard', 'true');
      try {
        const eventHandler = new EventHandler({
          store: this.store,
        });
        eventHandler.track('Create Dashboard', {
          dashboardName: newDashboard.name,
          dashboardId: newDashboard.id,
        });
      } catch (e) {
        // ignore this
      }
      this.$router.push('/myboard/' + newDashboard.id);
    } else {
      this.newDashboardError = true;
    }
  }
}
