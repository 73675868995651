import { get } from '@/services/http';
import { DimensionList } from '@/collections/dimensions';
import { ParamList } from '@/collections/params';
import { AnalyticsTypeList } from '@/collections/analytics_types';

interface DataResource {
  id: number;
  name: string;
  code: string;
}

export class MeasureModel {
  public id: number;
  public name: string;
  public code: string;
  public analyticsTypes: AnalyticsTypeList;
  public dimensions: DimensionList;
  public filterDimensions: DimensionList;
  public params: ParamList;
  public isOlap?: boolean;
  public athenaDw?: boolean;
  public isPositiveTrend?: boolean;
  public hasMetric?: boolean;
  public hasDimension?: boolean;
  public hasTimerange?: boolean;
  public isTimeseries?: boolean;
  public createdAt?: string;
  public updatedAt?: string;
  public isFullLoad: boolean;
  public measureTags?: any;
  public helpLinks: any;
  public dataResources: DataResource[];

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.code = data.code;
    this.dimensions = new DimensionList();
    this.params = new ParamList();
    this.filterDimensions = new DimensionList();
    this.analyticsTypes = new AnalyticsTypeList();
    this.isFullLoad = false;
    this.measureTags = [];
    this.helpLinks = [];
    this.dataResources = [];
  }

  public resetData() {
    this.dimensions = new DimensionList();
    this.params = new ParamList();
    this.filterDimensions = new DimensionList();
    this.analyticsTypes = new AnalyticsTypeList();
    this.isFullLoad = false;
    this.measureTags = [];
  }

  public async fetch() {
    this.resetData();
    const res: any = await get(`/measures/${this.code}`);
    this.mapData(res.data);
    this.isFullLoad = true;
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.code = data.luis_mapping;
    this.isOlap = data.is_olap;
    this.isPositiveTrend = data.is_positive_trend;
    this.hasMetric = data.has_metric;
    this.hasDimension = data.has_dimension;
    this.hasTimerange = data.has_timerange;
    this.isTimeseries = data.is_timeseries;
    this.dimensions = new DimensionList();
    this.dimensions.mapData(data.dimensions);
    this.dimensions.sort();
    if (data.report_params) {
      this.params.mapData(data.report_params);
    }
    this.filterDimensions.mapData(data.filter_dimensions);
    this.filterDimensions.sort();
    this.analyticsTypes.mapData(data.analytic_types);
    this.athenaDw = data.athena_dw;
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
    this.measureTags = data.measure_tags;
    if (data.helps) {
      this.helpLinks = this.extractHelpLinks(data.helps);
    }
    this.dataResources = data.dw_data_resources;
  }

  public async isSupportedColumn(code: string) {
    if (!this.isFullLoad) {
      await this.fetch();
    }
    for (const dim of this.dimensions.toArray()) {
      if (dim.fieldCode === code) {
        return true;
      }
      for (const subDim of dim.subDimensions.toArray()) {
        if (subDim.dimension.fieldCode === code) {
          return true;
        }
      }
    }
    for (const dim of this.filterDimensions.toArray()) {
      if (dim.fieldCode === code) {
        return true;
      }
      for (const subDim of dim.subDimensions.toArray()) {
        if (subDim.dimension.fieldCode === code) {
          return true;
        }
      }
    }
    return false;
  }

  public extractHelpLinks(helpLinksData: any) {
    const links: any = [];
    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/;
    const items = helpLinksData.split('\n');
    for (const item of items) {
      const match = item.match(linkRegex);
      if (match) {
          const linkLabel = match[1];
          const link = match[2];
          links.push({linkLabel, link});
        }
    }
    return links;
  }
}
