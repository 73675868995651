
import { Component, Prop, Vue } from "vue-property-decorator";
import Config from "../models/config";
import { StoreModel } from "@/models/store";
import ActionButton from "@/components/ActionButton.vue";
import { MeasureList } from "@/collections/measures";
import BaseComponent from "@/components/BaseComponent.vue";
import { EventBus } from "@/main";
import HeaderTitle from "../components/HeaderTitle.vue";
import DataSyncTimeline from "@/components/DataSyncTimeline.vue";
import AddChatChannel from "@/components/AddChatChannel.vue";
import moment from 'moment';
import InteractiveGuides from '@/components/InteractiveGuides.vue';

@Component({
  components: {
    HeaderTitle,
    ActionButton,
    AddChatChannel,
    InteractiveGuides,
  },
})
export default class SetupGuide extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: null })
  public app!: any;
  public isExpandPageMenu: boolean = true;
  public totalSteps: number = 6;
  public created() {
    
  }

  public openChat() {
    let message: string = 'I need assistance with configuring the report.';
    // @ts-ignore: Unreachable code error
    this.$crisp.do('chat:open');
    // @ts-ignore: Unreachable code error
    this.$crisp.do('message:send', ['text', message]);
  }

  
}
