import { get, post } from '@/services/http';
import { ScheduleProfileModel } from '@/models/schedule_profile';
import List from './list';

export class ScheduleProfileList extends List<ScheduleProfileModel> {
  public countAll: number = 0;

  public async fetch() {
    const res: any = await get(`/schedule/profile`, {});
    const data = res.data || [];
    this.mapData(data);
  }

  private mapData(data: any = []) {
    this.items = [];
    for (const s of data) {
      const profile = new ScheduleProfileModel();
      profile.mapData(s);
      this.add(profile);
    }
  }
}
