
import { Component, Watch, Prop, Vue } from "vue-property-decorator";

import ActionButton from "@/components/ActionButton.vue";
import { EventBus } from "@/main";
import { InventoryTransferRuleList } from '@/collections/inventory_transfer_rules';


@Component({
  components: {
    ActionButton,
  },
})
export default class StoreLocationSettings extends Vue {
  public settingDialog: boolean = false;
  public saveLoading!: boolean;
  public numberRule: any = [
    (v: string) => !v || !isNaN(Number(v)) || "Value must be a number",
  ];
  public isFormValid: boolean = false;  
  public locationHeaders: any = [
    { text: "Location", value: "locationName" },
    { text: "Type", value: "locationType" },
    { text: "Transferable", value: "transferable"},
    { text: "Receivable", value: "receivable" },
    { text: "Region", value: "region", sortable: false },
    { text: "Priority", value: "priority", sortable: false },
  ];
  @Prop({ default: () => new InventoryTransferRuleList()})
  public locations!: InventoryTransferRuleList;
  public locationRegions: any = [
    
  ];
  public locationTypes: any = [
    { name: "Warehouse", value: "warehouse" },
    { name: "Store", value: "store" },
  ];
  public loadingLocations: boolean = false;
  public async created() {
  }

  public saveNewRegion(newRegion: string) {
    let exists = this.locationRegions.find((region: string) => region === newRegion);
    if (!exists) {
      this.locationRegions.push(newRegion);
    }
  }

  public async applySetting() {
    if (this.$refs.form) {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    }
    if (this.isFormValid) {
      try {
        this.loadingLocations = true;
        await this.locations.updateLocationRule();
        EventBus.$emit("show-snackbar", "Location settings saved successfully");
        this.loadingLocations = false;
      } catch (error: any) {
        EventBus.$emit("show-snackbar", error.message);
        this.loadingLocations = false;
      }
    }
  }

  @Watch("locations", { immediate: true, deep: true })
  public onLocationsChange() {
    if (!this.locations || this.locations.items.length === 0) {
      return;
    }
    for (const location of this.locations.items) {
      if (location.region) {
        this.saveNewRegion(location.region);
      }
    }
  }
}
