import { RuleModel } from '@/models/rule';
import List from './list';

export class RuleList extends List<RuleModel> {
  public mapData(rules: []) {
    for (const s of rules) {
      const rule = new RuleModel();
      rule.mapData(s);
      this.add(rule);
    }
  }
}
