
import { Component, Vue, Watch, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class SchedulerDaySelector extends Vue {
  @Prop({ default: [] })
  public initData!: any[];
  @Prop({ default: [] })
  public value!: string[];
  public selectingItems: any[] = [];
  @Prop({ default: [] })
  public selectedDays!: any[];

  public created() {
    this.selectingItems = [];
    for (const item of this.initData) {
      this.selectingItems.push({
        key: item,
        value: this.isSelected(item),
      });
    }
  }

  public isSelected(item: any) {
    for (const day of this.selectedDays) {
      if (day + "" === item.value) {
        return true;
      }
    }
    return false;
  }

  public selectMetricItem(item: any) {
    item.value = !item.value;
    const selectedItems = [];
    for (const selectingItem of this.selectingItems) {
      if (selectingItem.value) {
        selectedItems.push(selectingItem.key.value);
      }
    }
    this.$emit("input", selectedItems);
  }

  @Watch("selectedDays", { immediate: true, deep: true })
  private async onSelectedDaysChanged(newVal: any) {
    if (this.selectedDays && this.selectedDays.length > 0) {
      this.selectingItems = [];
      for (const item of this.initData) {
        this.selectingItems.push({
          key: item,
          value: this.isSelected(item),
        });
      }
    }
  }
}
