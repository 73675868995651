import { get, post } from '@/services/http';
import List from './list';
import { FileDownloadModel } from '@/models/file_download';
import axios from 'axios';

export class FileDownloadList extends List<FileDownloadModel> {
  public async fetch() {
    const res: any = await get('/file_downloads');

    for (const value of res.data) {
      const fileDownload: FileDownloadModel = new FileDownloadModel();
      fileDownload.mapData(value);
      this.add(fileDownload);
    }
  }
}
