import { get, post, put, del } from '@/services/http';
export class EmailReceiverModel {
  public id: number;
  public merchantId: number;
  public name: string
  public email: string

  constructor(data: any = {}) {
    this.id = data.id;
    this.merchantId = data.merchantId;
    this.name = data.name;
    this.email = data.email;
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.merchantId = data.merchant_id;
    this.name = data.name;
    this.email = data.email;
  }

  public async fetch() {
    const res: any = await get(`/email_receivers/${this.id}`);
    this.mapData(res.data);
  }

  public async create() {
    const res: any = await post('/email_receivers', {
      merchantId: this.merchantId,
      name: this.name,
      email: this.email,
    });
    this.mapData(res.data);
  }

  public async update() {
    const res: any = await put(`/email_receivers/${this.id}`, {
      id: this.id,
      merchantId: this.merchantId,
      name: this.name,
      email: this.email,
    });
    this.mapData(res.data);
  }

  public async remove() {
    await del(`/email_receivers/${this.id}`);
  }
}
