import { get } from '@/services/http';

export default class Config {
  public currency: string = '';
  public timezone: string = '';

  constructor(data: any = null) {
    if (data) {
      this.currency = data.currency;
      this.timezone = data.timezone;
    }
  }

  public async load() {
    const res: any = await get('/settings');
    this.currency = res.data.currency_code;
    this.timezone = res.data.timezone;
  }
}
