
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import { StoreModel } from '@/models/store';
import ActionButton from '@/components/ActionButton.vue';
import Alert from '@/components/Alert.vue';
import FeatureLockedNotify from "@/components/FeatureLockedNotify.vue";


@Component({
  components: {
    ActionButton,
    Alert,
    FeatureLockedNotify,
  },
})
export default class BaseComponent extends Vue {
  @Prop({ default: () => new StoreModel() })
  public storeObj!: StoreModel;
  @Prop({ default: () => '' })
  public moduleName!: string;
  @Prop({ default: () => '' })
  public moduleValue!: string;
  @Prop({ default: () => false })
  public displayLock!: boolean;

  public get hasPermission() {
    return this.storeObj.validateRule([{
      code: this.moduleName,
      value: this.moduleValue
    }]).isValidate;
  }
}
