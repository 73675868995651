
import { Component, Prop, Watch } from 'vue-property-decorator';
import { StoreModel } from '@/models/store';
import ActionButton from '@/components/ActionButton.vue';
import Alert from '@/components/Alert.vue';
import BaseComponent from "@/components/BaseComponent.vue";
import GoogleDriveSelector from '@/components/GoogleDriveSelector.vue';
import Selector from '@/components/OptionSelector.vue';

@Component({
  components: {
    BaseComponent,
    ActionButton,
    Alert,
    Selector,
    GoogleDriveSelector,
  },
})
export default class DownloadFullData extends BaseComponent {
  public module: string = 'download_full_data';

  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: () => 0 })
  public reportTotal!: number;
  public downloadError: boolean = false;
  public downloadErrorMessage: string = '';
  public downloadSuccess: boolean = false;
  public downloadSuccessMessage: string = '';
  public downloadLoading: boolean = false;
  public emails: string[] = [];
  public merchantEmails: string[] = [];
  public selectedGoogleAccount: string = '';
  public selectedDriveFolder: string = '';
  public currentValueEmail: string | null = null;
  public fileFormats: string[] = ['csv', 'excel', 'pdf', 'google_sheets'];
  public selectedFileFormats: string[] = ['csv'];
  public downloadName: string = '';

  public async created() {
    this.loadMerchantSavedEmails();
  }

  public loadMerchantSavedEmails() {
    const merchantEmailStr = localStorage.getItem('merchantEmails') || '';
    if (!merchantEmailStr) {
      if (this.store.config.contactEmail) {
        this.merchantEmails.push(this.store.config.contactEmail);
      }
    } else {
      this.merchantEmails = JSON.parse(merchantEmailStr);
      if (!this.merchantEmails || this.merchantEmails.length === 0) {
        if (this.store.config.contactEmail) {
          this.merchantEmails.push(this.store.config.contactEmail);
        }
      }
    }
    if (this.merchantEmails.length > 0) {
      this.emails.push(this.merchantEmails[this.merchantEmails.length - 1]);
    }
  }

  public async download() {
    this.downloadLoading = true;
    // if (this.isLockedDownload) {
    //   return;
    // }

    if (this.currentValueEmail) {
      this.emails.push(this.currentValueEmail);
    }
    if (this.selectedFileFormats[0] === 'google_sheets') {
      this.emails = [this.selectedGoogleAccount];
    }

    if (this.emails.length === 0) {
      this.downloadError = true;
      this.downloadErrorMessage = `Please enter your email!`;
      return;
    }

    for (const email of this.emails) {
      if (!this.validateEmail(email)) {
        this.downloadError = true;
        this.downloadErrorMessage = `"${email}" is not valid`;
        return;
      }
    }
    this.saveMerchantEmail();

    this.$emit('startDownload', this.emails, this.selectedDriveFolder, this.downloadName);

    this.downloadError = false;
    this.downloadErrorMessage = '';
    this.downloadSuccess = true;
    this.downloadSuccessMessage = `The exported file will be sent to emails above`;
    await this.sleep(3000);
    this.downloadSuccess = false;
    this.downloadLoading = false;
    this.downloadSuccessMessage = '';
    this.cancel();
  }

  public cancel() {
    this.$emit('cancel-download');
  }

  public saveMerchantEmail() {
    for (const email of this.emails) {
      let exist = false;
      for (const savedEmail of this.merchantEmails) {
        if (email === savedEmail) {
          exist = true;
        }
      }
      if (!exist) {
        this.merchantEmails.push(email);
      }
    }
    localStorage.setItem('merchantEmails', JSON.stringify(this.merchantEmails));
  }

  public remove(item: string) {
    this.emails.splice(this.emails.indexOf(item), 1);
    this.emails = [...this.emails];
  }

  public updateSelectedGoogleAccount(account: string) {
    this.selectedGoogleAccount = account;
  }

  public updateSelectedDriveFolder(folder: string) {
    this.selectedDriveFolder = folder;
  }

  private validateEmail(email: string) {
    const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return emailRegexp.test(email);
  }

  private sleep(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  public updateEmails(currentValue: string) {
    this.currentValueEmail = currentValue;
  }

  @Watch('selectedFileFormats', { immediate: true, deep: true })
  private onSelectedFileFormatChanged(newVal: any) {
    this.$emit('updateDownloadFileExt', this.selectedFileFormats[0])
  }
}
