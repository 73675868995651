export enum Locales {
  EN = 'en',
  FR = 'fr',
  JP = 'jp',
  CN = 'cn',
  ES = 'es',
}

export const LOCALES = [
  { value: Locales.EN, caption: 'English'  },
  { value: Locales.ES, caption: 'Español' },
  { value: Locales.FR, caption: 'Français' },
  { value: Locales.JP, caption: '日本語' },
  { value: Locales.CN, caption: '中国人' },
];
