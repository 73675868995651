import { get, post } from '@/services/http';
import zlib from 'zlib';
import { Buffer } from 'buffer';

export class SpreadsheetTemplateModel {
  public id: number;
  public name: string;
  public referenceId: number;
  public description: string;
  public link: string;
  public type: string; //custom, customReport, customDashboard
  public reportQueries: any;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.referenceId = data.referenceId;
    this.type = data.type;
    this.description = data.description;
    this.link = data.link;
    this.reportQueries = data.reportQueries;
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.referenceId = data.referenceId;
    this.type = data.type;
    this.description = data.description;
    this.link = data.link;
    this.reportQueries = data.reportQueries;
  }

  public async clone(email: string, folter: string) {
    const response: any = await post('/files/generate_sheets', {
      type: 'FROM_URL',
      source: this.link,
      email: email,
    });
    const data = response.data;      
    return data.link;
  }

  public scheduleDataFromQuery() {
    const options: any = [];
    if (!this.reportQueries || this.reportQueries.length === 0) {
      return options;
    }
    
    for (let reportQuery of this.reportQueries) {
      const m = reportQuery.length % 4;
      reportQuery = reportQuery.replace(/-/g, '+').replace(/_/g, '/').padEnd(reportQuery.length + (m === 0 ? 0 : 4 - m), '=')
        //decode the reportQuery using base64
        const bufferQuery = Buffer.from(reportQuery, 'base64');
        //decompress the reportQuery
        reportQuery = zlib.inflateSync(bufferQuery).toString('ascii');
        reportQuery = JSON.parse(reportQuery);
        const reportData: any = {
          name: '',
          measureCode: '',
          measureName: '',
          dimensionCode: '',
          dimensionName: '',
          analyticsTypeCode: '',
          pivotOption: '',
          timeRange: [],
          filterColumns: [],
          reportParams: [],
          reportColumns: [],
          calculatedMetrics: [],
          sortBy: [],
        };
        reportData.name = reportQuery.title;
        if (reportQuery.measure) {
          reportData.measureCode = reportQuery.measure;
          reportData.measureName = reportQuery.measure;
        }
        if (reportQuery.dimension) {
          reportData.dimensionCode = reportQuery.dimension;
          reportData.dimensionName = reportQuery.dimension;
        }
        if (reportQuery.analyticsType) {
          reportData.analyticsTypeCode = reportQuery.analyticsType;
        }
        if (reportQuery.pivot_option) {
          reportData.pivotOption = reportQuery.pivot_option;
        }
        
        reportData.timeRange = [reportQuery.default_range];

        if (reportQuery.filter && reportQuery.filter.length > 0) {
          reportData.filterColumns = reportQuery.filter;
        }
        
        if (reportQuery.selected_report_columns && reportQuery.selected_report_columns.length > 0) {
          reportData.reportColumns = reportQuery.selected_report_columns;
        }

        if (reportQuery.page_options) {
          reportData.sortBy = this.generateSortByParam(
            reportQuery.page_options.sortBy,
            reportQuery.page_options.sortDesc
          );
        }

        if (reportQuery.calculated_metrics && reportQuery.calculated_metrics.length > 0) {
          reportData.calculatedMetrics = reportQuery.calculated_metrics;
        }
        if (reportQuery.params && reportQuery.params.length > 0) {
          reportData.reportParams = reportQuery.params;
        }
        options.push(reportData);
    }
    return options;
  }
  public generateSortByParam(sortBy: any = [], sortDesc: any = []) {
    if (!sortBy || sortBy.length === 0) {
      return [];
    }
    let index: number = 0;
    const sortByResult: any = [];
    for (let index = 0; index < sortBy.length; index++) {
      sortByResult.push({
        key: sortBy[index],
        order: sortDesc[index],
      });
    }

    return sortByResult;
  }

  public async createSchedule(spreadsheetFile: string, timezone: string) {
    return await post('/schedule', {
      type: 'custom_spreadsheet_template',
      referenceId: this.referenceId,
      deliveryDate: [],
      deliveryHour: [
        1
      ],
      deliveryWeekday: [
        1
      ],
      destination: spreadsheetFile,
      fileFormat: "existed_google_sheets",
      interval: "daily",
      outputType: "google_sheets",
      title: this.name,
      timezone: timezone,
      options: this.scheduleDataFromQuery(),
    });
  }
}
