
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { EventHandler } from "@/modules/events";
import { MyErrorHandler } from "@/modules/errors";
import HeaderTitle from "@/components/HeaderTitle.vue";
import { EventBus } from "@/main";
import Alert from "@/components/Alert.vue";
import ActionButton from "@/components/ActionButton.vue";
import LoadingSlider from "@/components/LoadingSlider.vue";
import EtlStatusPage from "@/components/EtlStatusPage.vue";
import { StoreModel } from "@/models/store";
import { ProductSegmentModel } from "@/models/product_segment";
import { DimensionModel } from "@/models/dimension";
import { AnalyticsTypeModel } from "@/models/analytics_type";
import { MeasureModel } from "@/models/measure";
import { FilterColumnList } from "@/collections/filter_columns";
import { ParamList } from "@/collections/params";

@Component({
  components: {
    HeaderTitle,
    Alert,
    ActionButton,
    LoadingSlider,
    EtlStatusPage,
  },
})
export default class ProductSegmentBuilder extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: "available" })
  public isLockedReports!: string;
  @Prop({ default: false })
  public hasSchemas!: boolean;
  public segmentTemplates: any = [];
  public headers: any = [];
  public createProductSegmentDialog: boolean = false;
  public productSegment: ProductSegmentModel = new ProductSegmentModel();
  public createLoading: boolean = false;
  public isCreatedProductSegment: boolean = false;
  public isCreateSegmentError: boolean = false;
  public isGeneratedProductList: boolean = false;
  public isGenerateProductError: boolean = false;
  public generateProductLoading: boolean = false;
  public loading: boolean = false;

  public created() {
    this.initSegmentTemplates();
  }

  public initSegmentTemplates() {
    this.headers = [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Description",
        value: "description",
      },
      {
        text: "Action",
        value: "action",
      },
    ];

    this.segmentTemplates = [
      {
        type: "data_driven",
        name: "Listing Products",
        description:
          "For categorizing the products by type, vendor, tag, etc",
        status: "ACTIVE",
        options: {
          filters: [],
          params: [],
          measure: "product_info",
          dimension: "by_variant",
          reportColumns: [],
          timerange: [],
          pageOptions: {
            page: 1,
            itemsPerPage: 50,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
          },
        },
      },
      {
        id: 244,
        type: "data_driven",
        name: "High-Profit Highlights",
        description:
          "For prioritizing the replenishment and promotion of high-profit items.",
        status: "ACTIVE",
        count: 10,
        options: {
          filters: [],
          params: [
            {
              name: "Show Top",
              code: "topn",
              value: ["10"],
              valueType: "single",
              dataType: "text",
              listValues: [
                {
                  id: 56,
                  Label: "5",
                  Value: "5",
                },
                {
                  id: 57,
                  Label: "10",
                  Value: "10",
                },
                {
                  id: 58,
                  Label: "20",
                  Value: "20",
                },
                {
                  id: 59,
                  Label: "50",
                  Value: "50",
                },
                {
                  id: 60,
                  Label: "All",
                  Value: "All",
                },
              ],
              loading: false,
            },
          ],
          measure: "sales_overview",
          dimension: "by_product",
          reportColumns: [],
          timerange: ["2024-03-03", "2024-04-01", "30d"],
          pageOptions: {
            page: 1,
            itemsPerPage: 50,
            sortBy: ["[Profit Margin]"],
            sortDesc: [true],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
          },
        },
        createdAt: "2024-04-02T08:52:26.287Z",
        updatedAt: "2024-04-02T08:55:10.102Z",
        createdBy: "user",
      },
      {
        id: 243,
        type: "data_driven",
        name: "Reorder Now Alert",
        description:
          "For products that have already fallen below their reorder points, signaling the urgent need for replenishment to maintain inventory levels and prevent stockouts.",
        status: "PROCESSING",
        count: 20,
        options: {
          filters: [
            {
              name: "Reorder Quantity",
              code: "[replenishment_quantity_inventory_planning]",
              value: ["0"],
              operator: "gt",
              dataType: "decimal",
            },
          ],
          params: [
            {
              name: "Vendor Lead Time",
              code: "lead_time",
              value: ["14"],
              valueType: "single",
              dataType: "number",
              loading: false,
            },
            {
              name: "Safety Stock Days",
              code: "stock_cover_days",
              value: ["7"],
              valueType: "single",
              dataType: "number",
              loading: false,
            },
            {
              name: "Show Top",
              code: "topn",
              value: ["10"],
              valueType: "single",
              dataType: "text",
              listValues: [
                {
                  id: 56,
                  Label: "5",
                  Value: "5",
                },
                {
                  id: 57,
                  Label: "10",
                  Value: "10",
                },
                {
                  id: 58,
                  Label: "20",
                  Value: "20",
                },
                {
                  id: 59,
                  Label: "50",
                  Value: "50",
                },
                {
                  id: 60,
                  Label: "All",
                  Value: "All",
                },
              ],
              loading: false,
            },
          ],
          measure: "inventory_planning_date",
          dimension: "by_product",
          reportColumns: [],
          timerange: ["2024-03-03", "2024-04-01", "30d"],
          pageOptions: {
            page: 1,
            itemsPerPage: 50,
            sortBy: [],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
          },
        },
        createdAt: "2024-04-02T08:50:06.499Z",
        updatedAt: "2024-04-02T08:55:33.368Z",
        createdBy: "user",
      },
      {
        id: 242,
        type: "data_driven",
        name: "Reorder Alert Zone",
        description:
          "For products nearing their reorder points, signaling the need for replenishment.",
        status: "ACTIVE",
        count: 1,
        options: {
          filters: [
            {
              name: "Days of Inventory Remaining",
              code: "[stock_days_inventory_planning]",
              value: ["21"],
              operator: "gte",
              dataType: "decimal",
            },
            {
              name: "Days of Inventory Remaining",
              code: "[stock_days_inventory_planning]",
              value: ["28"],
              operator: "lte",
              dataType: "decimal",
            },
          ],
          params: [
            {
              name: "Vendor Lead Time",
              code: "lead_time",
              value: ["14"],
              valueType: "single",
              dataType: "number",
              loading: false,
            },
            {
              name: "Safety Stock Days",
              code: "stock_cover_days",
              value: ["7"],
              valueType: "single",
              dataType: "number",
              loading: false,
            },
            {
              name: "Show Top",
              code: "topn",
              value: ["All"],
              valueType: "single",
              dataType: "text",
              listValues: [
                {
                  id: 56,
                  Label: "5",
                  Value: "5",
                },
                {
                  id: 57,
                  Label: "10",
                  Value: "10",
                },
                {
                  id: 58,
                  Label: "20",
                  Value: "20",
                },
                {
                  id: 59,
                  Label: "50",
                  Value: "50",
                },
                {
                  id: 60,
                  Label: "All",
                  Value: "All",
                },
              ],
              loading: false,
            },
          ],
          measure: "inventory_planning_date",
          dimension: "by_product",
          reportColumns: [],
          timerange: ["2024-01-02", "2024-04-01", "3m"],
          pageOptions: {
            page: 1,
            itemsPerPage: 50,
            sortBy: [],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
          },
        },
        createdAt: "2024-04-02T08:48:39.872Z",
        updatedAt: "2024-04-02T08:48:51.955Z",
        createdBy: "user",
      },
      {
        id: 241,
        type: "data_driven",
        name: "Safety Stock Monitor",
        description:
          "For products tracked closely to maintain required safety stock levels.",
        status: "ACTIVE",
        count: 1,
        options: {
          filters: [
            {
              name: "Days of Inventory Remaining",
              code: "[stock_days_inventory_planning]",
              value: ["14"],
              operator: "lte",
              dataType: "decimal",
            },
            {
              name: "Days of Inventory Remaining",
              code: "[stock_days_inventory_planning]",
              value: ["7"],
              operator: "gte",
              dataType: "decimal",
            },
          ],
          params: [
            {
              name: "Vendor Lead Time",
              code: "lead_time",
              value: ["14"],
              valueType: "single",
              dataType: "number",
              loading: false,
            },
            {
              name: "Safety Stock Days",
              code: "stock_cover_days",
              value: ["7"],
              valueType: "single",
              dataType: "number",
              loading: false,
            },
            {
              name: "Show Top",
              code: "topn",
              value: ["All"],
              valueType: "single",
              dataType: "text",
              listValues: [
                {
                  id: 56,
                  Label: "5",
                  Value: "5",
                },
                {
                  id: 57,
                  Label: "10",
                  Value: "10",
                },
                {
                  id: 58,
                  Label: "20",
                  Value: "20",
                },
                {
                  id: 59,
                  Label: "50",
                  Value: "50",
                },
                {
                  id: 60,
                  Label: "All",
                  Value: "All",
                },
              ],
              loading: false,
            },
          ],
          measure: "inventory_planning_date",
          dimension: "by_product",
          reportColumns: [],
          timerange: ["2023-04-02", "2024-04-01", "1y"],
          pageOptions: {
            page: 1,
            itemsPerPage: 50,
            sortBy: [],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
          },
        },
        createdAt: "2024-04-02T08:42:49.092Z",
        updatedAt: "2024-04-02T08:43:01.583Z",
        createdBy: "user",
      },
      {
        id: 240,
        type: "data_driven",
        name: "Slow-Sell Watchlist",
        description:
          "For products that move slowly and may need pricing or promotion adjustments.",
        status: "ACTIVE",
        count: 1,
        options: {
          filters: [
            {
              name: "Zero Sales Days",
              code: "[Slow Days]",
              value: ["30"],
              operator: "gt",
              dataType: " number",
            },
            {
              name: "Inventory qty",
              code: "[Actual Inventory Quantity]",
              value: ["0"],
              operator: "gt",
              dataType: "decimal",
            },
            {
              name: "Product Status",
              code: "product_status",
              value: ["Active"],
              operator: "eq",
              dataType: "text",
            },
          ],
          params: [
            {
              name: "Show Top",
              code: "topn",
              value: ["All"],
              valueType: "single",
              dataType: "text",
              listValues: [
                {
                  id: 56,
                  Label: "5",
                  Value: "5",
                },
                {
                  id: 57,
                  Label: "10",
                  Value: "10",
                },
                {
                  id: 58,
                  Label: "20",
                  Value: "20",
                },
                {
                  id: 59,
                  Label: "50",
                  Value: "50",
                },
                {
                  id: 60,
                  Label: "All",
                  Value: "All",
                },
              ],
              loading: false,
            },
          ],
          measure: "slow stock sku",
          dimension: "by_product",
          reportColumns: [],
          timerange: ["2023-04-02", "2024-04-01", "1y"],
          pageOptions: {
            page: 1,
            itemsPerPage: 50,
            sortBy: [],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
          },
        },
        createdAt: "2024-04-02T08:39:06.858Z",
        updatedAt: "2024-04-02T08:39:18.003Z",
        createdBy: "user",
      },
      {
        id: 239,
        type: "data_driven",
        name: "Quick-Sell Inventory",
        description:
          "For high turnover products that sell quickly and need frequent replenishment.",
        status: "ACTIVE",
        count: 10,
        options: {
          filters: [],
          params: [
            {
              name: "Vendor Lead Time",
              code: "lead_time",
              value: ["14"],
              valueType: "single",
              dataType: "number",
              loading: false,
            },
            {
              name: "Safety Stock Days",
              code: "stock_cover_days",
              value: ["7"],
              valueType: "single",
              dataType: "number",
              loading: false,
            },
            {
              name: "Show Top",
              code: "topn",
              value: ["10"],
              valueType: "single",
              dataType: "text",
              listValues: [
                {
                  id: 56,
                  Label: "5",
                  Value: "5",
                },
                {
                  id: 57,
                  Label: "10",
                  Value: "10",
                },
                {
                  id: 58,
                  Label: "20",
                  Value: "20",
                },
                {
                  id: 59,
                  Label: "50",
                  Value: "50",
                },
                {
                  id: 60,
                  Label: "All",
                  Value: "All",
                },
              ],
              loading: false,
            },
          ],
          measure: "inventory_planning_date",
          dimension: "by_product",
          reportColumns: [],
          timerange: ["2024-01-02", "2024-04-01", "3m"],
          pageOptions: {
            page: 1,
            itemsPerPage: 50,
            sortBy: ["[units_sold_inventory_planning]"],
            sortDesc: [true],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
          },
        },
        createdAt: "2024-04-02T08:37:23.604Z",
        updatedAt: "2024-04-02T08:37:36.445Z",
        createdBy: "user",
      },
      {
        id: 238,
        type: "data_driven",
        name: "Cost-Care Inventory",
        description:
          "For products with high holding costs needing quicker turnover.",
        status: "ACTIVE",
        count: 10,
        options: {
          filters: [],
          params: [
            {
              name: "Vendor Lead Time",
              code: "lead_time",
              value: ["14"],
              valueType: "single",
              dataType: "number",
              loading: false,
            },
            {
              name: "Safety Stock Days",
              code: "stock_cover_days",
              value: ["7"],
              valueType: "single",
              dataType: "number",
              loading: false,
            },
            {
              name: "Show Top",
              code: "topn",
              value: ["10"],
              valueType: "single",
              dataType: "text",
              loading: false,
            },
          ],
          measure: "inventory_planning_date",
          dimension: "by_product",
          reportColumns: [],
          timerange: ["2024-03-03", "2024-04-01", "30d"],
          pageOptions: {
            page: 1,
            itemsPerPage: 50,
            sortBy: ["[unit_cost]"],
            sortDesc: [true],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
          },
        },
        createdAt: "2024-04-02T08:30:54.221Z",
        updatedAt: "2024-04-02T08:33:48.002Z",
        createdBy: "user",
      },
    ];
  }

  public showCreateSegmentDialog(segmentTemplate: ProductSegmentModel) {
    this.productSegment = new ProductSegmentModel();
    this.productSegment.type = "data_driven";
    this.productSegment.name = segmentTemplate.name;
    this.productSegment.description = segmentTemplate.description;
    this.productSegment.measure = new MeasureModel({
      code: segmentTemplate.options.measure,
    });
    this.productSegment.dimension = new DimensionModel({
      code: segmentTemplate.options.dimension,
    });
    this.productSegment.analyticsType = new AnalyticsTypeModel({
      code: segmentTemplate.options.analyticsType,
    });
    this.productSegment.filters = this.importFilerColumns(
      segmentTemplate.options.filters
    );
    this.productSegment.params = this.importParams(
      segmentTemplate.options.params
    );
    this.productSegment.timerange = segmentTemplate.options.timerange;
    this.productSegment.pageOptions = segmentTemplate.options.pageOptions;
    this.createProductSegmentDialog = true;
  }

  public importFilerColumns(items: any) {
    const filterColumns: FilterColumnList = new FilterColumnList();
    filterColumns.mappData(items);
    return filterColumns;
  }

  public importParams(items: any) {
    const params: ParamList = new ParamList();
    params.mapData(items);
    return params;
  }

  public async createProductSegment() {
    this.createLoading = true;
    this.isCreatedProductSegment = false;
    this.isCreateSegmentError = false;
    try {
      await this.productSegment.save();
      this.createLoading = false;
      this.isCreatedProductSegment = true;
      await this.generateSegmentProducts();
      EventBus.$emit("show-snackbar", {
        message: "Product segment created successfully",
        color: "success",
      });
    } catch (e: any) {
      this.createLoading = false;
      this.isCreateSegmentError = true;
      this.isCreatedProductSegment = false;
      EventBus.$emit("show-snackbar", {
        message: "Failed to create product segment",
        color: "error",
      });
    }
  }

  public async generateSegmentProducts() {
    if (!this.productSegment.id) {
      return;
    }
      this.isGenerateProductError = false;
      this.isGeneratedProductList = false;
      this.generateProductLoading = true;
      await this.productSegment.generateProducts();
      this.isGeneratedProductList = true;
      this.generateProductLoading = false;
  }

  public viewSegment() {
    this.createProductSegmentDialog = false;
    this.$router.push(`/product-segment/${this.productSegment.id}`);
  }
}
