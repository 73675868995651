
import { Component, Vue, Prop } from 'vue-property-decorator';
import { StoreModel } from '@/models/store';
import ChatWithUs from '@/components/ChatWithUs.vue';

@Component({
  components: {
    ChatWithUs,
  },
})
export default class EtlStatusPage extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;

  public created() {
    return;
  }
}
