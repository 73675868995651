
import { Component, Vue, Prop } from 'vue-property-decorator';
import { EventBus } from '@/main';

interface ColumnView {
  name?: string;
  reportColumns: [];
  isDefault?: boolean;
}

@Component({
  components: {
  },
})
export default class ColumnViewListing extends Vue {
  @Prop({default: false})
  public canCreateViewForTemplateReport!: boolean;
  @Prop({default: () => []})
  public columnViews!: ColumnView[];
  @Prop({default: {name: '', isDefault: false, reportColumns: []} })
  public activeColumnView!: ColumnView;
  public maxItemShow: number = 0;
  public defaultColumnView: ColumnView = {name: 'All Columns', isDefault: false, reportColumns: []};

  public get isShowingActive() {
    if (this.defaultColumnView.name === this.activeColumnView.name) {
      return true;
    }
    return false;
  }
  public canItemShow(index: number) {
    if (!this.activeColumnView || !this.activeColumnView.name) {
      return index < this.maxItemShow;
    }
    if (this.isShowingActive) {
      return index < this.maxItemShow;
    } else {
      return index < this.maxItemShow - 1;
    }
  }

  public get canShowMore() {
    if (this.columnViews.length > this.maxItemShow) {
      return true;
    }
    return false;
  }

  public get moreItemCount() {
    if (this.columnViews.length > this.maxItemShow) {
      return this.columnViews.length - this.maxItemShow;
    }
    return 0;
  }

  public openCreateColumnViewForm() {
    EventBus.$emit('open-create-column-view-form');
  }

  public loadColumnView(columnView: ColumnView) {
    EventBus.$emit('load-column-view', columnView);
  }

  public showDialogEditColumnView() {
    EventBus.$emit('show-dialog-edit-column-fiew');
  }

}
