
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { EventHandler } from "@/modules/events";
import { MyErrorHandler } from "@/modules/errors";
import HeaderTitle from "@/components/HeaderTitle.vue";
import { EventBus } from "@/main";
import Alert from "@/components/Alert.vue";
import ActionButton from "@/components/ActionButton.vue";
import LoadingSlider from "@/components/LoadingSlider.vue";
import EtlStatusPage from "@/components/EtlStatusPage.vue";
import { StoreModel } from "@/models/store";

@Component({
  components: {
    HeaderTitle,
    Alert,
    ActionButton,
    LoadingSlider,
    EtlStatusPage,
  },
})
export default class ScheduleTemplate extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: "available" })
  public isLockedReports!: string;
  @Prop({ default: false })
  public hasSchemas!: boolean;
  public ScheduleTemplates: any = [];
  public headers: any = [];
  public createLoading: boolean = false;
  public loading: boolean = false;
  public created() {
    this.initScheduleTemplates();
  }
  public initScheduleTemplates() {
    this.headers = [
      {
        text: "Report",
        value: "title",
      },
      {
        text: "Description",
        value: "description",
      },

      {
        text: "Action",
        value: "action",
      },
    ];

    this.ScheduleTemplates = [
    {
  "id": 5999,
  "title": "Sales Summary Last Month",
  "description": 'A comprehensive report detailing total sales performance over the past month.',
  "options": [
    {
      "name": "Sales Summary",
      "measureCode": "sales_overview",
      "measureName": "Sales overview",
      "dimensionCode": "by_day",
      "dimensionName": "Day",
      "analyticsTypeCode": "",
      "pivotOption": "undefined",
      "timeRange": [
        "last-month"
      ],
      "filterColumns": [],
      "reportParams": [
        {
          "name": "Show Top",
          "code": "topn",
          "value": [
            "All"
          ],
          "valueType": "single",
          "dataType": "text",
          "listValues": [
            {
              "id": 56,
              "Label": "5",
              "Value": "5"
            },
            {
              "id": 57,
              "Label": "10",
              "Value": "10"
            },
            {
              "id": 58,
              "Label": "20",
              "Value": "20"
            },
            {
              "id": 59,
              "Label": "50",
              "Value": "50"
            },
            {
              "id": 60,
              "Label": "All",
              "Value": "All"
            }
          ],
          "sortOrder": "99999",
          "loading": false
        }
      ],
      "reportColumns": [
        {
          "name": "Day",
          "code": "Posting Date[Date Name]",
          "dataType": "date",
          "luisMapping": "by_day"
        },
        {
          "name": "Orders Count",
          "code": "[Order Count]",
          "dataType": "decimal",
          "luisMapping": "Order Count"
        },
        {
          "name": "Units Sold",
          "code": "[Quantity Sold]",
          "dataType": "decimal",
          "luisMapping": "Sold Quantity"
        },
        {
          "name": "Net Units Sold",
          "code": "[net_quantity_sold]",
          "dataType": "decimal",
          "luisMapping": "net_units_sold"
        },
        {
          "name": "Gross Sales",
          "code": "[Gross Amount]",
          "dataType": "currency",
          "luisMapping": "Gross Sales"
        },
        {
          "name": "Discount Amount",
          "code": "[Discount]",
          "dataType": "currency",
          "luisMapping": "Discount"
        },
        {
          "name": "Return Amount",
          "code": "[Return Amount]",
          "dataType": "currency",
          "luisMapping": "Refunded Amount"
        },
        {
          "name": "Net Sales",
          "code": "[Net Sales]",
          "dataType": "currency",
          "luisMapping": "Net Sales"
        },
        {
          "name": "Shipping Fee",
          "code": "[Shipping Amount]",
          "dataType": "currency",
          "luisMapping": "Shipping Amount"
        },
        {
          "name": "Tax Amount",
          "code": "[Tax Amount]",
          "dataType": "currency",
          "luisMapping": "Tax Amount"
        },
        {
          "name": "Total Sales",
          "code": "[Gross Sales]",
          "dataType": "currency",
          "luisMapping": "total_sales"
        },
        {
          "name": "COGs",
          "code": "[cogs]",
          "dataType": "currency",
          "luisMapping": "cogs"
        },
        {
          "name": "Gross Profit",
          "code": "[Profit Margin]",
          "dataType": "currency",
          "luisMapping": "Profit Margin"
        },
        {
          "name": "Profit Margin %",
          "code": "[Profit Margin %]",
          "dataType": "percent",
          "luisMapping": "profit_margin"
        },
        {
          "name": "Average Order Value",
          "code": "[avg_order_value]",
          "dataType": "currency",
          "luisMapping": "Average Order Value"
        },
        {
          "name": "Units per Transaction",
          "code": "[avg_basket_size]",
          "dataType": "decimal",
          "luisMapping": "avg_basket_size"
        },
        {
          "name": "Customers Count",
          "code": "[total_customers]",
          "dataType": "decimal",
          "luisMapping": "Count Customer"
        },
        {
          "name": "Tip Amount",
          "code": "[Tip Amount]",
          "dataType": "currency",
          "luisMapping": "tip_amount"
        }
      ],
      "calculatedMetrics": [],
      "sortBy": []
    }
  ],
  "profileId": null,
  "outputType": "email",
  "destination": this.store.config.email,
  "fileFormat": "csv",
  "interval": "monthly",
  "timezone": "America/New_York",
  "deliveryDate": [
    1
  ],
  "deliveryWeekday": [],
  "deliveryHour": [
    9
  ],
  "createdAt": "2024-04-08T09:01:41.040Z",
  "updatedAt": "2024-04-08T09:01:41.040Z",
  "upcomingAt": "2024-05-01 09:00:00"
},
{
  "id": 5994,
  "title": "Best selling Product Last 30 Days",
  "description": 'A summary identifying the top-selling product based on the last 30 days.',
  "options": [
    {
      "name": "Sales ",
      "measureCode": "sales_on_hand_inventory",
      "measureName": "Sales & On Hand Inventory",
      "dimensionCode": "by_variant",
      "dimensionName": "Product Variant",
      "analyticsTypeCode": "",
      "pivotOption": "undefined",
      "timeRange": [
        "30d"
      ],
      "filterColumns": [],
      "reportParams": [
        {
          "name": "Show Top",
          "code": "topn",
          "value": [
            "All"
          ],
          "valueType": "single",
          "dataType": "text",
          "listValues": [
            {
              "id": 56,
              "Label": "5",
              "Value": "5"
            },
            {
              "id": 57,
              "Label": "10",
              "Value": "10"
            },
            {
              "id": 58,
              "Label": "20",
              "Value": "20"
            },
            {
              "id": 59,
              "Label": "50",
              "Value": "50"
            },
            {
              "id": 60,
              "Label": "All",
              "Value": "All"
            }
          ],
          "sortOrder": "99999",
          "loading": false
        }
      ],
      "reportColumns": [
        {
          "name": "Product Image",
          "code": "image",
          "dataType": "image",
          "luisMapping": "product_image"
        },
        {
          "name": "Product",
          "code": "Item[Item Description]",
          "dataType": "text",
          "luisMapping": "by_product"
        },
        {
          "name": "Variant",
          "code": "variant",
          "dataType": "text",
          "luisMapping": null
        },
        {
          "name": "SKU",
          "code": "product_sku",
          "dataType": "text",
          "luisMapping": "by_product_sku"
        },
        {
          "name": "Barcode",
          "code": "product_barcode",
          "dataType": "text",
          "luisMapping": "by_product_barcode"
        },
        {
          "name": "Product Status",
          "code": "product_status",
          "dataType": "text",
          "luisMapping": "by_product_status"
        },
        {
          "name": "Product Type",
          "code": "product_type",
          "dataType": "text",
          "luisMapping": "by_product_type"
        },
        {
          "name": "Vendor",
          "code": "Vendor Name",
          "dataType": "text",
          "luisMapping": "by_vendor"
        },
        {
          "name": "Total Sales",
          "code": "[Gross Sales]",
          "dataType": "currency",
          "luisMapping": "total_sales"
        },
        {
          "name": "Orders Count",
          "code": "[order_count]",
          "dataType": "number",
          "luisMapping": "order_count"
        },
        {
          "name": "Units Sold",
          "code": "[Quantity Sold]",
          "dataType": "decimal",
          "luisMapping": "Sold Quantity"
        },
        {
          "name": "Net Units Sold",
          "code": "[net_quantity_sold]",
          "dataType": "decimal",
          "luisMapping": "net_units_sold"
        },
        {
          "name": "Gross Sales",
          "code": "[Gross Amount]",
          "dataType": "currency",
          "luisMapping": "Gross Sales"
        },
        {
          "name": "Discount Amount",
          "code": "[Discount]",
          "dataType": "currency",
          "luisMapping": "Discount"
        },
        {
          "name": "Return Amount",
          "code": "[Return Amount]",
          "dataType": "currency",
          "luisMapping": "Refunded Amount"
        },
        {
          "name": "Net Sales",
          "code": "[Net Sales]",
          "dataType": "currency",
          "luisMapping": "Net Sales"
        },
        {
          "name": "Tax Amount",
          "code": "[Tax Amount]",
          "dataType": "currency",
          "luisMapping": "Tax Amount"
        },
        {
          "name": "COGs",
          "code": "[cogs]",
          "dataType": "currency",
          "luisMapping": "cogs"
        },
        {
          "name": "Gross Profit",
          "code": "[Profit Margin]",
          "dataType": "currency",
          "luisMapping": "Profit Margin"
        },
        {
          "name": "Profit Margin %",
          "code": "[Profit Margin %]",
          "dataType": "percent",
          "luisMapping": "profit_margin"
        },
        {
          "name": "Inventory Quantity",
          "code": "[Actual Inventory Quantity]",
          "dataType": "decimal",
          "luisMapping": "Actual Inventory Quantity"
        },
        {
          "name": "Inventory Value",
          "code": "[Actual Inventory Cost]",
          "dataType": "currency",
          "luisMapping": "Actual Inventory Cost"
        },
        {
          "name": "Unit Cost",
          "code": "[unit_cost]",
          "dataType": "currency",
          "luisMapping": "unit_cost"
        },
        {
          "name": "Retail Value",
          "code": "[Retail Value]",
          "dataType": "currency",
          "luisMapping": null
        },
        {
          "name": "Selling Price",
          "code": "[Selling Price]",
          "dataType": "currency",
          "luisMapping": null
        }
      ],
      "calculatedMetrics": [],
      "sortBy": [
        {
          "key": "[Quantity Sold]",
          "order": true
        }
      ]
    }
  ],
  "profileId": null,
  "outputType": "email",
  "destination": this.store.config.email,
  "fileFormat": "csv",
  "interval": "monthly",
  "timezone": "America/New_York",
  "deliveryDate": [
    -1
  ],
  "deliveryWeekday": [],
  "deliveryHour": [
    9
  ],
  "createdAt": "2024-04-08T08:55:17.895Z",
  "updatedAt": "2024-04-08T08:55:17.895Z",
  "upcomingAt": "2024-04-30 09:00:00"
},
{
  "id": 5992,
  "title": "Best Selling Variants Last Week",
  "description": 'A report highlighting the product variants with the highest sales volume from the previous week.',
  "options": [
    {
      "name": "Sales ",
      "measureCode": "sales_on_hand_inventory",
      "measureName": "Sales & On Hand Inventory",
      "dimensionCode": "by_variant",
      "dimensionName": "Product Variant",
      "analyticsTypeCode": "",
      "pivotOption": "undefined",
      "timeRange": [
        "last-week"
      ],
      "filterColumns": [],
      "reportParams": [
        {
          "name": "Show Top",
          "code": "topn",
          "value": [
            "All"
          ],
          "valueType": "single",
          "dataType": "text",
          "listValues": [
            {
              "id": 56,
              "Label": "5",
              "Value": "5"
            },
            {
              "id": 57,
              "Label": "10",
              "Value": "10"
            },
            {
              "id": 58,
              "Label": "20",
              "Value": "20"
            },
            {
              "id": 59,
              "Label": "50",
              "Value": "50"
            },
            {
              "id": 60,
              "Label": "All",
              "Value": "All"
            }
          ],
          "sortOrder": "99999",
          "loading": false
        }
      ],
      "reportColumns": [
        {
          "name": "Product Image",
          "code": "image",
          "dataType": "image",
          "luisMapping": "product_image"
        },
        {
          "name": "Product",
          "code": "Item[Item Description]",
          "dataType": "text",
          "luisMapping": "by_product"
        },
        {
          "name": "Variant",
          "code": "variant",
          "dataType": "text",
          "luisMapping": null
        },
        {
          "name": "SKU",
          "code": "product_sku",
          "dataType": "text",
          "luisMapping": "by_product_sku"
        },
        {
          "name": "Barcode",
          "code": "product_barcode",
          "dataType": "text",
          "luisMapping": "by_product_barcode"
        },
        {
          "name": "Product Status",
          "code": "product_status",
          "dataType": "text",
          "luisMapping": "by_product_status"
        },
        {
          "name": "Product Type",
          "code": "product_type",
          "dataType": "text",
          "luisMapping": "by_product_type"
        },
        {
          "name": "Vendor",
          "code": "Vendor Name",
          "dataType": "text",
          "luisMapping": "by_vendor"
        },
        {
          "name": "Total Sales",
          "code": "[Gross Sales]",
          "dataType": "currency",
          "luisMapping": "total_sales"
        },
        {
          "name": "Orders Count",
          "code": "[order_count]",
          "dataType": "number",
          "luisMapping": "order_count"
        },
        {
          "name": "Units Sold",
          "code": "[Quantity Sold]",
          "dataType": "decimal",
          "luisMapping": "Sold Quantity"
        },
        {
          "name": "Net Units Sold",
          "code": "[net_quantity_sold]",
          "dataType": "decimal",
          "luisMapping": "net_units_sold"
        },
        {
          "name": "Gross Sales",
          "code": "[Gross Amount]",
          "dataType": "currency",
          "luisMapping": "Gross Sales"
        },
        {
          "name": "Discount Amount",
          "code": "[Discount]",
          "dataType": "currency",
          "luisMapping": "Discount"
        },
        {
          "name": "Return Amount",
          "code": "[Return Amount]",
          "dataType": "currency",
          "luisMapping": "Refunded Amount"
        },
        {
          "name": "Net Sales",
          "code": "[Net Sales]",
          "dataType": "currency",
          "luisMapping": "Net Sales"
        },
        {
          "name": "Tax Amount",
          "code": "[Tax Amount]",
          "dataType": "currency",
          "luisMapping": "Tax Amount"
        },
        {
          "name": "COGs",
          "code": "[cogs]",
          "dataType": "currency",
          "luisMapping": "cogs"
        },
        {
          "name": "Gross Profit",
          "code": "[Profit Margin]",
          "dataType": "currency",
          "luisMapping": "Profit Margin"
        },
        {
          "name": "Profit Margin %",
          "code": "[Profit Margin %]",
          "dataType": "percent",
          "luisMapping": "profit_margin"
        },
        {
          "name": "Inventory Quantity",
          "code": "[Actual Inventory Quantity]",
          "dataType": "decimal",
          "luisMapping": "Actual Inventory Quantity"
        },
        {
          "name": "Inventory Value",
          "code": "[Actual Inventory Cost]",
          "dataType": "currency",
          "luisMapping": "Actual Inventory Cost"
        },
        {
          "name": "Unit Cost",
          "code": "[unit_cost]",
          "dataType": "currency",
          "luisMapping": "unit_cost"
        },
        {
          "name": "Retail Value",
          "code": "[Retail Value]",
          "dataType": "currency",
          "luisMapping": null
        },
        {
          "name": "Selling Price",
          "code": "[Selling Price]",
          "dataType": "currency",
          "luisMapping": null
        }
      ],
      "calculatedMetrics": [],
      "sortBy": [
        {
          "key": "[Quantity Sold]",
          "order": true
        }
      ]
    }
  ],
  "profileId": null,
  "outputType": "email",
  "destination": this.store.config.email,
  "fileFormat": "csv",
  "interval": "weekly",
  "timezone": "America/New_York",
  "deliveryDate": [],
  "deliveryWeekday": [
    1
  ],
  "deliveryHour": [
    9
  ],
  "createdAt": "2024-04-08T08:50:30.401Z",
  "updatedAt": "2024-04-08T08:50:30.401Z",
  "upcomingAt": "2024-04-08 09:00:00"
},
      {
        id: 5991,
        title: "Variants need to reorder now",
        description: 'A weekly report, issued every Monday, detailing product variants that require immediate inventory replenishment.',
        options: [
          {
            name: "Just-in-Time Inventory Replenishment by Product Variant (custom historical date range)",
            measureCode: "inventory_planning_date",
            measureName: "Inventory Planning (custom date)",
            dimensionCode: "by_variant",
            dimensionName: "Product Variant",
            analyticsTypeCode: "",
            pivotOption: "",
            timeRange: ["3m"],
            filterColumns: [
              {
                name: "Reorder Quantity",
                code: "[replenishment_quantity_inventory_planning]",
                value: ["0"],
                operator: "gt",
                dataType: "decimal",
              },
            ],
            reportParams: [
              {
                name: "Vendor Lead Time",
                code: "lead_time",
                value: ["14"],
                valueType: "single",
                dataType: "number",
                loading: false,
              },
              {
                name: "Safety Stock Days",
                code: "stock_cover_days",
                value: ["7"],
                valueType: "single",
                dataType: "number",
                loading: false,
              },
              {
                name: "Show Top",
                code: "topn",
                value: ["All"],
                valueType: "single",
                dataType: "text",
                listValues: [
                  {
                    id: 56,
                    Label: "5",
                    Value: "5",
                  },
                  {
                    id: 57,
                    Label: "10",
                    Value: "10",
                  },
                  {
                    id: 58,
                    Label: "20",
                    Value: "20",
                  },
                  {
                    id: 59,
                    Label: "50",
                    Value: "50",
                  },
                  {
                    id: 60,
                    Label: "All",
                    Value: "All",
                  },
                ],
                loading: false,
              },
            ],
            reportColumns: [
              {
                name: "Product",
                code: "Item[Item Description]",
                dataType: "text",
                luisMapping: "by_product",
              },
              {
                name: "Variant",
                code: "variant",
                dataType: "text",
                luisMapping: null,
              },
              {
                name: "SKU",
                code: "product_sku",
                dataType: "text",
                luisMapping: "by_product_sku",
              },
              {
                name: "Lead Time",
                code: "[vendor_lead_time]",
                dataType: "decimal",
                luisMapping: "vendor_lead_time",
              },
              {
                name: "Safety Stock Days",
                code: "[safety_stock_days]",
                dataType: "decimal",
                luisMapping: "safety_stock_days",
              },
              {
                name: "Total Units Sold",
                code: "[units_sold_inventory_planning]",
                dataType: "decimal",
                luisMapping: "units_sold_inventory_planning",
              },
              {
                name: "Avg Units Sold per day",
                code: "[avg_unit_sold_inventory_planning]",
                dataType: "decimal",
                luisMapping: "avg_unit_sold_inventory_planning",
              },
              {
                name: "Quantity On-hand",
                code: "[Actual Inventory Quantity]",
                dataType: "decimal",
                luisMapping: "Actual Inventory Quantity",
              },
              {
                name: "Incoming Inventory",
                code: "[incoming_quantity]",
                dataType: "decimal",
                luisMapping: "incoming_quantity",
              },
              {
                name: "Days of Inventory Remaining",
                code: "[stock_days_inventory_planning]",
                dataType: "decimal",
                luisMapping: "stock_days_inventory_planning",
              },
              {
                name: "Reorder Quantity",
                code: "[replenishment_quantity_inventory_planning]",
                dataType: "decimal",
                luisMapping: "[replenishment_quantity_inventory_planning]",
              },
            ],
            calculatedMetrics: [],
            sortBy: [],
          },
        ],
        profileId: null,
        outputType: "email",
        destination: this.store.config.email,
        fileFormat: "csv",
        interval: "weekly",
        timezone: "America/New_York",
        deliveryDate: [],
        deliveryWeekday: [1],
        deliveryHour: [9],
        createdAt: "2024-04-08T08:33:27.424Z",
        updatedAt: "2024-04-08T08:33:27.424Z",
        upcomingAt: "2024-04-08 09:00:00",
      },
      {
        id: 5990,
        title: "Inventory forecasting for the next 30 days",
        description: 'A weekly report, delivered every Monday, predicting inventory levels for the coming month.',
        options: [
          {
            name: "Next 30 Days Inventory Need for Product Variant",
            measureCode: "inventory_forecasting",
            measureName: "Forecast Inventory Quantity Need",
            dimensionCode: "by_variant",
            dimensionName: "Product Variant",
            analyticsTypeCode: "",
            pivotOption: "undefined",
            timeRange: ["3m"],
            filterColumns: [
              {
                name: "[units_sold_inventory_planning]",
                code: "[units_sold_inventory_planning]",
                value: ["0"],
                operator: "gt",
                dataType: "text",
              },
            ],
            reportParams: [
              {
                name: "Forecast Time",
                code: "forecast_time",
                value: ["30"],
                valueType: "single",
                dataType: "number",
                loading: false,
              },
              {
                name: "Show Top",
                code: "topn",
                value: ["All"],
                valueType: "single",
                dataType: "text",
                listValues: [
                  {
                    id: 56,
                    Label: "5",
                    Value: "5",
                  },
                  {
                    id: 57,
                    Label: "10",
                    Value: "10",
                  },
                  {
                    id: 58,
                    Label: "20",
                    Value: "20",
                  },
                  {
                    id: 59,
                    Label: "50",
                    Value: "50",
                  },
                  {
                    id: 60,
                    Label: "All",
                    Value: "All",
                  },
                ],
                loading: false,
              },
            ],
            reportColumns: [
              {
                name: "Product",
                code: "Item[Item Description]",
                dataType: "text",
                luisMapping: "by_product",
              },
              {
                name: "Variant",
                code: "variant",
                dataType: "text",
                luisMapping: null,
              },
              {
                name: "SKU",
                code: "product_sku",
                dataType: "text",
                luisMapping: "by_product_sku",
              },
              {
                name: "Product status",
                code: "product_status",
                dataType: "text",
                luisMapping: "by_product_status",
              },
              {
                name: "Product type",
                code: "product_type",
                dataType: "text",
                luisMapping: "by_product_type",
              },
              {
                name: "Vendor",
                code: "product_vendor",
                dataType: "text",
                luisMapping: "by_product_vendor",
              },
              {
                name: "Unit Cost",
                code: "[unit_cost]",
                dataType: "currency",
                luisMapping: "unit_cost",
              },
              {
                name: "Selling Price",
                code: "[Selling Price]",
                dataType: "currency",
                luisMapping: null,
              },
              {
                name: "Units Sold",
                code: "[units_sold_inventory_planning]",
                dataType: "decimal",
                luisMapping: "units_sold_inventory_planning",
              },
              {
                name: "Avg Units Sold per day",
                code: "[avg_unit_sold_inventory_planning]",
                dataType: "decimal",
                luisMapping: "avg_unit_sold_inventory_planning",
              },
              {
                name: "Adjusted Units Sold per day",
                code: "[average_daily_units_sold_sales_trend]",
                dataType: "decimal",
                luisMapping: "average_daily_units_sold_sales_trend",
              },
              {
                name: "Quantity on hand",
                code: "[Actual Inventory Quantity]",
                dataType: "decimal",
                luisMapping: "Actual Inventory Quantity",
              },
              {
                name: "Incoming Inventory",
                code: "[incoming_quantity]",
                dataType: "decimal",
                luisMapping: "incoming_quantity",
              },
              {
                name: "Forecast Time",
                code: "[forecast_time]",
                dataType: "number",
                luisMapping: "forecast_time",
              },
              {
                name: "Forecast Sales Quantity",
                code: "[forecast_sales_quantity]",
                dataType: "decimal",
                luisMapping: "forecast_sales_quantity",
              },
              {
                name: "Forecast Sales Amount",
                code: "[forecast_sales_amount]",
                dataType: "currency",
                luisMapping: "forecast_sales_amount",
              },
              {
                name: "Forecast Inventory Quantity Need",
                code: "[inventory_forecasting]",
                dataType: "decimal",
                luisMapping: "inventory_forecasting",
              },
              {
                name: "Forecast Inventory Value Need",
                code: "[forecast_inventory_value]",
                dataType: "currency",
                luisMapping: "forecast_inventory_value",
              },
              {
                name: "Forecast Overstocked/ Understocked Quantity",
                code: "[forecast_over_under_stock]",
                dataType: "decimal",
                luisMapping: "forecast_over_under_stock",
              },
            ],
            calculatedMetrics: [],
            sortBy: [],
          },
        ],
        profileId: null,
        outputType: "email",
        destination: this.store.config.email,
        fileFormat: "csv",
        interval: "weekly",
        timezone: "America/New_York",
        deliveryDate: [],
        deliveryWeekday: [1],
        deliveryHour: [9],
        createdAt: "2024-04-08T08:30:06.701Z",
        updatedAt: "2024-04-08T08:30:06.701Z",
        upcomingAt: "2024-04-08 09:00:00",
      },
      {
        id: 5989,
        title: "Days of Inventory Remaining by Product Variant",
        description: 'A weekly report, scheduled every Monday, showing how many days current inventory levels will last for each product variant.',
        options: [
          {
            name: "Days of Inventory Remaining by Product Variant",
            measureCode: "days_of_supply",
            measureName: "Days of Inventory Remaining",
            dimensionCode: "by_variant",
            dimensionName: "Product Variant",
            analyticsTypeCode: "",
            pivotOption: "undefined",
            timeRange: [],
            filterColumns: [],
            reportParams: [
              {
                name: "Show Top",
                code: "topn",
                value: ["All"],
                valueType: "single",
                dataType: "text",
                listValues: [
                  {
                    id: 56,
                    Label: "5",
                    Value: "5",
                  },
                  {
                    id: 57,
                    Label: "10",
                    Value: "10",
                  },
                  {
                    id: 58,
                    Label: "20",
                    Value: "20",
                  },
                  {
                    id: 59,
                    Label: "50",
                    Value: "50",
                  },
                  {
                    id: 60,
                    Label: "All",
                    Value: "All",
                  },
                ],
                loading: false,
              },
            ],
            reportColumns: [
              {
                name: "Product",
                code: "Item[Item Description]",
                dataType: "text",
                luisMapping: "by_product",
              },
              {
                name: "Variant",
                code: "variant",
                dataType: "text",
                luisMapping: null,
              },
              {
                name: "SKU",
                code: "product_sku",
                dataType: "text",
                luisMapping: "by_product_sku",
              },
              {
                name: "Barcode",
                code: "product_barcode",
                dataType: "text",
                luisMapping: "by_product_barcode",
              },
              {
                name: "Product status",
                code: "product_status",
                dataType: "text",
                luisMapping: "by_product_status",
              },
              {
                name: "Product type",
                code: "product_type",
                dataType: "text",
                luisMapping: "by_product_type",
              },
              {
                name: "Vendor",
                code: "Vendor Name",
                dataType: "text",
                luisMapping: "by_vendor",
              },
              {
                name: "Units Sold last 30 days",
                code: "[units_sold_last_30d]",
                dataType: "decimal",
                luisMapping: "units_sold_last_30d",
              },
              {
                name: "Units Sold last 60 days",
                code: "[units_sold_last_60d]",
                dataType: "number",
                luisMapping: "units_sold_last_60d",
              },
              {
                name: "Units Sold last 90 days",
                code: "[units_sold_last_90d]",
                dataType: "number",
                luisMapping: "units_sold_last_90d",
              },
              {
                name: "Average Units Sold per day",
                code: "[avg_daily_qty_sold]",
                dataType: "decimal",
                luisMapping: "avg_daily_units_sold",
              },
              {
                name: "Inventory Quantity",
                code: "[Actual Inventory Quantity]",
                dataType: "decimal",
                luisMapping: "Actual Inventory Quantity",
              },
              {
                name: "Incoming Inventory",
                code: "[incoming_quantity]",
                dataType: "decimal",
                luisMapping: "incoming_quantity",
              },
              {
                name: "Days of inventory remaining",
                code: "[stock_days]",
                dataType: "decimal",
                luisMapping: "days_of_supply",
              },
              {
                name: "Unit Cost",
                code: "[unit_cost]",
                dataType: "currency",
                luisMapping: "unit_cost",
              },
              {
                name: "Selling Price",
                code: "[Selling Price]",
                dataType: "currency",
                luisMapping: null,
              },
              {
                name: "Inventory Value",
                code: "[Actual Inventory Cost]",
                dataType: "currency",
                luisMapping: "Actual Inventory Cost",
              },
              {
                name: "Retail Value",
                code: "[Retail Value]",
                dataType: "currency",
                luisMapping: null,
              },
            ],
            calculatedMetrics: [],
            sortBy: [],
          },
        ],
        profileId: null,
        outputType: "email",
        destination: this.store.config.email,
        fileFormat: "csv",
        interval: "weekly",
        timezone: "America/New_York",
        deliveryDate: [],
        deliveryWeekday: [1],
        deliveryHour: [9],
        createdAt: "2024-04-08T08:14:58.019Z",
        updatedAt: "2024-04-08T08:14:58.019Z",
        upcomingAt: "2024-04-08 09:00:00",
      },
      {
        id: 5988,
        title: "Sales by Day Last Week",
        description: 'A weekly summary, provided every Monday, of daily sales figures from the previous week.',
        options: [
          {
            name: "Last 30 days Sales Summary",
            measureCode: "sales_overview",
            measureName: "Sales overview",
            dimensionCode: "by_day",
            dimensionName: "Day",
            analyticsTypeCode: "",
            pivotOption: "undefined",
            timeRange: ["last-week"],
            filterColumns: [],
            reportParams: [
              {
                name: "Show Top",
                code: "topn",
                value: ["All"],
                valueType: "single",
                dataType: "text",
                listValues: [
                  {
                    id: 56,
                    Label: "5",
                    Value: "5",
                  },
                  {
                    id: 57,
                    Label: "10",
                    Value: "10",
                  },
                  {
                    id: 58,
                    Label: "20",
                    Value: "20",
                  },
                  {
                    id: 59,
                    Label: "50",
                    Value: "50",
                  },
                  {
                    id: 60,
                    Label: "All",
                    Value: "All",
                  },
                ],
                sortOrder: "99999",
                loading: false,
              },
            ],
            reportColumns: [
              {
                name: "Day",
                code: "Posting Date[Date Name]",
                dataType: "date",
                luisMapping: "by_day",
              },
              {
                name: "Orders Count",
                code: "[Order Count]",
                dataType: "decimal",
                luisMapping: "Order Count",
              },
              {
                name: "Units Sold",
                code: "[Quantity Sold]",
                dataType: "decimal",
                luisMapping: "Sold Quantity",
              },
              {
                name: "Net Units Sold",
                code: "[net_quantity_sold]",
                dataType: "decimal",
                luisMapping: "net_units_sold",
              },
              {
                name: "Gross Sales",
                code: "[Gross Amount]",
                dataType: "currency",
                luisMapping: "Gross Sales",
              },
              {
                name: "Discount Amount",
                code: "[Discount]",
                dataType: "currency",
                luisMapping: "Discount",
              },
              {
                name: "Return Amount",
                code: "[Return Amount]",
                dataType: "currency",
                luisMapping: "Refunded Amount",
              },
              {
                name: "Net Sales",
                code: "[Net Sales]",
                dataType: "currency",
                luisMapping: "Net Sales",
              },
              {
                name: "Shipping Fee",
                code: "[Shipping Amount]",
                dataType: "currency",
                luisMapping: "Shipping Amount",
              },
              {
                name: "Tax Amount",
                code: "[Tax Amount]",
                dataType: "currency",
                luisMapping: "Tax Amount",
              },
              {
                name: "Total Sales",
                code: "[Gross Sales]",
                dataType: "currency",
                luisMapping: "total_sales",
              },
              {
                name: "COGs",
                code: "[cogs]",
                dataType: "currency",
                luisMapping: "cogs",
              },
              {
                name: "Gross Profit",
                code: "[Profit Margin]",
                dataType: "currency",
                luisMapping: "Profit Margin",
              },
              {
                name: "Profit Margin %",
                code: "[Profit Margin %]",
                dataType: "percent",
                luisMapping: "profit_margin",
              },
              {
                name: "Average Order Value",
                code: "[avg_order_value]",
                dataType: "currency",
                luisMapping: "Average Order Value",
              },
              {
                name: "Units per Transaction",
                code: "[avg_basket_size]",
                dataType: "decimal",
                luisMapping: "avg_basket_size",
              },
              {
                name: "Customers Count",
                code: "[total_customers]",
                dataType: "decimal",
                luisMapping: "Count Customer",
              },
              {
                name: "Tip Amount",
                code: "[Tip Amount]",
                dataType: "currency",
                luisMapping: "tip_amount",
              },
            ],
            calculatedMetrics: [],
            sortBy: [],
          },
        ],
        profileId: null,
        outputType: "email",
        destination: "steve@assisty.ai",
        fileFormat: "csv",
        interval: "weekly",
        timezone: "America/New_York",
        deliveryDate: [],
        deliveryWeekday: [1],
        deliveryHour: [9],
        createdAt: "2024-04-08T07:59:06.238Z",
        updatedAt: "2024-04-08T07:59:06.238Z",
        upcomingAt: "2024-04-08 09:00:00",
      },
      {
        id: 5987,
        title: "Sold Out Variants Yesterday",
        description: 'A report listing product variants that were sold yesterday and are now completely out of stock.',
        options: [
          {
            name: "Sold out Product Variants",
            measureCode: "sold_out_product",
            measureName: "Sold Out",
            dimensionCode: "by_variant",
            dimensionName: "Product Variant",
            analyticsTypeCode: "",
            pivotOption: "undefined",
            timeRange: ["yesterday"],
            filterColumns: [],
            reportParams: [
              {
                name: "Show Top",
                code: "topn",
                value: ["All"],
                valueType: "single",
                dataType: "text",
                listValues: [
                  {
                    id: 56,
                    Label: "5",
                    Value: "5",
                  },
                  {
                    id: 57,
                    Label: "10",
                    Value: "10",
                  },
                  {
                    id: 58,
                    Label: "20",
                    Value: "20",
                  },
                  {
                    id: 59,
                    Label: "50",
                    Value: "50",
                  },
                  {
                    id: 60,
                    Label: "All",
                    Value: "All",
                  },
                ],
                loading: false,
              },
            ],
            reportColumns: [
              {
                name: "Product",
                code: "Item[Item Description]",
                dataType: "text",
                luisMapping: "by_product",
              },
              {
                name: "Variant",
                code: "variant",
                dataType: "text",
                luisMapping: null,
              },
              {
                name: "SKU",
                code: "product_sku",
                dataType: "text",
                luisMapping: "by_product_sku",
              },
              {
                name: "Barcode",
                code: "product_barcode",
                dataType: "text",
                luisMapping: "by_product_barcode",
              },
              {
                name: "Product status",
                code: "product_status",
                dataType: "text",
                luisMapping: "by_product_status",
              },
              {
                name: "Product Type",
                code: "product_type",
                dataType: "text",
                luisMapping: "by_product_type",
              },
              {
                name: "Vendor",
                code: "Vendor Name",
                dataType: "text",
                luisMapping: "by_vendor",
              },
              {
                name: "Last Sales Date",
                code: "[Last Sales Date]",
                dataType: "text",
                luisMapping: "last_sales_date",
              },
              {
                name: "Unit cost",
                code: "[unit_cost]",
                dataType: "currency",
                luisMapping: "unit_cost",
              },
              {
                name: "Selling Price",
                code: "[Selling Price]",
                dataType: "currency",
                luisMapping: null,
              },
              {
                name: "Inventory Quantity",
                code: "[Actual Inventory Quantity]",
                dataType: "decimal",
                luisMapping: "Actual Inventory Quantity",
              },
              {
                name: "Variants Sold Out",
                code: "[variant_count]",
                dataType: "number",
                luisMapping: "variant_count",
              },
              {
                name: "Variants Sold Out %",
                code: "[product_variant_count_percent]",
                dataType: "percent",
                luisMapping: "product_variant_count_percent",
              },
            ],
            calculatedMetrics: [],
            sortBy: [],
          },
        ],
        profileId: null,
        outputType: "email",
        destination: this.store.config.email,
        fileFormat: "csv",
        interval: "daily",
        timezone: "America/New_York",
        deliveryDate: [],
        deliveryWeekday: [],
        deliveryHour: [9],
        createdAt: "2024-04-08T07:56:13.661Z",
        updatedAt: "2024-04-08T07:56:13.661Z",
        upcomingAt: "2024-04-08 09:00:00",
      },
      {
        id: 5986,
        title: "Low Stock by Product Variant",
        description: 'A report identifying product variants with inventory levels projected to last less than seven days.',
        options: [
          {
            name: "Low Stock by Product Variant",
            measureCode: "days_of_supply",
            measureName: "Days of Inventory Remaining",
            dimensionCode: "by_variant",
            dimensionName: "Product Variant",
            analyticsTypeCode: "",
            pivotOption: "undefined",
            timeRange: [],
            filterColumns: [
              {
                name: "Days of Inventory Remaining",
                code: "[stock_days]",
                value: ["7"],
                operator: "lte",
                dataType: "number",
              },
              {
                name: "Units Sold last 90 days",
                code: "[units_sold_last_90d]",
                value: ["0"],
                operator: "gt",
                dataType: " decimal",
              },
            ],
            reportParams: [
              {
                name: "Show Top",
                code: "topn",
                value: ["All"],
                valueType: "single",
                dataType: "text",
                listValues: [
                  {
                    id: 56,
                    Label: "5",
                    Value: "5",
                  },
                  {
                    id: 57,
                    Label: "10",
                    Value: "10",
                  },
                  {
                    id: 58,
                    Label: "20",
                    Value: "20",
                  },
                  {
                    id: 59,
                    Label: "50",
                    Value: "50",
                  },
                  {
                    id: 60,
                    Label: "All",
                    Value: "All",
                  },
                ],
                loading: false,
              },
            ],
            reportColumns: [
              {
                name: "Product",
                code: "Item[Item Description]",
                dataType: "text",
                luisMapping: "by_product",
              },
              {
                name: "Variant",
                code: "variant",
                dataType: "text",
                luisMapping: null,
              },
              {
                name: "SKU",
                code: "product_sku",
                dataType: "text",
                luisMapping: "by_product_sku",
              },
              {
                name: "Barcode",
                code: "product_barcode",
                dataType: "text",
                luisMapping: "by_product_barcode",
              },
              {
                name: "Product status",
                code: "product_status",
                dataType: "text",
                luisMapping: "by_product_status",
              },
              {
                name: "Product type",
                code: "product_type",
                dataType: "text",
                luisMapping: "by_product_type",
              },
              {
                name: "Vendor",
                code: "Vendor Name",
                dataType: "text",
                luisMapping: "by_vendor",
              },
              {
                name: "Units Sold last 30 days",
                code: "[units_sold_last_30d]",
                dataType: "decimal",
                luisMapping: "units_sold_last_30d",
              },
              {
                name: "Units Sold last 60 days",
                code: "[units_sold_last_60d]",
                dataType: "number",
                luisMapping: "units_sold_last_60d",
              },
              {
                name: "Units Sold last 90 days",
                code: "[units_sold_last_90d]",
                dataType: "number",
                luisMapping: "units_sold_last_90d",
              },
              {
                name: "Average Units Sold per day",
                code: "[avg_daily_qty_sold]",
                dataType: "decimal",
                luisMapping: "avg_daily_units_sold",
              },
              {
                name: "Inventory Quantity",
                code: "[Actual Inventory Quantity]",
                dataType: "decimal",
                luisMapping: "Actual Inventory Quantity",
              },
              {
                name: "Incoming Inventory",
                code: "[incoming_quantity]",
                dataType: "decimal",
                luisMapping: "incoming_quantity",
              },
              {
                name: "Days of inventory remaining",
                code: "[stock_days]",
                dataType: "decimal",
                luisMapping: "days_of_supply",
              },
              {
                name: "Unit Cost",
                code: "[unit_cost]",
                dataType: "currency",
                luisMapping: "unit_cost",
              },
              {
                name: "Selling Price",
                code: "[Selling Price]",
                dataType: "currency",
                luisMapping: null,
              },
              {
                name: "Inventory Value",
                code: "[Actual Inventory Cost]",
                dataType: "currency",
                luisMapping: "Actual Inventory Cost",
              },
              {
                name: "Retail Value",
                code: "[Retail Value]",
                dataType: "currency",
                luisMapping: null,
              },
            ],
            calculatedMetrics: [],
            sortBy: [],
          },
        ],
        profileId: null,
        outputType: "email",
        destination: this.store.config.email,
        fileFormat: "csv",
        interval: "daily",
        timezone: "America/New_York",
        deliveryDate: [],
        deliveryWeekday: [],
        deliveryHour: [9],
        createdAt: "2024-04-08T04:19:10.336Z",
        updatedAt: "2024-04-08T04:19:10.336Z",
        upcomingAt: "2024-04-08 09:00:00",
      },
    ];
  }

  public createScheduleFromTemplate(scheduleData: any) {
    scheduleData.id = null;
    localStorage.setItem("schedule_to_create", JSON.stringify(scheduleData));
    this.$router.push({ name: "scheduleCreate" });
  }
}
