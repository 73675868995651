
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { ParamList } from '@/collections/params';
import { ParamModel } from '@/models/param';
import dateFormat from 'dateformat';
import ActionButton from '@/components/ActionButton.vue';
import DateFilterParam from '@/components/DateFilterParam.vue';

interface Range {
  start?: any;
  end?: any;
}

@Component({
  components: {
    ActionButton,
    DateFilterParam,
  },
})
export default class ReportParam extends Vue {
  @Prop({ default: null })
  public value!: ParamList;
  @Prop({ default: [] })
  public measureParams!: ParamList;
  public addParamMenu: boolean = false;
  @Prop({ default: false })
  public showColumnFilterMenu!: boolean;
  public operators: any = [];
  @Prop({ default: null })
  public initParams!: ParamList;
  public paramForms: any = [];
  public errorMessage: string = '';
  public search: string[] = [];
  public loading: boolean[] = [];
  public currentSearchIndex: number = 0;
  public currentSearchCode: string = '';
  public numberRangeDialog: boolean = false;
  public selectingIndex: number = -1;
  public selectingParam: ParamModel = new ParamModel();
  public dateRangeDialog: boolean = false;
  public dateValueDialog: boolean = false;
  public singleDate: boolean = false;
  public dateRangeValues: Range = {};
  public dateValue: Date = new Date();
  public numberRange: any = { from: null, to: null };
  public numberRangeError: boolean = false;
  public editingParam: ParamModel = new ParamModel();
  public menuX: number = 0;
  public menuY: number = 0;
  public numberRules: any = [
    this.isNumber,
  ];
  public created() {
    //this.assignParams();
  }

  public isNumber(v: any) {
    return (!Number.isNaN(v)) || 'You must enter a number';
  }

  public getItemMenuPosition() {
    const e = window.event;
    if (e === undefined) {
      return;
    }
    if (e && e !== undefined) {
      // @ts-ignore: Unreachable code error
      this.menuX = e.clientX;

      // @ts-ignore: Unreachable code error
      this.menuY = e.clientY + 20;
    }

  }

  public isEditingParam(code: string) {
    if (!this.editingParam.code) {
      return true;
    } else {
      if (this.editingParam.code === code) {
        return true;
      }
    }
    return false;
  }

  public resetEditingParam() {
    this.getItemMenuPosition();
    this.editingParam = new ParamModel();
  }

  public showParamSelector(index: number) {
    this.selectingIndex = index;
    this.selectingParam = this.paramForms[index].param;

    this.addParamMenu = false;
    if (this.selectingParam.dataType === 'date') {
      if (this.selectingParam.valueType === 'range') {
        this.dateRangeDialog = true;
      } else {
        this.dateValueDialog = true;
      }
    } else if (
      this.selectingParam.dataType === 'number'
      && this.selectingParam.valueType === 'range'
    ) {
      this.numberRangeDialog = true;
    }
  }

  public getParamValues(code: string) {
    let param: ParamModel = new ParamModel();
    for (const item of this.paramForms) {
      if (item.param.code === code) {
        param = item.param;
        break;
      }
    }
    if (param.code) {
      let values: any = [];
      switch (param.valueType) {
        case 'range': {
          if (param.value) {
            values = param.value;
          }
          break;
        }
        default: {
          values.push(param.value);
        }
      }
      return values;
    }
    return [];
  }

  public async selectParam(code: string) {
    this.addParamMenu = true;
    this.getItemMenuPosition();
    this.editingParam = new ParamModel();
    let index: number = -1;
    let exist: boolean = false;
    for (const item of this.paramForms) {
      index = index + 1;
      const param = item.param;
      if (param.code === code) {
        exist = true;
        break;
      }
    }
    if (!exist) {
      this.addBlankParam();
      index = this.paramForms.length - 1;
      this.paramForms[index].param.code = code;
      if (this.paramForms[index].param.valueType === 'range') {
        this.paramForms[index].param.value = [];
      } else {
        this.paramForms[index].param.value = null;
      }
    }
    this.paramForms[index].loading = true;
    await this.loadCurrentParamConfig(index);
    this.paramForms[index].loading = false;
    this.editingParam = this.paramForms[index].param;
    this.selectingIndex = index;
  }

  public addNumberRange() {
    if (this.numberRange.from && this.numberRange.to) {
      this.paramForms[this.selectingIndex].param.value = [];
      this.paramForms[this.selectingIndex].param.value.push(
        this.numberRange.from + ' → ' + this.numberRange.to,
      );
      this.numberRangeError = false;
      this.numberRangeDialog = false;
      this.addParamMenu = true;
      this.numberRange = { from: null, to: null };
    } else {
      this.numberRangeError = true;
    }
  }

  public getParamByCode(code: string) {
    for (const item of this.measureParams.items) {
      if (item.code === code) {
        return item;
      }
    }
    return new ParamModel();
  }

  public async loadCurrentParamConfig(index: number) {
    let param: ParamModel = new ParamModel();
    for (const item of this.measureParams.items) {
      if (item.code === this.paramForms[index].param.code) {
        param = item;
      }
    }
    this.paramForms[index].param.dataType = param.dataType;
    this.paramForms[index].param.valueType = param.valueType;
    this.paramForms[index].param.name = param.name;
    const defaultParamValue = this.paramForms[index].param.value;
    if (
      !this.paramForms[index].param.listValues
      || this.paramForms[index].param.listValues.length === 0
    ) {
      await this.loadParamListValueFromAPI('', index, param.code);
      // if (this.paramForms[index].param.code === 'variant_option_title') {
      //   let foundDefault = false;
      //   for (const valueParam of this.paramForms[index].param.listValues) {
      //     if (valueParam['Value'] === defaultParamValue) {
      //       foundDefault = true;
      //       break;
      //     }
      //   }
      //   if (!foundDefault) {
      //     this.saveParam();
      //     setTimeout(() => {
      //       this.$emit('need-refresh-table-data');
      //     }, 3000);
      //   }
      // }
    }
  }

  public async loadParamListValueFromAPI(
    search: string, index: number, code: string, resetValue: boolean = false
  ) {
    if (resetValue) {
      this.paramForms[index].param.value = [];
    }
    if (!this.paramForms[index]) {
      return;
    }
    this.currentSearchCode = code;
    this.currentSearchIndex = index;
    const param =  this.getParamByCode(code);

    const listValues = [];

    if (param.listValues && param.listValues.length > 0) {
      this.paramForms[index].param.listValues = param.listValues;
      return;
    } else {
      this.paramForms[index].param.listValues = [];
    }
    if (param && param.code && param.NeedApiRequest) {
      try {
        this.paramForms[index].loading = true;
        const paramModel = new ParamModel({code: param.code});
        const values = await paramModel.fetch({ keyword: search });
        if (!values || values.length === 0) {
          this.paramForms[index].loading = false;
          return;
        }
        for (const item of values) {
          if (item && item.name) {
            listValues.push({Label: item.name, Value: item.name});
          }
        }
        this.paramForms[index].param.listValues = listValues;
        let valueExists = false;
        for (const value of listValues) {
          if (value.Value === this.paramForms[index].param.value) {
            valueExists = true;
            break;
          }
        }
        if (!valueExists) {
          this.paramForms[index].param.value = listValues[0].Value;
        }
        this.paramForms[index].loading = false;
      } catch {
        // do nothing
      }
    }
  }

  public getAppendIcon(valueType: string, dataType: string) {
    if (dataType === 'date') {
      return 'mdi-calendar-month-outline';
    }
    if (valueType === 'range') {
      return 'mdi-plus';
    }

    return 'mdi-chevron-down';
  }

  public async assignParams() {
    if (this.measureParams && this.measureParams.items && this.measureParams.items.length > 0) {
      const paramForms: any = [];
      let data: any;
      let index = 0;
      for (const item of this.measureParams.items) {
        for (const param of this.initParams.items) {
          if (param.code === item.code) {
            item.value = param.value;
          }
        }

        const values: string[] = [];
        if (item.value && item.value.length !== 0) {
          for (const v of item.value) {
            if (item.valueType === 'range') {
              const newV = v.replace('_to_', ' → ');
              values.push(newV);
            } else {
              values.push(v);
            }
          }
        }

        if (item.valueType === 'range') {
          data = {
            name: item.name,
            code: item.code,
            value: values,
            valueType: item.valueType,
            dataType: item.dataType,
            listValues: item.listValues,
            default: item.default,
          };
        } else {
          data = {
            name: item.name,
            code: item.code,
            value: values[0],
            valueType: item.valueType,
            dataType: item.dataType,
            listValues: item.listValues,
            default: item.default,
          };
        }
        paramForms.push({
          param: new ParamModel(data),
          loading: false,
          search: '',
        });
        index = index + 1;
      }
      this.paramForms = paramForms;
      for (let i=0; i < this.paramForms.length; i++) {
        const itemParam = this.paramForms[i];
        if (itemParam.param.code === 'variant_option_title') {
          await this.loadCurrentParamConfig(i);
        }
      }
    }
  }

  public get paramsCount() {
    let count: number = 0;
    if (this.paramForms) {
      for (const item of this.paramForms) {
        if (item.param.code && item.param.value) {
          count = count + 1;
        }
      }
      if (count > 0) {
        return ' (' + count + ' ' + this.$t('filter.actives') + ')';
      }

    }
    return '';
  }

  public deleleFilter(index: number) {
    this.paramForms.splice(index, 1);
    if (this.paramForms.length === 0) {
      this.addBlankParam();
    }
    this.addParamMenu = false;
  }

  public clearFilter() {
    this.errorMessage = '';
    this.addParamMenu = false;
    const selectedParams: ParamList = new ParamList();
    this.paramForms = [];
    this.currentSearchIndex = 0;
    this.currentSearchCode = '';
    this.$emit('input', selectedParams);
    this.addBlankParam();
  }

  public addSelectingParamInputValue() {
    if (!this.paramForms[this.selectingIndex]) {
      return;
    }
    const newValue = this.paramForms[this.selectingIndex].search;
    if (newValue === null) {
      return;
    }
    const param = this.paramForms[this.selectingIndex].param;
    const oldValue = param.value;
    const paramDataType = this.getParamDataType(param.code);
    const paramValueType = this.getParamValueType(param.code);
    if (paramDataType === 'number') {
      if (!Number(newValue)) {
        return;
      }
    }
    if (paramValueType !== 'range') {
      param.value = newValue;
    }
  }

  public saveParam(changedValue: any) {
    if (changedValue === null) {
      return;
    }
    this.addSelectingParamInputValue();
    const selectedParams: ParamList = new ParamList();
    let data: any;
    for (const item of this.paramForms) {
      const param = item.param;
      if (param.code) {
        if (param.value) {
          const paramName = this.getParamName(param.code);
          const paramDataType = this.getParamDataType(param.code);
          const paramValueType = this.getParamValueType(param.code);
          const values: string[] = [];
          switch (paramValueType) {
            case 'range': {
              if (paramDataType === 'datetime') {
                for (const v of param.value) {
                  const newV = v.split(' → ');
                  if (newV.length === 2) {
                    values.push(newV[0] + '_to_' + newV[1]);
                  } else {
                    param.default = v;
                  }
                }
              } else {
                for (const v of param.value) {
                  const newV = v.split(' → ');
                  if (newV.length === 2) {
                    values.push(newV[0] + '_to_' + newV[1]);
                  } else {
                    values.push(v);
                  }
                }
              }

              break;
            }
            default: {
              values.push(param.value);
              break;
            }
          }

          data = {
            name: paramName,
            code: param.code,
            value: values,
            valueType: paramValueType,
            dataType: paramDataType,
            listValues: param.listValues,
            default: param.default,
          };
          selectedParams.add(new ParamModel(data));
        }
      }
    }
    this.$emit('input', selectedParams);
    this.errorMessage = '';
    this.addParamMenu = false;
  }

  public getParamName(code: string) {
    for (const param of this.measureParams.items) {
      if (param.code === code) {
        return param.name;
      }
    }
    return code;
  }

  public getParamDataType(code: string) {
    for (const param of this.measureParams.items) {
      if (param.code === code) {
        return param.dataType;
      }
    }
    return 'text';
  }

  public getParamValueType(code: string) {
    for (const param of this.measureParams.items) {
      if (param.code === code) {
        return param.valueType;
      }
    }
    return 'single';
  }

  public removeParamValue(index: number, item: string) {
    const newValues = this.paramForms[index].param.value;
    if (newValues && newValues.length > 0) {
      const itemIndex = newValues.indexOf(item);
      if (itemIndex !== -1) {
        newValues.splice(itemIndex, 1);
      }
      this.paramForms[index].param.value = newValues;
    }
  }

  public addBlankParam() {
    this.errorMessage = '';
    for (const item of this.paramForms) {
      if (!item.param.code) {
        return;
      }
    }
    const blankParam = new ParamModel({ name: '', code: '', valueType: 'single', value: [], dataType: 'text' });
    this.paramForms.push({ param: blankParam, loading: false });
  }

  @Watch('measureParams', { immediate: true, deep: true })
  private async onInitParamsChange(newVal: any) {
    if (this.measureParams && this.measureParams.items && this.measureParams.items.length > 0) {
      this.assignParams();
    }
  }

  @Watch('dateRangeValues', { immediate: true, deep: true })
  private async onDateValueRangesChanged(newVal: any) {
    if (this.dateRangeValues.start && this.dateRangeValues.end && this.selectingIndex !== -1) {
      let newDateString = dateFormat(this.dateRangeValues.start, 'yyyy-mm-dd').toString();
      newDateString = newDateString + ' → ' + dateFormat(this.dateRangeValues.end, 'yyyy-mm-dd').toString();
      this.paramForms[this.selectingIndex].param.value = [];
      this.paramForms[this.selectingIndex].param.value.push(newDateString);
      this.dateRangeDialog = false;
      this.addParamMenu = true;
      this.selectingIndex = -1;
    }
  }

  @Watch('dateValue', { immediate: true, deep: true })
  private async onDateValueChanged(newVal: any) {
    if (this.dateValue && this.selectingIndex !== -1) {
      const newDateString = dateFormat(this.dateValue, 'yyyy-mm-dd').toString();

      this.paramForms[this.selectingIndex].param.value = newDateString;
      this.dateValueDialog = false;
      this.addParamMenu = true;
      this.selectingIndex = -1;
    }
  }
}
