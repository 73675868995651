
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EventHandler } from '@/modules/events';
import HeaderTitle from '../components/HeaderTitle.vue';
import ChatWithUs from '../components/ChatWithUs.vue';
import { NotificationModel } from '@/models/notification';
import { NotificationList } from '@/collections/notifications';
import { MeasureList } from '@/collections/measures';
import ContentLoadResultMessage from '@/components/ContentLoadResultMessage.vue';
import { StoreModel } from '@/models/store';
import { RuleModel } from '@/models/rule';
import FeatureLockedNotify from '@/components/FeatureLockedNotify.vue';
import ActionButton from '@/components/ActionButton.vue';
import EmptyStage from '@/components/EmptyStage.vue';
import SidebarMenu from '../components/SidebarMenu.vue';

@Component({
  components: {
    HeaderTitle,
    ChatWithUs,
    ContentLoadResultMessage,
    FeatureLockedNotify,
    ActionButton,
    EmptyStage,
    SidebarMenu,
  },
})
export default class Notification extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public success: boolean = false;
  public error: boolean = false;
  public isLoading: boolean = false;
  public notifications: NotificationList = new NotificationList();
  public deletingNotification: NotificationModel = new NotificationModel();
  public dialogDelete: boolean = false;
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;
  public notificationHeaders: any = [];
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  public rule: RuleModel = new RuleModel({ code: 'schedule_reports_count' });

  public async created() {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`view notification page`);
    } catch (e) {
      // ignore this
    }
    this.isLoading = true;
    for (const rule of this.store.currentPackage.rules.items) {
      if (rule.code === this.rule.code) {
        this.rule = rule;
        break;
      }
    }
    this.notificationHeaders = [
      {
        text: 'Reports',
        value: 'sentence',
        width: '25%',
      },
      {
        text: 'Send To',
        value: 'noticeId',
        align: 'center',
      },
      {
        text: 'File Format',
        value: 'fileFormat',
        align: 'center',
      },
      {
        text: this.$t('schedule.Schedule For') + '',
        value: 'day',
        align: 'center',
      },
      {
        text: this.$t('schedule.time') + '',
        value: 'time',
        align: 'center',
      },
      {
        text: this.$t('schedule.action') + '',
        value: 'action',
        width: '10%',
        align: 'center',
      },
    ];
    await this.measures.fetch({ isOlap: true });
    await this.fullLoadData();
    this.isLoading = false;
  }

  public canScheduleReport() {
    const validateValues: any = [
      {
        code: 'schedule_reports_count',
        value: this.notifications.size() + '',
      },
    ];
    const validateResult = this.store.validateRule(validateValues);
    return validateResult.isValidate;
  }

  public getFileFormatText(fileFormat: string) {
    switch (fileFormat) {
      case 'csv':
        return 'CSV';
      case 'existed_google_sheets':
        return 'Current Google Spreadsheet';
      case 'existed_google_new_sheets':
        return 'Add Sheet to Google Spreadsheet';
      case 'google_sheets':
        return 'Fresh Google Spreadsheet';
      default:
        return 'CSV';
    }
  }
  public getScheduleReportName(data: any) {
    if (data.name) {
      return data.name;
    }
    let reportName = '';
    for (const measure of this.measures.items) {
      if (measure.code === data.measureCode) {
        reportName = measure.name;
        for (const dimension of measure.dimensions.items) {
          if (dimension.code === data.dimensionCode) {
            reportName += ' by ' + dimension.name;
            break;
          }
        }
        return reportName;
      }
    }
    return '';
  }

  public async fullLoadData() {
    this.isLoading = true;
    this.notifications = new NotificationList();
    await this.notifications.fetch();
    this.isLoading = false;
  }

  public confirmDelete(notification: NotificationModel) {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`click delete notification`);
    } catch (e) {
      // ignore this
    }
    this.deletingNotification = notification;
    this.dialogDelete = true;
  }

  public async deleteItem(notification: NotificationModel) {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`delete notification`, {});
    } catch (e) {
      // ignore this
    }
    await this.deletingNotification.remove();
    await this.fullLoadData();
    this.dialogDelete = false;
  }

  public upperCaseFirstLetter(str: string) {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  }
}
