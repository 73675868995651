import { get, post } from '@/services/http';
import { CustomReportModel } from '@/models/custom_report';
import List from './list';

export class CustomReportList extends List<CustomReportModel> {
  public countAll: number = 0;

  public async fetch() {
    const res: any = await get(`/custom_reports`, {});
    const customReports = res.data || [];
    for (const s of customReports) {
      const customReport = new CustomReportModel();
      customReport.mapData(s);
      this.add(customReport);
    }
  }

  public async count() {
    const res: any = await get(`/custom_reports/count`, {});
    this.countAll = res.data || 0;
  }
}
