
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class VideoGuides extends Vue {
  public canShowGuide: boolean = false;
  @Prop({default: []})
  public feature!: string[];
  @Prop({default: 'Explore Our Interactive Guides'})
  public pageTitle!: string;
  public guides: any = [
    {
      feature: ['assisty', 'inventory'],
      title: 'Solving Inventory Problems with Assisty',
      link: 'https://app.arcade.software/share/JwjYxQ49YBHnqUDbhI85',
      thumbnail: 'inventory_problem.svg',
      description: 'Learn how to solve inventory problems with Assisty',
      report: {
        link: '/board/337',
        title: 'Inventory metrics',
        icon: 'mdi-chart-bar',
      },
    },
    {
      feature: ['assisty','report_page'],
      title: 'Navigating the Report Page Structure',
      link: 'https://app.arcade.software/share/4Q1KwHWrBhgVKOdx9w5g',
      thumbnail: 'report_page_navigation.svg',
      description: 'Learn how to navigate the report page',
      report: {
        link: '',
        title: '',
        icon: '',
      },
    },
    {
      feature: ['report_page'],
      title: 'How to visualize data',
      link: 'https://app.arcade.software/share/fwkLameYzsn0K5vngfuK',
      thumbnail: 'report_page_visualization.svg',
      description: 'Learn how to visualize data',
      report: {
        link: '',
        title: '',
        icon: '',
      },
    },
    {
      feature: ['assisty', 'data_import'],
      title: 'How to Check Data Import Status',
      link: 'https://app.arcade.software/share/ht1J1nqjJjZLx1DJYTkk',
      thumbnail: 'data_import.svg',
      description: 'Learn how to check data import status',
      report: {
        link: '/importing-status',
        title: 'Check status',
        icon: 'mdi-check-circle',
      },
    },
    {
      feature: ['assisty','settings'],
      title: 'How to Configure Replenishment Parameters',
      link: 'https://app.arcade.software/share/A1KnI76LyI4WyQvSCbb5',
      thumbnail: 'replenishment_setting.svg',
      report: {
        link: '/settings/inventory-replenishment-parameters',
        title: 'Setup now',
        icon: 'mdi-cog',
      },
    },
    {
      feature: ['settings', 'inventory_replenishment'],
      title: 'How to Configure Incoming Inventory Settings',
      link: 'https://app.arcade.software/share/3mvZQOUtCJmOC9OWzUK2',
      thumbnail: 'setting_incoming_inventory.svg',
      description: 'Learn how to configure incoming inventory settings',
      report: {
        link: '/settings/incoming-inventoryw',
        title: 'Setup now',
        icon: 'mdi-cog',
      },
    },
    {
      feature: ['assisty', 'inventory_replenishment'],
      title: 'How to Replenish Inventory',
      link: 'https://app.arcade.software/share/aBOFS9Seslx5FSV2meRc',
      thumbnail: 'reorder_suggestions.svg',
      report: {
        link: '/reports/36/inventory-replenishment/672?report_query=eJztWe1P4zYY_1eiSJM20ZzaQAv0G4NNOu24Y8AhTQhFJnGLd06cs50eFeJ_32OnrZ-4adcW6XSb-gWw_XveHT8vvISaaU7DYfi-mNBCCzkNrmnJacHUUw4bYSdMn4jUt9PSoGD5RNS52QmHWla0E6on8W22MSJcmR3Kaappdk5kdi54lRcqHN6_hAXJDY8rKbIqtZxFZkVrmt-bH8EFValkpWaieIDzjGgyE6zps6HgFVOXpCxZMYbNx2lSzpi9dhb874hkpED8J4uNf2M4RyJuN398dpxm0hL1pdpKPUuAmF5TITMqgz8rkMb01Em4Z_M4JBLHwXNHRlOWE74kcgV1m-wLoimSK-vtBIRQX1iNbErCeMz-c8G0Cm4EzxBzBcvk68zYpCSS5Bsa1EK5LM2AghJsygh2JZlQScYUVGR8mlQGmlh-2yiwngnW5Sz7u1Jw7YNqJ6UU4VQlWtIie6tqiBVW8AMlWXDLchr8DDqpX5BWcGsyIRMOiEQDYkMNfLLGh0NGVE-DGy3SL_gq2O1EmW0gnFC-6U1YImy71VeC4U9_ca1Ls7-hpAYNFuLeyJYP9yzVFeHBMmZDqSvpmxqkIgd8-8tRnyXuEfi6nQprOGAlLuDyBGIU4JSRE1YYJijQNlDmoiF2JSeFwW0a83U8sEqXID2v8jbvOY3AtN19007c0IE8b6ADeX6DDq3EbX6wX11wLib2oZ8qzwu1X1NzbL27hQ980jYPrJMOJuwqvYW0zfZPq1KrUb_-sneIfZOwzerVckHx3eQuEWK5v7NnSDYrpY7M8W5y20jbHtvzacrbaojU7G_52FoaP7XDHVL4NZ9nPJPr4BYo_0VPKwlJL52uTJULwvD1oRPmlKhK0jV1E3CHtFYoKEjrgs7VkkpDzVtnvWEYd-OjqHsa9XpwAkmxsd-Lo17XsKIjUnGdSFKMzeFhDpsjxjWVUB-DPiWbCJ0IW__WxbYtMhrV8w2U28GtKJ1XtCgLWE0Ir2B5H55xHj7M1jO_KPCCjVRLycqUvjPQWgrLwmF_0Ak_kEdIsMOwD5C7mjP8bcJjEccOYU2bQ2CxwJw4TIwxMcKcIkkY03eYQddhjGkOZFYmjFyQzIbZ9h_oCv32nPLKHAWi0pEYRfb5CLLGkwRHiRglKNXQORn2aiHe5tRBz5nxUSArgPHC1Nhh_qIKgaawWmvqovi8tVXfwjpcCiJrer4xRuOmKUWVP8LFbDUGhfag1-3-hKP7zoUORffgtIHqvTtdoHoowAcnHuzEwZADD4492LGDIR8eDDzYwMEOEazvwdxF7x0h2JEHO3KwPoIderBDB0Mf1kHswWIHO264twnrORiOQhPlMCgEUZNVF4UgRiGIYg_mQhCjEESHHsyFIEYhiI48mAtBjEIQ9T2YC0GMQhANPJgLQYxCEB17MBeCGIUgOvFgLgQxCkF06sF6bZ_ig5t7mBwipMkzq2YfwfscspH7Spldos95PyP5AZqtRVez3C9_1-ZmPy3aT4v206L_4LToB5jV7Acj-8HIfjDyvxqM3Nln1HGv18FHc7pRwVW_w99z1uIkfcKNuPcJLHXiG3pqia6lkg7g9wiMvCRyzAokdF59lvY8ye25JxjyclpPg5qCW2mb9wGVVej981zdqL7QQ7aN19fxWK3SNWTGoqIb6CKphsIhsTODt-qEea3WrY7YBqqNpVBqFoS3qoZ52TavNFe5HslBJ_di19BXd0IGzZi6ovLKbvShg1bQ9P06NeOV1XX5Qw0zTRwAzX_QYQeEVqWlnC9m52ZGCVXijUD_Xc8rrhnaee24louOa3OggwV9UsLTihPTkeZUS5aqmiUUDlCOMiWKuh4fvry-_gMdJ_wS',
        title: 'View report',
        icon: 'mdi-invoice-clock'
      },
    },
    {
      feature: ['assisty', 'settings', 'inventory_replenishment'],
      title: 'How to Set Up Replenishment for Bundle Products',
      link: 'https://app.arcade.software/share/l2iD9b4yVW9CCI0Ech46',
      thumbnail: 'bundle_mapping.svg',
      report: {
        link: '/settings/bundle-product-mapping',
        title: 'Setup now',
        icon: 'mdi-tshirt-crew',
      },
    },
    {
      feature: ['assisty', 'settings', 'inventory_transfer_suggestion'],
      title: 'How to Configure Replenishment from Warehouse',
      link: 'https://app.arcade.software/share/4JoNi8tZtsdfiWjvqq4J',
      thumbnail: 'inventory_transfer.svg',
      description: 'Learn how to configure replenishment from warehouse',
      report: {
        link: '/reports/measures/inventory_transfer_suggestion?report_query=eJztWMlu2zAQ_RVBpwbwoT304lvjokDQBW6W9hAEwoQay0QoUeDi1jD87x1SlE3Hm6wcetHFEGf43jxRM9KDV6nhRmA6Tn-Dwrm0GpNbrAVWXM9LrAytmCzpKgfDZaXTUcrmoMz9snYoWs5BT1wkHRtlcZTqufwTAjMQ2kVQIDOYT0DlEylsSTTjx1VaQek4pkrmlhnHLHMXuDFYPrqf5DNqpnjtKj9RnjRAKGzwr0MIy_V3qGteFRR8XmZ1IFuPNvy_QHGoIv7FJnCOsN0Zsd19fdgyhWqZfrEd2OLdEeM1KE-3x_q8SXRlbhERezjd5M6AsfqA9Dbe_XBbzIEqnmCvhmmiF1TwiPgZUgNKtWVu1skPl-1EvGgIIsqHiptkIvV-Y2SWUhlrUhE3s0phxZbH2iTCxQ2DQtC2ZKo4i87G761DrEuRCBCx38vkm2R-NtvTCAUejcxESGVud5cJeo2JS31aABfwLDD5aeluuVkm76LyV0dKNzdkMl7RMzBSLV_pyJHxEsRJKXscsa6bit5Q7oQvksUD6k269kliYV-kQgaaZg8E6k6qZgGSaQfpoWiXYLffc1TaSPaCeeKav5si62C-s_vIidCxllssgVfumfVUpVqC3speMew8NyXLo1M1o-Tlc7WPOjdZOyKujgroO11nWM7MV0dxvWfsHM2pKeuordeknaLYeSsrqPQMlfIPNbR1R2EmYB30ou4-R3N4AN8itd8UnuQ4dIqNqvhNEBKXDf8OKF0_jdISQVvlQJvO2pxbpm1RoHYaHT8nC6zddeMmtg4SZ2CFyQhVBEM848KgIodLFWq-kCaT3sH6bzjt5xXmtK0GBaVutrUOmU6jlsp5iMElDy55cMmDSx5c8uCSB5c8uOTBJQ8u-T-55BoKDBaWHOnKr9Pxh1HKyXzqKaqpD3x8Tz6WzOv1MnhaunbOlFb-f2AKFUraus37RdjgvLjV5k5G_xqXZKt5FFmPtm4Mi9IPYp6OqSoDwawA559LNPTtD66a5oFMNtd0fnTnJHG1Xv8DZjwqYA',
        title: 'View report',
        icon: 'mdi-warehouse',
      },
    },
    {
      feature: ['assisty', 'settings'],
      title: 'Configure Multiple Store Data Combination',
      link: 'https://app.arcade.software/share/ex68AHNEJOUgbe9ho4ho',
      thumbnail: 'multi-store-data-combination.svg',
      description: 'Learn how to configure multiple store data combination',
      report: {
        link: '/settings/internal-inventory-transfer-settings',
        title: 'Setup now',
        icon: 'mdi-cog',
      },
    },
    {
      feature: ['assisty', 'inventory_forecasting'],
      title: 'How to Forecast Demand',
      link: 'https://app.arcade.software/share/lmedvCyXcO0b8fPuvAG1',
      thumbnail: 'inventory_forecasting.svg',
      description: 'Learn how to forecast demand',
      report: {
        link: '/reports/38/demand-forecasting/609?report_query=eJzNVltv2jAU_iuRn6kG3fYw3ijTJDR1Y0tbaaqQZWwDXh0784UNIf77jpOQmDQU0PbQF6Sc2_dxbj5b5ISTHA3RRK25ctpskk_acEqsE2qZzDfJAzGCKId6iK6IcXebPJin97e3o-8_QLoidhwUaOiM5z1kV_p3JVgQaYOES04dZ2Ni2FhLnymLho9bpEgWQkUAmgXBuhYw4kgF6PifIJFe2FuS50AODZWXcterA-2JJymR3CbfPAQRbtMEflxUFtgGC_yrspgdQjFORUZkGw0d8UYRhTvtiEzulXA2SbVkEbYPQmxBiMU-1ziXRCmIfiaDF2Og3ayHMk6sNyFGY7BoCgoRrYPaYCcKvtf963dX_Q9XgwFouGIH8sH11aAfiPEF8dJhQ9QyKN9mIFwI6biBOgJoLtbaYZ07oRXoQZsTQzJbavflx4bnGqDp8xaYGs08dckkI4BQ50wUn1F6K7vGYuJ49hh-ko_cUiMKCrPTnYPmkLcqWBT_v7Vi-vm-CVIBYfvkL2JWOLyu_m5RGGXax-lqEyCFvgVPvTFc0c0p_NK5E71ZVvskJF84j6ets_vPzEP35HTx-LrmxjpNnzh7A1PP6q8Xi6PBC_tgjQvzS8vT9n89C6jhMVovIxZJzk3CSJwNsl7iEO0fCZ0Kc8CJ_fQWFtEpYtzA2sGMCLnB0R8uu9JB77KzyZ0OFROsm1mrZEVUXLwRdR4K-7zxz6Ry1D_Gnyiqs_Ds12YHE1UqL90qz_w6J-kuvDsdsxLeoxaQ8tkcXp6j41E8YS9vjQciPW-vjDpA00XrYHfx-mr5x1xC5yVjbV1rLuFRtGfvydohjpxyKUPppkbQOJMH8jMRWo9ZNC5wD6ZgAt3bvVhsobx8mZR-7Uw1oDew7o6Czgvl5aClX3E25WFMywMGDpNt8Y2Ggx4ScFvYKTfTQvC-D-cM3DA3G7heTm7UWWkcLhMwD9cxSJZG-7zw339U-nC8wXpKdXQ5Z3B2iUiy6zXHBF9mgIgFQ0NgRYmkXpJwZmXcQa2r4wtmD24xYbWCLeTgL2x3u79xBzzx',
        title: 'Forecast now',
        icon: 'mdi-chart-line',
      },
    },
    {
      feature: ['assisty','schedule'],
      title: 'Report Scheduling Made Simple',
      link: 'https://app.arcade.software/share/TWSQanMkxS0cwl97kIL6',
      thumbnail: 'schedule_introduction.svg',
      description: 'Learn how to schedule reports',
      report: {
        link: '/schedules',
        title: 'Schedule now',
        icon: 'mdi-calendar-clock',
      },
    },
    {
      feature: ['schedule'],
      title: 'How to Schedule a Report',
      link: 'https://app.arcade.software/share/wWSPeNPU2r83CBnANmbm',
      thumbnail: 'schedule_create.svg',
      description: 'Learn how to schedule a report',
      report: {
        link: '/schedules',
        title: 'Schedule now',
        icon: 'mdi-calendar-clock',
      },
    },
    {
      feature: ['schedule'],
      title: 'How to Edit an Existing Schedule',
      link: 'https://app.arcade.software/share/r3QoanaRYGIS3M3At2uO',
      thumbnail: 'schedule_edit.svg',
      description: 'Learn how to edit an existing schedule',
      report: {
        link: '/schedules',
        title: 'List schedules',
        icon: 'mdi-calendar-clock',
      },
    },
    {
      feature: ['assisty','product_segment'],
      title: 'Navigating Product Segmentation',
      link: 'https://app.arcade.software/share/4Q1KwHWrBhgVKOdx9w5g',
      thumbnail: 'product_segment.svg',
      description: 'Learn how to navigate product segmentation',
      report: {
        link: '/product-segment',
        title: 'Try now',
        icon: 'mdi-cube'
      },
    },
    {
      feature: ['product_segment'],
      title: 'Create Product Segment from a Report',
      link: 'https://app.arcade.software/share/H8ng2pZLWc0PypMyDF3E',
      thumbnail: 'create_product_from_report.svg',
      description: 'Learn how to create product segment from a report',
      report: {
        link: '',
        title: '',
      },
    },
    {
      feature: ['report_page'],
      title: 'How to set up email alerts for low stock',
      link: 'https://app.arcade.software/share/LpjfaoBMuidAqIlq0XoU',
      thumbnail: 'setup_low_stock.svg',
      description: 'Learn how to set up email alerts for low stock',
      report: {
        link: '/card/view/1276?report_query=eJztWOFP6zYQ_1eiSJM2rXlqAy3Qbww2CQ3eGAWkJ4Qik7jgPSfOs51Chfjfd3ba-uKmpS3TNGnvS6Scf3e_s8--O_s11ExzGg7Dc_EcKC3Sr0EpRValWoWdMH0iUl9PSwMY3VxcHF99AekTUSdmIBxqWdFOqJ7E80wwJlwZCeU01TQ7ITI7EbzKCxUO717DguTG1GXNYAhEZgRnmuZ35hOcUpVKVmominsYz4gmM35NX4wGr5i6IGXJikcQPkyTmbvhW2dh_5ZIRgpkf7IQvGdwjkTWRr_fOEsztkR9rbZyzyogo1dUyIzK4M8K2JieOoY7VkxooYWcJpKWnBZMPeUg8JYjoynLCV-iXKHdxn1KNEW8shYnQEJ9shrZZMJ4bP6mYFoFI8EzZFzBb_JtNtmkJJLkG06oRXOZzYCCEuaUEbyUZEIleaTgIuPTpDLQxNrbxoH1RrAvx9lflYJtH1Q7OaUIpyrRkhbZR11DprCD55RkwTXLafAj-KR-Ql7BrsmETDggEg2IDT3w1RoHh4ypngYjk1XwVrDixCYbUJxQvulOWFJs29WXguGjv9jWpZFvyNTQwSRn89PVdnCPU10RHixjNmRdqd_0IBU54NszRz2WuCTwbTsX1ljATpzC5gnEGLl6RXPCCmMEBdoGymw0ZK7kpDC4TWO-zgZ26QLY8ypvWz3nEUxt97VpV274QF428IG8fMCHVuW2dbCnLjgRE5vop8pbhXpdUzNsV3eLNfBV21ZgHTtMYVf2FtW2uf-xqrQa9-uTvUPsm4pts17NC47vxrukiHl_Yy9QbFayjs3wbrxtqm3J9mSa8rYeIjXyLZOt1fFLO-whhbP5vOKZWge7QPkZPa0kFL10urJULhTDt_tOmFOiKknX9E1gHcpaoaAhrRs610sqDT1vXfWGYdyN96PuUdTrwQgUxYa8F0e9rjFFx6TiOpGkeDSDezkIx4xrKnF__A-l1wnhFSDvwoMQpiqgEyGAAVVuu7mW0KDF_0x1sE0ntyDremSP-l2u2X0A7h9EVyhXLHrnuXxBAqWSTajHRL-1tOQmyiWbCJ0Ie6sAOaCs2407yaJ3u7ZN08IH3EkhB3qG2_7NyDhT3kSLKn-AuHbs0K2B1oQsC4eHnfCcPEADMwx_7nW7PwDqtjYdxp-6dm0M7AjBjhqo3qejBarXRbBDD3boYD0EO_BgBw4WI9jAgw0cbA_B-h6s72D7CLbvwfYdrI9gex5sz8EGCBZ7sNjBDhrL24T1HAxHoYlyGBSCqGmqi0IQoxBEsQdzIYhRCKI9D-ZCEKMQRPsezIUgRiGI-h7MhSBGIYgGHsyFIEYhiA48mAtBjEIQHXowF4IYhSA68mA9eyq5IJnNzfbRAKWDX19SXpmhQFQ6EuOofpfIGn0EDCVinKAkSOdq-JwWwj-oChCctmSK1oM6QBH7LNAswPB8rgMUri9UIdAU_tZOdfQknoNrUbp5aVEWjUzH-cdm0EcB6yPf3Abpo1jZMrU4Bi4T9dFZiTEmRhh0VvoY03eYATooZmoOZP5a1urevSaZyiykqd6rXpSCsxxqvFtMZn-XK83_-eXp-xV21sHOOqrlp5B_9d76_bXsP_ZaZvpFg6rbRcgyr_YfWoFOyCBRqEsqL62gD7lMQUL6ZWoS9Xvb5r4GmzQDcJvcQPQoRVVaA_OfGcBcTmB5RgI9q-dwd2BI8tZxWYE-mutKYnIsuJUSnlacmKSZUy1ZqmqTcDQgDkyJon62Hb6-vf0NUsMWYA',
        title: 'Low stock report',
        icon: 'mdi-email-alert',
      },
    },
    {
      feature: ['getting_started_inventory_replenishment'],
      title: 'Inventory Forecasting',
      link: 'https://app.arcade.software/share/lmedvCyXcO0b8fPuvAG1',
      thumbnail: 'inventory_forecasting.svg',
      description: 'Learn how to forecast inventory',
      report: {
        link: '/reports/38/demand-forecasting/609?report_query=eJztWd1v2zYQ_1cEAgM6zGpt2Upiv6UZNhRb1wxO82IEAiPRDldK1EjKnRH4f99Rks0Tbcc29jTAeQjC4-8-eF-6Q16J4UYwMiGfiiUrjFSr4BepWEq14cUieF4Fj1RxWhjSI-kLVeZhVVo4HF-ovrMUMjGqYj2iX-T3ljCnQlsKEyw1LLujKruTosoLTSazV1LQ3Mq4VzKr0lqyzGobDMtn9lfwM9Op4qXhsniC-4wa2io27B_LISquP9OyBCOB-LxKylbYureVjyxv5C-3hCMCi0oIJGj621cnpFWU6G_VWZbVDEjoxs3BlAqmgz8rsIyblVM0m7eIRFtE8neL8BySsZTnVOxoPsD9hgm3uaywu3wDaH3vqU8rpViRro7pb5j3anept3FC8AdjGTKEbxDJ3CXniX7Yy7vXji9LprSR6TeWfQi-Ftn29GZwJHAllUUnNfzc8Pj82LQHaagAU7jRwVQK7JLKEhMNxMS9sBS0KE53zZsysB23ywWyIiiZCjKKvUGXi8RK-48GHRPTsSn7q9LQXI4ZxhRdsCSjXKwS9OAmKw3kbnayccdFYQO3ySyL4IUWOHi3qakgsLuJf6IpB_mx_k9FKnPbxLewTkU1l-d2lR2-vZX0wOG8p1YM0D1FRZU_M3W4PCzLka7xSEXF_JaxFeCyaGlxZ7cvjx_bYjMvuJPaeHWZpEA7VdGWAUueMiFs6O4VT7EnO_QTNXgfM1Qu8HWfAgSyd39j0fXl-c2k4fM95ZR-hHZ3UOlzfXm-0oYPK_1VSa3BVXLOcYQaQvCZqgX3p4uDUeowkfVTj-SM6kqxg18YkAupW2gYYZo5wE0f2sCU1GT2hET9aBT2x-GgDzfQQjr0QQRXVhSb00qYRNFiYS-HORDnXBgonckMrCn5UsK3pJ6YmvGspIrmnXmrU57BO2iU-kfnl27F9UiT7ZMZGfbJU3tsvdTGd38da6nMF5VZw8igbx8lJM1qL9Zj4b4W_lD3zq0puKEiQwa-HYJrc8AKe1X3hcYDPCOTmx75nT4zAbCfwLAfAPXYiCbR-36dOBY2RrBxBzV4P96iBn0Eu_FgNw42QLBrD3btYBGCXXmwKwcbIljswWIHGyHYyIONHCxGsKEHGzrYFYJFHixysOuOe7uwgYPhKHRRDoNCEHZF9VEIIhSCMPJgLgQRCkE49GAuBBEKQTjyYC4EEQpBGHswF4IIhSC88mAuBBEKQXjtwVwIIhSC8MaDuRBEKATh2IMN6qaFi3P0Zm1OYZsLHmTpqtLIssDleCvEKY1hsxbtFmSMnhUjY50bY_Siuj9uk8XVa4wyKsKYCGFQRsUYEzvMFUon-zQHsiffeWP7s-u_J7f0JoqVwACf9svie1l8L4vvZfF9w6DL4ntZfC-LL7ksvv-Dxbe0vaDZNGGqea3PMLT3CIcBRt8zdV8T4n4zMX1cNRuq_dtON3CqZyUgLZSsys19fWgBdr2GHjeV6J8JOey_HFHW8DAq0kpQO2zlzEBm6IYXKhW2X65lAT3LgC2v6_W_5PppUA',
        title: 'View report',
        icon: 'mdi-chart-line',
      },
    },
    {
      feature: ['getting_started_inventory_replenishment'],
      title: 'Reorder Suggestions',
      link: 'https://app.arcade.software/share/aBOFS9Seslx5FSV2meRc',
      thumbnail: 'reorder_suggestions.svg',
      description: 'Learn how to view reorder suggestions',
      report: {
        link: '/reports/36/inventory-replenishment/672?report_query=eJztWe1P4zYY_1eiSJM20ZzaQAv0G4NNOu24Y8AhTQhFJnGLd06cs50eFeJ_32OnrZ-4adcW6XSb-gWw_XveHT8vvISaaU7DYfi-mNBCCzkNrmnJacHUUw4bYSdMn4jUt9PSoGD5RNS52QmHWla0E6on8W22MSJcmR3Kaappdk5kdi54lRcqHN6_hAXJDY8rKbIqtZxFZkVrmt-bH8EFValkpWaieIDzjGgyE6zps6HgFVOXpCxZMYbNx2lSzpi9dhb874hkpED8J4uNf2M4RyJuN398dpxm0hL1pdpKPUuAmF5TITMqgz8rkMb01Em4Z_M4JBLHwXNHRlOWE74kcgV1m-wLoimSK-vtBIRQX1iNbErCeMz-c8G0Cm4EzxBzBcvk68zYpCSS5Bsa1EK5LM2AghJsygh2JZlQScYUVGR8mlQGmlh-2yiwngnW5Sz7u1Jw7YNqJ6UU4VQlWtIie6tqiBVW8AMlWXDLchr8DDqpX5BWcGsyIRMOiEQDYkMNfLLGh0NGVE-DGy3SL_gq2O1EmW0gnFC-6U1YImy71VeC4U9_ca1Ls7-hpAYNFuLeyJYP9yzVFeHBMmZDqSvpmxqkIgd8-8tRnyXuEfi6nQprOGAlLuDyBGIU4JSRE1YYJijQNlDmoiF2JSeFwW0a83U8sEqXID2v8jbvOY3AtN19007c0IE8b6ADeX6DDq3EbX6wX11wLib2oZ8qzwu1X1NzbL27hQ980jYPrJMOJuwqvYW0zfZPq1KrUb_-sneIfZOwzerVckHx3eQuEWK5v7NnSDYrpY7M8W5y20jbHtvzacrbaojU7G_52FoaP7XDHVL4NZ9nPJPr4BYo_0VPKwlJL52uTJULwvD1oRPmlKhK0jV1E3CHtFYoKEjrgs7VkkpDzVtnvWEYd-OjqHsa9XpwAkmxsd-Lo17XsKIjUnGdSFKMzeFhDpsjxjWVUB-DPiWbCJ0IW__WxbYtMhrV8w2U28GtKJ1XtCgLWE0Ir2B5H55xHj7M1jO_KPCCjVRLycqUvjPQWgrLwmF_0Ak_kEdIsMOwD5C7mjP8bcJjEccOYU2bQ2CxwJw4TIwxMcKcIkkY03eYQddhjGkOZFYmjFyQzIbZ9h_oCv32nPLKHAWi0pEYRfb5CLLGkwRHiRglKNXQORn2aiHe5tRBz5nxUSArgPHC1Nhh_qIKgaawWmvqovi8tVXfwjpcCiJrer4xRuOmKUWVP8LFbDUGhfag1-3-hKP7zoUORffgtIHqvTtdoHoowAcnHuzEwZADD4492LGDIR8eDDzYwMEOEazvwdxF7x0h2JEHO3KwPoIderBDB0Mf1kHswWIHO264twnrORiOQhPlMCgEUZNVF4UgRiGIYg_mQhCjEESHHsyFIEYhiI48mAtBjEIQ9T2YC0GMQhANPJgLQYxCEB17MBeCGIUgOvFgLgQxCkF06sF6bZ_ig5t7mBwipMkzq2YfwfscspH7Spldos95PyP5AZqtRVez3C9_1-ZmPy3aT4v206L_4LToB5jV7Acj-8HIfjDyvxqM3Nln1HGv18FHc7pRwVW_w99z1uIkfcKNuPcJLHXiG3pqia6lkg7g9wiMvCRyzAokdF59lvY8ye25JxjyclpPg5qCW2mb9wGVVej981zdqL7QQ7aN19fxWK3SNWTGoqIb6CKphsIhsTODt-qEea3WrY7YBqqNpVBqFoS3qoZ52TavNFe5HslBJ_di19BXd0IGzZi6ovLKbvShg1bQ9P06NeOV1XX5Qw0zTRwAzX_QYQeEVqWlnC9m52ZGCVXijUD_Xc8rrhnaee24louOa3OggwV9UsLTihPTkeZUS5aqmiUUDlCOMiWKuh4fvry-_gMdJ_wS',
        title: 'View report',
        icon: 'mdi-table',
      },
    },
    {
      feature: ['getting_started_inventory_replenishment'],
      title: 'Inventory Planning in Google Sheets',
      link: 'https://app.arcade.software/share/TzNNyZjmK8IQ1rgr5JKH',
      thumbnail: 'inventory_planning_google_spreadsheet.svg',
      description: 'Learn how to plan inventory in Google Sheets',
      report: {
        link: '/spreadsheet-templates',
        title: 'List templates',
        icon: 'mdi-google-spreadsheet',
      },
    },
    {
      feature: ['getting_started_inventory_replenishment'],
      title: 'Replenishment for Bundle Products',
      link: 'https://app.arcade.software/share/l2iD9b4yVW9CCI0Ech46',
      thumbnail: 'replenishment_for_bundle_products.svg',
      description: 'Learn how to replenish bundle products',
      report: {
        link: '/reports/36/inventory-replenishment/672?report_query=eJztWF9P4zgQ_ypRpJPudM2qCaRA31jQSatbdBwUpBNCkUlc8K0TZ22nS4X47jd22nript1SXla6fYmU8W_-eGY8M_ZLqJnmNByHn6oZrbSQ8-CK1pxWTD2VQAimQgYfm6rgNLiUomhyrcJBmD8RqSfz2nBe31xcnF79A9Qnos7MQjjWsqGDUD2JbwvClHBlKJTTXNPijMjiTPCmrFQ4vnsJK1IaUQsNRoEorFWalnfmE5xTlUtWayaqe1gviCYL_Zo-Gw7eMHVB6ppVj0B8mGf1QtjrYCX_lkhGKiR_tiJ8T-ASiaRd_3njJC20ZepLs4M0jEYSr6iQBZXB3w2oYnruxN-xZXwyiePj-aKgOSsJX9O3gbtP9znRFOmVLTkDJdRX1iK7mjAei7-pmFbBteAFEq7gN_u62GxWE0nKHTfUw7muzYCCGvZUEOxKMqOSPFIwkfF51hhoZuW9xYDtQrAtp8W_jYKcD5q9jFKEU5VpSavivaYhUdjAz5QUwYSVNPgVbFK_IasgawohMw6ITANiRwt8ts6pIVOq58G1FvkXnAqWnClDBsYZ5btmwhpjX1ZfCobP_Sqta0PfUVOHBytxtbPn4J7muiE8WMfsqHUjf9eCXJSA768c7VrmisDXt5mwRQI24hySJxDTALeSkrDKCEGBtoEyiYbE1ZxUBrdrzLfJwCZdgPayKfu85yyCre3vm37mjg3keQcbyPM7bOhl7vODPXXBmZjZQj9Xnhdav-Zm2Xr3DT7wWfs8sE07bGFf7T2sfXv_a1NrNea3J3uP2HcZ-3a9WS8Yvp_eNUas9w_2DM1mo9apWd5Pbx9rX7E9m-e8b4bIDf2Nxdby-K0dckjhar7seKbXQRYov6LnjYSml883tsoVY_h6PwhLSlQj6Za5CaRDW6sUTKPtsOkGSaVh4G273jhMhslhNDyJ4hhWoCl26HESxUMjik5Jw3UmSfVoFg9KIE4Z11TCcAz21GwmdCbs8AvrsGqHjM7ovJoyJra9r3yDe_4ApljeAPUujMP7xd_CRZwpbwyumvIBLBjYpVsDbRWyIhwfD8LP5AFa7Tj8PR4OfwHUbSs6TD4MbbgM7ATBTjqo-MPJChUPEezYgx07WIxgRx7syMESBBt5sJGDHSBY6sFSBztEsEMPduhgKYIdeLADBxshWOLBEgc76ri3C4sdDEehi3IYFIKoK2qIQpCgEESJB3MhSFAIogMP5kKQoBBEhx7MhSBBIYhSD-ZCkKAQRCMP5kKQoBBERx7MhSBBIYiOPZgLQYJCEJ14sNhWCS5IYauIvdviCReuvcFE1O4QalFX-PSdcu6fPwWibNHsuTqun78U7SJFtjmvpWgDtsqscsMdzxQlUIIxCcKgBEoxJnWYEcoeszUHMn89vrp3LwGmsAppiu-m14DgUwkl2jmTLX6Ro5Ykb2y1VBSYn88L_4PnheABLpf2MOH5Et-C7eKOO1vj26y0fSXDSruPFA92fa_3jZYVq54IDZfCn48qP-yjSvcV1d39vMm1c17QJe4tQ-w2GZtNgmI4ZbuY8iiFUqbcAfy9JmFZtjHUxqftZAu1_8X-w9QyCBlUZXVJ5aUlpNBhFLSJj3PTPvtT_L6FmEoOIPMCDRRQ2NSWa_mzWDdjPmTQtUCv0yVM4QxRXgeuktLHdivQ7sCWnPC84cT0r5JqyXLVisxFCdYwJaq2Qo1fXl__A0RY6cM',
        title: 'Try this report',
        icon: 'mdi-tshirt-crew',
      },
    },
    {
      feature: ['getting_started_inventory_replenishment'],
      title: 'Inventory Repenishment from Warehouse to Warehouse',
      link: 'https://app.arcade.software/share/4JoNi8tZtsdfiWjvqq4J',
      thumbnail: 'transfer_from_warehouse.svg',
      description: 'Learn how to transfer inventory from warehouse to warehouse for replenishment',
      report: {
        link: '/reports/measures/inventory_transfer_suggestion?report_query=eJztWMtu2zAQ_BVBpwbwoT304lviokDRpnDjpEURBMKGWttEKFHgw61h-N-7pCibjl-ycuhFF0Pa5cyOyF1p4FVquBGYDtNfoHAurcbkDiuBJdfzAktDd0wWdJWD4bLU6SBlc1Dmflk51OTh9vb67jdF56BHLpEOjbI4SPVc_gmBKQjtIiiQGcxHoPKRFLYgtuHjKi2hcFRjJXPLjCsgcxf4YrB4dD_JJ9RM8coJeKI8SYFQ3-BfhxCW61uoKl7OKPi8zKpAth5s-H-C4lBG_ItN4BxhszJim3x92DKFapl-sS3Y4tUR4w0oT7fH-rxJtGVuEBF72N1kYsBYfUB6E2-_uQ3mQBVPsFfD1NELKnhEfIbUh1Jtmev75LvLtiJe1AQR5UPJTTKSer8xMkupjNWpiJtZpbBky2NtEuHihkEhaFkyVpxFe-PXViHWpkgEiNjvZfJNMj-izW6EAo9GZiKkMre6zQS9xsSlrhfABTwLTH5Yelpulsm7qPzVkdL1A5mMl3QGRqrlKx05Ml6AOClljyPW9aWkF5Xb4Ytk8YB6k659kljYZ6mQgabZA4G6lappgGTaQToo2iXY7fcclTaSvWCeuOZvp8g6mO_sLnIidKzlDgvgpTuzjqpUQ9BZ2SuGnXNTsjg6VVNKXj5X-6hzk7Uj4uqogK7TdYblzHy1FNd5xs7RnJqylto6Tdopip23soJST1Epf6ihrVsKMwHroBd19zmawwP4FqndpvAkx6FdrFXFb4KQuGz4d0Dp-mmQFgjaKgfadNZm3zJtZzPUTqPj5-SEtbuu3cTWQeIUrDAZoWaOiEJTLgwqcrhUoeILaTLpHaz_htN6XmJOyypQUOh6WeOQaTcqqZyH6F1y75J7l9y75N4l9y65d8m9S-5dcu-S_5NLrmCGwcKSI135-3T4YZByMp96jGrsAx_fk48l83qzDJ6Wrp0zpTv_PzCFZkraqsn7m7DAeXGrzURG_xoXZKt5FFkPtm4MZ4UfxDwdUlUGglkBzj8XaOjbH1w1zQOZbK5p_-jJSeJqvf4Hix8sjg',
        title: 'Try now',
        icon: 'mdi-truck-delivery',
      },
    },
    {
      feature: ['getting_started_integration'],
      title: 'Google Spreadsheet Integration',
      link: 'https://app.arcade.software/share/TzNNyZjmK8IQ1rgr5JKH',
      thumbnail: 'google_spreadsheet_integration.svg',
      description: 'Learn how to integrate Google Spreadsheet',
      report: {
        link: '/spreadsheet-templates',
        title: 'Try now',
        icon: 'mdi-google-spreadsheet',
      },
    },
    {
      feature: ['getting_started_integration'],
      title: 'QuickBooks Integration',
      link: 'https://app.arcade.software/share/4VSu6yBbrZOOmZh6iVlP',
      thumbnail: 'quickbooks_integration.svg',
      description: 'Learn how to integrate QuickBooks',
      report: {
        link: '/settings/incoming-inventory',
        title: 'Setup now',
        icon: 'mdi-cog',
      },
    },
    {
      feature: ['getting_started_inventory_tracking'],
      title: 'Inventory Tracking',
      link: 'https://app.arcade.software/share/718di3HylET3XM8hJ6so',
      thumbnail: 'inventory_tracking.svg',
      description: 'Learn how to track inventory',
      report: {
        link: '/reports/31?report_query=eJztWEtPGzEQ_ivWnoqUA0WCtrlBeigqVJRXVUXRavCaxMJrL34kjaL89473kXWym7C0KhzKBeHxN983Hns94ywiy61gUT-6soo-ECXJBGRC7ubkQqvEUUtuQXOQNupFdALaXs-zHH5zfn58-ROtEzADPxH1rXasF5mJmpWGexDGW5hg1LJkADoZKOFSaaL-cBFJSD1VKeQFVOINp5alQ_-HfGaGap5ZruQI5xOwUOpb9st7CMfNOWQZl2M03s3jrCRb9lb8wQIK_unK8BRhhQzYrr7e1EylWmweXAe2EB0wnoDO6Rqsd6uJrsyVR8BebeOVBetMS-iVvXtyK58WlZygoWEL6zMUco9wD5lMlK6ZizH55mc7EU8LgoDyRnJLBso0D0bscCqmxVTATZ3WTNL5tmMS-IUHhgmBMPyeOA1yk2Oz0tZFJHAI2E8lrswqPSffHYbA7byWGB5T60CQJmbjY0oY5SmIhuRW__UIqEr9AlsC4OVc_Pg84YZfKDhQacot3idtirSafK5k07E9zbcgHNuVY3-kRh33tNU51L1kFrhoiIbmrlprVDtPELlgmrLwxsSNLEGr7MRZAdqQz1aumzu6jWBHmncGMvWIP45izTsM4Rw3wRmrUnKmKPjKQ941g9-rQ6pgw-qfUZzxqbLxjs-vRKB83KaHtViz1Xiv2wF-gTjCW22iMiJeJEMbUq-RnCdCCPMi1YwM_Ef0AzSbKGfYP85Om-Cr5KhLIMtRL0oZGKfZzvqCAfOUSYNOZf1e9WwgQcwtp6ZczjVuzRc-nngfdg9O2FiDHPspNN1zYZluaTefbojyKwI9I6CWT1mEoStcKGCkCGePLY2HX16REZU3rWiPtZqZuCW3VAmzK7VIn4GGdK1XxoM4I7jiOmyrMhkGeyyEjzQfl6EZ3F-xpU_ixuZXbaHCk6h_eNSLzuCOCUQdIqQoGP5_f8xzxIca8X4_gOBghflYYw5CzEGA-RQohZjDGnO0X2P80mqQH_l8CwVJfoDz14a3VO-NWLNMad-RbXtzkNMUxkFh5eUwSFRl2igkubXZAP-3j5iXeWa8dbtv3e5bt_vX3a6vUnh_lUUKL8ZFPo7673sRxyvLYCQXueEQr1-Dd-jJ3NeWXZ_YqAD6yw6h_qcgtIy1clnuWw3Ked8GYLd5pYKfiVIs3TywLHv17cHG2A3Y2FcEjIiCoE6AP74ps_gkNgUlnmosmdwoGWN6MPzFcvkbpaGimg',
        title: 'Try now',
        icon: 'mdi-package-variant-closed',
      },
    },
    {
      feature: ['getting_started_inventory_tracking'],
      title: 'Low Stock Products',
      link: 'https://app.arcade.software/share/KdAn5M1Yw6lk1JnUKunY',
      thumbnail: 'low_stock_products.svg',
      description: 'Learn how to view low stock products',
      report: {
        link: '/card/view/1276?report_query=eJztWOFP6zYQ_1eiSJM2rXlqAi3QbzzYJDR4YxSQnhCKTOIW7zlxiJ1Chfjfd3ba-uKmpS1P06S9L5Fy_t39znf2-exXXzHFqT_wz8WzJ5VIvnlFKdIqUdLv-MkjKdX1tNCA4c3FxfHVV5A-EnmiB_yBKiva8eWjeJ4JRoRLLaGcJoqmJ6RMTwSvslz6g7tXPyeZNnVZM2gCkWrBmaLZnf54p1QmJSsUE_k9jKdEkRm_oi9ag1dMXpCiYPkYhA_TeOau_9ZZ2L8lJSM5sj9ZCN4zOEcia8M_bqylGVssv1VbuWcUkNErKsqUlt5fFbAxNbUMdyyf0FyJchqXtOA0Z_IxA4ETjpQmLCN8iXKFdhv3KVEU8Za1OAYS6pLVyCYTxmPzNzlT0hsKniLjEn7jp9lk44KUJNtwQi2ay2wa5BUwp5TgUJIJLcmYgouMT-NKQ2NjbxsH1hvBvhynf1cSlr1X7eSUJJzKWJU0Tz_qGjKFHTynJPWuWUa9n8En-QvyClZNKsqYAyJWgNjQA1etsXHIiKqpN9RVBS8FI45NsQHFCeWbroQlxbZVfSkY3vqLZV1o-YZMDR1McjbfXW0b9zhRFeHeMmZD1pX6TQ8SkQG-vXLUY7EtAk_bubDGAnbiFBaPJ0bI1SuaEZZrIyjRJlF6oSFzBSe5xm2a83U2sEsXwJ5VWVv0rEcwtd1j067c8IG8bOADefmAD63KbXEwu847ERNT6KfSiUId10QPm-huEQNXtS0C69hhCruyt6i2zf3PVUerdr_e2TvkvqnYNuvVvOD4brxLipj3d_YCh81K1pEe3o23TbWt2J5ME97WQyRavmWxNTru0Q5rSOJqPj_x9FkHq0C6FT2pSjj0kunKo3Kh6L_dd_yMElmVdE3fBNbhWMslNKR1Q2d7Samg561PvYEfdaP9oHsUhBGMwKHYkIdREIbaFB2Riqu4JPlYD-5lIBwxrmiJ--PvVF4nhFeAvPMPfJiqgE6EAAZUuenmWlKDgv-FKm-bTm5B1nXIxupdrtl9AO4fRFWoVix657l8QQJHJZtQh4k-tbTkOssFmwgVC3OrADmgjNuNO8mid7s2TdPCB9xJIQdCzW3-ZmScSWeieZU9QF47ZuhWQ2tClvqDw45_Th6ggRn4v4bd7k-Auq1N-9GnromNhh0h2FEDFX46WqDCLoIdOrBDCwsR7MCBHVhYhGB9B9a3sD0E6zmwnoXtI9i-A9u3sB6C7TmwPQvrI1jkwCILO2iEtwkLLQxnoYmyGJSCoGmqi1IQoRQEkQOzKYhQCoI9B2ZTEKEUBPsOzKYgQikIeg7MpiBCKQj6DsymIEIpCA4cmE1BhFIQHDowm4IIpSA4cmCh2ZVckNTUZvNogMrBby8Jr_SQJyoViFFQv0ukjT4ChmIxilERpHM1vE9z4W5UCQhOWypF60bto4x9EWgWYHg-1z5K11cqEWgKf2unOnwUz961KOy8lCjyRqXj_GMz6KGE9ZBvdoH0UK7CLt4GthL10F6JMCZCGLRXehjTs5g-2ih6ahak_1pidW9fk_TJLEp9eq96UfLOMjjjbTCZ-V0-af7PL08_rrCzDnbWUS0_hfyr99Yfr2X_sdcy3S9qVN0uQpV5Nf_QCnR8BoVCXtLy0gh6UMskFKTPU12o31s29zVYlxmAm-IGonEpqsIYmP_MAPpyAuEZCvSsnsHdgSHJW8dWBTrW15VY11hwKyE8qTjRRTOjqmSJrE3C1oA8MCny-tl28Pr29g9sDRZi',
        title: 'Low stock report',
        icon: 'mdi-battery-low',
      },
    },
    {
      feature: ['getting_started_inventory_tracking'],
      title: 'Out of Stock Products',
      link: '',
      thumbnail: 'out_stock_products.svg',
      description: 'Learn how to view out of stock products',
      report: {
        link: '/reports/288?report_query=eJztWW1v4zYM_iuGgQEbGh9it-lLvnXtBhS73nV9Aw5FYai20monWz5JzjUo-t9HyUlEK06WpsBwG_KlqKWHfEhREinmJdRMcxoOw8-1jsQoUlpkX4MLKfI60yrshdkTkfp6UhnM1c35-fHlFxh9IurETIRDLWvaC9WT-D4dGBGuzAjlNNM0PyEyPxG8LkoVDu9ewpIURtWUwRCI3AycaVrcmT_BKVWZZJVmoryH-ZxoMuXX9NlI8Jqpc1JVrHyEwYdJWk2Vvfbm-m-JZKRE-sfzgX9SOEMibVd_3DhNU7ZUfa3fZJ4VQEovqZA5lcGfNbAxPXEMd6wc01ILOUklrTgtmXoqYMBbjpxmrCB8gXKJdBf3KdEU8cpmOAUS6pM1yDYTxmP1NyXTKrgSPEfKFXym36bOphWRpFjToQ7JRTYDCirwKSd4KcmYSvJIwUTGJ2ltoKnV9xYDVivBthznf9UKtn1Qb2SUIpyqVEta5u81DanCBn6kJA-uWUGDn8Em9QuyCnZNLmTKAZFqQKxpgS_WOjhkRPUkuDK3Ct4Kdji1lw0IjilfdycsCHbt6gvB8NGfb-vKjK_J1JLBJGez09V1cI8zXRMeLGLWZF0q37YgEwXgu2-OZi51l8C3t5mwQgM24hQ2TyBGyNRLWhBWGiUo0DZQZqMhdRUnpcGtG_NVOrBJ58Be1EXX6jmLwLXN16ZbuGUDeV7DBvL8Dhs6hbvWwZ664ESM7UU_Ud4qNOuamWm7um9YA1-0awVWsYMLm7J3iHb5_nlZajXmNyd7g9i3Bbu8Xs4Lhm_GuyCIeX9nz5BslrKOzPRmvF2iXZftySTjXTVEZsbfeNlaGT-1wx5S-DafZTyT62AXKP9Gz2oJSS-bLE2Vc8Hw9b4XFpSoWtIVdRNoh7RWKihIm4LO1ZJKQ83bZL1hmPSTvah_FMUJzEBSbI3HSRTHRhUdkZrrVJLy0UzuFjA4YlxTievjzbPMmPAaUHdhPwTvBBQfBDAgxm0B1xENswoVGwudClt1wxQAbWnTqtmvoMgPrkXlLNGiKjHlMeeG1H5PSRSsPfeIZ4UyU_rWQBsWlofDwX4v_EgeIK0PwwFAbhvN8L_ZFBZx4BBxH0HgY445dJgEYxKEOUJMGDNwmP2-wxjXHMh8mWXjguR2c9lXD9q4vz1nvDZTgcAPq7x1EcJUKkYpSnB0JoZXtRTvW9T92LnxSSAvQPHc1cRhvlCFQBP4WunqvOS9trXm3DtcgCJvYt8ZY3HblbIuHuA4dDqDQrsT9_s_4eh-cKFD0d05aqHiD0dzVIwCvHPowQ4dDC3gzoEHO3AwtIY7-x5s38F2EWzgwdxGj_cQbM-D7TnYAMF2Pdiug6GDtZN4sMTBDlrL24bFDoaj0EY5DApB1FbVRyFIUAiixIO5ECQoBNGuB3MhSFAIoj0P5kKQoBBEAw_mQpCgEET7HsyFIEEhiA48mAtBgkIQHXowF4IEhSA68mBx11G8d90Wk7mENNltWcclOCsgB7pTyuwnOs7bzswP8MRrdeO86nkha6xJvCC3bUZtm1HbZtR_vBn1A7SCtn2Xbd9l23f5X_Vdbu016rQ338EnM7tWZdXcw_9mK2ehgjW_Yo6A85zIR1Yi1lnVV9n5tLDzHjOkyazp_bSJO2Xb4UFVDrqOPM9bxRC6V96yCKt0LDfpEhJVWdM1bJFUQx5P7Vv9vTZhXcttayK2hmmPUig1DcJ7TcO6ml6Y2VlNKwxeUC_2G96zvZDBI0hdUHlhBwbwclXw2Pp1Ytoa3RXlfQMxDycAmd_KYQQI68pKzT6m86YbCQXblUC_oxc11wyNvPbcM4c-Nq7AqxFsyQjPak7MK7CgWrJMNSohh4M1TImyKY2HL6-vfwNr3fID',
        title: 'View report',
        icon: 'mdi-battery-outline',
      },
    },
    {
      feature: ['getting_started_inventory_tracking'],
      title: 'Days of Inventory Remaining',
      link: '',
      thumbnail: 'inventory_remaining_days.svg',
      description: 'Learn how to view days of inventory remaining',
      report: {
        link: '/reports/212?report_query=eJztWF9P6zYU_ypRpEmb1lw1gRToG4NNQrvsMgpIVwhFJnHBu06caztdI8R333HS1idu2pUiTXvgJVKOf-e_fc6xX3zNNKf-2D8ntfLE1GPFjBZayNqTNCesYMWTP_DTZyL1TV0a5OT28vL0-itQn4k6Mwv-WMuKDnz1LP5eEKaEK0OhnKaaZmdEZmeCV3mh_PH9i1-Q3Ii6kiKrUm0UiMwQLjTN783HO6cqlazUTBQPsJ4RTRb6NZ0bDl4xdUnK0hg49h_rpFwIex2s5N8RyUiB5M9WhH8TuEQiaZPfb62khbZEfaveZF7DgIReUyEzKr0_K9DGdG013K9SkUhaclow9ZwDwQlHRlOWE76mcgN3n-5zoinSK1tyAkqoq6xFdjVhPBZ_WzCtvIngGRKu4Df5vnA2KYkk-Y4O9XCuazMgrwSfMoJDSWZUkicKJjJeJ5WBJo28txiwXQi25TT7q1Kw7b1qL6MU4VQlWtIie69pSBQ28DMlmXfDcur9CDapn5BVsGsyIRMOiEQDYkcLXLbOwSFTqmtvokX6DW-FhpwoQwbGGeW77oQ1xr5dfSUYPvqrbV0a-o6aOjxYycWqTPYc3NNUV4R765gdtW7k71qQihzw_ZWjXUtsEfj-NhO2SMBGLLuGNfUad41loptEmY2GxJWcFAa3a863ycAmXYL2vMr7omctAtf2j00_c8cGMt_BBjJ_hw29zH1xaE6ddyZmTaGvlROFNq6pWW6i-4YYuKx9EdimHVzYV3sPa5_vXza1VmN-e7L3yH2Xsc_rzXrB8P30rjFivb-xOTSbjVqnZnk_vX2sfcX2rE553wyRGvobi23D47Z22EMKV_NlxzO9DnaBcit6Wkloemm9sVWuGP3Xh4GfU6IqSbfMTSAd2lqhYCBtBzo7SyoNM2_b9cZ-NIwOg-FJEEawAk2xQw-jIAyNKDolFdeJJMWTWTzIgThlXFMJ8zHYU7KZ0Ilo5l9Yh9VmyOhMz6sp46Zp76vY4J4_gEGWV0C990P_YfG3CBFnypmEiyp_BAsGzdKdgbYKWeaPjwf-Z_IIrXbs_xwOhz8A6q4V7Uefhk26DOwEwU46qPDTyQoVDhHs2IEdW1iIYEcO7MjCIgQbObCRhR0gWOzAYgs7RLBDB3ZoYTGCHTiwAwsbIVjkwCILO-qEtwsLLQxnoYuyGJSCoCtqiFIQoRQEkQOzKYhQCoIDB2ZTEKEUBIcOzKYgQikIYgdmUxChFAQjB2ZTEKEUBEcOzKYgQikIjh2YTUGEUhCcOLCwqRJckKypIs31FlWoX-cpr8ySJyodiGnQdCcv63Q8WErENEGTDF2y4XNaCPegKkA01bXnmrl-UEcoY38I5AUIXvo6Qun6ShUC1fC31dUJXPK9G1Fav7QoC-zAKefv8yBGCYuRbXaDxChX4RAfA1uJYnRWIoyJEAadlRhjYosZoYNiXLMg89cTqwf77mF6iJCmz2x6-_AucuhGNpis-UXx_ngj-bhsLWetxdVq_dL-n96wPt51_mfvOmZeNKh2XIQq89L8wygw8BkUCnVF5VVDiKGWKShIv9SmUPcn7aGFmOICIPOyC5QnKaqy4Vr-LNbN7AwxmQj06pvDaMsQ5XVgSwF9MtN0Ygor2JISnlacmEqZUy1ZqlqRcB7AGqZE0b4qjl9eX_8BkbGgtg',
        title: 'View report',
        icon: 'mdi-calendar-clock',
      },
    },
    {
      feature: ['getting_started_inventory_tracking'],
      title: 'Inventory by Store Locations',
      link: '',
      thumbnail: 'inventory_by_store_locations.svg',
      description: 'Learn how to view inventory by store locations',
      report: {
        link: '/sfds',
        title: 'View report',
        icon: 'mdi-store',
      },
    },
    {
      feature: ['getting_started_inventory_optimization'],
      title: 'Inventory Optimization',
      link: '',
      thumbnail: 'inventory_optimization.svg',
      description: 'Learn how to optimize inventory',
      report: {
        link: '/board/337',
        title: 'Inventory metrics',
        icon: 'mdi-numeric',
      },
    },
    {
      feature: ['getting_started_inventory_optimization'],
      title: 'Slow-moving Products',
      link: '',
      thumbnail: 'slow_moving_products.svg',
      description: 'Learn how to view slow-moving products',
      report: {
        link: '/reports/56?report_query=eJzVV0tvGjEQ_isrnzkkqUhbbgm5RG1aWpIciqKV2TVgxWtv7TEpQvz3jr0vA0sANQ_1EsXz-L7x7DzMkgAHwUiPDIV6im7UnMtpNASVPEbjRTTQKrUJRPdUcyqBdEgyoxpuF7lzweOMmr6TkB5oyzrEzNRTKZhQYZyECZYAS_tUp30lbCYN6Y2WRNLMYZQMDlmlTnANLBu5P9EVM4nmOXAlH1CfUqAlMbA_zkNYbm5onmPIKBwv4rwEW3Vq_CDyAn9eC_YASitEAFSlYggUrGnwSs7YVPLD46x8Apbhl7sW6Ed7JC46BKCXVHvELeBxrTgCvHJqyY1H2GKBQnoEhfcIPyKTqdINcnGOvjntQcDzAiAM2Y4FNzOWRlcUWoLOK32cFvqApZSss-xwDCh_Ma2iIRXMIOciKKGR7z0n26hzabMx022F2SFUcIqdRC5CimuJNwWlF9EPi0XOYRGwXCRgqYi2bTZYU5bwjIqtG-70J3ujuafCsudC6SsDG2EkVmsmk8X-OJzzrhh-MqBcbAUQig_kfSbtX6mB6LtOmd6oppHXVN8cNqlay1WgS2ycy1YF3UkO1W0rBouyODkifbXDrpQNmRBuCww0T8K7rMn_PWk3VE-5DPDrDtJqgiFmXr_BkzOdMLmdtFbf9Qs-dEjGqLHa4RjXccavuXK68oxJg6umHBjNlmATagXEmsppufQmXAC2ZbDFDmztua_C3oh8OCEYjsLbUKxgtJtCa-e_SGvXrPtIq8Zvmexm39arSTAUPmcbTOx3S-G7L5LzuYJY-SVfJDenmmZrT4QhvimiW5U37KByucYphCP055LBYF2IHduBG_CNX7DwlPS65x1s4jETaNVFk2JcuP9dLrzFx8bi9CQwwUNt86mxOQttzgKbzwFTaNNtbM5PGht3tcbInVzahKKpL3z_yHKS6pkVa5Yr7Rr8_31qvcoj6K1m9Etu-fff7O-1zV9tg7_eNn3FDfq2W9PPZTpl5VjGGbL0Z9I77RCO48IMmB54QRcnlcFxc7lwYzis8IdC4QYLqvyYQtFUK5t74-pQGrjlbA0MVfDLMcO1ywPJqtMMAzbFdQ2xm5YYQkJFYgV14y9jgMk1BWSiMlwn3ChZtGlvuVr9BeugEb0',
        title: 'View report',
        icon: 'mdi-timer-sand',
      },
    },
    {
      feature: ['getting_started_inventory_optimization'],
      title: 'Dead Stock Products',
      link: '',
      thumbnail: 'dead_stock.svg',
      description: 'Learn how to view dead stock products',
      report: {
        link: '/reports/207?report_query=eJztV0tz2jAQ_isen8kM0JI23BJyaKbthJYkFybjWWwFNJElR1rRehj-e1eysYUhk-TWAxwyaB_fp31ol2xi5ChYPI6vGWTRDFX6HC3KaKpVZlOMHkBzkBj34nQFGu_KwtnScQVm4iTxGLVlvdis1J9a8ATCOAkTLEWWTUBnEyVsLk08nm9iCbnDqBkcssqc4AZZPnd_omtmUs0L5Eo-kj4DhJoY2V_nISw3P6EouFyScFEmRQ227TX4wc0r_HUjeANQWiECoNn3-xakJkrMs_3QzbxDAHoF2iMeAC8axQfAd04Bwa6AU7sQ3KxYFl0DHuErdvokq_QBbS3Zp33FMaC-lxxNNFMia-nm1gkTQ8JOSTOW8hzEAU_rEGJXDXoro28gQ_jLFC2I6EaumUSly-iXpVpzLN_J9qp_SP4DDEa_Wcr4-iCfc13LfTq6rEcS2emyfWyfwgBckDZpGF7eHdaBXxjO5dJHUYZEjaxDIG2-YPowbQ1Ep_7RRBnslD9JSdbBTa3WTKbl0fJ7h73qMyFITdOJp2Hu9-TvZPD5f-zFOQNjtbOEJSkS4zrMQfCcSUMjqHpq7fQwSFMuQe5vNOwPP58NBmeDIWmYzPblQ1I5KPYEVlAhQC6d8lPfte4TF0gpDUbimwVZg7AknRNCTFdXBdNA3Up2y85ci-qCHZkIMwS05nASmJ28YaFHQX3TYWIvR2ZTQNM-Iddu73yhDeVbce0a3VWu4GuFifJ7olpKBWjI97bMjNZSdKeK9iKoCrkXpBCO059rEkN9IF4ZwdzggzOtWHgWj0fnPXq9CybIakQmDxUyfXdZ8RZfWotBPzChQ2PztbUZhjbDwOYiYAptRq3Neb-1caG1Ru7k0maUxlududaLL9zHhaUg86_C725vVW9vmh4FOdBTPG3w0wY_bfDTBv_vNngBS1avAZpPG3-Ox4NezGkUmSnTUy8Y9avZd1XSEKvnoJtTdPJTj0RLrWyx0_tDbeB-J1iDMxX8f5PTSueBZEvRgEitADc2c4YUiql8U5XTauJGyeqpjTfb7T-PR4t_',
        title: 'View report',
        icon: 'mdi-skull',
      },
    },
    {
      feature: ['getting_started_inventory_optimization'],
      title: 'Aging Stock Products',
      link: '',
      thumbnail: 'aging_stock.svg',
      description: 'Learn how to view aging stock products',
      report: {
        link: '/reports/562?report_query=eJxVksFu2zAMhl_F0NkFnHTp1tyy7DJgwDq06KUoDFZiHGGypElUNiPIu490vNkNcjB_fuJPSjwrsuRQbdWus76rMgX9s4opmKKpOkGy4CmrWukjJHoaoqAcHiHvRVFbSgVrlY_h9yQcwGVR0KEmNHtIZh9c6X1W25fXWvUIuSSpA2LZjpZc0tgefbbBc-ZtaCdvTmTiui1xmjPrZv3hZrW6Wd1yBr15r6_5L6XwAMVRm8B3krxtDKsH6wgTN3FWHsZDX_0JPYU0VL9okCGDEfllp6mAq-b0j8KtWBpeGTqBK0y9qEbxNCFiAmb4WCfNGiCYrsmgtj04dWEs2lOgNkS6zsdghAR9XnbzyHdYPYU4N0Ih-qXjzjnxHOPJJPMV8vu9Myb8I604m-lZ0KuLNWq7uavVN3hDx9SGkedrZf6-1BPxcSZWzQLh4D_zaWbWS2a9YO4XTktmMzN3zczIaDMkkVxbDom-JyOvpu7lJ2MFMDz0tGgjNa1amzDygVYv1y1Ch9PFs3IeY7Vd1coS9vkB08MobJqr2-fheky-v2DWHI0-LHUplPgvPwYTIEtdMj2Gxfr3vH92oVz4UcHp4kAa7ZGS1VOHOvS8DDYH3_Ijci_ny-UvoDgp0w',
        title: 'View report',
        icon: 'mdi-clock-alert',
      },
    },
    {
      feature: ['getting_started_inventory_optimization'],
      title: 'Sell-through Rate',
      link: '',
      thumbnail: 'sell_through_rate.svg',
      description: 'How to Improve Sell-Through Rate',
      report: {
        link: '/reports/186?report_query=eJxVUctqwzAQ_BWjswNx0l58bPoBISm9lCI28sYW6IW0SjEh_96Vo5QUdNDOaGdnVldBmgyKXhzRmBVN0edxag5A2JzmZh_9kBU1nxA1OBKtUBNE-phDaeFygrQriOgpZmxFmvxPBc5gUkHQoCIcdhCHnTfZuiT6r-9WWISUY9HhJ0bW0TLyaBYetEWXtHfMn2Z5-TOQiNUlMc3MZr15WXXdqtsyg274j2_4FCk8QzbEym4s5HY9MHrWhjDerQR98SR9oPs8ZgNEsNXoI4GMGDzPVs8pAoxYOxm5LrXou1ZoQpv2GPcL8LpmHW5-m6sm398xKa6WPTE0cv7w4JeiPii7yomO_mmrlgPpJ-TGPwNGZQPFqEWKWlWHyltOo5N3cii77a-32y_aUqvr',
        title: 'Improve now',
        icon: 'mdi-trending-up',
      },
    },
    {
      feature: ['getting_started_inventory_optimization'],
      title: 'Stock Availability Rate',
      link: '',
      thumbnail: 'stock_availability_rate.svg',
      description: 'How to Improve Stock Availability Rate',
      report: {
        link: '/reports/668?report_query=eJxVUctqwzAQ_BWjswNx0l58a9MPMKT0UorYSJtEVC-kVYoJ-feuHKe4oIN2Rjszu7oKMmRR9GJPQX03LxcwFg7GGhqbw9gMKeiiqPmAZMCTaIU6Q6L3MdYeLs-QdxURPaWCrcjn8DMDR7C5ImhREeodJL0LtjifRf_51QqHkEuqOrl6S1h4s7I2Dn02wfODwygvfwkysbwkppnZrDdPq65bdVtm0Ov_-IZPlcIjFEsygT9VcrvWjB6NJUz3LNFcAskQ6e7HbIQEbk76GEEmjIG91XKMCCecOxm5TrXou1YYQpcHTMMEPK9Zh5tfx1mT72-YFVfTohg6pVDig5-K-UFdVsm0D4u1Oh7ILJAbfw1YVSzUoA4pGTUnVMHxNCYHLzWzHPJ2-wW21azj',
        title: 'Improve now',
        icon: 'mdi-trending-up',
      },
    },
    {
      feature: ['getting_started_inventory_optimization'],
      title: 'Stockout Lost Revenue Analysis',
      link: '',
      thumbnail: 'stockout_lost_revenue_analysis.svg',
      description: 'How to minimize stockout lost revenue',
      report: {
        link: '/board/372',
        title: 'Analysis now',
        icon: 'mdi-currency-usd',
      },
    },
    {
      feature: ['getting_started_sales_analytics'],
      title: 'Sales Analytics',
      link: '',
      thumbnail: 'sales_analytics.svg',
      description: 'Learn how to analyze sales',
      report: {
        link: '/board/117/public',
        title: 'Sales overview',
        icon: 'mdi-chart-bar',
      },
    },
    {
      feature: ['getting_started_sales_analytics'],
      title: 'Sales vs Inventory Matching',
      link: '',
      thumbnail: 'sales_inventory_matching.svg',
      description: 'Learn how to analyze sales and inventory together',
      report: {
        link: '/reports/measures/sales_on_hand_inventory?report_query=eJztWE1v2zgQ_SsCgd5cwHY33a1vqQsUQbdttk57MQKBkcY2UYrU8sNbI8h_75CSrLHktPJxAQVBYD7OvDccSuRzHpkTTgJbsBWXYJO9TW7UHpTT5pB85C7bCbVlE5btuHF3hzJE4nDH7TIgbOGMhwmzO_1fDWy4tAEBCZmDfMlNvtTSF8qyxfqRKV4Ejlujc5-55KbgWwj8Og-wqIc5d7xWayDphf3IyzLUs2BllZ9Ws0-TLnFLeeOgWIc_yTuwmRGlE1rdn2o4-OF6Eg-HtFah_N-4EVwR_v0R-A2h8lISotWHry1Jsxz73V9UWUwgpG-5iYw94ofjxAXkTVK_vcnKceftmQU0-CVrqHLOqESCnoar0AsUYgbdRFC5Ni1zNU4-hdlBxPuKgFB-NmIrFJfJrREZKVrXONZS4YQ988aAyg49hU4SfWpASozpqqxP8PthMp0H8qsSLllqS57ttUcszRAbSMmOCbToO-2wMfGAIdzvjba2Qoeyu0CU2kh00vscjMXaPX0x1zrAWAyiHQHliwcwZ9p-TGCdxmChWuaE_B-PL71wh4h36HPI8FySPf4QmjSJVOETuOSsigKX_lsnpHa4UsgLW2FjEpUiXe_txXVxplfPbgZlIgLvhI0drNmISDMzVKCJp-xfwHmj-twn-FCBL7DxKoe8oevsSLdJR2wof0tCXwb-o19-Cw7lJjSEfPn5Pa0409vBxYbY_oOCJ_FG0EorAK2BwRNqKPdJUuegbyeSF8_pJC86SiWYDFT_aC5jVlr0hFpTc3z_Wq3rzHk8oPoxA9-2Z_PPV_CNSw-_kl9ecOCeTe68MVzIniiFL7kv7iesAG69CZHxJE61Sndc5aloagh0ogBl0WnV9-XRJOFlb_BCFrG2-XT-x8vZ7OXsFc7glXqKz_E3UMGGe-lSw9U2TL6ahhNyI6TDI3yxxoJKsdcu1dHZxTsIU4SCEFZyw4sT57lCq5rc6bLthdOlwtE-dmixZtdSsvt6XLfEYgPkM95AWBe7WKmInC2uXk_Y3_wBJEZdYUjV-_A57EuM-LONmE1JCA6OMX-1MXMaMycxb4gSjblqY15P25iwtDYojMKGWm1cvEMRexN-wrI0z-OuRz8fo2pHnxooMQHvydHVj65-dPWjqx9d_ejqR1c_uvrR1f-PXX2Jlqv20OjpHuOYLWYTJtBl2VswtxG4mlZ-8e2h8t7hc7BgOIpOEaGt0b5s5uOgDgjfHbx1K03-T1ygtRcEecLFcJl5yYPVLMDh1Wer3EwXaOeFxe8buC6s5fHp6SeMOrtG',
        title: 'Analyze now',
        icon: 'mdi-chart-line',
      },
    },
    {
      feature: ['getting_started_sales_analytics'],
      title: 'Sales by Locations',
      link: '',
      thumbnail: 'sales_by_location.svg',
      description: 'Learn how to analyze sales by locations',
      report: {
        link: '/reports/measures/sales_overview?report_query=eJztWE1P20AQ_SuWJW5BSkKhbW5pULlAoUB7iZC12JOw6nrX3Y9AivjvnVnb8doOkrmn6iH7ZuY97-zY-8RrbLkVEM_iOybARI_b6FKlzHIl41GcPjFt77cFxXH5xMyCkHhmtYNRbJ7UcwWsmDCEgIDUQrZgOlso4XJp4tnyNZYs9xpWaWgJqIzwGlnWPx4wljHLKm0LLxYR4bi5YkXB5RrBx20iaqa30U7jWmegTbRQTtpGYenhEu2QZ5DynIkef1AR8v-S3JroToksYP_pmMRGbj0-kJ9So7owVPgBNtqrIsEmf6uCxAxXojpHhL4olLrQyqAMHX2gU6LzfE-zUqc1yHTb0wiZAoFzblKiqdgCkToyVKDOD9lvwTot-9wtfKjALayczCCr6Ton0m3SDhvK35AEzHdP3Mej7wAB-Q7-2BY6ZaHQPXvp96kBhyoENOEpO8tbzckcTij7EHNQElLPs4zTK85EtGq1iO0CCQaGqrSr9sxSW0R78CMCTUWr-8riBva_Zx8aIktEiemO0eL6IiRO1XowI-X2Pwk3Wq14OColEF0xvebdz_O73K2iUKQViI7e04mOOkoF6BRk_y4ofFWS94TmG9BsDVH5Mf_NhGvN0GadKIokG4oM3dY-0t4NgY8a3WsmDUvbl51XfWTmD36VDf_XVX3vI96pap2-M1bl-269clzSOj5QyrNENWtrjnmx5yuyAwdPMS92r_rDKM6BGaepwg92orC_Gw7PxMZzkIb6177w0WpYNB6J5f65puPpp-PJ5HhyghGQWRuf4n_ighVzwiZ4KGsKnozpcl1xYXGTsyU-SME3yiaqsKUgXQcrLoHSCqZZ3jYz6H6ie1U0nbCqoCfzs4SZ8VyI-KFaVy0x2AAB-90NN9bPUqnCs3h2ejaKL9kjCMw6xZRy1Og3nYnP-NxkTMZBCi52OV-anGmYMw1yvgZKYc5pk3M2bnJoa00SreggjdLWvxSIfaV_tC3FMn_q3iL6rMokJhoKLEjSg1E8GMWDUTwYxYNRPBjFg1E8GMV3jWKBzazsGdqFV7-OZ5NRzC3k5gb0jQdOx6UV-bYtbR39PgeT4sqbEITWWrmijvtFlUB2FPdzp4K_auXoGnmAvOGWmEidYORicrCap6asTVWOTpEbJRPcHz7L69vbf2EzkGA',
        title: 'Analyze now',
        icon: 'mdi-warehouse',
      },
    },
    {
      feature: ['getting_started_sales_analytics'],
      title: 'Sales by Locations',
      link: '',
      thumbnail: 'sales_by_location.svg',
      description: 'Learn how to analyze sales by locations',
      report: {
        link: '/reports/measures/sales_on_hand_inventory?report_query=eJztWE1v2zgQ_SsCgd5cwHY33a1vqQsUQbdttk57MQKBkcY2UYrU8sNbI8h_75CSrLHktPJxAQVBYD7OvDccSuRzHpkTTgJbsBWXYJO9TW7UHpTT5pB85C7bCbVlE5btuHF3hzJE4nDH7TIgbOGMhwmzO_1fDWy4tAEBCZmDfMlNvtTSF8qyxfqRKV4Ejlujc5-55KbgWwj8Og-wqIc5d7xWayDphf3IyzLUs2BllZ9Ws0-TLnFLeeOgWIc_yTuwmRGlE1rdn2o4-OF6Eg-HtFah_N-4EVwR_v0R-A2h8lISotWHry1Jsxz73V9UWUwgpG-5iYw94ofjxAXkTVK_vcnKceftmQU0-CVrqHLOqESCnoar0AsUYgbdRFC5Ni1zNU4-hdlBxPuKgFB-NmIrFJfJrREZKVrXONZS4YQ988aAyg49hU4SfWpASozpqqxP8PthMp0H8qsSLllqS57ttUcszRAbSMmOCbToO-2wMfGAIdzvjba2Qoeyu0CU2kh00vscjMXaPX0x1zrAWAyiHQHliwcwZ9p-TGCdxmChWuaE_B-PL71wh4h36HPI8FySPf4QmjSJVOETuOSsigKX_lsnpHa4UsgLW2FjEpUiXe_txXVxplfPbgZlIgLvhI0drNmISDMzVKCJp-xfwHmj-twn-FCBL7DxKoe8oevsSLdJR2wof0tCXwb-o19-Cw7lJjSEfPn5Pa0409vBxYbY_oOCJ_FG0EorAK2BwRNqKPdJUuegbyeSF8_pJC86SiWYDFT_aC5jVlr0hFpTc3z_Wq3rzHk8oPoxA9-2Z_PPV_CNSw-_kl9ecOCeTe68MVzIniiFL7kv7iesAG69CZHxJE61Sndc5aloagh0ogBl0WnV9-XRJOFlb_BCFrG2-XT-x8vZ7OXsFc7glXqKz_E3UMGGe-lSw9U2TL6ahhNyI6TDI3yxxoJKsdcu1dHZxTsIU4SCEFZyw4sT57lCq5rc6bLthdOlwtE-dmixZtdSsvt6XLfEYgPkM95AWBe7WKmInC2uXk_Y3_wBJEZdYUjV-_A57EuM-LONmE1JCA6OMX-1MXMaMycxb4gSjblqY15P25iwtDYojMKGWm1cvEMRexN-wrI0z-OuRz8fo2pHnxooMQHvydHVj65-dPWjqx9d_ejqR1c_uvrR1f-PXX2Jlqv20OjpHuOYLWYTJtBl2VswtxG4mlZ-8e2h8t7hc7BgOIpOEaGt0b5s5uOgDgjfHbx1K03-T1ygtRcEecLFcJl5yYPVLMDh1Wer3EwXaOeFxe8buC6s5fHp6SeMOrtG',
        title: 'Analyze now',
        icon: 'mdi-warehouse',
      },
    },
  ];
  public async created() {
    if (this.activeGuides.length > 0) {
      this.canShowGuide = true;
    }
  }
  public get activeGuides() {
    // Filter the guides based on the feature prop
    // if the feature prop is not provided, return all guides
    if (this.feature.length === 0) {
      return this.guides;
    } else {
      return this.guides.filter((guide: any) => {
        return this.feature.some((feature: string) => {
          return guide.feature.includes(feature);
        });
      });
    }
  }
}
