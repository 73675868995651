
import {Component, Prop, Vue} from 'vue-property-decorator';
import Installing from '@/components/Installing.vue';
import { post } from '@/services/http';
import {StoreModel} from '@/models/store';
import {displayAlert} from "@/services/alert";


@Component({
  components: {
    Installing,
  },
})
export default class Oauth2Callback extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;

  public async mounted() {
    let email: string = '';
    try {
      let paramCode = this.$route.query.code.toString();
      paramCode = paramCode.replaceAll('assisty_slash', '/');
      email = await this.saveOauth(paramCode);
    } catch (e) {
      // skipped
    }

    const currentFullPath = sessionStorage.getItem('current_full_path');
    if (currentFullPath) {
      sessionStorage.removeItem('current_full_path');
      displayAlert(`Connected Google account ${email} successfully`);
      await this.$router.push({path: currentFullPath});
    } else {
      await this.$router.push('/');
    }
  }

  private async saveOauth(authorizedCode: string) {
    const redirectUrl = `${process.env.VUE_APP_HOST}/oauth2callback`;
    const res: any = await post('/token/google_oauth', {
      code: authorizedCode,
      redirectUri: redirectUrl,
    });
    if (res.data) {
      return res.data['email'];
    }
    return '';
  }
}
