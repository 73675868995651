
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { BillingPackageModel } from '@/models/billing_package';

@Component
export default class CountdownOffer extends Vue {
  public minutes: number = 0;
  public seconds: number = 0;
  public offerTime: number = 0;
  @Prop({ default: 14 })
  public trialDays!: number;
  @Prop({ default: 0.2 })
  public discount!: number;
  @Prop({ default: new BillingPackageModel() })
  public billingPackage!: BillingPackageModel;
  @Prop({ default: 0 })
  public offerAt!: number;
  public claiming: boolean = false;
  public isFinished: boolean = true;

  public async created() {
    this.offerTime = Number(localStorage.getItem('offer_time'));
    if (this.offerTime === 0) {
      let timestampNow = this.toTimestamp();
      if (this.offerAt > 0) {
        timestampNow = this.offerAt;
      } else {
        this.$emit('offered', timestampNow);
      }
      localStorage.setItem('offer_time', `${timestampNow}`);
    } else {
      if (this.offerTime !== this.offerAt) {
        localStorage.setItem('offer_time', `${this.offerAt}`);
      }
    }
    const refreshId = setInterval(() => {
      const startTime = Number(localStorage.getItem('offer_time'));
      const timestampNow = this.toTimestamp();
      this.offerTime = 3600 - timestampNow + startTime;
      if (this.offerTime > 0) {
        this.minutes = Math.floor(this.offerTime / 60);
        this.seconds = this.offerTime - this.minutes * 60;
        this.isFinished = false;
      } else {
        this.isFinished = true;
        clearInterval(refreshId);
      }
    }, 1000);
  }

  public toTimestamp(strDate: string = '') {
    let datum: number = 0;
    if (!strDate) {
      datum = Date.now();
    } else {
      datum = Date.parse(strDate);
    }
    return Math.floor(datum / 1000);
  }

  public claim() {
    this.claiming = true;
    this.$emit('claimed', this.billingPackage, this.trialDays, this.discount);
  }
}
