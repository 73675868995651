import { get, post } from '@/services/http';
import { UserModel } from '@/models/user';
import List from './list';

export class UsersList extends List<UserModel> {

  public async fetch() {
    const res: any = await get(`/users`, {});
    const users = res.data || [];
    for (const data of users) {
      const user = new UserModel();
      user.mapData(data);
      this.add(user);
    }
  }
}
