interface Column {
  name: string;
  values: any[];
  originalValues: string[];
  dataType: string;
}

export class PivotDataModel {
  public row?: Column;
  public column?: Column;
  public value?: Column;
  public summarizeType?: string;
}
