import { get, post, put } from '@/services/http';
import { PluginPackageList } from '@/collections/plugin_packages';
import { PluginRuleList } from '@/collections/plugin_rules';

export class PluginModel {
  public id: number = 0;
  public code: string = '';
  public name: string = '';
  public status: string = 'ACTIVE';
  public icon: string = '';
  public tagline: string = '';
  public packages: PluginPackageList = new PluginPackageList();
  public rules: PluginRuleList = new PluginRuleList();
  public introduction: string = '';
  public description: string = '';
  public helpLink: string = '';
  public images: string[] = [];
  public features: string[] = [];

  public mapData(data: any = {}) {
    this.id = data.id;
    this.code = data.code;
    this.name = data.name;
    this.icon = data.icon;
    this.tagline = data.tagline;
    this.introduction = data.introduction;
    this.description = data.description;
    this.helpLink = data.helpLink;
    this.images = data.images;
    this.features = data.features;
    this.status = data.status;
  }

  public async fetch() {
    const res: any = await get(`/plugin/${this.id}`);
    this.mapData(res.data);
    await this.packages.fetchByPlugin(this.id);
    await this.rules.fetchOnePlugin(this.id);
  }

  public async save() {
    if (!this.id) {
      const res: any = await post('/plugin/create', {
        code: this.code,
        name: this.name,
        icon: this.icon,
        tagline: this.tagline,
        introduction: this.introduction,
        description: this.description,
        helpLink: this.helpLink,
        images: this.images,
        features: this.features,
        status: 'ACTIVE',
      });
      this.mapData(res.data);
    } else {
      const res: any = await put(`/plugin/${this.id}`, {
        code: this.code,
        name: this.name,
        icon: this.icon,
        tagline: this.tagline,
        introduction: this.introduction,
        description: this.description,
        helpLink: this.helpLink,
        images: this.images,
        features: this.features,
        status: this.status,
      });
    }

    for (const pluginPackage of this.packages.items) {
      pluginPackage.save();
    }
    for (const pluginRule of this.rules.items) {
      pluginRule.save();
    }

  }

  public async changeStatus() {
    if (this.status === 'ACTIVE') {
      const res: any = await put(`/plugin/${this.id}/inactive`);
      this.status = 'INACTIVE';
    } else if (this.status === 'INACTIVE') {
      const res: any = await put(`/plugin/${this.id}/active`);
      this.status = 'ACTIVE';
    }
  }

  public get hasCustomIcon() {
    return this.icon && !this.icon.includes('mdi-');
  }
}
