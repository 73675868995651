
import { Component, Prop, Vue } from 'vue-property-decorator';
import HeaderTitle from '@/components/HeaderTitle.vue';
import ActionButton from '@/components/ActionButton.vue';
import { SpreadsheetConnectedModel } from '@/models/spreadsheet_connected';
import { StoreModel } from '@/models/store';
import { SpreadsheetConnectedList } from "@/collections/spreadsheet_connected";
import { EventBus } from "@/main";

@Component({
  components: {
    HeaderTitle,
    ActionButton,
  },
})
export default class SpreadsheetTemplates extends Vue {
  @Prop({ default: null })
  public app!: any;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public isLoading = false;
  public spreadsheets = new SpreadsheetConnectedList();
  public templateHeaders: any = [
    { text: 'Title', value: 'title' },
    { text: 'Actions', value: 'actions', width: '300px' },
  ];
  public editingSheet: SpreadsheetConnectedModel = new SpreadsheetConnectedModel();
  public dialogCloneTemplate: boolean = false;
  public dialogDisconnectSpreadsheet: boolean = false;
  public selectedGoogleAccount: string = '';
  public selectedDriveFolder: string = '';
  public cloneTemplateLoading: boolean = false;
  public loadingItems: any = [];
  public disconnectLoading: boolean = false;
  public async created() {
    this.isLoading = true;
    await this.spreadsheets.fetch();
    for (const item of this.spreadsheets.items) {
      this.loadingItems.push(false);
    }
    this.isLoading = false;
  }

  public async refreshData(item: SpreadsheetConnectedModel, index: number) {
    this.updateLoading(index, true);

    await item.refreshData();
    EventBus.$emit("show-snackbar", "The "+ item.title + "spreadsheet data has been refreshed successfully.", "success");
    this.updateLoading(index, false);
  }

  public updateLoading(index: number, value: boolean) {
    this.loadingItems[index] = value;
    let newLoadingItems: any = [];
    for (const item of this.loadingItems) {
      newLoadingItems.push(item);
    }
    this.loadingItems = newLoadingItems;
  }

  public showDialogDisconnectSpreadsheet(item: SpreadsheetConnectedModel) {
    this.editingSheet = item;
    this.dialogDisconnectSpreadsheet = true;
  }
  public async disconnectSpreadsheet() {
    try {
      this.disconnectLoading = true;
      await this.editingSheet.disconnect();
      for (let i = 0; i < this.spreadsheets.items.length; i++) {
        if (this.spreadsheets.items[i].scheduleId === this.editingSheet.scheduleId) {
          this.spreadsheets.items.splice(i, 1);
          break;
        }
      }
      this.editingSheet = new SpreadsheetConnectedModel();
      this.disconnectLoading = false;
    } catch (error) {
      EventBus.$emit("show-snackbar", "An error occurred while disconnecting the spreadsheet.", "error");
      this.disconnectLoading = false;
    }
    EventBus.$emit("show-snackbar", "The spreadsheet has been disconnected successfully.", "success");
    this.dialogDisconnectSpreadsheet = false;
  }
  
}
