
import { Component, Prop, Vue } from 'vue-property-decorator';
import { get } from '@/services/http';
import Config from '../models/config';
import List from '../collections/list';
import HeaderTitle from '../components/HeaderTitle.vue';
import CountdownOffer from '../components/CountdownOffer.vue';
import { StoreModel } from '@/models/store';
import { isShopifyEmbedded } from '@shopify/app-bridge/utilities';
import { Redirect, History } from '@shopify/app-bridge/actions';
import { BillingPackageList } from '@/collections/billing_packages';
import { BillingPackageModel } from '@/models/billing_package';
import { UserPackageModel } from '@/models/user_package';
import dateFormat from 'dateformat';
import { EventHandler } from '@/modules/events';
import { EventBus } from '@/main';
import StatusPageComponent from '@/components/StatusPage.vue';
import { UserPluginList } from '@/collections/user_plugins';
import { PluginList } from '@/collections/plugins';
import ActionButton from "@/components/ActionButton.vue";


@Component({
  components: {
    HeaderTitle,
    CountdownOffer,
    StatusPageComponent,
    ActionButton,
  },
})
export default class Subscription extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public success: boolean = false;
  public error: boolean = false;
  public loading: boolean = false;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: null })
  public app!: any;
  public downgradeDialog: boolean = false;
  public offerDiscountDialog: boolean = false;
  public downgrade: boolean = false;
  public isFree: boolean = false;
  public hasPermission: boolean = true;
  public headers: any = [
    {
      text: 'Attribute',
      align: 'start',
      value: 'attribute',
    },
    {
      text: 'Value',
      align: 'end',
      value: 'value',
      cellClass: 'font-weight-bold',
    },
  ];
  public pluginHeaders: any = [
    {
      text: 'Plugin Name',
      align: 'start',
      value: 'name',
    },
    {
      text: 'Price',
      align: 'end',
      value: 'price',
      cellClass: 'font-weight-bold',
    },
    {
      text: 'Action',
      align: 'end',
      value: 'action',
      cellClass: 'font-weight-bold',
      width: '100px',
    },
  ];

  public packageDetailed: any = [];
  public discount: number = 0.9;

  public billingPackages: BillingPackageList = new BillingPackageList();
  public userPackage: UserPackageModel = new UserPackageModel();
  public filteredBillingPackages: BillingPackageList = new BillingPackageList();
  public freeBillingPackage: BillingPackageModel = new BillingPackageModel();
  public paidBillingPackage: BillingPackageModel = new BillingPackageModel();
  public submitting: boolean = false;
  public userPlugins: UserPluginList = new UserPluginList();
  public plugins: PluginList = new PluginList();
  public installedPlugins: any = [];
  public totalPrice: number = 0;
  public totalPluginPrice: number = 0;
  public isYearly: boolean = true;
  public downgradeReason: string = '';
  public canSubscribePaidPlan: boolean = true;
  public async created() {
    this.loading = true;
    if (this.$route.query.discount) {
      this.userPackage.discountCode = this.$route.query.discount + '';
    }
    try {
      this.canSubscribePaidPlan = await this.store.canSubscribePaidPlan();
      await this.loadCurrentPackage();
      await this.billingPackages.fetch();
      this.getBillingPackage();
      await this.plugins.fetch();
      await this.userPlugins.fetch();
      this.generateInstalledPluginData();
      this.generatePackageDetailed();
      this.error = false;
      this.success = true;
    } catch (e: any) {
      this.error = true;
      this.success = false;
      if (e.response && e.response.status === 403) {
        this.hasPermission = false;
      }
    }
    this.loading = false;
  }

  public processDowngrade(item: BillingPackageModel) {
    this.offerDiscountDialog = false;
    this.openChatAfterDowngrade();
    this.submitPackage(item, 0);
  }

  public openChatAfterDowngrade() {
    let message: string = 'The reason for my downgrade: ' + this.downgradeReason;
    // @ts-ignore: Unreachable code error
    this.$crisp.do('chat:open');
    // @ts-ignore: Unreachable code error
    this.$crisp.do('message:send', ['text', message]);
  }

  public calcualtePackagePrice(packagePrice: number) {
    let isYearly: boolean = true;
    if (this.userPackage.name === 'Free') {
      isYearly = this.isYearly;
    } else {
      if (this.userPackage.interval === 'EVERY_30_DAYS') {
        isYearly = false;
      } else {
        isYearly = true;
      }
    }
    let price: number = packagePrice;
    if (isYearly) {
      price = (10 * price) / 12;
    }
    return price.toFixed(1);
  }

  public calcualteYearlyPrice(packagePrice: number) {
    let price: number = packagePrice;
    if (this.isYearly) {
      price = (10 * price) / 12;
      return price.toFixed(1);
    }
    return price;
  }

  public removePlugin(pluginId: string) {
    this.$router.push(`/order_confirm?uninstallingId=${pluginId}`);
  }

  public generateInstalledPluginData() {
    this.totalPrice = this.store.currentPackage.price;
    this.totalPluginPrice = 0;
    for (const userPlugin of this.userPlugins.items) {
      for (const plugin of this.plugins.items) {
        if (userPlugin.pluginId === plugin.id) {
          this.totalPrice = this.totalPrice + userPlugin.price;
          this.totalPluginPrice = this.totalPluginPrice + userPlugin.price;
          this.installedPlugins.push(
            {
              name: plugin.name,
              price: '$' + userPlugin.price + '/Month',
              pluginId: plugin.id,
            },
          );
        }
      }
    }
  }

  public generatePackageDetailed() {
    this.packageDetailed = [
      {
        attribute: 'Your current plan',
        value: this.userPackage.name,
      },
      {
        attribute: 'Member since',
        value: dateFormat(this.userPackage.activatedAt, 'mmm d, yyyy', true),
      },
      {
        attribute: 'Billing cycle',
        value: this.userPackage.interval === 'EVERY_30_DAYS' ? 'Monthly' : 'Yearly',
      },
      {
        attribute: 'Package Price',
        value: '$' + this.userPackage.price,
      },
      {
        attribute: 'Total Plugins Price',
        value: '$' + this.totalPluginPrice,
      },
      {
        attribute: 'Recurring charge',
        value: '$' + this.totalPrice,
      },
      {
        attribute: 'Discount',
        value: `$${Math.round(this.userPackage.discount * this.userPackage.price * 100) / 100} (${this.userPackage.discount * 100}%)`,
      },
    ];
    if (this.userPackage.discount) {
      this.packageDetailed.push(
        {
        attribute: 'Discount limit intervals (month)',
        value: this.userPackage.durationLimitInIntervals,
        }
      );
    }
  }

  public getBillingPackage() {
    const assistyPlan = this.store.assistyPlan;
    for (const item of this.billingPackages.items) {
      if (item.price === 0) {
        this.freeBillingPackage = item;
      } else if (assistyPlan === item.name) {
        this.paidBillingPackage = item;
      }
    }
    this.filteredBillingPackages = new BillingPackageList();
    this.filteredBillingPackages.add(this.freeBillingPackage);
    this.filteredBillingPackages.add(this.paidBillingPackage);
  }

  public async submitPackage(item: BillingPackageModel, trialDays: number = 14,
                             discount: number = 0, swithPlan: boolean = false) {
    let isYearly: boolean = this.isYearly;
    if (swithPlan) {
      isYearly = !isYearly;
    }
    this.submitting = true;
    this.userPackage.billingPackageId = item.id;
    let returnUrl = `${process.env.VUE_APP_SUBSCRIPTION_RETURN_URL}`;
    if (isShopifyEmbedded()) {
      returnUrl = `https://admin.shopify.com/store/${this.store.shopName.split('.')[0]}/apps/${process.env.VUE_APP_NAME}/subscription/confirm`;
    }
    const confirmationUrl = await this.userPackage.create(
      trialDays,
      discount,
      returnUrl,
      [],
      isYearly,
    );
    if (this.userPackage.status === 'CONFIRMING' && confirmationUrl) {
      let eventName = 'CONFIRMING_PAID_PACKAGE';
      if (discount > 0) {
        eventName = `CONFIRMING_PAID_PACKAGE_AFTER_OFFER_${discount * 100}`;
      }
      try {
        const eventHandler = new EventHandler({
          store: this.store,
        });
        eventHandler.track(eventName, {
          billing_package_id: this.userPackage.billingPackageId,
          version: this.userPackage.billingPackageVersion,
        });
        this.store.sendEvent(eventName, {
          billing_package_id: this.userPackage.billingPackageId,
          version: this.userPackage.billingPackageVersion,
        });
      } catch (e) {
        // ignore this
      }
      if (isShopifyEmbedded()) {
        Redirect.create(this.app).dispatch(
          Redirect.Action.REMOTE,
          confirmationUrl,
        );
      } else {
        window.location.assign(confirmationUrl);
      }
    } else if (this.$route.name === 'onboardPricing') {
      this.$router.push('/subscription');
    } else {
      try {
        EventBus.$emit('current-package-changed');
        const eventHandler = new EventHandler({
          store: this.store,
        });
        eventHandler.track('START_FREE_PACKAGE', {
          billing_package_id: this.userPackage.billingPackageId,
          version: this.userPackage.billingPackageVersion,
        });
        this.store.sendEvent('START_FREE_PACKAGE', {
          billing_package_id: this.userPackage.billingPackageId,
          version: this.userPackage.billingPackageVersion,
        });
      } catch (e) {
        // ignore this
      }
      this.offerDiscountDialog = false;
      if (isShopifyEmbedded()) {
        Redirect.create(this.app).dispatch(
          Redirect.Action.REMOTE,
          '/subscription',
        );
      } else {
        window.location.assign('/subscription');
      }
    }
    this.submitting = false;
  }

  public async startOffer(offerAt: number) {
    this.store.startOffer(offerAt);
  }

  public toTimestamp(strDate: string = '') {
    let datum: number = 0;
    if (!strDate) {
      datum = Date.now();
    } else {
      datum = Date.parse(strDate);
    }
    return Math.floor(datum / 1000);
  }

  private async loadCurrentPackage() {
    await this.userPackage.getCurrent();
    this.store.currentPackage = this.userPackage;
    if (this.userPackage.name !== 'Free') {
      if (this.userPackage.interval === 'ANNUAL') {
        this.isYearly = true;
      } else {
        this.isYearly = false;
      }
    }
  }
}
