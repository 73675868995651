
import { Component, Prop, Vue } from 'vue-property-decorator';
import HeaderTitle from '@/components/HeaderTitle.vue';
import ActionButton from '@/components/ActionButton.vue';
import { SpreadsheetTemplateModel } from '@/models/spreadsheet_template';
import { SpreadsheetTemplateList } from "@/collections/spreadsheet_templates";
import { SpreadsheetConnectedModel } from '@/models/spreadsheet_connected';
import { StoreModel } from '@/models/store';
import { EventBus } from "@/main";

@Component({
  components: {
    HeaderTitle,
    ActionButton,
  },
})
export default class SpreadsheetClone extends Vue {
  @Prop({ default: null })
  public app!: any;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public isLoading = false;
  public editingTemplate: SpreadsheetTemplateModel = new SpreadsheetTemplateModel();
  public templates: SpreadsheetTemplateList = new SpreadsheetTemplateList();
  public cloneTemplateLoading: boolean = false;
  public connectedSheet: SpreadsheetConnectedModel = new SpreadsheetConnectedModel();
  public clonedSpreadsheetURL: string = '';
  public connectSuccess: boolean = false;
  public isCopyClicked: boolean = false;
  public clonedSpreadsheetLabel: string = 'Cloned Spreadsheet URL';
  public clonedSpreadsheetPlaceholder: string = 'Paste the URL of your cloned spreadsheet here';
  public async created() {
    this.isLoading = true;
    const templateId: number = Number(this.$route.params.id);
    await this.templates.fetch();
    this.editingTemplate = this.templates.getTemplate(templateId);
    this.isLoading = false;
  }

  public copyClick() {
    this.isCopyClicked = true;
    this.clonedSpreadsheetLabel = 'Paste the URL of your cloned spreadsheet here';
    this.clonedSpreadsheetPlaceholder = 'Paste the URL of your cloned spreadsheet here';
    
  }
  public async connectSpreadsheet() {
    try {
      this.cloneTemplateLoading = true;
      this.connectSuccess = false;
      const res: any = await this.editingTemplate.createSchedule(this.clonedSpreadsheetURL, this.store.config.timezone);
      if (res.data) {
        const connectedSheet = new SpreadsheetConnectedModel(res.data);
        connectedSheet.refreshData();
      }
      this.cloneTemplateLoading = false;
      this.connectSuccess = true;
      EventBus.$emit("show-snackbar", 
      {
        message: "The "+ this.editingTemplate.name + "spreadsheet data has been refreshed successfully.",
        color: "success",
      });
    } catch(error: any) {
      EventBus.$emit("show-snackbar", 
      {
        message: "Error: " + error.message,
        color: "error",
      });
      this.cloneTemplateLoading = false;
      this.connectSuccess = false;
    }
  }
  public viewConnectedSpreadsheet() {
    this.$router.push('/spreadsheet-connected');
  }
  

}
