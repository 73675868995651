
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class ChatWithUs extends Vue {
  @Prop({ default: 'deep_sku_blue' })
  public color!: string;
  @Prop({ default: 'button' })
  public type!: string;
  @Prop({ default: 'Chat with us' })
  public title!: string;
  @Prop({ default: 'Hello there!' })
  public defaultMessage!: string;
  @Prop({ default: false })
  public outline!: boolean;
  public openChat() {
    // @ts-ignore: Unreachable code error
    this.$crisp.do('chat:open');
    // @ts-ignore: Unreachable code error
    // this.$crisp.do('message:send', ['text', this.$t(`chat_with_us.${this.defaultMessage}`)]);
    this.$crisp.do('message:send', ['text', this.defaultMessage]);
  }
}
