import List from './list';
import { ParamModel } from '@/models/param';

export class ParamList extends List<ParamModel> {
  public mapData(data: any = []) {
    this.items.splice(0, this.items.length);
    for (const s of data) {
      const param = new ParamModel();
      param.mapData(s);
      this.add(param);
    }
    this.sort();
  }

  public sort() {
    const compare = (a: ParamModel, b: ParamModel) => {
      if (a.sortOrder > b.sortOrder) {
        return 1;
      } else {
        return -1;
      }
    };
    this.items = this.items.sort(compare);
  }
}
