import { get } from '@/services/http';
import { EmailReceiverModel } from '@/models/email_receiver';
import List from './list';

export class EmailReceiverList extends List<EmailReceiverModel> {

  public async fetch() {
    const res: any = await get(`/email_receivers`, {});
    const data = res.data || [];
    this.mapData(data);
  }

  public mapData(data: any = []) {
    for (const s of data) {
      const receiver: EmailReceiverModel = new EmailReceiverModel();
      receiver.mapData(s);
      this.add(receiver);
    }
  }
}
