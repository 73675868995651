import { get, post } from '@/services/http';
import { TagModel } from '@/models/tag';
import List from './list';

export class TagList extends List<TagModel> {
  public async fetch() {
    const res: any = await get(`/tags`, {});
    const tags = res.data || [];
    for (const s of tags) {
      const tag = new TagModel();
      tag.mapData(s);
      this.add(tag);
    }
  }
}
