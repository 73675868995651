
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class Steps extends Vue {
  @Prop({ default: 1 })
  public active!: number;
  @Prop({ default: 3 })
  public steps!: number;
}
