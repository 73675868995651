import { get } from '@/services/http';
import { DimensionModel } from '@/models/dimension';

export class SubDimensionModel {
  public dimension: DimensionModel;
  public label: string;

  constructor(data: any = {}) {
    this.dimension = new DimensionModel();
    this.label = data.label;
  }

  public mapData(data: any = {}) {
    this.label = data.label;
    this.dimension.mapData(data.dimension);
  }
}
