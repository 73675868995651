
import { Component, Prop, Vue } from 'vue-property-decorator';
import HeaderTitle from '../components/HeaderTitle.vue';
import ActionButton from '../components/ActionButton.vue';
import { StoreModel } from '@/models/store';
import { RouterLink } from 'vue-router';
import { PluginModel } from '@/models/plugin';
import { UserPluginModel } from '@/models/user_plugin';
import { UserPluginList } from '@/collections/user_plugins';
import { EventHandler } from '@/modules/events';

@Component({
  components: {
    HeaderTitle,
    ActionButton,
  },
})
export default class Plugin extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: false })
  public hasSchemas!: boolean;
  public plugin: PluginModel = new PluginModel();
  public selectedImage: string = '';
  public userPlugins: UserPluginList = new UserPluginList();
  public loading: boolean = false;
  public currentUserPlugin: UserPluginModel = new UserPluginModel();
  public installing: boolean = false;
  public async created() {
    const pluginId = Number(this.$route.params.pluginId);
    if (pluginId) {
      this.loading = true;
      this.plugin.id = pluginId;
      await this.plugin.fetch();
      await this.userPlugins.fetch();
      this.getCurrentUserPlugin();
      this.selectedImage = this.plugin.images[0];
      this.loading = false;
      try {
        const eventHandler = new EventHandler({
          store: this.store,
        });
        eventHandler.track('VIEW_PLUGIN_DETAILED', {
          id: pluginId,
        });
        this.store.sendEvent('VIEW_PLUGIN_DETAILED', {
          id: pluginId,
        });
      } catch (e) {
        // ignore this
      }
    }
  }

  public selectImage(image: string) {
    this.selectedImage = image;
  }

  public viewLink(link: any) {
    if (link) {
      this.$router.push(link);
    } else {
      this.$router.push('/');
    }
  }

  public get isAvailable() {
    const packageId: number = this.store.currentPackage.billingPackageId;
    for (const item of this.plugin.packages.items) {
      if (item.packageId === packageId) {
        return true;
      }
    }
    return false;
  }

  public getPluginPrice() {
    if (this.isInstalledPlugin) {
      return '$' + this.currentUserPlugin.price + '/Month';
    }
    const packageId: number = this.store.currentPackage.billingPackageId;
    for (const item of this.plugin.packages.items) {
      if (item.packageId === packageId) {
        if (item.price === 0) {
          return 'Free';
        }
        return '$' + item.price + '/ Month';
      }
    }
    return 'Not Available';
  }

  public async installPlugin() {
    this.$router.push(`/order_confirm?installingId=${this.plugin.id}`);
  }

  public removePlugin() {
    this.$router.push(`/order_confirm?uninstallingId=${this.plugin.id}`);
  }

  public getCurrentUserPlugin() {
    for (const plugin of this.userPlugins.items) {
      if (plugin.pluginId === this.plugin.id) {
        this.currentUserPlugin = plugin;
      }
    }
  }

  public get isInstalledPlugin() {
    if (this.currentUserPlugin.id) {
      return true;
    }
    return false;
  }
}
