
import { Component, Prop, Vue } from 'vue-property-decorator';
import HeaderTitle from '@/components/HeaderTitle.vue';
import ActionButton from '@/components/ActionButton.vue';
import { StoreModel } from '@/models/store';
import { EventBus } from "@/main";
import { EmailTemplateModel } from "@/models/email_template";
import { EmailTemplateList } from "@/collections/email_templates";
import { EventHandler } from "@/modules/events";
import { VueEditor } from "vue2-editor";
import StatusPageComponent from '@/components/StatusPage.vue';
import { EmailReceiverList } from "@/collections/email_receivers";
import { EmailReceiverModel } from '@/models/email_receiver';

@Component({
  components: {
    HeaderTitle,
    ActionButton,
    VueEditor,
    StatusPageComponent,
  },
})
export default class EmailTemplateNew extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public success: boolean = false;
  public error: boolean = false;
  public isLoading: boolean = false;
  public dialogDelete: boolean = false;
  public headers: any = [];
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  public deleteLoading: boolean = false;
  public saveLoading: boolean = false;
  public emailTemplate: EmailTemplateModel = new EmailTemplateModel();
  public notFoundError: boolean = false;
  public testEmailTemplateDialog: boolean = false;
  public testingReceiver: EmailReceiverModel = new EmailReceiverModel();
  public emailReceivers: EmailReceiverList = new EmailReceiverList();
  public sendingTestingEmail: boolean = false;

  public async created() {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`view email template detail page`);
    } catch (e) {
      // ignore this
    }
    this.isLoading = true;
    this.emailReceivers = new EmailReceiverList();
    await this.emailReceivers.fetch();
    this.emailTemplate.content = `<p>Here is the example of the content of a template.</p>`;
    this.emailTemplate.content += `<p>You can use the variables by using the code like {{report_content}}, {{report_title}} and more.</p>`;
    this.emailTemplate.content += `<p>You also click to the list of variables to copy the code and paste here.</p>`;
    this.emailTemplate.content += `<p>The variable {{report_content}} is required for the content of the template and it can not be in the title.</p>`;
    this.emailTemplate.content += `<p>The template support <strong>bold</strong>, <i>italic</i>, <u>underline</u> format.</p>`;
    this.isLoading = false;
  }

  public get variables() {
    return {
      items: [
        {
          name: 'Report content',
          description: 'The content of report such as the summary, the table detail, etc. Report content is a required param in the content of a template with the code {{report_content}}',
          code: '{{report_content}}'
        },
        {
          name: 'Report title',
          description: '',
          code: '{{report_title}}'
        },
        {
          name: 'Report download links',
          description: '',
          code: '{{report_download_url}}'
        },
        {
          name: 'Receiver name',
          description: '',
          code: '{{receiver_name}}'
        },
        {
          name: 'Receiver email',
          description: '',
          code: '{{receiver_email}}'
        },
      ]
    };
  }

  public async copyVariable(code: string) {
    await navigator.clipboard.writeText(code);
    EventBus.$emit(
      'show-snackbar',
      `Copied ${code} successfully!`
    );
  }

  public get customToolbar() {
    return [
      ['bold', 'italic', 'underline', { list: 'bullet' }, 'link'],
    ];
  }

  public async saveTemplate() {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`save email template`, {});
    } catch (e) {
      // ignore this
    }
    this.saveLoading = true;
    try {
      await this.emailTemplate.create();
      EventBus.$emit(
        'show-snackbar',
        'Saved template successfully!'
      );
      this.$router.push(`/email-templates/${this.emailTemplate.id}`);
    } catch (e: any) {
      if (e.name === 'AxiosError') {
        if (e.response.status === 400) {
          const error: any = JSON.parse(e.response.data);
          EventBus.$emit(
            'show-snackbar',
            {
              message: error.message,
              color: 'red',
            }
          );
        }
      }
    }
    this.saveLoading = false;
  }
}
