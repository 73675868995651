
import { Component, Prop, Vue } from 'vue-property-decorator';
import { get } from '@/services/http';
import { isShopifyEmbedded } from '@shopify/app-bridge/utilities';
import { Redirect } from '@shopify/app-bridge/actions';

@Component
export default class SlackConnection extends Vue {
  public slackConnected: boolean = false;
  public slackData: any = {};
  public slackUrl: string = `${process.env.VUE_APP_BACKEND_API_URL}/slack/install`;
  public slackAccountName: string = '';
  public success: boolean = false;
  @Prop({default: null})
  public app!: any;

  public async created() {
    await this.fetchCurrentStore();
  }

  public async getInstallUrl() {
    const res: any = await get('/slack/install');
    const installUrl: string = res.install_url;
    if (isShopifyEmbedded()) {
      Redirect.create(this.app).dispatch(Redirect.Action.REMOTE, installUrl);
      // Otherwise, redirect using the `window` object
    } else {
      window.location.assign(installUrl);
    }
  }

  public openSlackApp() {
    const slackUrl: string = `https://app.slack.com/client/${this.slackData.team_id}/${this.slackData.bot.bot_user_id}`;
    if (isShopifyEmbedded()) {
      Redirect.create(this.app).dispatch(Redirect.Action.REMOTE, slackUrl);
      // Otherwise, redirect using the `window` object
    } else {
      window.location.assign(slackUrl);
    }
  }

  private getSlackAccountName(name: string): string {
    const maxLength = 25;
    return name.length > maxLength
      ? name.substring(0, maxLength - 3) + '...'
      : name;
  }

  private async fetchCurrentStore() {
    try {
      const res: any = await get('/stores/detail');
      this.slackConnected = res.data.slack_connected;
      if (res.data.slack_data) {
        this.slackData = res.data.slack_data;
        this.slackAccountName = this.getSlackAccountName(
          this.slackData.team_name,
        );
      }
      localStorage.setItem('shopify_store', res.data.shop_name);
      this.$root.$emit('event.received_shop_name', res.data.shop_name);
      this.success = true;
    } catch (e) {
      this.success = false;
    }
  }
}
