
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class OptionSelector extends Vue {
  @Prop({ default: [] })
  public initData!: string[];
  @Prop({ default: [] })
  public value!: string[];
  public selectedItems: string[] = [];
  @Prop({default: () => []})
  public initSelectedItems!: string[];

  public selectingItems: any[] = [];
  @Prop({ default: 35 })
  public height!: number;
  @Prop({})
  public width!: number;
  @Prop({ default: true })
  public multiple!: boolean;
  @Prop({ default: true })
  public defaultFirstItem!: boolean;

  public created() {
    if (this.defaultFirstItem) {
      this.initSelectedItems.push(this.initData[0]);
    }
    this.initOptionData();
  }

  public resetSelect() {
    for (const item of this.selectingItems) {
      item.value = false;
    }
  }

  public selectMetricItem(item: any) {
    if (!this.multiple) {
      this.resetSelect();
    }
    item.value = !item.value;
    
    const selectedItems: any = [];
    for (const selectingItem of this.selectingItems) {
      if (selectingItem.value) {
        selectedItems.push(selectingItem.key);
      }
    }
    this.$emit('input', selectedItems);
  }

  public initOptionData() {
    let setDefault: boolean = true;
    if (this.initSelectedItems && this.initSelectedItems.length > 0) {
      setDefault = false;
    }
    this.selectingItems = [];
    for (const item of this.initData) {
        this.selectingItems.push({
          key: item,
          value: this.isSelected(item),
        });
    }
  }

  public isSelected(option: string) {
    for (const item  of this.initSelectedItems) {
      if (item + '' === option + '') {
        return true;
      }
    }
    return false;
  }

  @Watch("initSelectedItems", { immediate: true, deep: true })
  private async onInitSelectedItemsChanged(newVal: any) {
    if (this.initSelectedItems && this.initSelectedItems.length > 0) {
      //this.initOptionData();
    }
  }
}
