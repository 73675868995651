import { get, post } from '@/services/http';
import { BillingPackageModel } from '@/models/billing_package';
import List from './list';

export class BillingPackageList extends List<BillingPackageModel> {
  public async fetch() {
    const data: any = {};
    const res: any = await get(`/billing_packages`, data);
    const billingPackages = res.data || [];
    for (const s of billingPackages) {
      const billingPackage: BillingPackageModel = new BillingPackageModel();
      billingPackage.mapData(s);
      this.add(billingPackage);
    }
    this.items.sort((obj1, obj2) => {
      if (obj1.id > obj2.id) {
        return 1;
      }
      if (obj1.id < obj2.id) {
        return -1;
      }
      return 0;
    });
  }
}
