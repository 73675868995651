
import { Component, Prop, Vue } from 'vue-property-decorator';
import HeaderTitle from '@/components/HeaderTitle.vue';
import ActionButton from '@/components/ActionButton.vue';
import { DashboardModel } from '@/models/dashboard';
import { StoreModel } from '@/models/store';

@Component({
  components: {
    HeaderTitle,
    ActionButton,
  },
})
export default class LiveDemo extends Vue {
  @Prop({ default: null })
  public app!: any;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public async created() {
    try {
      if (!this.store.config.homepageDashboardId) {
        const dashboardModel: DashboardModel = new DashboardModel();
        dashboardModel.id = 117;
        await dashboardModel.copy();
        this.store.config.homepageDashboardId = dashboardModel.id;
        this.store.config.homepageDashboardType = 'PRIVATE';
        this.store.saveSettings();
      }
    } catch (e: any) {
      //
    }
  }
  public mounted() {
      const calendly = document.createElement('script');
      calendly.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
      document.head.appendChild(calendly);
    }
}
