
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { AnalyticsTypeModel } from '@/models/analytics_type';
import { AnalyticsTypeList } from '@/collections/analytics_types';
import dateFormat from 'dateformat';

@Component({
  components: {
  },
})
export default class AnalyticsTypeFilter extends Vue {
  @Prop({ default: () => new AnalyticsTypeList() })
  public analyticsTypes!: AnalyticsTypeList;
  @Prop({ default: null })
  public initAnalyticsType!: AnalyticsTypeModel;
  @Prop({ default: null })
  public value!: AnalyticsTypeModel|null;

  public analyticsType: AnalyticsTypeModel = new AnalyticsTypeModel();
  public analyticsTypeName: string = '';
  public selectingAnalyticsTypes: AnalyticsTypeList = new AnalyticsTypeList();
  public analyticsTypeMenu: boolean = false;
  public keyword: string = '';

  @Watch('keyword', { immediate: true, deep: true  })
  private async onKeywordChanged(newVal: any) {
    if (this.keyword === '') {
      this.selectingAnalyticsTypes.items = this.analyticsTypes.items.slice(0);
      this.analyticsType = this.analyticsType;
      return;
    }
    const search = new RegExp(this.keyword , 'i');
    this.selectingAnalyticsTypes.items = this.analyticsTypes.items.filter(
      (item) => search.test(item.name),
    );
  }

  @Watch('initAnalyticsType', { immediate: true, deep: true  })
  private async onInitAnalyticsTypeChanged(newVal: any) {
    this.analyticsType = this.initAnalyticsType;
    this.analyticsTypeName = this.analyticsType.name;
    this.selectingAnalyticsTypes.items = this.analyticsTypes.items.slice(0);
  }

  @Watch('analyticsType', { immediate: true, deep: true  })
  private async onAnalyticsTypeChanged(newVal: any, oldVal: any) {
    if (!this.analyticsType) {
      this.analyticsType = new AnalyticsTypeModel();
      return;
    }
    this.analyticsTypeName = this.analyticsType.name;
    this.analyticsTypeMenu = false;
    this.keyword = '';
    this.$emit('input', this.analyticsType);
  }
}
