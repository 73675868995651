
import SlackChannelSelector from '@/components/SlackChannelSelector.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { get } from '@/services/http';
import HeaderTitle from '@/components/HeaderTitle.vue';
import { StoreModel } from '@/models/store';

@Component({
  components: {
    SlackChannelSelector,
    HeaderTitle,
  },
})
export default class SlackCallbackPage extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  public loading: boolean = false;
  public error: boolean = false;
  public errorMessage: string = '';
  public canSelectChannel: boolean = false;

  public async created() {
    const error = this.$route.query.error;
    if (error === 'invalid_scope_requested') {
      this.$router.push('/settings');
    } else {
      this.loading = true;
      await this.createSlack();
      this.loading = false;
    }
  }

  public async createSlack() {
    const query = this.$route.query;
    const data: { [key: string]: any } = {};
    for (const key of Object.keys(query)) {
      data[key] = query[key];
    }
    try {
      await get(`/slack/callback`, data);
      this.$emit('needToReloadStore');
      this.$router.push('/schedule/new');
      this.canSelectChannel = true;
    } catch (e: any) {
      this.error = true;
      this.errorMessage = e.response.data.message;
      return;
    }
  }
}
