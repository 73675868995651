
import { Component, Watch, Prop, Vue } from "vue-property-decorator";

import ActionButton from "@/components/ActionButton.vue";
import { InventoryParam } from "@/models/inventory_param";
import { EventBus } from "@/main";

@Component({
  components: {
    ActionButton,
  },
})
export default class ReplenishmentSimulator extends Vue {
  public settingDialog: boolean = false;
  @Prop({ default: false })
  public isPrimaryButton!: boolean;
  @Prop({ default: "Inventory Parameters" })
  public buttonTitle!: string;
  @Prop({ default: "mdi-calculator" })
  public buttonIcon!: string;
  @Prop({ default: "small" })
  public buttonSize!: string;
  @Prop({ default: () => new InventoryParam() })
  public inventoryParam!: InventoryParam;
  public simulatorDialog: boolean = false;
  public inventoryOnhand: number = 50;
  public incomingStock: number = 30;
  public avgUnitSoldPerDay: number = 12;
  public reorderPoint: number = 0;
  public reorderQuantity: number = 0;
  public reorderPointFomular: string = "";
  public reorderQuantityFomular: string = "";
  public safetyStock: number = 0;
  public safetyStockFomular: string = "";
  public created() {
    this.calculateReorderPoint();
  }

  public calculateReorderPoint() {
    const inventoryOnhand: number = Number(this.inventoryOnhand);
    const incomingStock: number = Number(this.incomingStock);
    const avgUnitSoldPerDay: number = Number(this.avgUnitSoldPerDay);
    const leadtime: number = Number(this.inventoryParam.leadtime);
    const orderCycle: number = Number(this.inventoryParam.orderCycle);
    const minInventoryQuantity: number = Number(this.inventoryParam.minInventoryQuantity);
    const maxInventoryQuantity: number = Number(this.inventoryParam.maxInventoryQuantity);
    const minStockCoverDays: number = Number(this.inventoryParam.minStockCoverDays);
    const maxStockCoverDays: number = Number(this.inventoryParam.maxStockCoverDays);
    const fixOrderQuantity: number = Number(this.inventoryParam.fixOrderQuantity);
    const minOrderQuantity: number = Number(this.inventoryParam.minOrderQuantity);
    const maxOrderQuantity: number = Number(this.inventoryParam.maxOrderQuantity);
    let safetyStock: number = 0;
    let reorderPoint: number = 0;
    let reorderQuantity: number = 0;
    

    //calcualte safety stock
    if (minStockCoverDays) {
      safetyStock = minStockCoverDays * avgUnitSoldPerDay;
      this.safetyStockFomular = `minStockCoverDays * avgUnitSoldPerDay`;
    }
    if (minInventoryQuantity) {
      safetyStock = minInventoryQuantity;
      this.safetyStockFomular = `minInventoryQuantity`;
    }
    // calculate reorder point
    this.reorderPointFomular = `avgUnitSoldPerDay * leadtime + safetyStock`;
    reorderPoint = avgUnitSoldPerDay * leadtime + safetyStock;
    if (orderCycle) {
      this.reorderPointFomular = `avgUnitSoldPerDay * (leadtime + orderCycle) + safetyStock`;
      reorderPoint = avgUnitSoldPerDay * (leadtime + orderCycle) + safetyStock;
    }

    //calculate reorder quantity
    if (inventoryOnhand + incomingStock - reorderPoint < 0) {
      reorderQuantity = reorderPoint;
      this.reorderQuantityFomular = `reorderPoint`;
    } else {
      reorderQuantity = 0;
      this.reorderQuantityFomular = `0`;
    }
    if (orderCycle) {
      reorderQuantity = reorderPoint - (inventoryOnhand + incomingStock);
      this.reorderQuantityFomular = `reorderPoint - (inventoryOnhand + incomingStock)`;
    }

    if (fixOrderQuantity) {
      reorderQuantity = fixOrderQuantity;
      this.reorderQuantityFomular = `fixOrderQuantity`;
    }

    if (minStockCoverDays) {
      reorderQuantity = minStockCoverDays * avgUnitSoldPerDay;
      this.reorderQuantityFomular = `minStockCoverDays * avgUnitSoldPerDay`;
    }

    if (maxStockCoverDays) {
      reorderQuantity = maxStockCoverDays * avgUnitSoldPerDay;
      this.reorderQuantityFomular = `maxStockCoverDays * avgUnitSoldPerDay`;
    }

    if (maxInventoryQuantity) 
    { 
      if (inventoryOnhand + incomingStock > maxInventoryQuantity) {
        reorderQuantity = 0;
        this.reorderQuantityFomular = `0`;
      } else {
        reorderQuantity = maxInventoryQuantity - (inventoryOnhand + incomingStock);
        this.reorderQuantityFomular = `maxInventoryQuantity - (inventoryOnhand + incomingStock)`;
      }
    }

    if (minOrderQuantity && reorderQuantity < minOrderQuantity) {
      reorderQuantity = minOrderQuantity;
      this.reorderQuantityFomular = `minOrderQuantity`;
    }

    if (maxOrderQuantity && reorderQuantity > maxOrderQuantity) {
      reorderQuantity = maxOrderQuantity;
      this.reorderQuantityFomular = `maxOrderQuantity`;
    }

    try {
      this.safetyStock = safetyStock;
      this.reorderPoint = reorderPoint;
      this.reorderQuantity = reorderQuantity;
    } catch (error) {
      // console.log(error);
    }
  }
  @Watch("simulatorDialog", { deep: true })
  public onDialogChange() {
    if (this.simulatorDialog) {
      this.calculateReorderPoint();
    }
  }

}
