import { get, post } from '@/services/http';
import { MeasureModel } from '@/models/measure';
import { DimensionModel } from '@/models/dimension';

export class FileDownloadModel {
  public id: number;
  public measure: MeasureModel;
  public dimension: DimensionModel;
  public createdAt?: string;
  public startTime?: string;
  public endTime?: string;
  public emails?: string[];
  public status?: string;
  public link?: string;
  public downloadName?: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.measure = new MeasureModel();
    this.dimension = new DimensionModel();
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.measure = new MeasureModel({ code: data.measure });
    this.dimension = new DimensionModel({ code: data.dimension });
    this.createdAt = data.createdAt || '';
    this.startTime = data.startTime || '';
    this.endTime = data.endTime || '';
    this.emails = data.emails;
    this.status = data.status;
    this.link = data.link ? data.link.replace('#', '%23') : '';
    this.downloadName = data.downloadName;
  }
}
