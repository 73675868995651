
import { Component, Prop, Vue } from "vue-property-decorator";
import { EventHandler } from "@/modules/events";
import HeaderTitle from "../components/HeaderTitle.vue";
import ChatWithUs from "../components/ChatWithUs.vue";
import { ScheduleProfileModel } from "@/models/schedule_profile";
import { ScheduleProfileList } from "@/collections/schedule_profiles";
import { MeasureList } from "@/collections/measures";
import { StoreModel } from "@/models/store";
import FeatureLockedNotify from "@/components/FeatureLockedNotify.vue";
import ActionButton from "@/components/ActionButton.vue";
import EmptyStage from "@/components/EmptyStage.vue";

@Component({
  components: {
    HeaderTitle,
    ChatWithUs,
    FeatureLockedNotify,
    ActionButton,
    EmptyStage,
  },
})
export default class ScheduleProfiles extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public success: boolean = false;
  public error: boolean = false;
  public isLoading: boolean = false;
  public scheduleProfiles: ScheduleProfileList = new ScheduleProfileList();
  public deletingProfile: ScheduleProfileModel = new ScheduleProfileModel();
  public dialogDelete: boolean = false;
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;
  public profileHeaders: any = [];
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  public deleteLoading: boolean = false;
  public async created() {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`view notification page`);
    } catch (e) {
      // ignore this
    }
    this.isLoading = true;

    this.profileHeaders = [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Destination",
        value: "destination",
      },
      {
        text: "Output Type",
        value: "outputType",
      },
      {
        text: "File Format",
        value: "fileFormat",
      },
      {
        text: "Interval",
        value: "interval",
      },
      {
        text: "Time",
        value: "time",
      },
      {
        text: this.$t("schedule.action") + "",
        value: "action",
        width: "10%",
      },
    ];
    await this.measures.fetch({ isOlap: true });
    await this.fullLoadData();
    this.isLoading = false;
  }

  public canScheduleReport() {
    return true;
  }

  public getFileFormatText(fileFormat: string) {
    switch (fileFormat) {
      case "csv":
        return "CSV";
      case "excel":
        return "Excel";
      case "pdf":
        return "PDF";                
      case "existed_google_sheets":
        return "Create a single sheet";
      case "existed_google_new_sheets":
        return "Add a new sheet on every run";
      case "google_sheets":
        return "New Google Spreadsheet";
      default:
        return fileFormat;
    }
  }
  public getDeliveryHourLabel(profile: ScheduleProfileModel) {
    let timeLabel: string = '';
    if (profile.deliveryHour && profile.deliveryHour.length > 0) {
      let hours: string[] = [];
      for (const item of profile.deliveryHour) {
        hours.push(item + ':00');
      }
      timeLabel = hours.join(', ');
    }
    return timeLabel;
  }

  public getDeliveryWeekdayLabel(profile: ScheduleProfileModel) {
    let timeLabel: string = '';
    if (profile.deliveryWeekday && profile.deliveryWeekday.length > 0) {
      let weekdayLabels: string[] = [];
      for (const item of profile.deliveryWeekday) {
        weekdayLabels.push(this.convertWeekdayToLabel(item));
      }
      timeLabel = weekdayLabels.join(', ');
    }
    return timeLabel;
  }

  public getDeliveryDateLabel(profile: ScheduleProfileModel) {
    let timeLabel: string = '';
    if (profile.deliveryDate && profile.deliveryDate.length > 0) {
      let dates: string[] = [];
      for (const item of profile.deliveryDate) {
        dates.push(profile.convertDayOfMonthToLabel(item));
      }
      timeLabel = dates.join(', ');
    }
    return timeLabel;
  }

  public convertWeekdayToLabel(weekday: string) {
    let weekdayLabels: any = [
      {value: '0', label: 'Sunday'},
      {value: '1', label: 'Monday'},
      {value: '2', label: 'Tuesday'},
      {value: '3', label: 'Wednesday'},
      {value: '4', label: 'Thursday'},
      {value: '5', label: 'Friday'},
      {value: '6', label: 'Saturday'},
      {value: 'everyday', label: 'Everyday'}
    ];
    for (const item of weekdayLabels) {
      if (item.value === weekday + '') {
        return item.label;
      }
    }
    return '';
  }
  public getScheduleReportName(data: any) {
    if (data.name) {
      return data.name;
    }
    let reportName = "";
    for (const measure of this.measures.items) {
      if (measure.code === data.measureCode) {
        reportName = measure.name;
        for (const dimension of measure.dimensions.items) {
          if (dimension.code === data.dimensionCode) {
            reportName += " by " + dimension.name;
            break;
          }
        }
        return reportName;
      }
    }
    return "";
  }

  public async fullLoadData() {
    this.isLoading = true;
    this.scheduleProfiles = new ScheduleProfileList();
    await this.scheduleProfiles.fetch();
    this.isLoading = false;
  }

  public editProfile(scheduleProfile: ScheduleProfileModel) {
    this.$router.push(`/schedule/profile/${scheduleProfile.id}`);
  }

  public confirmDelete(scheduleProfile: ScheduleProfileModel) {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`click delete schedule profile`);
    } catch (e) {
      // ignore this
    }
    this.deletingProfile = scheduleProfile;
    this.dialogDelete = true;
  }

  public async deleteItem() {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`delete profile`, {});
    } catch (e) {
      // ignore this
    }
    this.deleteLoading = true;
    let index: number = -1;
    for (const item of this.scheduleProfiles.items) {
      index = index + 1;
      if (item.id === this.deletingProfile.id) {
        this.scheduleProfiles.items.splice(index, 1);
        break;
      }
    }
    await this.deletingProfile.remove();
    this.deleteLoading = false;
    this.dialogDelete = false;
  }

  public upperCaseFirstLetter(str: string) {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  }
}
