
interface Option {
  sortBy?: any;
  sortDesc?: any;
  page?: string;
  itemsPerPage?: string;
  multiSort?: boolean;
}

import { Component, Watch, Prop } from 'vue-property-decorator';
import Report from '@/components/Report';
import ChatWithUs from '@/components/ChatWithUs.vue';
import LoadingSlider from '@/components/LoadingSlider.vue';
import ContentLoadResultMessage from '@/components/ContentLoadResultMessage.vue';
import { EventHandler } from '@/modules/events';
import { StoreModel } from '@/models/store';

@Component({
  components: {
    ChatWithUs,
    LoadingSlider,
    ContentLoadResultMessage,
  },
})
export default class DrilldownTableReport extends Report {
  public typeReport: string = 'TABLE';
  public headers: any = [];
  public rows: any = [];
  public options: Option = {};
  public tableLoading: boolean = false;
  public downloadDialog: boolean = false;
  public downloadLoading: boolean = false;
  public currentValueEmail: string | null = null;
  public enablePivot: boolean = false;
  public error: boolean = false;
  public loadingSlides: string[] = [
    'Connecting to analytic services',
    'Fetching data',
    'Building report',
    'Downloading data',
    'Generating datatable',
  ];
  public needCount: boolean = true;
  public isResetOptions: boolean = false;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: [] })
  public selectedReportColumns!: any;

  public async created() {
    this.report.typeReport = this.typeReport;
    this.report.isDetail = this.isDetail;
  }

  public doNotTranslateRowValue(item: any) {
    return 'notranslate';
  }

  public removeFilter(index: number) {
    this.filterColumns.items.splice(index, 1);
  }

  public closeDrilldownDatatableDialog() {
    this.$emit('close-drilldown-table', true);
  }

  public FullAnalyze() {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`Click on Full Analyze data on drilldown dialog`, {
        measure: this.measure.code,
        dimension: this.dimension.code,
      });
    } catch (e) {
      // ignore this
    }
    let fullAnalyzeUrl = '/reports/measures/' + this.measure.code;
    if (this.dimension.code) {
      fullAnalyzeUrl = fullAnalyzeUrl + '?dimension=' + this.dimension.code;
    }
    if (this.analyticsType.code && this.analyticsType.code !== undefined) {
      fullAnalyzeUrl = fullAnalyzeUrl + '&analytic=' + this.analyticsType.code;
    }
    if (this.filterTimerange && this.filterTimerange.length >= 2) {
      fullAnalyzeUrl = fullAnalyzeUrl + '&start_time=' + this.filterTimerange[0];
      fullAnalyzeUrl = fullAnalyzeUrl + '&end_time=' + this.filterTimerange[1];
    }

    const filterParams = [];
    for (const item of this.filterColumns.items) {
      filterParams.push(`${item.name}__${item.code}__${item.operator}__${item.value.join('_or_')}__${item.dataType}`);
    }
    if (filterParams.length > 0) {
      fullAnalyzeUrl = fullAnalyzeUrl + '&filter=' + filterParams.join('_col_');
    }
    this.$router.push(fullAnalyzeUrl);
  }

  public hasFilterDate() {
    for (const filter of this.filterColumns.items) {
      if (['Day', 'Week', 'Month', 'Quarter', 'Year'].includes(filter.name)) {
        return true;
      }
    }
    return false;
  }

  public reload() {
    let selectedReportColumns: any = this.selectedReportColumns;
    if (!this.selectedReportColumns || this.selectedReportColumns.length === 0) {
      selectedReportColumns = this.report.columns;
    }

    this.headers = [];
    this.rows = [];

    for (const item of selectedReportColumns) {
      for (const c of this.report.columns) {
        if (c.code !== item.code) {
          continue;
        }
        this.headers.push(
          {
            text: c.name,
            value: c.code,
            class: (c.sortable !== false) ? 'table-header' : ['table-header', 'non-sortable-header'],
            sortable: (c.sortable !== false) ? true : false,
            filterable: (c.filterable !== false) ? true : false,
            cellClass: (c.sortable !== false) ? 'table-item' : ['table-item', 'non-sortable-item'],
          });
      }
    }

    /* this.headers = this.report.columns.map((c: any) => {
      return {
        text: c.name,
        value: c.code,
        class: 'table-header',
        cellClass: 'table-item',
        sortable: true,
      };
    }); */
    for (const index in this.report.rows) {
      if (this.report.rows[index]) {
        const res: any = {};
        for (const column of this.report.columns) {
          if (!this.isSelectedColumn(column.code)) {
            continue;
          }
          if (column.values[index]) {
            res[column.code] = column.values[index];
          } else {
            if (column.dataType === 'number'
              || column.dataType === 'decimal'
              || column.dataType === 'currency'
              || column.dataType === 'percent') {
              res[column.code] = '0';
            } else {
              res[column.code] = '-';
            }
          }
        }
        this.rows.push(res);
      }
    }
    this.generateReportFilterColumns();
  }

  public isSelectedColumn(code: string) {
    if (!this.selectedReportColumns || this.selectedReportColumns.length === 0) {
      return true;
    }
    for (const c of this.selectedReportColumns) {
      if (c.code === code) {
        return true;
      }
    }
    return false;
  }

  public resetOptions() {
    if (this.isCreated) {
      this.isResetOptions = true;
      this.page = 1;
      this.perPage = this.options.itemsPerPage;
      this.options = {};
      this.options.sortBy = this.sortBy;
      this.options.sortDesc = this.sortDesc;
    }
  }

  public resetSortOptions() {
    if (this.isCreated) {
      this.sortBy = [];
      this.sortDesc = [];
    }
  }

  public generateReportFilterColumns() {
    let reportColumns: any = [];
    if (this.report && this.report.columns && this.report.columns.length > 0) {
      reportColumns = this.report.columns.map((c: any) => {
      return {
        name: c.name,
        code: c.code,
        dataType: c.dataType,
      };
    });
    }
    if (reportColumns.length > 0) {
      this.$emit('input', reportColumns);
    }
  }
  private validateEmail(email: string) {
    const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return emailRegexp.test(email);
  }

  @Watch('options', { immediate: true, deep: true })
  private async optionsChanged(newVal: any, oldVal: any) {
    if (this.isResetOptions) {
      this.isResetOptions = false;
      return;
    }
    if (this.page !== this.options.page
      || (this.options.sortBy && this.options.sortBy.length  && this.sortBy[0] !== this.options.sortBy[0])
      || (this.options.sortDesc && this.options.sortDesc.length && this.sortDesc[0] !== this.options.sortDesc[0]) ) {
      this.page = this.options.page;
      this.perPage = this.options.itemsPerPage;
      this.sortBy = this.options.sortBy;
      this.sortDesc = this.options.sortDesc;
      this.tableLoading = true;
      this.rows = [];
      try {
        if (this.isCreated) {
          await this.getData();
        } else {
          await this.getReport();
        }
      } catch (error: any) {
        if (error.code === 'ERR_CANCELED') {
          if (this.isLoading) {
            this.tableLoading = false;
          }
        }
        throw(error);
      }
      this.tableLoading = false;
    }
  }

  private sleep(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
}
