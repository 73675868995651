
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { get } from '@/services/http';
import ChatWithUs from '@/components/ChatWithUs.vue';

@Component({
  components: {
    ChatWithUs,
  },
})
export default class DataSyncWarning extends Vue {
  @Prop({default: 'daily'})
  public etlSchedule!: string;
  public showing: boolean = false;

  public created() {
    this.canShow();
  }

  public canShow() {
    if (this.etlSchedule === 'hourly') {
      this.showing = false;
      return;
    }
    if (this.$route.name === 'settings') {
      this.showing = true;
      return;
    }
    if (['daily', 'hourly_confirming'].includes(this.etlSchedule) && localStorage.getItem('dismiss_syncing_notice') !== 'true') {
      this.showing = true;
      return;
    }
    this.showing = false;
  }

  public dismissSyncingNotice() {
    localStorage.setItem('dismiss_syncing_notice', 'true');
    this.canShow();
  }
}
