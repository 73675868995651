import { get, del, post } from '@/services/http';
import { MeasureModel } from '@/models/measure';
import { DimensionModel } from '@/models/dimension';
import { AnalyticsTypeModel } from '@/models/analytics_type';
import { FilterColumnList } from '@/collections/filter_columns';
import { ParamList } from '@/collections/params';
import { ParamModel } from '@/models/param';
import { FilterColumnModel } from '@/models/filter_column';

export class NotificationModel {
  public id: number;
  public sentence: string;
  public day: string;
  public dayOfMonth: string;
  public merchant: string;
  public noticeId: string;
  public emails: string[];
  public repeat: string;
  public sent: string;
  public status: string;
  public time: string;
  public type: string;
  public measure: MeasureModel;
  public dimension: DimensionModel;
  public analyticsType: AnalyticsTypeModel;
  public filterColumns: FilterColumnList;
  public reportColumns: any = [];
  public reportParams: ParamList;
  public pivotOption: string = '';
  public timeRange?: string;
  public dataType?: string;
  public createdAt?: string;
  public updatedAt?: string;
  public scheduleDate: string;
  public title?: string;
  public fileFormat: string = 'csv';
  public data?: any;

  constructor(data: any = {}) {
    this.id = data.id;
    this.sentence = data.sentence;
    this.day = data.day;
    this.dayOfMonth = data.day_of_month;
    this.merchant = data.merchant;
    this.noticeId = data.noticeId;
    this.emails = data.emails || [];
    this.repeat = data.repeat;
    this.sent = data.sent;
    this.status = data.status;
    this.time = data.time;
    this.type = data.type;
    this.measure = new MeasureModel();
    this.dimension = new DimensionModel();
    this.analyticsType = new AnalyticsTypeModel();
    this.filterColumns = new FilterColumnList();
    this.reportParams = new ParamList();
    this.scheduleDate = this.getScheduleDate(data.day, data.day_of_month);
    this.title = data.title;
    this.fileFormat = data.file_format;
    this.data = [];
  }

  public async fetch() {
    const res: any = await get(`/user-notifications/${this.id}`);
    this.mapData(res.data);
  }

  public async save() {
    await post('/user-notifications', {
      sentence: this.sentence,
      day: this.day,
      day_of_month: this.convertDayOfMonthToValue(this.dayOfMonth),
      noticeId: this.noticeId,
      emails: this.emails,
      repeat: this.repeat,
      sent: this.sent,
      status: this.status,
      time: this.time,
      type: this.type,
      title: this.title,
      file_format: this.fileFormat,
      data: this.data,
    });
  }

  public async remove() {
    await del(`/user-notifications/${this.id}`);
  }

  public mapData(scheduleData: any = {}) {
    this.id = scheduleData.id;
    this.sentence = scheduleData.sentence;
    this.day = scheduleData.day;
    this.dayOfMonth = scheduleData.day_of_month;
    this.merchant = scheduleData.merchant;
    this.noticeId = scheduleData.noticeId;
    this.repeat = scheduleData.repeat;
    this.sent = scheduleData.sent;
    this.status = scheduleData.status;
    this.time = scheduleData.time;
    this.type = scheduleData.type;
    this.dataType = scheduleData.dataType;
    this.title = scheduleData.title;
    this.fileFormat = scheduleData.file_format;
    if (this.dataType === 'rules') {
      if (Array.isArray(scheduleData.data)) {
        this.data = scheduleData.data;
      } else {
        this.data.push(scheduleData.data);
      }
    }
    this.createdAt = scheduleData.created_at;
    this.updatedAt = scheduleData.updated_at;
    this.scheduleDate = this.getScheduleDate(scheduleData.day, scheduleData.day_of_month);
  }

  public getScheduleDate(day: string, dayOfMonth: string) {
    if (dayOfMonth && dayOfMonth !== '0') {
      return this.convertDayOfMonthToLabel(dayOfMonth);
    }
    return day;
  }

  public convertDayOfMonthToLabel(dayOfMonth: string) {
    switch (dayOfMonth) {
      case '-1':
        return 'Last day of Month';
      case '-2':
        return 'Day before end of Month';
      case '-3':
        return 'Two days before end of Month';
      default:
        return 'Day ' + dayOfMonth + ' of Month';
    }
  }

  public convertDayOfMonthToValue(dayOfMonth: string) {
    switch (dayOfMonth) {
      case 'Last day of Month':
        return '-1';
      case 'Day before end of Month':
        return '-2';
      case 'Two days before end of Month':
        return '-3';
      default:
        return dayOfMonth;
    }
  }

}
