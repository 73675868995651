import { get, put } from '@/services/http';

export class PermissionModel {
  public id?: number;
  public features: { [key: string]: boolean };
  public data: { [key: string]: boolean };
  public merchantId?: string;
  public status: string;
  public createdAt?: string;
  public updatedAt?: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.features = {
      notification: true,
    };
    this.data = {
      threeMonths: true,
      allHistory: true,
    };
    this.status = data.status;
  }

  public async fetch() {
    const res: any = await get('/permissions');
    this.mapData(res.data);
  }

  public mapData(data: any = {}) {
    if (!data) {
      return;
    }
    this.id = data.id;
    this.features = (data.data.features) ? data.data.features : { notification: false };
    this.data = (data.data.data) ? data.data.data : { threeMonths: true, allHistory: false };
    this.merchantId = data.merchantId;
    this.status = data.status;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}
