
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';


@Component({
  components: {

  },
})
export default class SchedulerComingSoon extends Vue {

}
