import { get, del, post, put } from '@/services/http';

export class ScheduleJobModel {
  public scheduleId: number;
  public id: number;
  public status: string;
  public localTime: any;
  public runTime: string;
  public result: string;
  public updatedAt: string;

  constructor(data: any = {}) {
    this.scheduleId = data.scheduleId;
    this.id = data.id;
    this.status = data.status;
    this.localTime = data.localTime;
    this.runTime = data.runTime;
    this.result = data.result;
    this.updatedAt = data.updatedAt;
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.status = data.status;
    this.localTime = data.localTime;
    this.runTime = data.runTime;
    this.result = data.result;
    this.updatedAt = data.updatedAt;
  }

}
