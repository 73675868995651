export class ChannelModel {
  public id: string;
  public name: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
  }
}
