
import { Component, Prop, Vue } from 'vue-property-decorator';
import OnboardPage from '../components/Onboard.vue';
import { StoreModel } from '@/models/store';
import LanguageSetting from '@/components/LanguageSetting.vue';
import { avatarColor } from '@/services/configs';

@Component({
  components: {
    OnboardPage,
    LanguageSetting,
  },
})
export default class Onboard extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public created() {
    const page = localStorage.getItem('onboard_page');
    if (page) {
      this.$router.push(page);
    }
  }
  public async nextStep() {
    this.$router.push('/onboard-contact');
  }

  public get avatarColor() {
    const s = this.store.shopName[0].toUpperCase();
    const value = s.charCodeAt(0);
    return avatarColor[value % avatarColor.length];
  }
}
