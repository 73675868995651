import { get, post } from '@/services/http';
import { ScheduleJobModel } from '@/models/schedule_job';
import List from './list';

export class ScheduleJobList extends List<ScheduleJobModel> {
  public async fetch(scheduleId: number) {
    const res: any = await get(`/schedule/${scheduleId}/jobs`, {});
    const data = res.data || [];
    this.mapData(data);
  }

  private mapData(data: any = []) {
    this.items = [];
    for (const s of data) {
      const job = new ScheduleJobModel();
      job.mapData(s);
      this.add(job);
    }
  }
}
