const DEEP_SKU_BLUE = '#59c8e4';
const ORANGE = '#faaf3b';
const DARK_BLUE = '#12222D';
const CHARCOAL_BLUE = '#2c4051';
const DARK_GRAY = '#484d4f';
const BLACK = '#000000';
const WHITE = '#fff';
const LIME_GREEN = '#99be50';
const YELLOW = '#f9d085';
const PINK = '#f6687b';
const GREEN = '#33b528';
const PURPLE = '#7657e4';
const TURQUOISE_BLUE = '#16b8a9';
const LIGHT1 = '#f4f4f4';
const LIGHT2 = '#fefaf3';
const LIGHT3 = '#e7f8f6';
const LIGHT4 = '#f6f6f7';
const LIGHT5 = '#dddddd';
const LIGHT6 = '#f4f8ed';

const chartColors = [
  DEEP_SKU_BLUE,
  TURQUOISE_BLUE,
  LIME_GREEN,
  YELLOW,
  PINK,
  GREEN,
  PURPLE,
  DEEP_SKU_BLUE,
  ORANGE,
];

const avatarColor = [
  ORANGE,
  LIME_GREEN,
  YELLOW,
  PINK,
  GREEN,
  PURPLE,
  TURQUOISE_BLUE,
];

module.exports = {
  chartColors,
  avatarColor,
  DEEP_SKU_BLUE,
  ORANGE,
  DARK_BLUE,
  CHARCOAL_BLUE,
  DARK_GRAY,
  BLACK,
  WHITE,
  LIME_GREEN,
  YELLOW,
  PINK,
  GREEN,
  PURPLE,
  TURQUOISE_BLUE,
  LIGHT1,
  LIGHT2,
  LIGHT3,
  LIGHT4,
  LIGHT5,
  LIGHT6,
}
