import { get, del } from '@/services/http';
import zlib from 'zlib';
import { Buffer } from 'buffer';

export class SpreadsheetConnectedModel {
  public title: string;
  public referenceId: number;
  public destination: string;
  public type: string;
  public scheduleId: number;

  constructor(data: any = {}) {
    this.title = data.title;
    this.referenceId = data.referenceId;
    this.type = data.type;
    this.destination = data.destination;
    this.scheduleId = data.id;
  }

  public mapData(data: any = {}) {
    this.title = data.title;
    this.referenceId = data.referenceId;
    this.type = data.type;
    this.destination = data.destination;
    this.scheduleId = data.id;
  }

  public async refreshData() {
    const response: any = await get(`/schedule/${this.scheduleId}/execute`);
    const data: string[] = response.data;
      if (data && Array.isArray(data) && data.length > 0) {
        return data[0];
      } else {
        return '';
      }
  }

  public async disconnect() {
    const response: any = await del(`/schedule/${this.scheduleId}`);
    return response.data;
  }
}
