
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import HeaderTitle from '@/components/HeaderTitle.vue';
import { get } from '@/services/http';
import { StoreModel } from '@/models/store';
import { EventHandler } from '@/modules/events';
import ActionButton from '@/components/ActionButton.vue';
import ScheduleCreate from '@/components/ScheduleCreate.vue';
import { ScheduleModel } from "@/models/schedule";
import {ScheduleProfileList} from "@/collections/schedule_profiles";
import {ScheduleJobList} from "@/collections/schedule_jobs";
import { EventBus } from '@/main';
import {isArray, isObject} from 'chart.js/helpers';
import InteractiveGuides from '@/components/InteractiveGuides.vue';

@Component({
  components: {
    HeaderTitle,
    ActionButton,
    ScheduleCreate,
    InteractiveGuides,
  },
})
export default class ScheduleDetail extends Vue {
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({default: null})
  public app!: any;
  public loading: boolean = false;
  public schedule: ScheduleModel = new ScheduleModel();
  public scheduleJobs: ScheduleJobList = new ScheduleJobList();
  public scheduleJobHeaders = [
    {
      text: "Status",
      value: "status",
      width: "",
    },
    {
      text: "Execute at",
      value: "localTime",
      width: "",
    },
    {
      text: "Result",
      value: "result",
      width: "",
    },
  ];

  public files!: any[];
  public testResult: string = '';
  public isLoadingTestSchedule: boolean = false;
  public dialogErrorTest: boolean = false;
  public errorTestMessage: string = '';

  public async created() {
    try {
      await this.initScheduleData();
    } catch (e) {
      // show error
      EventBus.$emit('show-snackbar', {
        message: 'Failed to load schedule data',
        color: 'error',
      });
    }

  }

  public async initScheduleData() {
    let scheduleId: number = Number(this.$route.params.schedule_id);
    if (scheduleId) {
      this.schedule.id = scheduleId;
      this.loading = true;
      await this.schedule.fetch();

      if (isObject(this.schedule.options)) {
        if (!this.schedule.options.filterColumns) {
          this.schedule.options.filterColumns = [];
        }
        if (!this.schedule.options.reportParams) {
          this.schedule.options.reportParams = [];
        }
        this.schedule.options = [this.schedule.options];
      }

      this.scheduleJobs = new ScheduleJobList();
      await this.scheduleJobs.fetch(scheduleId);
      this.loading = false;
    }
  }

  public operatorName(value: string) {
    const operators: any = [
      { name: "equal", value: "eq" },
      { name: "equal (case-insensitive)", value: "eqi" },
      { name: "not equal", value: "ne" },
      { name: "is lower than", value: "lt" },
      { name: "is lower than or equal to", value: "lte" },
      { name: "is greater than", value: "gt" },
      { name: "is greater than or equal to", value: "gte" },
      { name: "contains", value: "contains" },
      { name: "not contains", value: "notContains" },
      { name: "is blank", value: "null" },
      { name: "is not blank", value: "notNull" },
    ];
    for (const item of operators) {
      if (item.value === value) {
        return item.name;
      }
    }
    return value;
  }

  public filterValuesToString(values: any) {
    if (!values) {
      return "";
    }
    return values.join(" or ");
  }

  public async back() {
    this.$router.push("/schedules");
  }

  public async testSchedule() {
    try {
      this.isLoadingTestSchedule = true;
      const response: any = await this.schedule.runTest();
      const data: string[] = response.data;
      if (data && isArray(data) && data.length > 0) {
        this.testResult = data[0];
      } else {
        this.testResult = '';
      }
      this.isLoadingTestSchedule = false;
      EventBus.$emit('show-snackbar', {
        message: 'This schedule has been run successfully.',
        color: 'success',
      });
    } catch (e: any) {
      const errorJson = JSON.parse(e.response.data);
      if (errorJson && errorJson.error) {
        this.errorTestMessage = errorJson.message;
      } else {
        this.errorTestMessage = 'Failed to run this schedule';
      }
      this.dialogErrorTest = true;
    }
    this.isLoadingTestSchedule = false;
  }

}
