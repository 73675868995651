import { get } from '@/services/http';
import { ChannelModel } from '@/models/channel';
import List from './list';

export class ChannelList extends List<ChannelModel> {
  public async fetch() {
    const res: any = await get(`/channels`);
    this.mapData(res.data);
  }

  public mapData(data: any = []) {
    for (const s of data) {
      const channel = new ChannelModel();
      channel.mapData(s);
      this.add(channel);
    }
  }
}
