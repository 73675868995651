import { get, post } from '@/services/http';
import { UserPluginModel } from '@/models/user_plugin';
import List from './list';

export class UserPluginList extends List<UserPluginModel> {
  public mapData(data: any = []) {
    for (const item of data) {
      const plugin = new UserPluginModel();
      plugin.mapData(item);
      this.add(plugin);
    }
  }

  public async fetch(params: any = {}) {
    const res: any = await get(`/user_plugins`, params);
    const plugins = res.data || [];
    for (const data of plugins) {
      const plugin: UserPluginModel = new UserPluginModel();
      plugin.mapData(data);
      this.add(plugin);
    }
  }
}
