import { get } from '@/services/http';
import { ReportMetadataModel } from '@/models/report_metadata';
import List from './list';

export class ReportMetadataList extends List<ReportMetadataModel> {
  public async fetch() {
    const res: any = await get(`/template_reports`);
    const templateReports = res.data || [];
    const items = [];
    for (const s of templateReports) {
      const reportMetadata = new ReportMetadataModel();
      reportMetadata.mapData(s);
      items.push(reportMetadata);
    }
    this.items = items;
  }

  public async searchByOpenAI(search: string) {
    const res: any = await get(`/template_reports/search?keyword=${search}`);
    const templateReports = res.data || [];
    const items = [];
    for (const s of templateReports) {
      const reportMetadata = new ReportMetadataModel();
      reportMetadata.mapData(s);
      items.push(reportMetadata);
    }
    this.items = items;
  }
}
