
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { StoreModel } from '@/models/store';
import { LOCALES } from '@/i18n/locales';
import { defaultLocale } from '@/i18n';
import { i18n } from 'dateformat';
import { EventHandler } from '@/modules/events';

@Component({
  components: {
  },
})
export default class LanguageSetting extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public LOCALES: any = LOCALES;
  public defaultLocale: string = defaultLocale;
  public selectedLanguage: string = '';
  public languages: any = [
    {
      code: 'en',
      name: 'English',
      cname: '英语',
      ename: 'English',
    },
    {
      code: 'zh-CN',
      name: 'Chinese (Simplified)',
      cname: '中文 (简体)',
      ename: 'Chinese (Simplified)',
    },
    {
      code: 'fr',
      name: 'Français',
      cname: '法语',
      ename: 'French',
    },
    {
      code: 'de',
      name: 'Deutsch',
      cname: '德语',
      ename: 'German',
    },
    {
      code: 'ja',
      name: 'にほんご',
      cname: '日语',
      ename: 'Japanese',
    },
    {
      code: 'es',
      name: 'Español',
      cname: '西班牙语',
      ename: 'Spanish',
    },
    {
      code: 'it',
      name: 'Italiano',
      cname: '意大利语',
      ename: 'Italian',
    },
    {
      code: 'pt',
      name: 'Português',
      cname: '葡萄牙语',
      ename: 'Portuguese',
    },
    {
      code: 'nl',
      name: 'Nederlands',
      cname: '荷兰语',
      ename: 'Dutch',
    },
    {
      code: 'no',
      name: 'norsk',
      cname: '挪威语',
      ename: 'Norwegian',
    },
  ];

  public googleTranslateSelectedHandler(language: any) {
    const { code, name, cname, ename } = language;
    const selectedLanguage: string = code;
    this.defaultLocaleChanged(code);
  }

  private defaultLocaleChanged(code: string) {
    if (this.store.config) {
      this.$i18n.locale = code;
      this.store.config.language = code;
      localStorage.setItem('language', code);
      this.store.saveLanguage();
      try {
        const eventHandler = new EventHandler({
          store: this.store,
        });
        eventHandler.track(`Language Changed`, {
          locale: this.store.config.language,
        });
      } catch (e) {
        // ignore this
      }
    }
    switch (this.$i18n.locale) {
      case 'jp':
        i18n.dayNames = [
          '日曜日',
          '月曜日',
          '火曜日',
          '水曜日',
          '木曜日',
          '金曜日',
          '土曜日',
          '日曜日',
          '月曜日',
          '火曜日',
          '水曜日',
          '木曜日',
          '金曜日',
          '土曜日',
        ];

        i18n.monthNames = [
          '一月',
          '二月',
          '三月',
          '四月',
          '五月',
          '六月',
          '七月',
          '八月',
          '九月',
          '十月',
          '十一月',
          '十二月',
          '一月',
          '二月',
          '三月',
          '四月',
          '五月',
          '六月',
          '七月',
          '八月',
          '九月',
          '十月',
          '十一月',
          '十二月',
        ];

        i18n.timeNames = ['午前', '午後', '午前', '午後', '午前', '午後', '午前', '午後'];
        break;
      case 'cn':
      i18n.dayNames = [
        '太阳',
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六',
        '星期日',
        '周一',
        '周二',
        '周三',
        '周四',
        '星期五',
        '周六',
        ];

      i18n.monthNames = [
          '一月',
            '二月',
            '行进',
            '四月',
            '可能',
            '六月',
            '七月',
            '八月',
            '九月',
            '十月',
            '十一月',
            '十二月',
            '一月',
            '二月',
            '行进',
            '四月',
            '可能',
            '六月',
            '七月',
            '八月',
            '九月',
            '十月',
            '十一月',
            '十二月',
        ];
      i18n.timeNames = ['上午', '下午', '上午', '下午', '上午', '下午', '上午', '下午'];
      break;
      case 'es':
      i18n.dayNames = [
        'Sol',
        'Lun',
        'Mar',
        'Casarse',
        'Jue',
        'Vie',
        'Se sentó',
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
        ];

      i18n.monthNames = [
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre',
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre',
        ];
      i18n.timeNames = ['AM', 'PM', 'AM', 'PM', 'AM', 'PM', 'AM', 'PM'];
      break;
      case 'fr':
        i18n.dayNames = [
          'Soleil',
           'Lun',
           'Mar',
           'Épouser',
           'Jeu',
           'Ven',
           'Assis',
           'Dimanche',
           'Lundi',
           'Mardi',
           'Mercredi',
           'Jeudi',
           'Vendredi',
           'Samedi',
        ];

        i18n.monthNames = [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Peut',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre',
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Peut',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre',
        ];

        i18n.timeNames = [
          'du matin',
          'de l\'après-midi',
          'du matin',
          'de l\'après-midi',
          'du matin',
          'de l\'après-midi',
          'du matin',
          'de l\'après-midi',
          ];
        break;
      default:
        i18n.dayNames = [
          'Sun',
          'Mon',
          'Tue',
          'Wed',
          'Thu',
          'Fri',
          'Sat',
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ];

        i18n.monthNames = [
          'January',
           'February',
           'March',
           'April',
           'May',
           'June',
           'July',
           'August',
           'September',
           'October',
           'November',
           'December',
           'January',
           'February',
           'March',
           'April',
           'May',
           'June',
           'July',
           'August',
           'September',
           'October',
           'November',
           'December',
        ];

        i18n.timeNames = ['a', 'p', 'am', 'pm', 'a', 'p', 'am', 'pm'];
        break;
    }
  }
}
