import { get, post } from '@/services/http';
import { DimensionModel } from '@/models/dimension';
import List from './list';

export class DimensionList extends List<DimensionModel> {
  public mapData(data: any = []) {
    for (const s of data) {
      const dimension = new DimensionModel();
      dimension.mapData(s);
      this.add(dimension);
    }
  }

  public sort() {
    const compare = (a: DimensionModel, b: DimensionModel) => {
      if (a.sortOrder < b.sortOrder) {
        return -1;
      }
      if (a.sortOrder > b.sortOrder ) {
        return 1;
      }
      return 0;
    };
    this.items = this.items.sort(compare);
  }
}
