
import { Component, Watch, Vue, Prop } from 'vue-property-decorator';
import Chart from 'chart.js/auto';
import { ReportModel } from '@/models/report';
import { chartColors } from "@/services/configs";

@Component({})
export default class ReportCardDoughnutChart extends Vue {

  public chart?: any;
  @Prop({default: false})
  public needReload!: boolean;
  @Prop({default: []})
  public selectedReportColumns!: any;
  public activeColumns: any = [];
  @Prop({default: new ReportModel()})
  public report!: ReportModel;
  @Prop({default: 0})
  public id!: number;
  public noData: boolean = false;

  public reload() {
    if (this.chart) {
        this.chart.destroy();
      }
      if (this.report.columns.length <= 1) {
        this.noData = true;
        return;
      } else {
        this.noData = false;
      }
    this.removeUnselectedColumn();
    const timeSeries: string[] = ['Day', 'Week', 'Month', 'Quarter', 'Year'];
    if (this.report.rows.length > 5
      && this.report.columns[0] && !timeSeries.includes(this.report.columns[0].name)) {
      this.report.columns[0].values = this.report.columns[0].values.slice(0, 5);
      const originalValues: number[] = [];
      for (const index in this.report.columns[1].originalValues) {
        if (this.report.columns[1].originalValues[index]) {
          if (Number(index) < 6) {
            originalValues[index] = Number(this.report.columns[1].originalValues[index]);
            continue;
          }
        }
      }
      this.report.columns[1].originalValues = originalValues.map(String);
    }
    const ctx = document.getElementById('doughnutChartReport' + this.id);
    if (ctx instanceof HTMLCanvasElement) {
      if (this.chart) {
        this.chart.destroy();
      }
      /* Chart.defaults.font.size = 16; */
      /* Chart.defaults.font.family = 'Oxygen'; */
      Chart.defaults.elements.point.radius = 0;
      Chart.defaults.elements.point.hoverRadius = 5;
      const datasets = [];
      const color = chartColors;

      const multiDataSets: any = {};
      const maxLines: number = 5;
      let mainDimIdx: number = 0;
      let secondDimIdx: number = 1;
      if (this.report.columns.length > 1 && timeSeries.includes(this.report.columns['1'].name)) {
        mainDimIdx = 1;
        secondDimIdx = 0;
      }

      /** build dimensions */
      let dims: string[] = [];
      if (this.report.columns.length > 0) {
        dims = this.report.columns[mainDimIdx].values;
      }

      /** build data sets */

      for (const key in this.report.columns) {
        if (this.report.columns.hasOwnProperty(key)) {
          if (this.report.columns[key]) {
            if (key === '0') {
              continue;
            }
            const column = this.report.columns[key];
            if (column.dataType !== 'text' || key !== '1') {
              if (!this.isSelectedColumn(column.code)) {
                continue;
              }
              /** add measure to data sets */
              const bgColor: any = [];
              let i = 0;
              for (const c of color) {
                bgColor.push(c);

                if (i > column.originalValues.length) {
                  continue;
                }
                i = i + 1;
              }
              datasets.push(
              {
                label: this.getSelectedColumnName(column),
                data: column.originalValues,
                backgroundColor: bgColor,
                borderColor: bgColor,
                borderWidth: 3,
              },
              );
            } else {
              /** convert second dimension to multiple data sets */
              for (const idx in this.report.columns[secondDimIdx].values) {
                if (this.report.columns[secondDimIdx].values[idx] && this.report.columns["2"]) {
                  const v = this.report.columns[secondDimIdx].values[idx] + ' - ' + this.getSelectedColumnName(this.report.columns['2']);
                  if (!multiDataSets[v] && Object.keys(multiDataSets).length < maxLines) {
                    const newDataSet: any = {};
                    multiDataSets[v] = newDataSet;
                  }
                  if (multiDataSets[v]) {
                    multiDataSets[v][this.report.columns[mainDimIdx].values[idx]] =
                      this.report.columns['2'].originalValues[idx];
                  }
                }
              }
              break;
            }
          }
          break;
        }
      }

      this.chart = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: dims,
          datasets,
        },

      });

    }
    this.$emit('reload-completed', 'DOUGHNUT_CHART');
}

public getSelectedColumnName(column: any) {
    for (const item of this.selectedReportColumns) {
      if (item.code === column.code) {
        return item.name;
      }
    }
    return column.name;
  }

  public isSelectedColumn(code: string) {
    if (!this.selectedReportColumns || this.selectedReportColumns.length === 0) {
      return true;
    }
    for (const c of this.selectedReportColumns) {
      if (c.code === code) {
        return true;
      }
    }
    return false;
  }

  public removeUnselectedColumn() {
    const newColumns: any = [];
    let isFirst = true;
    this.activeColumns = this.selectedReportColumns;
    if (!this.selectedReportColumns || this.selectedReportColumns.length === 0) {
      this.activeColumns = this.report.columns;
    }
    for (const column of this.activeColumns) {
      if (['image'].includes(column.code) && isFirst) {
        continue;
      }
      if (['text', 'date', 'datetime'].includes(column.dataType) && !isFirst) {
        continue;
      }
      isFirst = false;
      newColumns.push(this.getReportColumnData(column));
    }
    this.activeColumns = newColumns;
    this.report.columns = newColumns;
  }

  public getReportColumnData(activeColumn: any) {
    for (const item of this.report.columns) {
      if (item.code === activeColumn.code) {
        item.name = activeColumn.name;
        return item;
      }
    }
  }

  @Watch('needReload', { immediate: true, deep: true  })
  private async onNeedReloadTableChanged(newVal: any, oldVal: any) {
    if (newVal === true) {
      this.reload();
    }
  }
}
