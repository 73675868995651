
import { Component, Vue, Prop } from 'vue-property-decorator';
import ActionButton from '@/components/ActionButton.vue';
import FeatureLockedNotify from '@/components/FeatureLockedNotify.vue';
import { StoreModel } from '@/models/store';
import { RuleModel } from '@/models/rule';
import { CustomReportList } from '@/collections/custom_reports';

@Component({
  components: {
    ActionButton,
    FeatureLockedNotify,
  },
})
export default class DialogCreateReport extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public customReports: CustomReportList = new CustomReportList();
  @Prop({default: () => new CustomReportList()})
  public preloadCustomReports!: CustomReportList;
  public dialogAddReport: boolean = false;
  public lockReportRule: RuleModel = new RuleModel({code: 'favorite_reports_count'});
  @Prop({default: ''})
  public buttonSize!: string;
  @Prop({default: true})
  public primary!: boolean;
  @Prop({default: true})
  public showIcon!: boolean;
  public async created() {
    this.getLockReportRule();
    if (this.preloadCustomReports.size() === 0) {
      await this.customReports.fetch();
    }
  }

  public get favoriteReportCount() {
    return this.preloadCustomReports.size() || this.customReports.size();
  }
  public canAddCustomReport() {
    const validateValues: any = [
      {
        code: 'favorite_reports_count',
        value: this.favoriteReportCount + '',
      },

    ];
    const validateResult = this.store.validateRule(validateValues);
    return validateResult.isValidate;
  }

  public createCustomReport() {
    if (!this.canAddCustomReport()) {
      this.dialogAddReport = true;
    } else {
      this.$router.push('/report-select');
    }
  }
  public getLockReportRule() {
    for (const rule of this.store.currentPackage.rules.items) {
      if (rule.code === this.lockReportRule.code) {
        this.lockReportRule = rule;
        break;
      }
    }
  }
}
