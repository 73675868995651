import { get } from '@/services/http';
import { DimensionModel } from '@/models/dimension';

export class FilterDimensionModel {
  public id: number;
  public name: string;
  public dimension: DimensionModel;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.dimension = new DimensionModel();
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
  }
}
