
import { Component, Prop, Vue } from 'vue-property-decorator';
import OnboardPage from '../components/Onboard.vue';
import { StoreModel } from '@/models/store';

@Component({
  components: {
    OnboardPage,
  },
})
export default class OnboardReport extends Vue {
  public topReports: string = '';
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public loading: boolean = false;
  public error: boolean = false;
  public valid: boolean = false;
  public purposeRules: any = [
    this.isPurposeExist,
    this.isMaxLength,
  ];

  public created() {
    localStorage.setItem('onboard_page', '/onboard-report');
    this.topReports = this.store.config.topReports;
  }

  public isPurposeExist(v: any) {
    return !!v || 'This is required!';
  }

  public isMaxLength(v: any) {
    return !!(v.length < 200) || 'The content is too long!';
  }

  public async nextStep() {
    if (this.$refs.form) {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    }
    if (this.valid) {
      this.store.config.topReports = this.topReports;
      this.error = false;
      this.loading = true;
      await this.store.saveSettings();
      this.loading = false;
      this.$router.push('/onboard-pricing');
    }
  }
}
