import { get, post, put, del } from '@/services/http';
import { TagList } from '@/collections/tags';
import { TagModel } from '@/models/tag';

export class TagTypeModel {
  public id: number;
  public name: string;
  public tags: TagList = new TagList();
  public hasReport: boolean = true;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.tags = new TagList();
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
  }
}
