
import { Component, Prop, Vue } from 'vue-property-decorator';
import HeaderTitle from '../components/HeaderTitle.vue';
import ActionButton from '../components/ActionButton.vue';
import { StoreModel } from '@/models/store';
import { RouterLink } from 'vue-router';
import { PluginList } from '@/collections/plugins';
import { PluginModel } from '@/models/plugin';
import { BillingPackageList } from '@/collections/billing_packages';
import { BillingPackageModel } from '@/models/billing_package';
import { UserPluginModel } from '@/models/user_plugin';
import { PluginPackageList } from '@/collections/plugin_packages';
import { UserPluginList } from '@/collections/user_plugins';
import { UserPackageModel } from '@/models/user_package';
import { isShopifyEmbedded } from '@shopify/app-bridge/utilities';
import { Redirect, History } from '@shopify/app-bridge/actions';
import { EventHandler } from '@/modules/events';
import { EventBus } from '@/main';
import { PluginPackageModel } from '@/models/plugin_package';


@Component({
  components: {
    HeaderTitle,
    ActionButton,
  },
})
export default class OrderConfirm extends Vue {
  @Prop({ default: null })
  public app!: any;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: false })
  public hasSchemas!: boolean;
  public plugins: PluginList = new PluginList();
  public installedPlugins: any = [];
  public availablePlugins: any = [];
  public uninstallingPlugins: any = [];
  public billingPackages: BillingPackageList = new BillingPackageList();
  public currentBillingPackage: BillingPackageModel = new BillingPackageModel();
  public pluginPackages: PluginPackageList = new PluginPackageList();
  public userPlugins: UserPluginList = new UserPluginList();
  public newUserPackage: UserPackageModel = new UserPackageModel();
  public loading: boolean = false;
  public submitting: boolean = false;
  public installingId: number = 0;
  public uninstallingId: number = 0;

  public async created() {
    this.loading = true;
    this.installingId = Number(this.$route.query.installingId);
    this.uninstallingId = Number(this.$route.query.uninstallingId);
    await this.getBillingCurrentPackage();
    await this.plugins.fetch();
    await this.pluginPackages.fetchByPackage(this.currentBillingPackage.id);
    await this.userPlugins.fetch();
    this.getInstalledPlugins();
    this.loading = false;
  }

  public getTotalPrice() {
    let totalPrice: number = 0;
    totalPrice = this.currentBillingPackage.price;
    console.log(this.store.currentPackage)
    for (const item of this.installedPlugins) {
      if (!item.uninstalling) {
        totalPrice = totalPrice + this.getInstalledPluginPrice(item.userPlugin);
      }

    }
    for (const item of this.availablePlugins) {
      if (item.selecting) {
        totalPrice = totalPrice + this.getPluginPrice(item.plugin);
      }
    }
    if (this.store.currentPackage.interval === 'ANNUAL') {
      totalPrice = totalPrice * 10;
    }
    return totalPrice;
  }

  public get selectedPluginCount() {
    let count: number = 0;
    for (const item of this.installedPlugins) {
      if (!item.uninstalling) {
        count = count + 1;
      }
    }
    for (const item of this.availablePlugins) {
      if (item.selecting) {
        count = count + 1;
      }
    }
    return count;
  }

  public cancelUninstall(index: number) {
    this.installedPlugins[index].uninstalling = false;
  }

  public getInstalledPlugins() {
    for (const plugin of this.plugins.items) {
      let isInstalled: boolean = false;
      for (const userPlugin of this.userPlugins.items) {
        if (userPlugin.pluginId === plugin.id) {
          isInstalled = true;
          this.installedPlugins.push({
            userPlugin,
            plugin,
            uninstalling: this.uninstallingId === plugin.id ? true : false,
          });
        }
      }
      let hasPackage: boolean = false;
      let pluginPackage: PluginPackageModel = new PluginPackageModel();
      for (const item of this.pluginPackages.items) {
        if (item.pluginId === plugin.id) {
          hasPackage = true;
          pluginPackage = item;
          break;
        }
      }
      if (!isInstalled) {
        this.availablePlugins.push(
          {
            selecting: this.installingId === plugin.id ? true : false,
            plugin,
            hasPackage,
            pluginPackage,
          },
        );
      }
    }
  }

  public async getBillingCurrentPackage() {
    await this.billingPackages.fetch();
    for (const item of this.billingPackages.items) {
      if (item.id === this.store.currentPackage.billingPackageId) {
        this.currentBillingPackage = item;
      }
    }
  }

  public addToPlan(plugin: PluginModel) {
    const isAdded: boolean = false;
    let index: number = 0;
    for (const item of this.availablePlugins) {
      if (item.plugin.id === plugin.id) {
        this.availablePlugins[index].selecting = item.selecting;
        item.selecting = !item.selecting;
        break;
      }
      index = index + 1;
    }

  }

  public viewLink(link: any) {
    if (link) {
      this.$router.push(link);
    } else {
      this.$router.push('/');
    }
  }

  public viewPluginDetail(pluginId: number) {
    this.$router.push(`/plugin/${pluginId}`);
  }

  public getPluginPrice(plugin: PluginModel) {
    if (plugin && plugin.id) {
      const packageId: number = this.currentBillingPackage.id;
      for (const item of this.pluginPackages.items) {
        if (item.packageId === packageId && item.pluginId === plugin.id) {
          return item.price;
        }
      }
    }
    return 0;
  }

  public getInstalledPluginPrice(userPlugin: UserPluginModel) {
    if (userPlugin && userPlugin.pluginId) {
      const packageId: number = this.currentBillingPackage.id;
      for (const item of this.pluginPackages.items) {
        if (item.packageId === packageId && item.pluginId === userPlugin.pluginId) {
          if (item.price < userPlugin.price) {
            return item.price;
          }
        }
      }
    }
    return userPlugin.price;
  }

  public getPluginOriginalPrice(plugin: PluginModel) {
    if (plugin && plugin.id) {
      const packageId: number = this.currentBillingPackage.id;
      for (const item of this.pluginPackages.items) {
        if (item.packageId === packageId && item.pluginId === plugin.id) {
          return item.originalPrice;
        }
      }
    }
    return 0;
  }

  public getTrialDayLeft() {
    let trialEndAt: any = this.store.currentPackage.trialEndAt;
    if (trialEndAt === undefined) {
      return 0;
    }
    trialEndAt = new Date((trialEndAt && trialEndAt !== undefined) ? trialEndAt : '');

    let trialDaysLeft: number = 0;
    const price: number = this.store.currentPackage.price;
    if (price > 0) {
      const currentDate = new Date();
      if (trialEndAt < currentDate) {
        trialDaysLeft = 0;
      } else {
        const timeDiff = Math.abs(trialEndAt.getTime() - currentDate.getTime());
        trialDaysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
      }
    }
    return trialDaysLeft;
  }

  public needInstallPlugins() {
    const plugins: number[] = [];
    for (const item of this.installedPlugins) {
      if (!item.uninstalling) {
        plugins.push(item.plugin.id);
      }
    }
    for (const item of this.availablePlugins) {
      if (item.selecting) {
        plugins.push(item.plugin.id);
      }
    }
    return plugins;
  }

  public isNeedShopifyApprove() {
    const needAprpove: boolean = false;
    for (const item of this.installedPlugins) {
      if (item.uninstalling && item.userPlugin.price > 0) {
        return true;
      }
    }
    for (const item of this.availablePlugins) {
      if (item.selecting && item.pluginPackage.price > 0) {
        return true;
      }
    }
    return needAprpove;
  }

  public async uninstallFreePlugins() {
    for (const item of this.installedPlugins) {
      if (item.uninstalling && item.userPlugin.price === 0) {
        const newUserPlugin: UserPluginModel = new UserPluginModel();
        await newUserPlugin.uninstall(item.userPlugin.id);
      }
    }
  }

  public async installFreePlugins() {
    for (const item of this.availablePlugins) {
      if (item.selecting && item.pluginPackage.price === 0) {
        const newUserPlugin: UserPluginModel = new UserPluginModel();
        newUserPlugin.pluginId = item.plugin.id;
        newUserPlugin.merchantId = this.store.id;
        newUserPlugin.price = item.pluginPackage.price;
        newUserPlugin.rules = item.plugin.rules;
        await newUserPlugin.create();
      }
    }
  }

  public async submitPackage() {
    this.submitting = true;
    if (!this.isNeedShopifyApprove()) {
      await this.uninstallFreePlugins();
      await this.installFreePlugins();
      this.submitting = false;

      await this.reloadPackageAdnRules();

      this.$router.push('subscription');
      return;
    }

    const trialDays = this.getTrialDayLeft();

    this.newUserPackage.billingPackageId = this.currentBillingPackage.id;
    let returnUrl = `${process.env.VUE_APP_SUBSCRIPTION_RETURN_URL}`;
    const discount = 0;

    if (isShopifyEmbedded()) {
      returnUrl = `https://admin.shopify.com/store/${this.store.shopName.split('.')[0]}/apps/${process.env.VUE_APP_NAME}/subscription/confirm`;
    } else {
      returnUrl = process.env.VUE_APP_SUBSCRIPTION_RETURN_URL;
    }
    let isYearly: boolean = true;
    if (this.store.currentPackage.interval === 'EVERY_30_DAYS') {
      isYearly = false;
    } else {
      isYearly = true;
    }
    const confirmationUrl = await this.newUserPackage.create(
      trialDays,
      discount,
      returnUrl,
      this.needInstallPlugins(),
      isYearly,
    );

    if (this.newUserPackage.status === 'CONFIRMING' && confirmationUrl) {
      let eventName = 'CONFIRMING_PAID_PACKAGE';
      if (discount > 0) {
        eventName = `CONFIRMING_PAID_PACKAGE_AFTER_OFFER_${discount * 100}`;
      }
      try {
        const eventHandler = new EventHandler({
          store: this.store,
        });
        eventHandler.track(eventName, {
          billing_package_id: this.newUserPackage.billingPackageId,
          version: this.newUserPackage.billingPackageVersion,
        });
        this.store.sendEvent(eventName, {
          billing_package_id: this.newUserPackage.billingPackageId,
          version: this.newUserPackage.billingPackageVersion,
        });
      } catch (e) {
        // ignore this
      }

      // reload current package and rules
      await this.reloadPackageAdnRules();

      if (isShopifyEmbedded()) {
        Redirect.create(this.app).dispatch(
          Redirect.Action.REMOTE,
          confirmationUrl,
        );
      } else {
        window.location.assign(confirmationUrl);
      }
    } else {
      try {
        EventBus.$emit('current-package-changed');
        const eventHandler = new EventHandler({
          store: this.store,
        });
        eventHandler.track('PLUGIN_CHANGED', {
          billing_package_id: this.newUserPackage.billingPackageId,
          version: this.newUserPackage.billingPackageVersion,
        });
        this.store.sendEvent('PLUGIN_CHANGED', {
          billing_package_id: this.newUserPackage.billingPackageId,
          version: this.newUserPackage.billingPackageVersion,
        });
      } catch (e) {
        // ignore this
      }

      // reload current package and rules
      await this.reloadPackageAdnRules();

      if (isShopifyEmbedded()) {
        Redirect.create(this.app).dispatch(
          Redirect.Action.REMOTE,
          returnUrl = `https://${this.store.shopName}/admin/apps/${process.env.VUE_APP_NAME}/subscription`,
        );
      } else {
        window.location.assign('/subscription');
      }
    }
    this.submitting = false;
  }

  public async reloadPackageAdnRules () {
    if (!this.uninstallingId && !this.installingId) {
      return;
    }
    try {
      await this.store.getRules();
      await this.store.currentPackage.getCurrent();
    } catch (e) {
      console.log(e);
      // skipped
    }
  }
}
