import { get, post } from '@/services/http';
import { NotificationModel } from '@/models/notification';
import List from './list';

export class NotificationList extends List<NotificationModel> {
  public countAll: number = 0;

  public async fetch() {
    const res: any = await get(`/user-notifications`, {});
    const data = res.data || [];
    this.mapData(data);
  }

  public async count() {
    const res: any = await get(`/user-notifications/count`, {});
    this.countAll = res.data || 0;
  }

  private mapData(data: any = []) {
    for (const s of data) {
      const notification = new NotificationModel();
      notification.mapData(s);
      this.add(notification);
    }
  }
}
