
import { Component, Vue, Prop } from 'vue-property-decorator';
import { StoreModel } from '@/models/store';
import ChatWithUs from '@/components/ChatWithUs.vue';

@Component({
  components: {
    ChatWithUs,
  },
})
export default class DataSyncTimeline extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  public get totalOrders() {
    if (!this.store.orderCount) {
      return 0;
    }
    return this.store.orderCount.apiCount;
  }
  public get totalProducts() {
    if (!this.store.productCount) {
      return 0;
    }
    return this.store.productCount.apiCount;
  }
  public get importedOrders () {
    if (!this.store.orderCount) {
      return 0;
    }
    return this.store.orderCount.modelCount;
  }
  public get importedProducts () {
    if (!this.store.productCount) {
      return 0;
    }
    return this.store.productCount.modelCount;
  }
  public get generateAnalyticsDataDone() {
    return this.isAthenaFinished;
  }
  public get isImportedOrdersFinished() {
    return this.importedOrders >= this.totalOrders;
  }
  public get isImportedProductsFinished() {
    return this.importedProducts >= this.totalProducts;
  }
  public get initialDataImportDone() {
    //return false;
    return this.isImportedOrdersFinished && this.isImportedProductsFinished;
  }
  public created() {
    return;
  }
}
