import { Locales } from './locales';

import en from './en.json';
import fr from './fr.json';
import jp from './jp.json';
import cn from './cn.json';
import es from './es.json';

export const messages = {
  [Locales.EN]: en,
  [Locales.FR]: fr,
  [Locales.JP]: jp,
  [Locales.CN]: cn,
  [Locales.ES]: es,
};

export const defaultLocale = Locales.EN;
