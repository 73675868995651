import md5 from 'md5';
import { get } from '@/services/http';
import { RelatedLinkModel } from '@/models/related_link';
import List from './list';

export class RelatedLinkList extends List<RelatedLinkModel> {
  public async fetch(params: any = {}) {
    const path = `/questions/related_links`;
    const hash = `cache_${md5(path)}_${md5(JSON.stringify(params))}`;
    let data = localStorage.getItem(hash);
    if (!data) {
      const relatedLinks: any = await get(
        path,
        params,
      );
      data = relatedLinks.data;
      localStorage.setItem(hash, JSON.stringify(data));
    } else {
      data = JSON.parse(data);
    }
    if (!data) {
      return;
    }
    for (const s of data) {
      const relatedLink: RelatedLinkModel = new RelatedLinkModel();
      relatedLink.mapData(s);
      this.add(relatedLink);
    }
  }
}
