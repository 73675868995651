import { filter } from 'vue/types/umd';
import List from './list';
import { FilterColumnModel } from '@/models/filter_column';

export class FilterColumnList extends List<FilterColumnModel> {
    public mappData(items: any) {
        this.items = [];
        for (const item of items) {
            const filterColumn: FilterColumnModel = new FilterColumnModel();
            filterColumn.mapData(item);
            this.add(filterColumn);
        }
    }
}
