
import { Component, Watch, Prop } from 'vue-property-decorator';
import Report from '@/components/Report';
import { StoreModel } from '@/models/store';

interface Option {
  sortBy?: any;
  sortDesc?: any;
  page?: string;
  itemsPerPage?: string;
  multiSort?: boolean;
}

interface SelectingItem {
  id?: number;
  isSelected?: boolean;
}

@Component({
  components: {
    Report,
  },
})
export default class DataTable extends Report {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public headers: any = [];
  public rows: any = [];
  public datatableClass: string = '';
  public tableLoading: boolean = false;
  @Prop({ default: () => {} })
  public initOptions!: Option;
  public options: Option = {};
  public menuX: number = 0;
  public menuY: number = 0;
  public headerMenuY: number = 0;
  public headerMenuX: number = 0;
  public showHeaderOptionMenu: boolean = false;
  public selectingHeader: any = {};
  public needCount: boolean = true;
  public typeReport: string = 'TABLE';
  public errorData: boolean = false;
  public fisrtTimeInitOption: boolean = false;
  public isResetOptions: boolean = false;
  public async created() {

  }

  public generateReportFilterColumns() {
    const reportFilterColumns: any = [];
    if (this.report && this.report.columns && this.report.columns.length > 0) {
      for (const c of this.report.columns) {
        if (c.filterable !== false) {
          reportFilterColumns.push({
            name: c.name,
            code: c.code,
            dataType: c.dataType,
            luisMapping: c.luisMapping,
          });
        }
      }
    }
    if (reportFilterColumns.length > 0) {
      this.$emit('init-report-filter-columns', reportFilterColumns);
    }
  }

  public reload() {
    if (!this.report || !this.report.rows || this.report.rows.length === 0) {
      return;
    }
    const selectedReportColumns = this.report.columns;
    this.headers = [];
    this.rows = [];
    /* this.headers.push({ */
    /*   text: 'Select All', */
    /*   value: 'actions', */
    /*   class: ['table-header', 'non-sortable-header'], */
    /*   sortable: false, */
    /*   filterable: false, */
    /*   cellClass: ['table-item', 'non-sortable-item'], */
    /*   description: '', */
    /* }); */
    this.headers.push({
      text: 'ID',
      value: 'product_id',
      class: 'table-header',
      sortable: false,
      filterable: false,
      cellClass: 'table-item',
      description: 'Product Id',
    });
    for (const c of this.report.columns) {
      if (c.code  === 'product_id') {
        continue;
      }
      this.headers.push({
        text: c.name,
        value: c.code,
        class:
          c.sortable !== false
            ? 'table-header'
            : ['table-header', 'non-sortable-header'],
        sortable: c.sortable !== false ? true : false,
        filterable: c.filterable !== false ? true : false,
        cellClass:
          c.sortable !== false
            ? 'table-item'
            : ['table-item', 'non-sortable-item'],
        description: c.description,
      });
    }

    for (const index in this.report.rows) {
      if (this.report.rows[index]) {
        const res: any = {};
        for (const column of this.report.columns) {
          if (column.values[index]) {
            res[column.code] = column.values[index];
          } else {
            if (
              column.dataType === 'number' ||
              column.dataType === 'decimal' ||
              column.dataType === 'currency' ||
              column.dataType === 'percent'
            ) {
              res[column.code] = '0';
            } else {
              res[column.code] = '-';
            }
          }
          if (
            column.originalValues[index] === null &&
            column.sortable === false
          ) {
            res[column.code] = '';
          }
        }
        this.rows.push(res);
      }
    }
    this.generateReportFilterColumns();
    this.generateReportColumns();
  }

  public isActiveSort(isDesc: boolean = true) {
    if (
      !this.sortDesc ||
      this.sortDesc.length === 0 ||
      !this.sortBy ||
      this.sortBy.length === 0
    ) {
      return false;
    }
    let isSorted: boolean = false;
    let sortDesc: boolean = false;
    let index: number = 0;
    for (const sortBy of this.sortBy) {
      if (sortBy === this.selectingHeader.value) {
        sortDesc = this.sortDesc[index];
        isSorted = true;
        break;
      }
      index++;
    }
    if (isSorted && sortDesc === isDesc) {
      return true;
    }
    return false;
  }

  public generateReportColumns() {
    const reportColumns: any = [];
    if (this.report && this.report.columns && this.report.columns.length > 0) {
      for (const c of this.report.columns) {
        reportColumns.push({
          name: c.name,
          code: c.code,
          dataType: c.dataType,
          luisMapping: c.luisMapping,
        });
      }
    }
    if (reportColumns.length > 0) {
      this.$emit('update-report-column', reportColumns);
    }
  }

  public get getTableHeight() {
    return document.documentElement.clientHeight - 200;
  }

  public headerOptionMenuClick(header: any) {
    if (header.value === 'actions') {
      return;
    }
    this.showHeaderOptionMenu = true;
    this.selectingHeader = header;
    this.getItemMenuPosition();
  }

  public getItemMenuPosition() {
    const e = window.event;
    if (e === undefined) {
      return;
    }
    if (e && e !== undefined) {
      // @ts-ignore: Unreachable code error
      this.menuX = e.clientX;
      this.headerMenuX = this.menuX;
      // @ts-ignore: Unreachable code error
      this.menuY = e.clientY;
      this.headerMenuY = this.menuY + 20;
    }
  }

  public showSortArrow(headerCode: string, isDesc: boolean = true) {
    if (
      !this.sortDesc ||
      this.sortDesc.length === 0 ||
      !this.sortBy ||
      this.sortBy.length === 0
    ) {
      return false;
    }
    let isSorted: boolean = false;
    let sortDesc: boolean = false;
    let index: number = 0;
    for (const sortBy of this.sortBy) {
      if (sortBy === headerCode) {
        sortDesc = this.sortDesc[index];
        isSorted = true;
        break;
      }
      index++;
    }
    if (isSorted && sortDesc === isDesc) {
      return true;
    }
    return false;
  }

  public applySortColumn(sortDesc: boolean = true) {
    // check if the selecting column is sorted or not
    let isSorted: boolean = false;
    let index: number = 0;
    let isSortDesc: boolean = false;
    if (!this.options.sortBy || this.options.sortBy.length === 0) {
      this.options.sortBy = [];
      this.options.sortDesc = [];
    }
    for (const item of this.options.sortBy) {
      if (item === this.selectingHeader.value) {
        isSorted = true;
        isSortDesc = this.options.sortDesc[index];
        break;
      }
      index++;
    }
    if (isSorted && isSortDesc === sortDesc) {
      // remove sort for this columns
      this.options.sortBy.splice(index, 1);
      this.options.sortDesc.splice(index, 1);
    } else if (isSorted && isSortDesc !== sortDesc) {
      // change direction of the sort for this column
      this.options.sortDesc[index] = sortDesc;
    } else {
      // add new sort for this columns
      this.options.sortBy.push(this.selectingHeader.value);
      this.options.sortDesc.push(sortDesc);
    }
    this.optionChanged();
    this.showHeaderOptionMenu = false;
  }

  public getSortIndex(headerCode: string) {
    if (
      !this.sortDesc ||
      this.sortDesc.length < 2 ||
      !this.sortBy ||
      this.sortBy.length < 2
    ) {
      return 0;
    }
    let index: number = 0;
    for (const sortBy of this.sortBy) {
      if (sortBy === headerCode) {
        return index + 1;
      }
      index++;
    }
    return 0;
  }

  public async optionChanged() {
    this.page = this.options.page;
    this.perPage = this.options.itemsPerPage;
    this.sortBy = this.options.sortBy;
    this.sortDesc = this.options.sortDesc;
    this.$emit('update-report-option', this.options);
    this.tableLoading = true;
    this.rows = [];

    try {
      if (this.isCreated) {
        await this.getData();
      } else {
        await this.getReport();
      }
    } catch (error: any) {
      this.tableLoading = false;
      this.isLoading = false;
      throw error;
    }
    this.tableLoading = false;
  }

  public isOptionsChanged() {
    if (this.page !== this.options.page) {
      return true;
    }
    if (this.perPage !== this.options.itemsPerPage) {
      return true;
    }
    if (
      this.options.sortBy &&
      this.options.sortBy.length &&
      this.sortBy &&
      this.sortBy.length > 0 &&
      this.options.sortBy.length !== this.sortBy.length
    ) {
      return true;
    }
    if (
      this.options.sortDesc &&
      this.options.sortDesc.length > 0 &&
      this.sortDesc &&
      this.sortDesc.length > 0 &&
      this.sortDesc.length !== this.options.sortDesc.length
    ) {
      return true;
    }

    if (
      this.options.sortDesc &&
      this.sortDesc &&
      this.options.sortDesc.length === this.sortDesc.length
    ) {
      for (let index = 0; index < this.options.sortDesc.length; index++) {
        if (this.options.sortDesc[index] !== this.sortDesc[index]) {
          return true;
        }
      }
    }

    if (
      this.options.sortBy &&
      this.sortBy &&
      this.options.sortBy.length === this.sortBy.length
    ) {
      for (let index = 0; index < this.options.sortDesc.length; index++) {
        if (this.options.sortBy[index] !== this.sortBy[index]) {
          return true;
        }
      }
    }
    return false;
  }

  @Watch('options', { immediate: true, deep: true })
  private async optionsChanged(newVal: any, oldVal: any) {
    if (this.fisrtTimeInitOption) {
      this.fisrtTimeInitOption = false;
      return;
    }
    if (this.isResetOptions) {
      this.isResetOptions = false;
      return;
    }
    if (this.isOptionsChanged()) {
      this.optionChanged();
    }
  }
}
