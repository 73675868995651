
import { Component, Prop, Vue } from 'vue-property-decorator';
import OnboardPage from '../components/Onboard.vue';
import { StoreModel } from '@/models/store';

@Component({
  components: {
    OnboardPage,
  },
})
export default class OnboardMessaging extends Vue {
  public selectedChannel: string = "";
  public accountID: string = "";
  public accountName: string = "";
  public saveLoading: boolean = false;
  public channels = [
    { name: "WhatsApp", value: "whatsapp" },
    { name: "Telegram", value: "telegram" },
    { name: "Skype", value: "skype" },
    { name: "Microsoft Teams", value: "teams" },
  ];
  
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public loading: boolean = false;
  public error: boolean = false;
  public created() {
    if (!this.store.config.chatChannels) {
      this.store.config.chatChannels = {
        channel: "",
        accountID: "",
        accountName: "",
      };
    }
    this.selectedChannel = this.store.config.chatChannels.channel;
    this.accountID = this.store.config.chatChannels.accountID;
    this.accountName = this.store.config.chatChannels.accountName;
  }
  public async saveChannel() {
    if (
      this.selectedChannel === "" ||
      this.accountID === "" ||
      this.accountName === "" ||
      this.accountName === undefined ||
      this.accountID === undefined ||
      this.selectedChannel === undefined
    ) {
      this.$router.push('/onboard-purpose');
      return;
    }
    const chatChannel = {
      channel: this.selectedChannel,
      accountID: this.accountID,
      accountName: this.accountName,
    };
    this.store.config.chatChannels = chatChannel;
    try {
      this.loading = true;
      await this.store.saveSettings();
      this.$router.push('/onboard-purpose');
    } catch (error: any) {
    }
    this.loading = false;
  }

  public async nextStep() {
    localStorage.setItem('onboard_page', '/onboard-messaging');
    this.saveChannel();
  }
}
