import { get, post } from '@/services/http';
import { SpreadsheetConnectedModel } from '@/models/spreadsheet_connected';
import List from './list';

export class SpreadsheetConnectedList extends List<SpreadsheetConnectedModel> {
  public async fetch() {
    //get list of schedules with type is not null
    const response: any = await get('/schedule', {has_type: true});
    const schedules = response.data;
    this.mapData(schedules);
  }

  public mapData(data: any = []) {
    this.items = [];
    for (const r of data) {
      if (r.type) {
        const spreadsheet = new SpreadsheetConnectedModel();
        spreadsheet.mapData(r);
        this.add(spreadsheet);
      }
    }
  }
}
