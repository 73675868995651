
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import HeaderTitle from '@/components/HeaderTitle.vue';

@Component({
  components: {
    HeaderTitle,
  },
})
export default class StatusPageComponent extends Vue {
  @Prop({ default: false })
  public hiddenHeader!: boolean;
  @Prop({ default: 404 })
  public statusCode!: number|string;
  public url: string = require('@/assets/processing.svg');
  public name: string = 'Processing';

  public async created() {
    switch (this.statusCode) {
      case 404:
      case '404':
        this.url = require('@/assets/404.svg');
        this.name = `${this.$t('status_page.404')}`;
        break;
      case 403:
      case '403':
        this.url = require('@/assets/403.svg');
        this.name = `${this.$t('status_page.403')}`;
        break;
      case 502:
      case '502':
        this.url = require('@/assets/502.svg');
        this.name = `${this.$t('status_page.502')}`;
        break;
      default:
        this.url = require('@/assets/network_error.svg');
        this.name = `${this.$t('status_page.network_error')}`;
        break;
    }
  }

  public goBack() {
    this.$router.go(-1);
  }
}
