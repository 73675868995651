
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { get, post } from '@/services/http';
import { StoreModel } from '@/models/store';
import ActionButton from '@/components/ActionButton.vue';
import Alert from '@/components/Alert.vue';
import GoogleDriveSelector from '@/components/GoogleDriveSelector.vue';
import {displayAlert} from "@/services/alert";

@Component({
  components: {
    ActionButton,
    Alert,
    GoogleDriveSelector,
  },
})
export default class SpreadsheetSettings extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public success: boolean = false;
  public error: boolean = false;
  public loading: boolean = false;
  @Prop({default: null})
  public app!: any;
  @Prop({default: 'bundle_products'})
  public settingResource!: string;
  @Prop({default: 'bundleProductUrl'})
  public settingResourceUrl!: string;
  @Prop({default: 'Bundle products'})
  public settingTitle!: string;
  @Prop({default: 'Sync Data to Assisty'})
  public syncDataButtonTitle!: string;
  public emails: string[] = [];
  public currentValueEmail: string | null = null;
  public saveSpreadsheetResourceSettingsError: boolean = false;
  public saveSpreadsheetResourceSettingsErrorMessage: string = '';
  public saveSpreadsheetResourceSettingsSuccess: boolean = false;
  public saveSpreadsheetResourceSettingsSuccessMessage: string = '';
  public saveSpreadsheetResourceSettingsDialog: boolean = false;
  public saveSpreadsheetResourceSettingsLoading: boolean = false;
  public spreadsheetSettingsDialog: boolean = false;
  public copyLoading: boolean = false;
  public extractSpreadsheetSettingsLoading: boolean = false;

  public selectedGoogleAccount: string = '';
  public selectedDriveFolder: string = '';

  public async created() {
    this.loading = false;
    this.success = true;
  }

  public async saveSpreadsheetResourceSettings() {
    // validate
    let payload = {};
    if (this.store.config[this.settingResourceUrl]) {
      if (this.currentValueEmail) {
        this.emails.push(this.currentValueEmail);
      }
      if (this.emails.length === 0) {
        this.saveSpreadsheetResourceSettingsError = true;
        this.saveSpreadsheetResourceSettingsErrorMessage = `Please enter your email!`;
        return;
      }
      for (const email of this.emails) {
        if (!this.validateEmail(email)) {
          this.saveSpreadsheetResourceSettingsError = true;
          this.saveSpreadsheetResourceSettingsErrorMessage = `"${email}" is not valid`;
          return;
        }
      }
      payload = {emails: this.emails};
    } else {
      if (!this.selectedGoogleAccount) {
        this.saveSpreadsheetResourceSettingsError = true;
        this.saveSpreadsheetResourceSettingsErrorMessage = `Please select account and folder`;
        return;
      }
      payload = {
        email: this.selectedGoogleAccount,
        folder: this.selectedDriveFolder,
      };
    }
    this.loading = true;

    const data: any = await post(`/${this.settingResource}/config`, payload);
    this.store.config[this.settingResourceUrl] = data.data;

    this.saveSpreadsheetResourceSettingsError = false;
    this.saveSpreadsheetResourceSettingsErrorMessage = '';
    this.saveSpreadsheetResourceSettingsSuccess = true;
    this.saveSpreadsheetResourceSettingsSuccessMessage = `The exported file will be sent to emails above`;
    this.loading = false;
    await this.sleep(3000);
    this.saveSpreadsheetResourceSettingsSuccess = false;
    this.saveSpreadsheetResourceSettingsSuccessMessage = '';
    this.saveSpreadsheetResourceSettingsDialog = false;
    this.spreadsheetSettingsDialog = false;
  }

  public async copyToClipboard(url: string) {
    try {
        await navigator.clipboard.writeText(url);
    } catch (err) {
      //
    }
    this.copyLoading = true;
    await this.sleep(3000);
    this.copyLoading = false;
  }

  public async extractSpreadsheetSettings() {
    this.extractSpreadsheetSettingsLoading = true;
    const data: any = await post('/etl_data_periods/manual_create', {
      merchantId: this.store.id,
      resourceType: this.settingResource,
      periodType: 'INCREMENTAL',
    });
    displayAlert("It will take a few minutes to update data from the spreadsheets file")
    this.extractSpreadsheetSettingsLoading = false;
  }

  public updateEmails(currentValue: string) {
    this.currentValueEmail = currentValue;
  }

  public updateSelectedGoogleAccount(account: string) {
    this.selectedGoogleAccount = account;
  }

  public updateSelectedDriveFolder(folder: string) {
    this.selectedDriveFolder = folder;
  }

  private validateEmail(email: string) {
    const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return emailRegexp.test(email);
  }

  private sleep(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
}
