import { get } from '@/services/http';
import { ProductSegmentModel } from '@/models/product_segment';
import List from './list';

export class ProductSegmentList extends List<ProductSegmentModel> {
  public async fetch(type: string = '') {
    const res: any = await get(`/segment`);
    const productSegments = res.data || [];
    const items = [];
    for (const s of productSegments) {
      if (type && s.type !== type) {
        continue;
      }
      const segment = new ProductSegmentModel();
      segment.mapData(s);
      items.push(segment);
    }
    this.items = items;
  }
}
