import { get, post } from '@/services/http';
import List from './list';
import { FilterDimensionModel } from '@/models/filter_dimension';
import { DimensionModel } from '@/models/dimension';
import axios from 'axios';

export class FilterDimensionList extends List<FilterDimensionModel> {
  public dimension?: DimensionModel;
  public source?: any;
  public loading: boolean = false;

  public async fetch(params = {}) {
    if (this.dimension) {
      if (!this.source) {
        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
      }
      if (this.loading) {
        this.source.cancel();
        this.loading = false;
        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
      }
      this.loading = true;
      const res: any = await get(`/dimensions/${this.dimension.code}`, params, this.source.token);
      this.items = [];
      for (const value of res.data) {
        const filterDimension: FilterDimensionModel = new FilterDimensionModel();
        filterDimension.mapData(value);
        filterDimension.dimension = this.dimension;
        this.add(filterDimension);
      }
      this.loading = false;
    }
  }
}
