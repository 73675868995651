
import { Component, Vue, Prop } from 'vue-property-decorator';
import ActionButton from '@/components/ActionButton.vue';

@Component({
  components: {
    ActionButton,
  },
})
export default class EmptyStage extends Vue {
  @Prop({ default: 'info' })
  public type!: string;
  public icon: string = 'mdi-chart-box-outline';
  public iconColor: string = 'primary';
  public borderColor: string = '#c4c4c4';
  public backgroundColor: string = '';
  @Prop({ default: '' })
  public title!: string;
  @Prop({ default: '' })
  public content!: string;
  @Prop({ default: true })
  public showAlert!: boolean;
  @Prop({ default: '' })
  public actionTitle!: string;
  @Prop({ default: '' })
  public actionLink!: string;
  @Prop({ default: '' })
  public secondaryActionTitle!: string;
  @Prop({ default: '' })
  public secondaryActionLink!: string;
  @Prop({ default: true })
  public canDimiss!: boolean;
  @Prop({ default: '' })
  public videoGuideLink!: string;
  @Prop({ default: '' })
  public target!: string; 
  public actionClick() {
    this.$emit('actionClick');
  }

  public secondaryActionClick() {
    this.$emit('secondaryActionClick');
  }
}
