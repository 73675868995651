import { get, post, put } from '@/services/http';
import { MeasureModel } from '@/models/measure';
import { DimensionModel } from '@/models/dimension';
import { AnalyticsTypeModel } from '@/models/analytics_type';
import { TagModel } from '@/models/tag';
import { TagList } from '@/collections/tags';
import { FilterColumnModel } from '@/models/filter_column';
import { FilterColumnList } from '@/collections/filter_columns';
import { ParamModel } from '@/models/param';
import { ParamList } from '@/collections/params';
import { CustomReportModel } from './custom_report';
import { MetricModel } from '@/models/metric';
import { MetricList } from '@/collections/metrics';

interface SortOrder {
  tag: any;
}

interface FilterView {
  name?: string;
  filter: FilterColumnList;
  isDefault?: boolean;
}

interface ColumnView {
  name?: string;
  reportColumns: [];
  isDefault?: boolean;
}

interface Option {
  sortBy?: any;
  sortDesc?: any;
  page?: string;
  itemsPerPage?: string;
  multiSort?: boolean;
}

export class TemplateReportModel {
  public id: number;
  public name: string;
  public measure: MeasureModel;
  public dimension: DimensionModel;
  public analyticsType?: AnalyticsTypeModel;
  public tags: TagList;
  public filterTimerange?: string[];
  public sortOrder: SortOrder;
  public image: string;
  public predefinedTimeRange?: string;
  public isLocked?: string;
  public createdAt?: string;
  public updatedAt?: string;
  public instruction?: string;
  public hasChart: boolean = true;
  public filterColumns: FilterColumnList;
  public reportParams: ParamList;
  public pivotOption: string = '';
  public terms: string[] = [];
  public reportColumns: any = [];
  public filterViews: FilterView[] = [];
  public columnViews: ColumnView[] = [];
  public pageOptions: Option = {};
  public calculatedMetrics: MetricList;


  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.measure = new MeasureModel();
    this.dimension = new DimensionModel();
    this.tags = new TagList();
    this.sortOrder = data.sortOrder;
    this.isLocked = data.isLocked;
    this.image = data.image;
    this.hasChart = data.hasChart;
    this.filterColumns = new FilterColumnList();
    this.reportParams = new ParamList();
    this.reportColumns = [];
    this.pivotOption = '';
    this.terms = [];
    this.filterViews = [];
    this.columnViews = [];
    this.hasChart = true;
    this.pageOptions = {};
    this.calculatedMetrics = new MetricList();
  }

  public async fetch() {
    const res: any = await get(`/template_reports/${this.id}`);
    this.mapData(res.data);
  }

  public async duplicate() {
    const customReport: CustomReportModel = new CustomReportModel();
    customReport.measure = this.measure;
    customReport.name = this.name;
    customReport.dimension = this.dimension;
    customReport.analyticsType = this.analyticsType;
    customReport.defaultRange = this.predefinedTimeRange;
    customReport.filterColumns = this.filterColumns;
    customReport.reportParams = this.reportParams;
    customReport.reportColumns = this.reportColumns;
    customReport.pivotOption = this.pivotOption;
    customReport.filterTimerange = this.filterTimerange;
    customReport.columnViews = this.columnViews;
    customReport.filterViews = this.filterViews;
    customReport.hasChart = this.hasChart;
    customReport.pageOptions = this.pageOptions;
    customReport.calculatedMetrics = this.calculatedMetrics;
    await customReport.save();
    return customReport;
  }

  public async updateOptions() {
    const res: any = await put(`/template_reports/${this.id}`, {
      options: {
        filter_views: this.filterViews,
        column_views: this.columnViews,
        page_options: this.pageOptions,
        calculated_metrics: this.calculatedMetrics,
        report_params: this.reportParams.items,
      },
    });
  }

  public async updateReportParams() {
    const res: any = await put(`/template_reports/${this.id}`, {
      options: {
        filter_views: this.filterViews,
        column_views: this.columnViews,
        page_options: this.pageOptions,
        calculated_metrics: this.calculatedMetrics,
        report_params: this.reportParams.items,
      },
    });
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.measure = new MeasureModel({ code: data.measureCode });
    this.dimension = new DimensionModel({ code: data.dimensionCode });
    this.analyticsType = new AnalyticsTypeModel({ code: data.analyticsTypeCode });
    if (data.tagIds) {
      for (const tagId of data.tagIds.split(',')) {
        this.tags.add(new TagModel({ id: Number(tagId) }));
      }
    }
    if (data.startTime && data.endTime) {
      this.filterTimerange = [data.startTime || '', data.endTime || ''];
    }
    if (data.reportColumns) {
      this.reportColumns = data.reportColumns;
    }
    this.sortOrder = data.sortOrder;
    this.predefinedTimeRange = data.predefinedTimeRange;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.isLocked = data.isLocked || 'AVAILABLE';
    this.image = data.image;
    this.instruction = data.instruction;
    this.hasChart = data.hasChart;
    if (data.filterColumn) {
      this.importFilterColumns(data.filterColumn);
    }
    if (data.pivotOption) {
      this.pivotOption = data.pivotOption;
    }
    if (data.terms) {
      this.terms = data.terms.split(',');
    } else {
      this.terms = [];
    }
    if (data.options && data.options.filter_views) {
      this.filterViews = this.prepareFilterViews(data.options.filter_views);
    }
    if (data.options && data.options.column_views) {
      this.columnViews = this.prepareColumnViews(data.options.column_views);
    }
    if (data.options && data.options.page_options) {
      this.pageOptions = data.options.page_options;
    }
    if (data.options && data.options.calculated_metrics) {
      this.importCalculatedMtrics(data.options.calculated_metrics);
    }
    if (data.options && data.options.report_params) {
      this.importReportParams(data.options.report_params);
    }
  }

  public importCalculatedMtrics(data: any = []) {
    if (!data) {
      return;
    }
    if (!Array.isArray(data.items)) {
      return;
    }
    this.calculatedMetrics = new MetricList();
    for (const values of data.items) {
      const metric: MetricModel = new MetricModel();
      metric.mapData(values);
      this.calculatedMetrics.add(metric);
    }
  }

  private prepareFilterViews(filterViews: any) {
    const newFilterViews: FilterView[] = [];
    for (const filter of filterViews) {
      if (filter) {
        newFilterViews.push(filter);
      }
    }
    return newFilterViews;
  }

  private prepareColumnViews(columnViews: any) {
    const newColumnViews: ColumnView[] = [];
    for (const column of columnViews) {
      if (column) {
        newColumnViews.push(column);
      }
    }
    return newColumnViews;
  }

  private importFilterColumns(data: any = []) {
    if (!data) {
      return;
    }
    if (!Array.isArray(data)) {
      return;
    }
    this.filterColumns = new FilterColumnList();
    for (const values of data) {
      const filterColumn: FilterColumnModel = new FilterColumnModel();
      filterColumn.mapData(values);
      this.filterColumns.add(filterColumn);
    }
  }

  private importReportParams(data: any = []) {
    if (!data) {
      return;
    }
    if (!Array.isArray(data)) {
      return;
    }
    this.reportParams = new ParamList();
    for (const values of data) {
      const param: ParamModel = new ParamModel();
      param.mapData(values);
      this.reportParams.add(param);
    }
  }
}
