
import { Component, Watch, Prop } from 'vue-property-decorator';
import Report from '@/components/Report';
import { PivotDataModel } from '@/models/pivot_data';
import ChatWithUs from '@/components/ChatWithUs.vue';
import LoadingSlider from '@/components/LoadingSlider.vue';
import ContentLoadResultMessage from '@/components/ContentLoadResultMessage.vue';
import DrilldownTableReport from '@/components/DrilldownTableReport.vue';
import { DimensionModel } from '@/models/dimension';
import { FilterColumnList } from '@/collections/filter_columns';
import { FilterColumnModel } from '@/models/filter_column';
import { EventHandler } from '@/modules/events';
import { StoreModel } from '@/models/store';
import FeatureLockedNotify from '@/components/FeatureLockedNotify.vue';
import { RuleModel } from '@/models/rule';
import FilterColumn from '@/components/FilterColumn.vue';
import Selector from '@/components/OptionSelector.vue';
import dateFormat from 'dateformat';
import Alert from '@/components/Alert.vue';
import { EventBus } from '@/main';
import { TemplateReportList } from '@/collections/template_reports';
import ActionButton from '@/components/ActionButton.vue';
import DTotal from '@/components/DTotal.vue';
import { MeasureList } from '@/collections/measures';
import ColumnViewListing from '@/components/ColumnViewListing.vue';
import FilterViewListing from '@/components/FilterViewListing.vue';
import GoogleDriveSelector from '@/components/GoogleDriveSelector.vue';
import { ProductSegmentModel } from '@/models/product_segment';
import { MeasureModel } from '@/models/measure';
import { ProductSegmentList } from '@/collections/product_segments';
import ProductSegmentDialog from '@/components/ProductSegmentDialog.vue';
import { ReportModel } from '@/models/report';
import DownloadFullData from '@/components/DownloadFullData.vue';


// Declare global objects
declare const gapi: any;
declare const google: any;

interface Option {
  sortBy?: any;
  sortDesc?: any;
  page?: string;
  itemsPerPage?: string;
  multiSort?: boolean;
}

interface SelectingItem {
  id?: number;
  isSelected?: boolean;
}

@Component({
  components: {
    ChatWithUs,
    LoadingSlider,
    ContentLoadResultMessage,
    DrilldownTableReport,
    FeatureLockedNotify,
    FilterColumn,
    Selector,
    Alert,
    ActionButton,
    DTotal,
    ColumnViewListing,
    FilterViewListing,
    GoogleDriveSelector,
    ProductSegmentDialog,
    DownloadFullData
  },
})
export default class ProductSegmentTable extends Report {
  public get selectedItemCount() {
    let count: number = 0;
    for (const item of this.selectingItems) {
      if (item.isSelected) {
        count++;
      }
    }
    return count;
  }



  public get getTableHeight() {
    return document.documentElement.clientHeight - 200;
  }


  public get dateRangeText() {
    return `${dateFormat(
      this.filterTimerange[0],
      'mmm d, yyyy',
      true,
    )} - ${dateFormat(this.filterTimerange[1], 'mmm d, yyyy', true)}`;
  }

  public get reportHeight() {
    let rowCount = this.report.rows.length;
    if (rowCount > 30) {
      rowCount = 30;
    }
    return rowCount * 60 + 300;
  }
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public typeReport: string = 'TABLE';
  public headers: any = [];
  public rows: any = [];
  public dialogPivot: boolean = false;
  public dialogReportWrongData: boolean = false;
  public wrongDataMessage: string = '';
  public pivotTable: PivotDataModel = new PivotDataModel();
  public pivotTableData: any[] = [];
  public pivotTableHeader: any[] = [];
  @Prop({ default: () => {} })
  public initOptions!: Option;
  public options: Option = {};
  public tableLoading: boolean = false;
  public enablePivot: boolean = false;
  public fisrtTimeInitOption: boolean = false;
  public columnNames: any = {
    productName: 'Item[Item Description]',
  };
  public error: boolean = false;
  @Prop({ default: [] })
  public filterReportColumns!: any;
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;
  public loadingSlides: string[] = [
    'Connecting to analytic services',
    'Fetching data',
    'Building report',
    'Downloading data',
    'Generating datatable',
  ];
  public notEmptyRules: any = [this.isNameExist];
  public needCount: boolean = true;
  public isResetOptions: boolean = false;
  public dialogDrilldown: boolean = false;
  public dialogDrilldownDatatable: boolean = false;
  public drilldownItem: any = {};
  public drilldownDimension: any = '';
  public drilldownFilterColumns: FilterColumnList = new FilterColumnList();
  public selectedFilterColumn: FilterColumnModel = new FilterColumnModel();
  public selectedDrilldownDimension: DimensionModel = new DimensionModel();
  public selectedCellItem: any;
  public rule: RuleModel = new RuleModel({ code: 'tracked_metrics_count' });
  public popularDimensions: any = [];
  public selectingDimensions: any = [];
  public itemFilterPosition: string = '';
  public showItemFilterMenu: boolean = false;
  public menuX: number = 0;
  public menuY: number = 0;
  public headerMenuY: number = 0;
  public headerMenuX: number = 0;
  public showHeaderOptionMenu: boolean = false;
  public selectingHeader: any = {};
  public selectingFilterValue: any = { columnCode: '', item: {} };
  public selectingFilterItem: any = {};
  public operatorButtons: any = [];
  public itemTooltipMenu: boolean = false;
  public selectingDrilldownDashboardAction: any = {};
  public selectingBreakdownTreeItem: any = {};
  @Prop({ default: () => new TemplateReportList() })
  public templateReports!: TemplateReportList;
  public selectedDrilldownReportColumns: any = [];
  public fullscreenTable: boolean = false;
  public datatableClass: string = '';
  public isCopied: boolean = false;
  @Prop({ default: false })
  public canCreateViewForTemplateReport!: boolean;

  public isSelectAll: boolean = false;
  public selectingItems: SelectingItem[] = [];
  @Prop({default: false})
  public isManualSegment!: boolean;
  public newProductSegment: ProductSegmentModel = new ProductSegmentModel();
  public addProductToNewSegmentDialog: boolean = false;
  public createNewSegmentLoading: boolean = false;
  public addRemoveProductLoading: boolean = false;
  public isCreateNewSegment: boolean = false;
  public isAddRemoveProduct: boolean = false;
  public productIds: any = [];
  public removeProductDialog: boolean = false;
  public addProductToExistingSegmentDialog: boolean = false;
  public productSegments: ProductSegmentList = new ProductSegmentList();
  public selectedProductSegmentId: number = 0;
  public selectedProductSegment: ProductSegmentModel = new ProductSegmentModel();
  public isLoadingAllSegment: boolean = false;
  @Prop({default: false})
  public isProcessingData!: boolean;

  public downloadDialog: boolean = false;

  public async created() {
    EventBus.$on('show-download-dialog', async () => {
      this.downloadDialog = true;
    });
    for (const rule of this.store.currentPackage.rules.items) {
      if (rule.code === this.rule.code) {
        this.rule = rule;
        break;
      }
    }
    this.report.typeReport = this.typeReport;
    this.report.isDetail = this.isDetail;
    if (this.measure.dimensions.items.length > 0) {
      this.drilldownDimension = this.measure.dimensions.items[0].code;
    }
    this.report.pivotOption = this.pivotOption;
  }

  public async addProductToExistingSegmentEvent() {
    this.addProductToExistingSegmentDialog = true;
    this.isAddRemoveProduct = false;
    const productIds: any = [];
    for (const item of this.selectingItems) {
      if (item.isSelected) {
        productIds.push(item.id);
      }
    }
    this.productIds = productIds;
    if (this.productSegments.items && this.productSegments.items.length === 0) {
      this.isLoadingAllSegment = true;
      await this.productSegments.fetch('manual');
      this.isLoadingAllSegment = false;
    }
  }

  public async processSaveProductToExistingSegment() {
    if (this.selectedProductSegment && this.selectedProductSegment.id) {
      this.addRemoveProductLoading = true;
      await this.selectedProductSegment.addProducts(this.productIds);
      this.addRemoveProductLoading = false;
      this.isAddRemoveProduct = true;
      this.initSelectingItems();
      this.sendMixPanelEvent('Product segment: add product', {is_exist_segment: true});
    }
  }

  public viewSegment(segmentId: number = 0) {
    this.addProductToNewSegmentDialog = false;
    this.$router.push(`/product-segment/${segmentId}`);
  }

  public saveProductToNewSegmentEvent() {
    const productIds: any = [];
    for (const item of this.selectingItems) {
      if (item.isSelected) {
        productIds.push(item.id);
      }
    }
    this.addProductToNewSegmentDialog = true;
    this.productIds = productIds;
    this.newProductSegment = new ProductSegmentModel();
    this.isCreateNewSegment = false;
    this.isAddRemoveProduct = false;
  }

  public async processSaveProductToNewSegment() {
    this.createNewSegmentLoading = true;
    this.newProductSegment.measure = new MeasureModel({code: 'product_info'});
    this.newProductSegment.dimension = new DimensionModel({code: 'by_product'});
    this.newProductSegment.type = 'manual';
    this.newProductSegment.status = 'active';
    await this.newProductSegment.save();
    this.sendMixPanelEvent('Create Segment', {
      segment_name: this.newProductSegment.name,
      type: 'manual',
    });
    this.isCreateNewSegment = true;
    this.createNewSegmentLoading = false;
    this.addRemoveProductLoading = true;
    await this.newProductSegment.addProducts(this.productIds);
    this.addRemoveProductLoading = false;
    this.isAddRemoveProduct = true;
    this.sendMixPanelEvent('Product segment: add product', {
      is_exist_segment: false,
      segment_name: this.newProductSegment.name,
    });
  }

  public removeProductFromSegmentEvent() {
    const productIds: any = [];
    for (const item of this.selectingItems) {
      if (item.isSelected) {
        productIds.push(item.id);
      }
    }
    this.productIds = productIds;
    this.removeProductDialog = true;
    this.isAddRemoveProduct = false;
  }

  public async processRemoveProductFromSegment() {
    this.addRemoveProductLoading = true;
    await this.productSegment.removeProducts(this.productIds);
    for (const id of this.productIds) {
      let count: number = 0;
      for (const item of this.rows) {
        if (item.product_id === id) {
          this.rows.splice(count, 1);
        }
        count++;
      }
    }
    this.report.total = Number(this.report.total) - this.productIds.length;
    this.sendMixPanelEvent('Remove Product', {
      segment_name: this.productSegment.name,
      count: this.productIds.length,
    });
    this.productIds = [];
    this.initSelectingItems();
    this.addRemoveProductLoading = false;
    this.removeProductDialog = false;

  }

  public initSelectingItems() {
    this.selectingItems = [];
    const count: number = 0;
    for (const item of this.rows) {
      const checkItem = {
        id: item.product_id,
        isSelected: false,
      };
      this.selectingItems.push(checkItem);
    }
  }
  public selectAllItems() {
    let index: number = 0;
    for (const item of this.selectingItems) {
        this.selectingItems[index].isSelected = this.isSelectAll;
        index++;
    }
  }


  public reloadData() {
    EventBus.$emit('reload-report-data');
  }

  public isNoPrintColumn(header: any) {
    if (header.value === 'actions') {
      return true;
    }
    return false;
  }
  public hideContextMenu() {
    this.showItemFilterMenu = false;
    this.showHeaderOptionMenu = false;
  }

  public getCurrentItemFilterValue() {
    const name = this.selectingBreakdownTreeItem.dimension.name;
    const code = this.selectingBreakdownTreeItem.dimension.filterCode;
    const value =
      this.selectingFilterItem[
        this.selectingBreakdownTreeItem.dimension.columnCode
      ];
    const operator = 'eq';
    const dataType = 'text';
    return `${name}__${code}__${operator}__${value}__${dataType}`;
  }


  public addFilter(operator: string) {
    const item = this.selectingFilterValue.item;
    const columnCode = this.selectingFilterValue.columnCode;
    const filter: FilterColumnModel = new FilterColumnModel();
    filter.code = columnCode;
    filter.dataType = 'number';
    filter.name = columnCode;
    filter.operator = operator;
    filter.value = [item[columnCode]];
    EventBus.$emit('add-new-filter', filter);
    this.showItemFilterMenu = false;
  }
  public getItemMenuPosition() {
    const e = window.event;
    if (e === undefined) {
      return;
    }
    if (e && e !== undefined) {
      // @ts-ignore: Unreachable code error
      this.menuX = e.clientX;
      this.headerMenuX = this.menuX;
      // @ts-ignore: Unreachable code error
      this.menuY = e.clientY;
      this.headerMenuY = this.menuY + 20;
    }
  }

  public showFilterOption(columnCode: string, item: any) {
    if (columnCode === 'actions' || columnCode === 'image') {
      this.showItemFilterMenu = false;
      return;
    }
    this.showItemFilterMenu = true;
    this.selectingFilterValue = { columnCode, item };
    this.selectingFilterItem = item;
    this.generateOperatorButton(this.getDataTypeByColumnCode(columnCode));
    this.getItemMenuPosition();
  }

  public headerOptionMenuClick(header: any) {
    if (header.value === 'actions') {
      return;
    }
    this.showHeaderOptionMenu = true;
    this.selectingHeader = header;
    this.getItemMenuPosition();
  }

  public isActiveSort(isDesc: boolean = true) {
    if (
      !this.sortDesc ||
      this.sortDesc.length === 0 ||
      !this.sortBy ||
      this.sortBy.length === 0
    ) {
      return false;
    }
    let isSorted: boolean = false;
    let sortDesc: boolean = false;
    let index: number = 0;
    for (const sortBy of this.sortBy) {
      if (sortBy === this.selectingHeader.value) {
        sortDesc = this.sortDesc[index];
        isSorted = true;
        break;
      }
      index++;
    }
    if (isSorted && sortDesc === isDesc) {
      return true;
    }
    return false;
  }

  public getSortIndex(headerCode: string) {
    if (
      !this.sortDesc ||
      this.sortDesc.length < 2 ||
      !this.sortBy ||
      this.sortBy.length < 2
    ) {
      return 0;
    }
    let index: number = 0;
    for (const sortBy of this.sortBy) {
      if (sortBy === headerCode) {
        return index + 1;
      }
      index++;
    }
    return 0;
  }

  public showSortArrow(headerCode: string, isDesc: boolean = true) {
    if (
      !this.sortDesc ||
      this.sortDesc.length === 0 ||
      !this.sortBy ||
      this.sortBy.length === 0
    ) {
      return false;
    }
    let isSorted: boolean = false;
    let sortDesc: boolean = false;
    let index: number = 0;
    for (const sortBy of this.sortBy) {
      if (sortBy === headerCode) {
        sortDesc = this.sortDesc[index];
        isSorted = true;
        break;
      }
      index++;
    }
    if (isSorted && sortDesc === isDesc) {
      return true;
    }
    return false;
  }

  public applySortColumn(sortDesc: boolean = true) {
    // check if the selecting column is sorted or not
    let isSorted: boolean = false;
    let index: number = 0;
    let isSortDesc: boolean = false;
    if (!this.options.sortBy || this.options.sortBy.length === 0) {
      this.options.sortBy = [];
      this.options.sortDesc = [];
    }
    for (const item of this.options.sortBy) {
      if (item === this.selectingHeader.value) {
        isSorted = true;
        isSortDesc = this.options.sortDesc[index];
        break;
      }
      index++;
    }
    if (isSorted && isSortDesc === sortDesc) {
      // remove sort for this columns
      this.options.sortBy.splice(index, 1);
      this.options.sortDesc.splice(index, 1);
    } else if (isSorted && isSortDesc !== sortDesc) {
      // change direction of the sort for this column
      this.options.sortDesc[index] = sortDesc;
    } else {
      // add new sort for this columns
      this.options.sortBy.push(this.selectingHeader.value);
      this.options.sortDesc.push(sortDesc);
    }
    this.optionChanged();
    this.showHeaderOptionMenu = false;
    this.sendMixPanelEvent('Sort Table Column', {
      columnName: this.selectingHeader.text,
      columnCode: this.selectingHeader.value,
    });
  }

  public async copyCellValue() {
    const item = this.selectingFilterValue.item;
    const columnCode = this.selectingFilterValue.columnCode;
    const value = item[columnCode];
    this.isCopied = true;
    navigator.clipboard.writeText(value);
  }


  public getDataTypeByColumnCode(code: string) {
    for (const column of this.report.columns) {
      if (column.code === code) {
        return column.dataType;
      }
    }
    return '';
  }

  public getSelectingColumnName() {
    const columnCode = this.selectingFilterValue.columnCode;
    return this.getColumnNameByColumnCode(columnCode);
  }

  public getColumnNameByColumnCode(code: string) {
    for (const column of this.report.columns) {
      if (column.code === code) {
        return column.name;
      }
    }
    return '';
  }
  public getDimensionCode(columnCode: string) {
    for (const column of this.report.columns) {
      if (column.code === columnCode) {
        return column.luisMapping;
      }
    }
    return '';
  }

  public generateOperatorButton(dataType: string) {
    const textOperators: any = [
      {
        value: 'eq',
        icon: 'mdi-equal',
      },
      {
        value: 'ne',
        icon: 'mdi-not-equal-variant',
      },
      {
        value: 'contains',
        icon: 'mdi-contain',
      },
    ];

    const numberOperators: any = [
      {
        value: 'eq',
        icon: 'mdi-equal',
      },
      {
        value: 'ne',
        icon: 'mdi-not-equal-variant',
      },
      {
        value: 'gt',
        icon: 'mdi-greater-than',
      },
      {
        value: 'lt',
        icon: 'mdi-less-than',
      },
    ];

    if (dataType === 'text') {
      this.operatorButtons = textOperators;
    } else {
      this.operatorButtons = numberOperators;
    }
  }
  public doNotTranslateRowValue(item: any) {
    return 'notranslate';
  }

  public sendMixPanelEvent(name: string = '', data: any = {}) {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      data.measure = this.measure.code;
      data.dimension = this.dimension.code;
      eventHandler.track(name, data);
    } catch (e) {
      // ignore this
    }
  }

  public viewLink(link: any) {
    if (link) {
      this.$router.push(link);
    } else {
      this.$router.push('/reports');
    }
  }

  public isNameExist(v: any) {
    return !!v || 'Please enter your name!';
  }

  public reload() {
    if (!this.report || !this.report.rows || this.report.rows.length === 0) {
      return;
    }
    const selectedReportColumns = this.report.columns;
    this.headers = [];
    this.rows = [];
    this.headers.push({
      text: 'Select All',
      value: 'actions',
      class: ['table-header', 'non-sortable-header'],
      sortable: false,
      filterable: false,
      cellClass: ['table-item', 'non-sortable-item'],
      description: '',
    });
    this.headers.push({
      text: 'ID',
      value: 'product_id',
      class: 'table-header',
      sortable: false,
      filterable: false,
      cellClass: 'table-item',
      description: 'Product Id',
    });
    for (const c of this.report.columns) {
      if (c.code  === 'product_id') {
        continue;
      }
      this.headers.push({
        text: c.name,
        value: c.code,
        class:
          c.sortable !== false
            ? 'table-header'
            : ['table-header', 'non-sortable-header'],
        sortable: c.sortable !== false ? true : false,
        filterable: c.filterable !== false ? true : false,
        cellClass:
          c.sortable !== false
            ? 'table-item'
            : ['table-item', 'non-sortable-item'],
        description: c.description,
      });
    }

    for (const index in this.report.rows) {
      if (this.report.rows[index]) {
        const res: any = {};
        for (const column of this.report.columns) {
          if (!this.isSelectedColumn(column.code)) {
            continue;
          }
          if (column.values[index]) {
            res[column.code] = column.values[index];
          } else {
            if (
              column.dataType === 'number' ||
              column.dataType === 'decimal' ||
              column.dataType === 'currency' ||
              column.dataType === 'percent'
            ) {
              res[column.code] = '0';
            } else {
              res[column.code] = '-';
            }
          }
          if (
            column.originalValues[index] === null &&
            column.sortable === false
          ) {
            res[column.code] = '';
          }
        }
        this.rows.push(res);
      }
    }
    this.initSelectingItems();
    this.generateReportFilterColumns();
    this.generateReportColumns();
  }

  public isSelectedColumn(code: string) {
    return true;
  }

  public async download(emails: any[], selectedDriveFolder: string) {
    const reportToDownload = new ReportModel();
    reportToDownload.measure = this.measure;
    reportToDownload.dimension = this.dimension;
    reportToDownload.analyticsType = this.analyticsType;
    reportToDownload.filterTimerange = this.filterTimerange;
    reportToDownload.isDetail = this.isDetail;
    reportToDownload.filterDimensions = this.filterDimensions;
    reportToDownload.filterColumns = this.filterColumns;
    reportToDownload.pivotOption = this.pivotOption;
    reportToDownload.reportParams = this.reportParams;
    reportToDownload.comparisonDate = this.comparisonDate;
    reportToDownload.reportColumns = [];
    reportToDownload.downloadFileExt = this.report.downloadFileExt;
    if (this.sortBy || this.report.sortBy) {
      reportToDownload.sortBy = this.sortBy;
      reportToDownload.sortDesc = this.sortDesc;
    }
    await reportToDownload.download(emails, true, selectedDriveFolder);
    this.sendMixPanelEvent('download data', {
      fileFormat: this.report.downloadFileExt,
      fullData: true,
      measure: this.measure.code,
      dimension: this.dimension.code,
    });
  }

  public resetOptions() {
    if (this.isCreated) {
      this.isResetOptions = true;
      this.page = 1;
      this.perPage = this.options.itemsPerPage;
      this.options = {};
      this.options.sortBy = this.sortBy;
      this.options.sortDesc = this.sortDesc;
    }
  }

  public resetSortOptions() {
    if (this.isCreated) {
      this.sortBy = [];
      this.sortDesc = [];
    }
  }

  public generateReportFilterColumns() {
    const reportFilterColumns: any = [];
    if (this.report && this.report.columns && this.report.columns.length > 0) {
      for (const c of this.report.columns) {
        if (c.filterable !== false) {
          reportFilterColumns.push({
            name: c.name,
            code: c.code,
            dataType: c.dataType,
            luisMapping: c.luisMapping,
          });
        }
      }
    }
    if (reportFilterColumns.length > 0) {
      this.$emit('init-report-filter-columns', reportFilterColumns);
    }
  }

  public saveReportHeaders() {
    this.showHeaderOptionMenu = false;
    this.$emit('save-report-headers', this.headers);
  }

  public generateReportColumns() {
    const reportColumns: any = [];
    if (this.report && this.report.columns && this.report.columns.length > 0) {
      for (const c of this.report.columns) {
        reportColumns.push({
          name: c.name,
          code: c.code,
          dataType: c.dataType,
          luisMapping: c.luisMapping,
        });
      }
    }
    if (reportColumns.length > 0) {
      this.$emit('update-report-column', reportColumns);
    }
  }

  public getDownloadExcelFileName() {
    let fileName: string;
    fileName = `${this.measure.name.split(' ').join('_').split('-').join('_')}`;
    fileName += `_group_by_${this.dimension.name
      .split(' ')
      .join('_')
      .split('-')
      .join('_')}`;
    if (this.filterTimerange.length > 1) {
      fileName += `_from_${this.filterTimerange[0]
        .split(' ')
        .join('_')
        .split('-')
        .join('_')}`;
      fileName += `_to_${this.filterTimerange[1]
        .split(' ')
        .join('_')
        .split('-')
        .join('_')}`;
    }
    return fileName;
  }

  public prepareDataExcel() {
    let data: any[];
    data = [];
    for (const row of this.rows) {
      let items: any;
      items = {};
      for (const col of this.headers) {
        if (col.text === 'Actions') {
          continue;
        }
        items[col.text] = row[col.value];
      }
      data.push(items);
    }
    return data;
  }

  public loadSavedReportColumnsFromTemplate() {
    if (this.store.currentPackage.name !== 'Free') {
      return;
    }
    this.selectedDrilldownReportColumns = [];
    for (const report of this.templateReports.items) {
      if (
        this.measure.code === report.measure.code &&
        this.selectedDrilldownDimension.code === report.dimension.code
      ) {
        if (report.reportColumns && report.reportColumns.length > 0) {
          this.selectedDrilldownReportColumns = report.reportColumns;
        }
      }
    }
  }

  public reportWrongData() {
    const reportUrl: string =
      `https://admin.shopify.com/store/${
        this.store.shopName.split('.')[0]
      }/apps/${process.env.VUE_APP_NAME}` + this.$route.fullPath;
    let message: string = 'This report is wrong: ' + reportUrl + '.\n';
    message = message + this.wrongDataMessage;
    this.openChat(message);
    this.dialogReportWrongData = false;
  }
  public openChat(openChatMessage: string) {
    // @ts-ignore: Unreachable code error
    this.$crisp.do('chat:open');
    // @ts-ignore: Unreachable code error
    // this.$crisp.do('message:send', ['text', this.$t(`chat_with_us.${this.defaultMessage}`)]);
    this.$crisp.do('message:send', ['text', openChatMessage]);
  }

  public isOptionsChanged() {
    if (this.page !== this.options.page) {
      return true;
    }
    if (this.perPage !== this.options.itemsPerPage) {
      return true;
    }
    if (
      this.options.sortBy &&
      this.options.sortBy.length &&
      this.sortBy &&
      this.sortBy.length > 0 &&
      this.options.sortBy.length !== this.sortBy.length
    ) {
      return true;
    }
    if (
      this.options.sortDesc &&
      this.options.sortDesc.length > 0 &&
      this.sortDesc &&
      this.sortDesc.length > 0 &&
      this.sortDesc.length !== this.options.sortDesc.length
    ) {
      return true;
    }

    if (
      this.options.sortDesc &&
      this.sortDesc &&
      this.options.sortDesc.length === this.sortDesc.length
    ) {
      for (let index = 0; index < this.options.sortDesc.length; index++) {
        if (this.options.sortDesc[index] !== this.sortDesc[index]) {
          return true;
        }
      }
    }

    if (
      this.options.sortBy &&
      this.sortBy &&
      this.options.sortBy.length === this.sortBy.length
    ) {
      for (let index = 0; index < this.options.sortDesc.length; index++) {
        if (this.options.sortBy[index] !== this.sortBy[index]) {
          return true;
        }
      }
    }
    return false;
  }

  public async optionChanged() {
    this.page = this.options.page;
    this.perPage = this.options.itemsPerPage;
    this.sortBy = this.options.sortBy;
    this.sortDesc = this.options.sortDesc;
    this.$emit('update-report-option', this.options);
    this.tableLoading = true;
    this.rows = [];

    try {
      if (this.isCreated) {
        await this.getData();
      } else {
        await this.getReport();
      }
    } catch (error: any) {
      this.tableLoading = false;
      this.isLoading = false;
      throw error;
    }
    this.tableLoading = false;
  }

  public updateDownloadFileExt(fileExt: string) {
    this.report.downloadFileExt = fileExt;
  }

  @Watch('initOptions', { immediate: true, deep: true })
  private async initOptionsChanged(newVal: any, oldVal: any) {
    if (this.initOptions && this.initOptions.sortBy) {
      this.options = this.initOptions;
      // this.page = this.options.page;
      // this.perPage = this.options.itemsPerPage;
      // this.sortBy = this.options.sortBy;
      // this.sortDesc = this.options.sortDesc;
      this.fisrtTimeInitOption = true;
    }
  }

  @Watch('options', { immediate: true, deep: true })
  private async optionsChanged(newVal: any, oldVal: any) {
    if (this.fisrtTimeInitOption) {
      this.fisrtTimeInitOption = false;
      return;
    }
    if (this.isResetOptions) {
      this.isResetOptions = false;
      return;
    }
    if (this.isOptionsChanged()) {
      this.optionChanged();
    }
  }
}
